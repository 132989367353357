import { authForgotPassword } from '@/auth';
import { LoggedInRedirect } from '@/auth/LoggedInRedirect';
import { Loading } from '@/common';
import logo from '@/images/logo.svg';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

export const ForgotPassword = () => {
  // console.log('RXD:ForgotPassword');
  const dispatch = useDispatch();
  const { isLoading, user, error, isSuccess } = useSelector(
    (state) => state.auth
  );
  const [email, setEmail] = useState('');

  if (user) {
    return <LoggedInRedirect />;
  }

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={12} md={4} lg={3} xl={3}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={0} direction="column" alignItems="center">
              <img alt="Opareta" src={logo} height={150} />
              <Typography variant="h4">Reset Password</Typography>
            </Grid>

            <Divider sx={{ marginY: 5 }} />

            {error && <Alert severity="error">{error}</Alert>}

            {isSuccess && (
              <Alert severity="success">
                Please check your email for instructions
              </Alert>
            )}

            <Box
              component="form"
              sx={{
                display: 'grid',
                '& > :not(style)': { m: 1 },
              }}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(authForgotPassword(email));
              }}
            >
              <TextField
                required
                type="email"
                label="Email Address"
                name="email"
                disabled={isLoading}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              {isLoading ? (
                <Loading />
              ) : (
                <Button variant="contained" type="submit">
                  Search Account
                </Button>
              )}
            </Box>
            <Grid container padding={1} direction="column" alignItems="center">
              <Link to="/" component={RouterLink}>
                Back to login
              </Link>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
