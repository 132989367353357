import React from 'react';
import RingLoader from 'react-spinners/RingLoader';

export const Loading = ({ size, css, wCss }) => {
  wCss ??= {};
  return (
    <div
      className="sweet-loading"
      style={{
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        ...wCss,
      }}
    >
      <RingLoader
        css={css}
        size={size ?? 70}
        color={'#21ce99'}
        loading={true}
      />
    </div>
  );
};

export const GridLoad = ({ size, css }) => {
  return (
    <div
      className="sweet-loading"
      style={{
        padding: 'calc(100vh / 6) 20px 20px 20px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <RingLoader
        css={css}
        size={size ?? 200}
        color={'#21ce99'}
        loading={true}
      />
    </div>
  );
};
