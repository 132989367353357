import { dateFormat, numberFormat, titleCase } from '@/common';

const getDateVerbose = (date) =>
  `${date}${
    date >= 10 && date <= 20
      ? 'th'
      : date % 10 === 1
        ? 'st'
        : date % 10 === 2
          ? 'nd'
          : date % 10 === 3
            ? 'rd'
            : 'th'
  }`;

const getNthVerbose = (nth) =>
  ['', 'first', 'second', 'third', 'fourth', 'last'][+nth];

const getDayVerbose = (day) =>
  [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ][day];

export const getExpenseKinds = (expensedAt) => {
  const now = new Date(expensedAt ?? null);
  const day = now.getDay();
  const dayVerbose = getDayVerbose(day);
  const date = now.getDate();
  const nth = Math.ceil(date / 7);
  const nthVerbose = getNthVerbose(nth);
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  const dateVerbose = getDateVerbose(date);

  const options = [
    ['one-time', 'One Time'],
    [`0 8 * * ${day}`, `Repeats every week on ${dayVerbose}`],
    [
      `0 8 * * ${day}#${nth}`,
      `Repeats every month on ${nthVerbose} ${dayVerbose}`,
    ],
    [`0 8 ${date} * *`, `Repeats every month on ${dateVerbose}`],
  ];

  if (date === 1) {
    options.push([`0 8 1 * *`, `Repeats every month on first day`]);
  } else if (date === lastDay.getDate()) {
    options.push([`0 8 L ? *`, `Repeats every month on last day`]);
  }

  return options;
};

export const getAmount = (expense) => numberFormat(expense?.amount);

export const getKind = (expense) => {
  if (expense.kind === 'one-time') {
    return 'One time';
  }
  const result =
    /^(0 8 \* \* (?<day>[1-7])|0 8 \* \* (?<day2>\d+)#(?<nth>[1-5])|0 8 (?<date>\d+) \* \*|(?<first>0 8 1 \* \*)|(?<last>0 8 L \? \*))$/.exec(
      expense.kind
    );

  if (result?.groups) {
    if (result.groups?.nth) {
      return `Repeats every month on ${getNthVerbose(
        result.groups.nth
      )} ${getDayVerbose(result.groups.day2)}`;
    } else if (result.groups?.day) {
      return `Repeats every week on ${getDayVerbose(result.groups.day)}`;
    } else if (result.groups?.date) {
      return `Repeats every month on ${getDateVerbose(result.groups.date)}`;
    } else if (result.groups?.first) {
      return `Repeats every month on first day`;
    } else if (result.groups?.last) {
      return `Repeats every month on last day`;
    } else {
      return expense.kind;
    }
  } else {
    console.log(`Failed ${expense.kind}`);
  }
};

export const getCreatedAt = (expense) => dateFormat(expense.createdAt);

export const getExpensedAt = (expense) =>
  dateFormat(expense.expensedAt, 'MMM DD, YYYY');

export const getPageTitle = (mode) =>
  `${titleCase(mode.replace('-', ' '))} Expense: `;
