import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';
import { setLoanDuration } from './loanDurationSlice';

const initialState = {
  status: 'editing',
  error: '',
};

const loanDurationFormSlice = createSlice({
  name: 'loanDurationForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const loanDurationSave = (id, data) => (dispatch) => {
  dispatch(setLoading(true));
  const action = id
    ? getApi().patch(`/api/loans/durations/${id}`, data)
    : getApi().post(`/api/loans/durations`, data);

  action
    .then((response) => {
      dispatch(resetState());
      dispatch(setLoanDuration(response.data));
      dispatch(push(`/loans/durations`));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { resetState, setLoading, setError } =
  loanDurationFormSlice.actions;

export const loanDurationFormReducer = loanDurationFormSlice.reducer;
