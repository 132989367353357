import { AppBar, Error, Loading, useInitial } from '@/common';
import { DynamicAutoComplete } from '@/common/DynamicAutoComplete';
import { captureEvent } from '@/firebase';
import { locationsFetch } from '@/location';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fieldAgentAssignSave } from './fieldAgentAssignFormSlice';
import { fieldAgentListFetch } from './fieldAgentListSlice';
import { fieldAgentTerritoryDataFetch } from './fieldAgentTerritoryDataSlice';

export const FieldAgentAssignForm = () => {
  const dispatch = useDispatch();

  const [editingIndex, setEditingIndex] = useState(-1);
  const [fieldAgents, setFieldAgents] = useState([]);
  const [country, setCountry] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [fieldAgentFilter, setFieldAgentFilter] = useState('');
  const [assignments, setAssignments] = useState([]);
  const [comment, setComment] = useState('');
  const [initialFormStatus, setInitialFormStatus] = useState('editing');

  const {
    locations,
    isLoading: locationsIsLoading,
    error: locationsError,
  } = useSelector((state) => state.locations);

  const { error: formError, status: formStatus } = useSelector(
    (state) => state.fieldAgentAssignForm
  );

  const {
    fieldAgentList,
    isLoading: fieldAgentsIsLoading,
    error: fieldAgentsError,
  } = useSelector((state) => state.fieldAgentList);

  const {
    fieldAgentTerritoryData,
    isLoading: fieldAgentTerritoryDataIsLoading,
    error: fieldAgentTerritoryDataError,
  } = useSelector((state) => state.fieldAgentTerritoryData);

  const fieldAgentLookup = useMemo(() => {
    // console.log('RXD:FieldAgentAssignForm: fieldAgentLookup()');
    const map = new Map();
    if (fieldAgentList) {
      for (let fieldAgent of fieldAgentList) {
        const { uuid, name } = fieldAgent;
        map.set(fieldAgent.uuid, { uuid, name });
      }
    }
    if (fieldAgents) {
      for (let fieldAgent of fieldAgents) {
        const { uuid, name } = fieldAgent;
        map.set(fieldAgent.uuid, { uuid, name });
      }
    }
    if (map.size !== fieldAgents.length) {
      const list = Array.from(map.values());
      list.sort((a, b) => a?.name?.localeCompare(b?.name));
      setFieldAgents(list);
    }

    return map;
  }, [fieldAgentList, fieldAgents]);

  useInitial(() => {
    // console.log('RXD:FieldAgentAssignForm: initial');
    loadData();
    return () => {};
  });

  useEffect(() => {
    if (formStatus !== initialFormStatus && formStatus === 'editing') {
      loadData();
    }
    setInitialFormStatus(formStatus);
  }, [dispatch, formStatus, initialFormStatus]);

  const loadData = () => {
    dispatch(
      locationsFetch(new URLSearchParams(`parentId=isEmpty:&pp=1:400&oo=name`))
    );
    const type = import.meta.env.VITE_TERRITORY_LEVEL;
    dispatch(fieldAgentTerritoryDataFetch(type));
  };

  const handleSave = useCallback(
    (e) => {
      // console.log('RXD:FieldAgentAssignForm: handleSave');
      e.preventDefault();
      const flattenAssignments = [];
      assignments.forEach((assignment) => {
        let { id, fieldAgentUuid, locationId } = assignment;
        if (id === '') {
          id = null;
        }
        if (fieldAgentUuid === '') {
          fieldAgentUuid = null;
        }
        if (locationId) {
          flattenAssignments.push({ id, fieldAgentUuid, locationId });
        }
      });
      dispatch(
        fieldAgentAssignSave({
          comment,
          assignments: flattenAssignments,
        })
      );
      captureEvent('ASSIGN_FIELD_AGENT_FORM_SAVE', {});
    },
    [dispatch, assignments, comment]
  );

  useEffect(() => {
    if (
      country == '' &&
      !locationsIsLoading &&
      !locationsError &&
      locations.length > 0
    ) {
      // console.log('RXD:FieldAgentAssignForm: setCountry()', { locations });
      setCountry(locations[0]);
    }
  }, [locations, locationsIsLoading, locationsError, country]);

  useEffect(() => {
    if (
      assignments.length === 0 &&
      !fieldAgentTerritoryDataIsLoading &&
      !fieldAgentTerritoryDataError &&
      fieldAgentTerritoryData
    ) {
      // console.log('RXD:FieldAgentAssignForm: setAssignments()', {
      //   fieldAgentTerritoryData,
      // });
      const notLoaded = []; // field agents not loaded
      const map = new Map(
        fieldAgentTerritoryData.assigned.map((f) => {
          return [f.locationId, f];
        })
      );
      const available = [...fieldAgentTerritoryData.available];
      available.sort((a, b) => {
        return a?.name.localeCompare(b?.name);
      });
      setAssignments(
        available.map(({ id: locationId, name }) => {
          const { id, fieldAgentUuid } = map.has(locationId)
            ? map.get(locationId)
            : { fieldAgentUuid: '', id: '' };
          if (fieldAgentUuid && !fieldAgentLookup.has(fieldAgentUuid)) {
            notLoaded.push(fieldAgentUuid);
          }
          return { locationId, id, name, fieldAgentUuid, fieldAgentName: '' };
        })
      );

      // find fieldAgents not listed
      if (notLoaded.length > 0) {
        dispatch(
          fieldAgentListFetch(
            new URLSearchParams(
              `pp=1:400&e=1&ff=fa.name,fa.uuid&oo=name&fa.uuid=isAnyOf:${notLoaded.join(',')}`
            )
          )
        );
      }
    }
  }, [
    dispatch,
    assignments,
    fieldAgentTerritoryData,
    fieldAgentTerritoryDataIsLoading,
    fieldAgentTerritoryDataError,
  ]);

  useEffect(() => {
    // console.log('RXD:FieldAgentAssignForm: noNames::check!');
    const noNames = [];
    for (let index in assignments) {
      const assignment = assignments[index];

      if (!assignment.fieldAgentName) {
        noNames.push([index, assignment.fieldAgentUuid]);
      }
    }

    if (noNames.length > 0) {
      // console.log('RXD:FieldAgentAssignForm: noNames', { noNames });
      const map = new Map();
      for (let fieldAgent of fieldAgents) {
        map.set(fieldAgent.uuid, fieldAgent.name);
      }
      for (let [index, uuid] of noNames) {
        if (map.has(uuid)) {
          assignments[+index].fieldAgentName = map.get(uuid);
        }
      }
      // console.log('RXD:FieldAgentAssignForm: noNames, setAssignments()', {
      //   assignments,
      // });
      setAssignments(assignments);
    }
  }, [assignments, fieldAgents]);

  const filterFieldAgents = useCallback(
    (value) => {
      if (value) {
        dispatch(
          fieldAgentListFetch(
            new URLSearchParams(
              `pp=1:10&e=1&isActive=true&ff=fa.name,fa.uuid&oo=name&name=contains:${value}`
            )
          )
        );
      }
    },
    [dispatch]
  );

  const totalAssigned = assignments.filter((a) => a.fieldAgentUuid).length;

  // console.log('RXD:FieldAgentAssignForm: render');

  return (
    <>
      <AppBar>Assign Territories: {!country ? '...' : country.name}</AppBar>

      {Boolean(locationsError || fieldAgentTerritoryDataError) ? (
        <Error error={locationsError ?? fieldAgentTerritoryDataError} />
      ) : locationsIsLoading || fieldAgentTerritoryDataIsLoading ? (
        <Loading />
      ) : (
        <Card>
          <CardContent>
            {formError && <Error error={formError} />}
            <Typography sx={{ px: 1 }}>
              Assigned {totalAssigned} of {assignments.length} territories
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Box
              component="form"
              method="POST"
              sx={{ p: 1 }}
              onSubmit={handleSave}
              autoComplete="off"
            >
              <Grid container spacing={1}>
                <Grid item md={7} xs={12}>
                  Location
                  <Input
                    type="text"
                    placeholder="..."
                    fullWidth
                    value={locationFilter}
                    onChange={(e) =>
                      setLocationFilter(e.target.value?.toLocaleLowerCase())
                    }
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  Field Agent
                  <Input
                    type="text"
                    placeholder="..."
                    fullWidth
                    value={fieldAgentFilter}
                    onChange={(e) =>
                      setFieldAgentFilter(e.target.value?.toLocaleLowerCase())
                    }
                  />
                </Grid>
              </Grid>
              {assignments
                .filter((a) =>
                  locationFilter
                    ? a.name.toLocaleLowerCase().indexOf(locationFilter) > -1
                    : true
                )
                .filter((a) =>
                  fieldAgentFilter
                    ? a.fieldAgentName
                        .toLocaleLowerCase()
                        .indexOf(fieldAgentFilter) > -1
                    : true
                )
                .map((assignment, index) => (
                  <Grid
                    container
                    spacing={0}
                    sx={{ pt: 1 }}
                    key={assignment.locationId}
                  >
                    <Grid
                      item
                      md={7}
                      xs={12}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border: 'solid 1px',
                        borderColor: 'grey.500',
                        color: 'grey',
                        borderRadius: 1,
                        pl: 2,
                        pt: 2,
                        pb: 2,
                      }}
                    >
                      {assignment.name}
                    </Grid>
                    <Grid item md={5} xs={12}>
                      {index === editingIndex ? (
                        <DynamicAutoComplete
                          options={fieldAgents}
                          isOptionEqualToValue={(option, value) => {
                            return option?.uuid === value?.uuid;
                          }}
                          getOptionLabel={(option) => {
                            return option?.name ?? '';
                          }}
                          handleSearch={(inputValue) => {
                            const option =
                              fieldAgentLookup.get(assignment.fieldAgentUuid) ??
                              '';
                            if (option?.name !== inputValue) {
                              filterFieldAgents(inputValue);
                            }
                          }}
                          renderOption={(props, fieldAgent) => (
                            <li {...props} key={fieldAgent?.uuid}>
                              {fieldAgent?.name ?? ''}
                            </li>
                          )}
                          onBlur={(e) => setEditingIndex(-1)}
                          onChange={(newValue) => {
                            setAssignments((oldAssignments) => {
                              const newAssignments = [...oldAssignments];
                              for (const newAssignment of newAssignments) {
                                if (
                                  assignment.locationId ===
                                  newAssignment.locationId
                                ) {
                                  newAssignment.fieldAgentUuid = newValue?.uuid;
                                  newAssignment.fieldAgentName = '';
                                  break;
                                }
                              }
                              return newAssignments;
                            });
                          }}
                          required
                          renderInput={(value) => (params) => (
                            <TextField {...params} value={value} />
                          )}
                          isLoading={fieldAgentsIsLoading}
                          error={fieldAgentsError}
                        />
                      ) : (
                        <Box
                          onClick={(e) => setEditingIndex(index)}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: 'solid 1px',
                            borderColor: 'grey.500',
                            borderRadius: 1,
                            cursor: 'pointer',
                            p: 2,
                          }}
                        >
                          {fieldAgentLookup.get(assignment.fieldAgentUuid)
                            ?.name ?? '-'}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                ))}

              <Divider sx={{ my: 2 }} />

              <FormControl fullWidth>
                <TextField
                  id="comment"
                  label="Reassignment Comment"
                  variant="outlined"
                  value={comment}
                  multiline
                  required
                  onChange={(e) => setComment(e.target.value)}
                  rows={3}
                />
              </FormControl>

              {formStatus === 'loading' ? (
                <Loading />
              ) : (
                <Button variant="contained" sx={{ my: 2 }} type="submit">
                  Save
                </Button>
              )}
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
};
