import { agentListFetch } from '@/agent/agentListSlice';
import { AppBar, Error, Loading, makeUnique, useInitial } from '@/common';
import { DynamicAutoComplete } from '@/common/DynamicAutoComplete';
import { fieldAgentListFetch } from '@/fieldAgent/fieldAgentListSlice';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFieldAgents } from './cashCollectionFormSlice';
import { fetchMyFieldAgentProfile } from './cashTransactionFormSlice';
import { cashTransactionSave } from './cashTransactionFormSlice';
import { captureEvent } from '@/firebase';

const calcLabel = (fieldAgent) =>
  fieldAgent?.uuid ? `${fieldAgent?.phoneNumber}: ${fieldAgent?.name}` : '';

const calcAgentLabel = (agent) =>
  agent?.uuid
    ? `${agent?.simNumbers[0]?.phoneNumber}: ${agent?.agentName}`
    : '';

export const IssueCashForm = () => {
  // console.log('RXD:IssueCashForm');
  const dispatch = useDispatch();

  // console.log('RXD:IssueCashForm', { fieldAgentUuid, agentUuid, uuid });

  const {
    agentList,
    isLoading: agentListIsLoading,
    error: agentListError,
  } = useSelector((state) => state.agentList);

  const {
    fieldAgentList,
    isLoading: fieldAgentListIsLoading,
    error: fieldAgentListError,
  } = useSelector((state) => state.fieldAgentList);

  const {
    error: formError,
    status,
    meFieldAgent,
  } = useSelector((state) => state.cashTransactionForm);

  const [currency, setCurrency] = useState('');
  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');
  const [agent, setAgent] = useState(null);
  const [fieldAgent, setFieldAgent] = useState(null);

  const currencyCode = import.meta.env?.VITE_CURRENCY_CODE || 'UGX';
  console.log('>>> currency:', import.meta.env?.VITE_CURRENCY_CODE);

  const filterFieldAgents = useCallback(
    (value) => {
      // console.log('RXD:filterFieldAgents', { value, fieldAgent });
      const query = new URLSearchParams(
        'pp=1:6&oo=name&ff=fa.uuid,fa.name,fa.phoneNumber'
      );

      if (value) {
        const isPhoneNumber = /^\d+$/.test(value);
        if (isPhoneNumber) {
          query.delete('name');
          query.set('phoneNumber', `contains:${value}`);
        } else if (value !== calcLabel(fieldAgent)) {
          // console.log({value, calcLabel:calcLabel(fieldAgent)})
          query.set('name', `contains:${value}`);
        }
      }
      dispatch(fieldAgentListFetch(query));
    },
    [dispatch, fieldAgent]
  );

  const filterAgents = useCallback(
    (value) => {
      // console.log('RXD:filterAgents', { value, agent, fieldAgent });
      if (fieldAgent?.uuid) {
        const query = new URLSearchParams(
          'pp=1:6&oo=agentName&ff=a.uuid,a.agentName,s.phoneNumber,fa.name' +
            `&fa.uuid=${fieldAgent.uuid}`
        );

        if (value) {
          const isPhoneNumber = /^\d+$/.test(value);
          if (isPhoneNumber) {
            query.delete('agentName');
            query.set('phoneNumber', `contains:${value}`);
          } else if (value !== calcAgentLabel(agent)) {
            query.set('agentName', `contains:${value}`);
          }
        }
        dispatch(agentListFetch(query));
      }
    },
    [dispatch, agent, fieldAgent]
  );

  // initial data
  useInitial(() => {
    // console.log('RXD:IssueCashForm: initial');
    setCurrency(currencyCode);
    setAmount('');
    setComment('');
    setFieldAgent(null);
    setFieldAgents(null);
    filterFieldAgents();
  });

  useEffect(() => {
    dispatch(fetchMyFieldAgentProfile());
  }, []);

  useEffect(() => {
    // console.log(`Changed?? filterAgents();`)
    setAgent(null);
    filterAgents();
  }, [fieldAgent?.uuid]);

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      currency,
      amount: +amount,
      comment: [comment],
      transactionType: 'CASH_OUT',
      fromAccountId: fieldAgent.uuid,
      toAccountId: agent.uuid,
      fromAccountSignatureUrl: '',
      toAccountSignatureUrl: '',
      extraInfo: {
        source: 'dashboard',
        submittedBy: meFieldAgent,
        agentTdr: agent?.fieldAgent?.name,
      },
      location: { type: 'Point', coordinates: [0, 0] },
      locationAccuracy: null,
    };

    dispatch(cashTransactionSave(data, '/float-cash/cash'));
    captureEvent('ISSUE_CASH_FORM_SAVE', {});
  };

  const w = (callback) => (e) => callback(e.target.value);

  // console.log({fieldAgent, agent, agentList, fieldAgentList,})

  return (
    <>
      <AppBar>Issue Cash to Agent</AppBar>

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {
                <Box
                  component="form"
                  method="POST"
                  sx={{ p: 1 }}
                  onSubmit={handleSave}
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ my: 2 }}>
                        <TextField
                          id="amount"
                          label="Amount"
                          variant="outlined"
                          type="number"
                          required
                          value={amount}
                          onChange={w(setAmount)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {currency}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>

                      <DynamicAutoComplete
                        controlProps={{ sx: { my: 1 } }}
                        options={makeUnique(
                          [
                            ...fieldAgentList,
                            ...(fieldAgent ? [fieldAgent] : []),
                          ],
                          (x) => x?.uuid
                        )}
                        multiple={false}
                        isOptionEqualToValue={(option, value) =>
                          option?.uuid === value?.uuid
                        }
                        getOptionLabel={calcLabel}
                        handleSearch={filterFieldAgents}
                        renderOption={(props, fieldAgent) => (
                          <li {...props} key={fieldAgent?.uuid}>
                            {calcLabel(fieldAgent)}
                          </li>
                        )}
                        value={fieldAgent}
                        onChange={setFieldAgent}
                        isLoading={fieldAgentListIsLoading}
                        error={fieldAgentListError}
                        required
                        renderInput={(value) => (params) => {
                          // console.log('RXD:IssueCashForm:renderInput', { params });
                          return (
                            <TextField
                              {...params}
                              value={value}
                              label="Field Agent"
                            />
                          );
                        }}
                      />

                      <DynamicAutoComplete
                        controlProps={{ sx: { mt: 1 } }}
                        options={makeUnique(
                          [...agentList, ...(agent ? [agent] : [])],
                          (x) => x?.uuid
                        )}
                        multiple={false}
                        isOptionEqualToValue={(option, value) =>
                          option?.uuid === value?.uuid
                        }
                        getOptionLabel={calcAgentLabel}
                        handleSearch={filterAgents}
                        renderOption={(props, agent) => (
                          <li {...props} key={agent?.uuid}>
                            {calcAgentLabel(agent)}
                          </li>
                        )}
                        value={agent}
                        onChange={setAgent}
                        isLoading={agentListIsLoading}
                        error={agentListError}
                        required
                        disabled={!Boolean(fieldAgent?.uuid)}
                        renderInput={(value) => (params) => {
                          // console.log('RXD:IssueCashForm:renderInput', { params });
                          return (
                            <TextField
                              {...params}
                              value={value}
                              label="Agent"
                            />
                          );
                        }}
                      />

                      <FormControl fullWidth sx={{ my: 2 }}>
                        <TextField
                          id="comment"
                          label="Comment"
                          variant="outlined"
                          type="text"
                          required
                          value={comment}
                          rows={3}
                          multiline
                          onChange={w(setComment)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {status === 'loading' ? (
                    <Loading />
                  ) : (
                    <Button variant="contained" sx={{ my: 2 }} type="submit">
                      Save
                    </Button>
                  )}
                </Box>
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
