import { dateFormat } from '@/common';

export const createdAt = (fieldAgent) =>
  dateFormat(fieldAgent?.createdAt, 'MMM DD, YYYY');

export const editCreatedAt = (fieldAgent) =>
  dateFormat(fieldAgent?.createdAt, 'YYYY-MM-DD');

export const birthday = (fieldAgent) =>
  dateFormat(fieldAgent?.birthday, 'MMM DD, YYYY');

export const getLocationLabels = () => [
  ['country', 'Country'],
  ['district', 'District'],
  ['county', 'County'],
  ['subcounty', 'Subcounty'],
  ['parish', 'Parish'],
  ['village', 'Village'],
];

export const getLoggedAt = (log) => dateFormat(log?.loggedAt) ?? '-';

export const getManagerName = (log) => log?.manager.name ?? '-';

export const getFieldAgentName = (log) => log?.fieldAgent.name ?? '-';

export const getLocationLabel = (locations, index) => {
  if (locations.length > 0) {
    const value = locations[0]?.type.replace(/_/g, ' ');
    if (value) {
      return [
        locations[0]?.type,
        value.substring(0, 1).toUpperCase() + value.substring(1),
      ];
    }
  }
  return getLocationLabels()[index];
};

export const makeHierarchy = (fieldAgentPositionList) => {
  if (Array.isArray(fieldAgentPositionList)) {
    let rootId = null;
    const list = [...fieldAgentPositionList].sort((a, b) => a.id - b.id);

    const map = new Map();
    for (const f of list) {
      map.set(f.id, { ...f, children: [] });
      if (!f.parentId) {
        rootId = f.id;
      }
    }

    if (rootId !== null) {
      const root = map.get(rootId);
      root.children = [];
      for (const f of list) {
        map.get(f.parentId)?.children.push(map.get(f.id));
      }
      return [root, map];
    }
  }
  return [null, null];
};

export const limitHierarchyToMe = (child) => {
  const children = [child];
  if (Array.isArray(children)) {
    let stack = [];
    let currentChildren = children;
    let childIndex = 0;
    let stackIndex = 0;
    while (true) {
      if (stackIndex > 1000 || childIndex > 1000) {
        break;
      }
      if (childIndex < currentChildren.length) {
        const child = currentChildren[childIndex];
        if (child) {
          stack.push(child);
        }
      }
      childIndex += 1;
      if (childIndex >= currentChildren.length) {
        if (stackIndex < stack.length) {
          const item = stack[stackIndex];
          stackIndex += 1;
          currentChildren = item.children ?? [];
          childIndex = 0;
        } else {
          break;
        }
      }
    }
    for (const item of stack) {
      if (item.canAddChild) {
        return item;
      }
    }
  }
  return child;
};

export const getPositionDropdown = (
  shouldLimit,
  list,
  currentId,
  children,
  prefix
) => {
  if (Array.isArray(children)) {
    children.forEach((child) => {
      if (!child) {
        return;
      }
      const iAmRoot = child.canAddChild && !child.parentId;
      if (
        !(shouldLimit && !child.canAddChild) &&
        (child.canEdit || iAmRoot) &&
        child.id !== currentId
      ) {
        list.push({ label: prefix + ' / ' + child.name, value: child });
      }
      getPositionDropdown(
        shouldLimit,
        list,
        currentId,
        child.children,
        prefix + ' / ' + child.name
      );
    });
  }
};

export const isValidUrl = (url) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return urlPattern.test(url);
};

export const getFileExtension = (url) => {
  return url.split(/[#?]/)[0].split('.').pop().trim();
};

export const isPdf = (url) => {
  const fileExtension = getFileExtension(url);
  console.log('>>> Url extension: ', fileExtension, url);
  return fileExtension === 'pdf';
};
