import React from 'react';
import Chip from '@mui/material/Chip';

export const Bubble = ({ label, background, color, size, sx }) => (
  <Chip
    color="primary"
    size={size ?? 'small'}
    label={label}
    sx={{
      background: background,
      color: color ?? 'white',
      fontWeight: 'bold',
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      borderRadius: 4,
      ...sx,
    }}
  />
);

const booleanText = (value) => (Boolean(value) ? 'Yes' : 'No');

const booleanTextColor = (value) => (Boolean(value) ? '#317159' : '#bf1100');

const booleanBackgroundColor = (value) =>
  Boolean(value) ? '#DCF2EA' : '#ffcec9';

export const BooleanBubble = ({ value, size, title }) => (
  <Chip
    size={size ?? 'small'}
    label={booleanText(value)}
    title={title}
    sx={{
      background: booleanBackgroundColor(value),
      color: booleanTextColor(value),
      fontWeight: 'bold',
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      borderRadius: 4,
    }}
  />
);
