import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  customerList: [],
  isLoading: false,
  lastFilter: null,
  error: '',
};

const customerListSlice = createSlice({
  name: 'customerList',
  initialState: initialState,
  reducers: {
    setCustomers: (state, { payload }) => {
      const { lastFilter, customers } = payload;
      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.customerList = customers;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const customersFetchUsingApi = (filters) =>
  getApi().get('/api/customer', { params: filters });

export const customersFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  customersFetchUsingApi(filters)
    .then((response) => {
      dispatch(
        setCustomers({
          lastFilter: filters?.toString(),
          customers: response?.data?.data ?? [],
        })
      );
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { setError, setLoading, setCustomers } = customerListSlice.actions;

export const customerListReducer = customerListSlice.reducer;
