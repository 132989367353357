import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  expense: null,
  isLoading: false,
  lastFilter: null,
  error: '',
};

export const expenseSlice = createSlice({
  name: 'expense',
  initialState: initialState,
  reducers: {
    setExpense: (state, { payload }) => {
      state.expense = payload;
      state.isLoading = false;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const expenseFetchUsingApi = (uuid) =>
  getApi().get(`/api/expenses/${uuid}`);

export const expenseFetch = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  expenseFetchUsingApi(uuid)
    .then((response) => dispatch(setExpense(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setExpense, setError, setLoading } = expenseSlice.actions;

export const expenseReducer = expenseSlice.reducer;
