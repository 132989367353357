import { capitalize } from './common/strings';

export const colors = {
  light_green: '#21ce99',
};
export const themeOptions = {
  palette: {
    background: {
      default: '#F4F6FA',
    },
    common: {
      black: '#141414',
      white: '#ffffff',
    },
    primary: {
      main: colors.light_green,
      light: '#A6EBD6',
      dark: '#1aa57a',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#141414',
      contrastText: '#ffffff',
    },
    error: {
      main: '#D14343',
      light: '#EE9191',
      dark: '#A73636',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#FFB020',
      light: '#ffdfa6',
      dark: '#996a13',
      contrastText: '#ffffff',
    },
    info: {
      main: '#3366FF',
      light: '#85A3FF',
      dark: '#1F3D99',
      contrastText: '#ffffff',
    },
    success: {
      main: '#52BD95',
      light: '#97D7BF',
      dark: '#429777',
      contrastText: '#ffffff',
    },
    grey: {
      50: '#FAFBFF',
      100: '#F4F5F9',
      200: '#edeff5',
      300: '#E6E8F0',
      400: '#d8dae5',
      500: '#c1c4d6',
      600: '#8f95b2',
      700: '#696f8c',
      800: '#474d66',
      900: '#101840',
    },
    contrastThreshold: 3,
    divider: '#E7EBF0',
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    text: {
      primary: '#1A2027',
      secondary: '#3E5060',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      '"Readex Pro"',
      '"Helvetica Neue"',
      'Ridley',
      'Gilroy',
      'Arial',
      'sans-serif',
    ].join(','),
    htmlFontSize: 16,
    fontSize: 14,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightSemiBold: 600,
    h1: {
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      fontSize: 'clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)',
      fontWeight: 700,
      lineHeight: 1.1142857142857143,
      color: '#0A1929',
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    h2: {
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
      fontWeight: 700,
      lineHeight: 1.2222222222222223,
      color: '#132F4C',
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    h3: {
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      fontSize: '2.25rem',
      lineHeight: 1.2222222222222223,
      letterSpacing: 0.2,
      fontWeight: 400,
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    h4: {
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      fontSize: '1.75rem',
      lineHeight: 1.5,
      letterSpacing: 0.2,
      fontWeight: 400,
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    h5: {
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      fontSize: '1.5rem',
      lineHeight: 1.5,
      letterSpacing: 0.1,
      fontWeight: 400,
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    h6: {
      fontSize: '1.25rem',
      lineHeight: 1.5,
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      fontWeight: 500,
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    button: {
      textTransform: 'initial',
      fontWeight: 700,
      letterSpacing: 0,
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      fontSize: '1rem',
      lineHeight: 1.75,
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    subtitle1: {
      fontSize: '1.125rem',
      lineHeight: 1.3333333333333333,
      letterSpacing: 0,
      fontWeight: 500,
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
      letterSpacing: 0,
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      fontWeight: 500,
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
      letterSpacing: 0,
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      fontWeight: 400,
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    caption: {
      display: 'inline-block',
      fontSize: '0.75rem',
      lineHeight: 1.5,
      letterSpacing: 0,
      fontWeight: 700,
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    allVariants: {
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    subtitle2: {
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
    overline: {
      fontFamily: "'Ridley', 'Helvetica', sans-serif",
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      textTransform: 'uppercase',
      scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    },
  },
  shadows: {
    0: 'none',
    1: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    2: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    3: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    4: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    5: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    6: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    7: '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    8: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    9: '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    10: '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    11: '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    12: '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    13: '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    14: '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    15: '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    16: '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    17: '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    18: '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    19: '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    20: '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    21: '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    22: '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    23: '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    24: '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  },
  shape: {
    borderRadius: 4,
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            fontSize: '0.9rem',
            fontWeight: 600,
            textTransform: 'none',
            letterSpacing: 0,
            margin: 2,
          },
        },
        {
          props: { variant: 'outlined', disableElevation: true },
          style: {
            fontSize: '0.875rem',
            fontWeight: 600,
            textTransform: 'none',
            // color: 'black',
            letterSpacing: 0,
            border: '2px solid',
            margin: 2,
            '&:hover': {
              borderWidth: '2px',
            },
          },
        },
        {
          props: { variant: 'rounded' },
          style: {
            borderRadius: 50,
            fontSize: '0.875rem',
            fontWeight: 500,
            textTransform: 'none',
            margin: 2,
            backgroundColor: colors.light_green,
            color: 'white',
          },
        },
      ],
    },
    MuiButtonGroup: {
      styleOverrides: {
        textTransform: capitalize,
        fontWeight: 500,
        letterSpacing: 0,
        fontFamily: "'Ridley', 'Helvetica', sans-serif",
        fontSize: '0.875rem',
        lineHeight: 1.5,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
      styleOverrides: {
        textTransform: 'capitalize',
        fontWeight: 500,
        letterSpacing: 0,
        fontFamily: "'Ridley', 'Helvetica', sans-serif",
        fontSize: '0.875rem',
        lineHeight: 1.25,
        scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: '70px',
          fontWeight: 600,
          margin: 2,
          borderRadius: '4px',
        },
        indicator: {
          indicator: 'primary.dark',
          height: 5,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '70px',
          // minWidth: '160px',
          fontWeight: 600,
          fontSize: '0.875rem',
          color: 'text.primary',
          selected: {
            color: 'black',
            fontWeight: 600,
          },
        },
        '&.Mui-selected': {
          color: 'black',
          fontWeight: 600,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {},
    },
    MuiCardHeader: {
      variants: [
        {
          props: {
            variant: 'header',
            styleOverrides: {
              textTransform: 'capitalize',
              fontWeight: 600,
              letterSpacing: 0,
              fontFamily: "'Ridley', 'Helvetica', sans-serif",
              fontSize: '0.9rem',
              lineHeight: 1.5,
            },
          },
        },
      ],
      styleOverrides: {
        textTransform: 'capitalize',
        fontWeight: 600,
        letterSpacing: 0,
        fontFamily: "'Ridley', 'Helvetica', sans-serif",
        fontSize: '0.9rem',
        lineHeight: 1.5,
      },
    },
  },
};
