import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  fieldAgentTerritoryData: null,
  isLoading: false,
  error: '',
};

const fieldAgentTerritoryDataSlice = createSlice({
  name: 'fieldAgentTerritoryData',
  initialState: initialState,
  reducers: {
    setFieldAgentTerritoryData: (state, { payload }) => {
      state.fieldAgentTerritoryData = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.fieldAgentTerritoryData = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const fieldAgentTerritoryDataFetch = (type) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/field-agents/territories/${type}/form`)
    .then((response) => dispatch(setFieldAgentTerritoryData(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setFieldAgentTerritoryData, resetState, setLoading, setError } =
  fieldAgentTerritoryDataSlice.actions;

export const fieldAgentTerritoryDataReducer =
  fieldAgentTerritoryDataSlice.reducer;
