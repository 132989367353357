import { Navigate, useLocation } from 'react-router-dom';
import { defaultLoginPath } from '@/auth/helpers';

export const LoggedInRedirect = ({ defaultTo }) => {
  const location = useLocation();
  // console.log('RXD:LoggedInRedirect location.search', location.search);

  const [referrer, search] = getReferrer(location.search);
  const to = defaultTo ?? `${referrer}${search}`;
  // console.log('RXD:LoggedInRedirect referrer', referrer);
  // console.log('RXD:LoggedInRedirect search', search);
  // console.log('RXD:LoggedInRedirect to', to);
  return <Navigate to={to} />;
};

const getReferrer = (search) => {
  const result = new URLSearchParams(search);
  if (result.has('login-next')) {
    const path = decodeURIComponent(result.get('login-next'));
    result.delete('login-next');
    return [path, `?${result.toString()}`];
  }
  return [defaultLoginPath(), `?${search}`];
};
