import {
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import { paginationValue } from '@/common/filter';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fieldAgentTerritoriesFetch } from './fieldAgentTerritoriesSlice';

export const FieldAgentTerritoryList = ({ embedded }) => {
  // console.log('RXD:FieldAgentTerritoryList');
  const dispatch = useDispatch();
  const { fieldAgentTerritories, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.fieldAgentTerritories;
    }
  );

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(
      filters,
      embedded,
      'fieldAgentUuid'
    );
    // if (embedded) {
    //   currentFilters.set('includePath', true);
    // }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(fieldAgentTerritoriesFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      sortingOrder: getSortOrder(),
    },
  ];
  return (
    <>
      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(fieldAgentTerritoriesFetch(filters))}
        />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(fieldAgentTerritory) => fieldAgentTerritory.id}
          rows={fieldAgentTerritories ?? []}
          columns={columns}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('FieldAgentTerritoryList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility(
                'FieldAgentTerritoryList',
                {}
              ),
            },
          }}
        />
      </div>
    </>
  );
};
