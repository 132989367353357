import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  parser: null,
  isLoading: false,
  error: '',
};

const parserSlice = createSlice({
  name: 'parser',
  initialState: initialState,
  reducers: {
    setParser: (state, { payload }) => {
      state.parser = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.parser = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const parserFetch = (id) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/ussd/parsers/${id}`)
    .then((response) => dispatch(setParser(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setParser, resetState, setLoading, setError } =
  parserSlice.actions;

export const parserReducer = parserSlice.reducer;
