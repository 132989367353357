import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { debounce } from '@/common';
import { useEffect, useRef, useState } from 'react';
import { Loading } from '.';
import { FormControl, FormHelperText } from '@mui/material';

export const DynamicAutoComplete = ({
  handleSearch,
  getOptionLabel,
  isOptionEqualToValue,
  isLoading,
  controlProps,
  error,
  options,
  filterOptions,
  value,
  renderInput,
  renderOption,
  renderTags,
  onChange,
  onBlur,
  multiple,
  helperText,
  required,
  disabled,
}) => {
  const prevInputValue = useRef('');
  const [inputValue, setInputValue] = useState('');
  controlProps = typeof controlProps === 'object' ? controlProps : {};

  useEffect(() => {
    if (handleSearch && inputValue && prevInputValue.current !== inputValue) {
      const doSearch = debounce((value) => handleSearch(value), 500);
      prevInputValue.current = inputValue;
      doSearch(inputValue);
    }
  }, [inputValue, handleSearch]);

  return (
    <Box sx={{ position: 'relative' }}>
      <FormControl fullWidth error={Boolean(error)} {...controlProps}>
        <Autocomplete
          autoComplete
          includeInputInList
          filterSelectedOptions
          getOptionLabel={getOptionLabel}
          filterOptions={filterOptions}
          options={options}
          multiple={multiple}
          required={required}
          value={value}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(_, value) => onChange(value)}
          onBlur={onBlur}
          renderOption={renderOption}
          disabled={disabled}
          renderTags={renderTags}
          renderInput={renderInput(inputValue)}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
        />
        {(Boolean(error) || Boolean(helperText)) && (
          <FormHelperText>{error ? error : helperText}</FormHelperText>
        )}
      </FormControl>
      <Box sx={{ position: 'absolute', right: '-10px', top: '-8px' }}>
        {isLoading && <Loading size={25} css="margin-top:20px;flex-grow:0;" />}
      </Box>
    </Box>
  );
};
