import { getApi, getApiError } from '@/common';
import { createSlice } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';

const initialState = {
  status: 'editing',
  error: '',
};

const fieldAgentReassignFormSlice = createSlice({
  name: 'fieldAgentReassignForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const fieldAgentReassignSave = (uuid, newUuid, data) => (dispatch) => {
  dispatch(setLoading(true));

  getApi()
    .post(`/api/field-agents/${uuid}/transfer/${newUuid}`, data)
    .then((response) => {
      dispatch(resetState());
      dispatch(push(`/field-agents/${uuid}`));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { resetState, setLoading, setError } =
  fieldAgentReassignFormSlice.actions;

export const fieldAgentReassignFormReducer =
  fieldAgentReassignFormSlice.reducer;
