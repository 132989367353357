import { AppBar, Info, Loading } from '@/common';
import { getRoleDefinition } from '@/user/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

export const Profile = () => {
  const { user, isLoading } = useSelector((state) => state.auth);
  const roles = (user?.role ?? '').split(',');
  roles.sort();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <AppBar>My Profile: {user?.email ?? '-'}</AppBar>

      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <Typography variant="h5" sx={{ p: 2 }}>
                My Roles
              </Typography>
              <Divider />
              {roles.map((role) => (
                <Accordion key={role}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{role}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      {getRoleDefinition(role).map((description, index) => (
                        <li key={`${role}-${index}`}>{description}</li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
            <Grid item md={8} xs={12}>
              <Info border={true} sx={{ my: 1 }} label="Email">
                {user?.email ?? '-'}
              </Info>
              <Info border={true} sx={{ my: 1 }} label="Phone number">
                {user?.phoneNumber ?? '-'}
              </Info>
              <Info border={true} sx={{ my: 1 }} label="External ID">
                {user?.externalId ?? '-'}
              </Info>
              <Info border={true} sx={{ my: 1 }} label="Created At">
                {user?.createdAt}
              </Info>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
