import { authLogin } from '@/auth';
import { LoggedInRedirect } from '@/auth/LoggedInRedirect';
import { Loading } from '@/common';
import { GridLoad } from '@/common/Loading';
import logo from '@/images/logo.svg';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

export const Login = () => {
  // console.log('RXD:Login');
  const dispatch = useDispatch();
  const { isInitializing, isLoading, user, error } = useSelector(
    (state) => state.auth
  );
  const fetchNewVersion = useRef(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const location = useLocation();
  // console.log('RXD:Login', { location });

  useEffect(() => {
    if (fetchNewVersion.current === 0) {
      // console.log('RXD:Login: Refresh app assests: attempt');
      fetchNewVersion.current = 1;
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then((registrationsArray) => {
            if (registrationsArray.length > 0) {
              registrationsArray.forEach((registration) => {
                registration.update();
                // console.log('RXD:Login: Refresh app assests: completed');
              });
            }
          })
          .catch((error) =>
            console.log('Login: Refresh app assests: failed', error)
          );
      }
    }
  }, [fetchNewVersion]);

  if (user) {
    // console.log('RXD:Login: LoggedInRedirect');
    return <LoggedInRedirect />;
  }

  // console.log('RXD:Login: rendered');
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={12} md={4} lg={3} xl={3}>
        {isInitializing ? (
          <>
            <Grid container spacing={0} direction="column" alignItems="center">
              <img alt="Opareta" src={logo} height={150} />
              <Typography variant="subtitle2">
                Loading application state . . .
              </Typography>
              <GridLoad />
            </Grid>
          </>
        ) : (
          <Card variant="outlined">
            <CardContent>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
              >
                <img alt="Opareta" src={logo} height={150} />
                <Typography variant="h4">Log in</Typography>
                <Typography variant="body1">To access the dashboard</Typography>
              </Grid>

              <Divider sx={{ marginY: 5 }} />

              {error ? (
                <Alert severity="error" sx={{ marginBottom: 2 }}>
                  {error}
                </Alert>
              ) : null}

              <Box
                component="form"
                sx={{
                  display: 'grid',
                  '& > :not(style)': { m: 1 },
                }}
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  dispatch(authLogin(email, password));
                }}
              >
                <TextField
                  required
                  type="email"
                  label="Email Address"
                  name="email"
                  disabled={isLoading}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                  required
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  name="password"
                  disabled={isLoading}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          data-testid="eye-icon"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {isLoading ? (
                  <Loading />
                ) : (
                  <Button variant="contained" type="submit">
                    Log in
                  </Button>
                )}
              </Box>
              <Grid
                container
                padding={1}
                direction="column"
                alignItems="center"
              >
                <Link to="/forgot-password" component={RouterLink}>
                  Forgot your password?
                </Link>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};
