import { getApi, getApiError } from '@/common';
import { notificationListFetch } from '@/notification/notificationListSlice';
import { createSlice } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';

const initialState = {
  status: 'editing',
  error: '',
};

const notificationFormSlice = createSlice({
  name: 'notificationForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const notificationSave = (uuid, data) => (dispatch) => {
  dispatch(setLoading());

  const action = !!uuid
    ? getApi().patch(`/api/notifications/${uuid}`, data)
    : getApi().post(`/api/notifications`, data);

  action
    .then((response) => {
      dispatch(resetState());
      dispatch(push(`/notifications`));
      dispatch(
        notificationListFetch(new URLSearchParams('pp=1:20&oo=parameter'))
      );
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { resetState, setLoading, setError } =
  notificationFormSlice.actions;

export const notificationFormReducer = notificationFormSlice.reducer;
