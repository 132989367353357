import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError, numberFormat, updateInList } from '@/common';

const initialState = {
  varianceList: [],
  isLoading: false,
  lastFilter: null,
  error: '',
};

export const varianceListSlice = createSlice({
  name: 'varianceList',
  initialState: initialState,
  reducers: {
    setVarianceList: (state, { payload }) => {
      const { lastFilter, varianceList } = payload;

      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.varianceList = varianceList;
    },
    updateVarianceList: (state, { payload }) => {
      const { rows, key } = payload;
      state.varianceList = updateInList(rows, state.varianceList, key);
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const varianceListFetchUsingApi = (filters) =>
  getApi().get('/api/float-cash/reconciliation/variance', { params: filters });

export const varianceListFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  varianceListFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setVarianceList({
          lastFilter: filters?.toString(),
          varianceList: response.data?.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};
export const { setError, setLoading, setVarianceList, updateVarianceList } =
  varianceListSlice.actions;

export const varianceListReducer = varianceListSlice.reducer;
