import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';
import { setLoanProduct } from './loanProductSlice';

const initialState = {
  status: 'editing',
  error: '',
};

const loanProductFormSlice = createSlice({
  name: 'loanProductForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const loanProductSave = (uuid, data) => (dispatch) => {
  dispatch(setLoading(true));
  const action = uuid
    ? getApi().patch(`/api/loans/products/${uuid}`, data)
    : getApi().post(`/api/loans/products`, data);

  action
    .then((response) => {
      dispatch(resetState());
      dispatch(setLoanProduct(response.data));
      dispatch(push(`/loans/products`));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { resetState, setLoading, setError } =
  loanProductFormSlice.actions;

export const loanProductFormReducer = loanProductFormSlice.reducer;
