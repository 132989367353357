import { AppBar, Error, Loading } from '@/common';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loanContractListFetch } from './loanContractListSlice';
import { loanProductSave, resetState } from './loanProductFormSlice';
import { loanProductFetch } from './loanProductSlice';
import { captureEvent } from '@/firebase';

export const LoanProductForm = () => {
  // console.log('RXD:LoanProductForm');
  const dispatch = useDispatch();
  const { uuid } = useParams();

  const { loanProduct, isLoading, error } = useSelector(
    (state) => state.loanProduct
  );

  const { loanContractList } = useSelector((state) => state.loanContractList);

  const { error: formError, status } = useSelector(
    (state) => state.loanProductForm
  );

  const [name, setName] = useState('');
  const [interestType, setInterestType] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [interestRateIntervalUnit, setInterestRateIntervalUnit] = useState('');
  const [paymentInterval, setPaymentInterval] = useState('');
  const [paymentIntervalUnit, setPaymentIntervalUnit] = useState('');
  const [principalHoliday, setPrincipalHoliday] = useState('');
  const [principalHolidayUnit, setPrincipalHolidayUnit] = useState('');
  const [contractUuid, setContractUuid] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    dispatch(loanContractListFetch());
  }, [dispatch]);

  useEffect(() => {
    if (uuid && uuid !== loanProduct?.uuid && !isLoading && !error) {
      dispatch(resetState());
      setPrincipalHolidayUnit('');
      setContractUuid('');
      setName('');
      setInterestType('');
      setInterestRate('');
      setInterestRateIntervalUnit('');
      setPaymentInterval('');
      setPaymentIntervalUnit('');
      setPrincipalHoliday('');
      setDescription('');
      dispatch(loanProductFetch(uuid));
    }
  }, [dispatch, loanProduct, uuid, isLoading, error]);

  // initial values
  useEffect(() => {
    if (uuid === loanProduct?.uuid) {
      setName(loanProduct?.name ?? '');
      setInterestType(loanProduct?.interestType ?? '');
      setInterestRate(loanProduct?.interestRate ?? '');
      setInterestRateIntervalUnit(loanProduct?.interestRateIntervalUnit ?? '');
      setPaymentInterval(loanProduct?.paymentInterval ?? '');
      setPaymentIntervalUnit(loanProduct?.paymentIntervalUnit ?? '');
      setPrincipalHoliday(loanProduct?.principalHoliday ?? '');
      setPrincipalHolidayUnit(loanProduct?.principalHolidayUnit ?? '');
      setContractUuid(loanProduct?.contractUuid ?? '');
      setDescription(loanProduct?.description ?? '');
    }
  }, [loanProduct, uuid]);

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      uuid: uuid,
      name,
      paymentInterval,
      interestType,
      interestRate,
      interestRateIntervalUnit,
      paymentIntervalUnit,
      principalHoliday,
      principalHolidayUnit,
      contract: { uuid: contractUuid },
      description,
    };
    dispatch(loanProductSave(uuid, data));
    captureEvent(
      uuid ? 'EDIT_LOAN_PRODUCT_FORM_SAVE' : 'ADD_LOAN_PRODUCT_FORM_SAVE'
    );
  };

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <>
      <AppBar>
        {uuid
          ? `Edit Loan Product: ${loanProduct?.name ?? uuid}`
          : 'Add Loan Product'}
      </AppBar>

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {error ? (
                <Error
                  error={error}
                  onRetry={() => dispatch(loanProductFetch(uuid))}
                />
              ) : isLoading ? (
                <Loading />
              ) : (
                <Box
                  component="form"
                  method="POST"
                  sx={{ p: 1 }}
                  onSubmit={handleSave}
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <TextField
                          id="name"
                          label="Product Name"
                          variant="outlined"
                          required
                          value={name}
                          onChange={w(setName)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="contractUuid">Loan Contract</InputLabel>
                        <Select
                          id="contractUuid"
                          label="Loan Contract"
                          variant="outlined"
                          value={contractUuid}
                          onChange={w(setContractUuid)}
                        >
                          {loanContractList &&
                            loanContractList?.map((contract) => (
                              <MenuItem
                                key={contract.uuid}
                                value={contract.uuid}
                              >
                                {contract.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="interestType">Interest Type</InputLabel>
                        <Select
                          id="interestType"
                          label="Interest Type"
                          variant="outlined"
                          value={interestType}
                          onChange={w(setInterestType)}
                          required
                        >
                          <MenuItem value="simple">Simple</MenuItem>
                          <MenuItem value="amortized">Amortized</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container>
                        <Grid item xs={7}>
                          <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                              id="interestRate"
                              label="Interest Rate"
                              variant="outlined"
                              type="number"
                              required
                              value={interestRate}
                              onChange={w(setInterestRate)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="interestRateIntervalUnit">
                              Unit
                            </InputLabel>
                            <Select
                              id="interestRateIntervalUnit"
                              label="Interest Type"
                              variant="outlined"
                              value={interestRateIntervalUnit}
                              onChange={w(setInterestRateIntervalUnit)}
                              required
                            >
                              <MenuItem value="week">week</MenuItem>
                              <MenuItem value="month">month</MenuItem>
                              <MenuItem value="year">year</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Grid container>
                        <Grid item xs={7}>
                          <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                              id="paymentInterval"
                              label="Payment Interval"
                              variant="outlined"
                              type="number"
                              required
                              value={paymentInterval}
                              onChange={w(setPaymentInterval)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="paymentIntervalUnit">
                              Unit
                            </InputLabel>
                            <Select
                              id="paymentIntervalUnit"
                              label="Interest Type"
                              variant="outlined"
                              value={paymentIntervalUnit}
                              onChange={w(setPaymentIntervalUnit)}
                              required
                            >
                              <MenuItem value="week">week</MenuItem>
                              <MenuItem value="month">month</MenuItem>
                              <MenuItem value="year">year</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container>
                        <Grid item xs={7}>
                          <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                              id="principalHoliday"
                              label="Principal Holiday"
                              variant="outlined"
                              type="number"
                              required
                              value={principalHoliday}
                              onChange={w(setPrincipalHoliday)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="principalHolidayUnit">
                              Unit
                            </InputLabel>
                            <Select
                              id="principalHolidayUnit"
                              label="Interest Type"
                              variant="outlined"
                              value={principalHolidayUnit}
                              onChange={w(setPrincipalHolidayUnit)}
                              required
                            >
                              <MenuItem value="day">day</MenuItem>
                              <MenuItem value="week">week</MenuItem>
                              <MenuItem value="month">month</MenuItem>
                              <MenuItem value="year">year</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      label="Description"
                      id="description"
                      variant="outlined"
                      multiline
                      rows="2"
                      required
                      value={description}
                      onChange={w(setDescription)}
                    />
                  </FormControl>

                  {status === 'loading' ? (
                    <Loading />
                  ) : (
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{ mb: 2, textTransform: 'none' }}
                      type="submit"
                    >
                      Save
                    </Button>
                  )}
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
