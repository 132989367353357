import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  fieldAgentManagerLogList: [],
  isLoading: false,
  lastFilter: null,
  error: '',
};

export const fieldAgentManagerLogListSlice = createSlice({
  name: 'fieldAgentManagerLogList',
  initialState: initialState,
  reducers: {
    setFieldAgents: (state, { payload }) => {
      const { lastFilter, fieldAgents } = payload;

      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.fieldAgentManagerLogList = fieldAgents;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const fieldAgentManagerLogListFetchUsingApi = (filters) =>
  getApi().get('/api/field-agents/logs', { params: filters });

export const fieldAgentManagerLogListFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  fieldAgentManagerLogListFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setFieldAgents({
          lastFilter: filters?.toString(),
          fieldAgents: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setFieldAgents, setError, setLoading } =
  fieldAgentManagerLogListSlice.actions;

export const fieldAgentManagerLogListReducer =
  fieldAgentManagerLogListSlice.reducer;
