import { useDispatch, useSelector } from 'react-redux';
import {
  getCreatedAt,
  getPaymentStatusesForSelect,
  getVarianceAgents,
  getVarianceAmount,
  getVarianceAmountPaid,
  getVarianceComments,
  getVarianceFieldAgentName,
  getVariancePaymentStatus,
  getVarianceTransactionType,
  getVarianceTypes,
} from '@/float-cash/util';
import {
  AppBar,
  BooleanBubble,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import { useEffect } from 'react';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { varianceListFetch } from '@/float-cash/varianceListSlice';
import { hasAnyRoles } from '@/auth/helpers';
import Button from '@mui/material/Button';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { getRequestStatuses } from '@/loan/utils';

export const VarianceList = ({ embedded }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { varianceList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.varianceList;
    }
  );
  const w = (callback) => (param) => callback(param.row);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, ['fa.uuid']);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-createdAt');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(varianceListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'createdAt',
      headerName: 'DateTime',
      type: 'dateTime',
      valueGetter: w(getCreatedAt),
      minWidth: 200,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'fieldAgent.name',
      headerName: 'Field Agent',
      minWidth: 200,
      valueGetter: w(getVarianceFieldAgentName),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'agents',
      headerName: 'Agents',
      minWidth: 200,
      valueGetter: w(getVarianceAgents),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'varianceType',
      headerName: 'Credit/Debit',
      sortingOrder: getSortOrder(),
      minWidth: 200,
      type: 'singleSelect',
      valueOptions: getVarianceTypes().map((type) => ({
        value: type,
        label: type,
      })),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      minWidth: 200,
    },
    {
      field: 'amountPaid',
      headerName: 'Amount Paid',
      type: 'number',
      minWidth: 200,
    },
    {
      field: 'comments',
      headerName: 'Comments',
      minWidth: 200,
      valueGetter: w(getVarianceComments),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'paymentStatus',
      headerName: 'Paid',
      minWidth: 200,
      type: 'singleSelect',
      valueOptions: getPaymentStatusesForSelect().map((type) => ({
        label: type[0],
        value: type[1],
      })),
    },
  ];
  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            hasAnyRoles(
              user.role,
              'CASH_COLLECTOR,FLOAT_DISTRIBUTOR,RECONCILIATION_ADMIN'
            ) ? (
              <>
                <Button
                  disableElevation
                  variant="contained"
                  component={RouterLink}
                  to="/float-cash/variances/add"
                >
                  Add Variance
                </Button>
              </>
            ) : null
          }
        >
          Variance
        </AppBar>
      )}

      <div>
        <OparetaTable
          getRowId={(transaction) => transaction.uuid}
          rows={varianceList ?? []}
          columns={columns}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('VarianceList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
          }}
          onCellClick={(param, e) => {
            const transaction = param.row;
            if (e.ctrlKey) {
              window.open(`/float-cash/variances/${transaction.uuid}`);
            } else {
              // dispatch(setFloatRequest(transaction));
              navigate(`/float-cash/variances/${transaction.uuid}`);
            }
          }}
        />
      </div>
    </>
  );
};
