import { createSlice } from '@reduxjs/toolkit';
import { getApiError } from '@/common';
import {
  apiAddExchangeRate,
  apiFetchRateHistory,
  apiGetCurrentExchangeRate,
} from '@/api/api-util';

const initialState = {
  isLoading: false,
  isLoadingCurrentExchangeRate: false,
  lastFilter: null,
  currentExchangeRate: null,
  rateHistory: [],
  error: '',
};
export const exchangeRateSettingsSlice = createSlice({
  initialState: initialState,
  name: 'exchangeRateSettings',
  reducers: {
    setLoading: (state, { payload }) => {
      state.error = '';
      state.isLoading = payload;
    },
    setLoadingCurrentExchangeRate: (state, { payload }) => {
      state.isLoadingCurrentExchangeRate = payload;
    },
    setRateHistory: (state, { payload }) => {
      const { lastFilter, rateHistory } = payload;
      state.lastFilter = lastFilter;
      state.rateHistory = rateHistory;
      state.isLoading = false;
    },
    setCurrentExchangeRate: (state, { payload }) => {
      state.currentExchangeRate = payload;
      state.isLoadingCurrentExchangeRate = false;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const currentRateFetch = (filters) => (dispatch) => {
  dispatch(setLoadingCurrentExchangeRate(true));
  return apiGetCurrentExchangeRate(filters)
    .then((response) => {
      dispatch(setCurrentExchangeRate(response.data));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};
export const fetchExchangeRateHistory = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  apiFetchRateHistory(filters)
    .then((response) =>
      dispatch(
        setRateHistory({
          lastFilter: filters?.toString(),
          rateHistory: response.data?.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const exchangeRateAdd = (data, filters) => (dispatch) => {
  dispatch(setLoadingCurrentExchangeRate(true));
  apiAddExchangeRate(data).then((res) => {
    dispatch(fetchExchangeRateHistory());
    dispatch(currentRateFetch(filters));
  });
};

export const {
  setLoading,
  setLoadingCurrentExchangeRate,
  setCurrentExchangeRate,
  setRateHistory,
  setError,
} = exchangeRateSettingsSlice.actions;

export const exchangeRateSettingsReducer = exchangeRateSettingsSlice.reducer;
