import {
  AppBar,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { loanRuleListFetch } from './loanRuleListSlice';
import { setLoanRule } from './loanRuleSlice';
import {
  getRuleFirstAfter,
  getRuleFirstBefore,
  getRuleLastAfter,
  getRuleLastBefore,
} from './utils';

export const LoanRuleList = ({ embedded }) => {
  // console.log('RXD:LoanRuleList');
  const dispatch = useDispatch();
  const { loanRuleList, isLoading, lastFilter, error } = useSelector(
    (state) => {
      return state.loanRuleList;
    }
  );

  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, []);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-preference');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      // console.log('RXD:TransactionList.fetching');
      dispatch(loanRuleListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 160,
      flex: 1,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'product',
      headerName: 'Product',
      headerAlign: 'center',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => params.row?.product?.name,
    },
    {
      field: 'preference',
      headerName: 'Preference',
      minWidth: 100,
      flex: 1,
      type: 'number',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'minimumPastLoanCount',
      headerName: 'Minimum Loans',
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'maximumPastLoanCount',
      headerName: 'Maximum Loans',
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'segmentations',
      headerName: 'Segmentations',
      align: 'center',
      headerAlign: 'center',
      minWidth: 200,
      flex: 2,
    },
    {
      field: 'firstLoanBefore',
      headerName: '1st Loan Start After',
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      minWidth: 100,
      flex: 1,
      valueGetter: w(getRuleFirstAfter),
    },
    {
      field: 'firstLoanAfter',
      headerName: '1st Loan Start Before',
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      minWidth: 100,
      flex: 1,
      valueGetter: w(getRuleFirstBefore),
    },
    {
      field: 'lastLoanBefore',
      headerName: 'Last Loan Start After',
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      minWidth: 100,
      flex: 1,
      valueGetter: w(getRuleLastAfter),
    },
    {
      field: 'lastLoanAfter',
      headerName: 'Last Loan Start Before',
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      minWidth: 100,
      flex: 1,
      valueGetter: w(getRuleLastBefore),
    },
  ];

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            <Button
              disableElevation
              sx={{ textTransform: 'none' }}
              variant="contained"
              border="2px solid"
              size="medium"
              component={RouterLink}
              to={`/loans/rules/add`}
            >
              Create Loan Rule
            </Button>
          }
        >
          Loan Rules
        </AppBar>
      )}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(loanRuleListFetch(filters))}
        />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(loan) => loan.uuid}
          rows={loanRuleList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const loanRule = param.row;
            const url = `/loans/rules/${loanRule.uuid}/edit`;
            if (e.ctrlKey) {
              window.open(url);
            } else {
              dispatch(setLoanRule(loanRule.loan));
              navigate(url);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('LoanRuleList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('LoanRuleList', {}),
            },
          }}
        />
      </div>
    </>
  );
};
