export const getExchangeRateFixed = (rate) => {
  if (typeof rate === 'number') {
    return rate.toFixed(2);
  } else if (typeof rate === 'string' && !isNaN(parseFloat(rate))) {
    return parseFloat(rate).toFixed(2);
  } else {
    return rate;
  }
};

export const getExchangeRate = (rate) => {
  return `1 ${rate.baseCurrency} = ${Number(rate.rate).toFixed(2)} ${rate.targetCurrency}`;
};
