import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  loanRuleList: [],
  isLoading: false,
  lastFilter: null,
  error: '',
};

const loanRuleListSlice = createSlice({
  name: 'loanRuleList',
  initialState: initialState,
  reducers: {
    setLoanRules: (state, { payload }) => {
      const { lastFilter, loanRuleList } = payload;

      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.loanRuleList = loanRuleList;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const loanRuleListFetchUsingApi = (filters) =>
  getApi().get('/api/loans/rules', { params: filters });

export const loanRuleListFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  loanRuleListFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setLoanRules({
          lastFilter: filters?.toString(),
          loanRuleList: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setError, setLoading, setLoanRules } = loanRuleListSlice.actions;

export const loanRuleListReducer = loanRuleListSlice.reducer;
