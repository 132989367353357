import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Box, Button } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Error,
  getColumnVisibility,
  useFilters,
  dateFormat,
  setColumnVisibility,
  getSortOrder,
} from '@/common';

import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { commissionListFetch } from './CommissionListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PayoutStructure, Type, indicators } from './utils';
import { getStatus } from '@/float-cash/util';

const getCommissionStatuses = () => ['amount per unit', 'tier', '% of revenue'];

const columns = [
  {
    field: 'updatedAt',
    headerName: 'Last Updated At',
    minWidth: 200,
    type: 'dateTime',
    sortingOrder: getSortOrder(),
    valueGetter: (params) => dateFormat(params.row.updatedAt, 'DD MMM, YYYY'),
  },
  {
    field: 'position',
    headerName: 'Recipients',
    sortingOrder: getSortOrder(),
    minWidth: 200,
  },
  {
    field: 'indicator',
    headerName: 'Indicator',
    sortingOrder: getSortOrder(),
    minWidth: 200,
    type: 'singleSelect',
    valueOptions: indicators.map(({ name, value }) => ({
      value: value,
      label: name,
    })),
  },
  {
    field: 'payoutStructure',
    headerName: 'Payout Structure',
    sortingOrder: getSortOrder(),
    minWidth: 200,
    type: 'singleSelect',
    valueOptions: getCommissionStatuses().map((status) => ({
      value: status,
      label: getStatus(status),
    })),
  },
  {
    field: 'parameters.start',
    headerName: 'Parameters',
    filterable: false,
    flex: 1,
    valueGetter: (params) => {
      const currencyCode = import.meta.env?.VITE_CURRENCY_CODE || 'UGX';

      const { parameters, payoutStructure } = params.row;

      if (payoutStructure === PayoutStructure.revenuePercent) {
        return `${parameters[0].value}%`;
      }

      if (payoutStructure === PayoutStructure.unitAmount) {
        return `${currencyCode} ${parameters[0].value}`;
      }

      if (payoutStructure === PayoutStructure.tier) {
        let string = '';
        parameters.forEach(({ start, end, type, value, percent }, index) => {
          if (type === Type.percentage) {
            string += `${end ? `${start} to ${end}` : `${start}+`}: ${value}%, `;
          } else if (type === Type.amount) {
            string += `${end ? `${start} to ${end}` : `${start}+`}: ${currencyCode} ${value}, `;
          } else if (type === Type.combined) {
            string += `${end ? `${start} to ${end}` : `${start}+`}: ${currencyCode} Amount: ${value} and Percent:${percent}%, `;
          }
        });

        return string.slice(0, -2);
      }
      return '-';
    },
  },
  {
    field: 'validFrom',
    headerName: 'Valid From',
    type: 'dateTime',
    minWidth: 200,
    sortingOrder: getSortOrder(),
    valueGetter: (params) => dateFormat(params.row.validFrom, 'DD MMM, YYYY'),
  },

  {
    field: 'validTo',
    headerName: 'Valid To',
    type: 'dateTime',
    minWidth: 200,
    sortingOrder: getSortOrder(),
    valueGetter: (params) => {
      const { validTo } = params.row;
      if (validTo) {
        return dateFormat(validTo, 'DD MMM, YYYY');
      }
      return '-';
    },
  },
  {
    headerName: 'Active',
    minWidth: 150,
    filterable: false,
    valueGetter: (params) => {
      const { validFrom, validTo } = params.row;
      dayjs.extend(isSameOrBefore);
      dayjs.extend(isSameOrAfter);

      const currentMoment = dayjs();
      const validFromMoment = dayjs(validFrom);

      if (!validTo) {
        return validFromMoment.isSameOrBefore(currentMoment, 'day')
          ? 'Active'
          : 'InActive';
      } else {
        const validToMoment = dayjs(validTo);
        return currentMoment.isSameOrAfter(validFromMoment, 'day') &&
          currentMoment.isSameOrBefore(validToMoment, 'day')
          ? 'Active'
          : 'InActive';
      }
    },
  },
  {
    field: 'floor',
    headerName: 'Floor',
    minWidth: 150,
    filterable: false,
    valueGetter: (params) => {
      const { floor } = params.row;

      if (floor === 0 || floor === null) {
        return '-';
      }
      return floor;
    },
  },
  {
    field: 'ceiling',
    headerName: 'Ceiling',
    filterable: false,
    minWidth: 150,
    valueGetter: (params) => {
      const { ceiling } = params.row;

      if (ceiling === 0 || ceiling === null) {
        return '-';
      }
      return ceiling;
    },
  },
];

export const CommissionList = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { commissionList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.commissionList;
    }
  );

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 50);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters);
    dispatch(commissionListFetch(currentFilters));
  }, []);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-updatedAt');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(commissionListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error]);

  return (
    <>
      <AppBar
        actions={
          <>
            <Button
              variant="contained"
              component={RouterLink}
              to={`/field-agents/commissions/add`}
            >
              Add Commission
            </Button>
          </>
        }
      >
        Team members: commission
      </AppBar>
      {error && (
        <Error error={error} onRetry={() => dispatch(commissionListFetch())} />
      )}
      <Box
        sx={{
          height: 'calc(100vh - 190px)',
          minHeight: 300,
        }}
      >
        <OparetaTable
          getRowId={(log) => log.uuid}
          rows={commissionList ?? []}
          columns={columns}
          onCellClick={(param) => {
            const commission = param.row;
            navigate(`/field-agents/commissions/${commission.uuid}/edit`);
          }}
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('CommissionsList', visible)
          }
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('CommissionsList', {
                recipient: false,
                position: false,
                indicator: true,
                validTo: false,
                payoutStructure: true,
              }),
            },
          }}
        />
      </Box>
    </>
  );
};
