import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  transaction: null,
  repayments: [],
  isLoading: false,
  isSubmitting: false,
  isLoadingBanks: false,
  attachmentUrl: null,
  isUploadingFiles: false,
  attachmentUrls: [],
  banks: [],
  error: '',
};

const varianceTransactionDetailsSlice = createSlice({
  name: 'varianceTransaction',
  initialState: initialState,
  reducers: {
    setVarianceTransaction: (state, { payload }) => {
      state.transaction = payload;
      state.isLoading = false;
    },
    setRepayments: (state, { payload }) => {
      state.repayments = payload;
      state.isLoading = false;
    },
    setBanks: (state, { payload }) => {
      const { banks } = payload;
      state.isLoadingBanks = false;
      state.banks = banks;
    },
    resetState: (state) => {
      state.transaction = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state, { payload }) => {
      state.error = '';
      state.isLoading = payload;
    },
    setLoadingBanks: (state, { payload }) => {
      state.error = '';
      state.isLoadingBanks = payload;
    },
    setUploadFile: (state, { payload }) => {
      let url = payload.url;
      console.log('>>> Setting uploading file: ', url);
      state.attachmentUrls.push(url);
      state.isUploadingFiles = false;
    },
    removeUploadFile: (state) => {
      console.log('>>> Removing uploaded file');
      state.attachmentUrl = null;
    },
    setUploadingFiles: (state, { payload }) => {
      state.error = '';
      state.isUploadingFiles = payload;
    },
    setSubmitting: (state, { payload }) => {
      console.log('>>> Setting submitting: ', payload);
      state.error = '';
      state.isSubmitting = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
    clearState: (state) => {
      state.isLoading = false;
      state.isLoadingBanks = false;
      state.isSubmitting = false;
      state.attachmentUrl = null;
      state.isUploadingFiles = false;
      state.banks = [];
      state.error = '';
      console.log('>>> State cleared');
    },
  },
});

export const varianceTransactionFetch = (uuid) => (dispatch) => {
  console.log('>>> Getting transaction: ', uuid);
  dispatch(setLoading(true));
  getApi()
    .get(`/api/float-cash/reconciliation/variance/${uuid}`)
    .then((response) => dispatch(setVarianceTransaction(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const fetchBanksFromNetwork = (filters) =>
  getApi().get('/api/float-cash/accounts', { params: filters });

export const uploadAttachmentsFile = (file) => (dispatch) => {
  if (!file) {
    dispatch(setUploadingFiles(false));
    return;
  }
  dispatch(setUploadingFiles(true));
  getApi()
    .post('/api/storage/upload', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      dispatch(setUploadFile(res.data));
    })
    .catch((err) => dispatch(setError(getApiError(err))))
    .finally(() => dispatch(setUploadingFiles(false)));
};

export const editTransaction = (uuid, payload) => (dispatch) => {
  console.log('>>> Editing transaction: ', uuid);
  dispatch(setSubmitting(true));
  getApi()
    .post(`/api/float-cash/reconciliation/variance/${uuid}/payment`, payload)
    .then(() => {
      dispatch(setSubmitting(false));
      dispatch(varianceTransactionFetch(uuid));
      clearState();
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const fetchRepayments = (uuid) => (dispatch) => {
  getApi()
    .get(
      `/api/float-cash/transaction?pp=1%3A20&isVariance=false&groupUuid=${uuid}`
    )
    .then((response) => dispatch(setRepayments(response.data?.data || [])))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const fetchBanks = (filters) => (dispatch) => {
  dispatch(setLoadingBanks(true));
  fetchBanksFromNetwork(filters)
    .then((response) =>
      dispatch(
        setBanks({
          banks: response.data?.data || [],
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const {
  setVarianceTransaction,
  setRepayments,
  resetState,
  setLoading,
  setSubmitting,
  setError,
  setLoadingBanks,
  setBanks,
  clearState,
  setUploadingFiles,
  removeUploadFile,
  setUploadFile,
} = varianceTransactionDetailsSlice.actions;

export const varianceTransactionReducer =
  varianceTransactionDetailsSlice.reducer;
