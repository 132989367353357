export const FORMAT = 'MMM DD, YYYY h:mm:ss A';

export const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 128, height: 150, mask: true },
};

export const IS_MULTI_PHONE = /^((\+\d+)(\s*,\s*|))+$/;

export const IS_MULTI_UUID =
  /^((\w{8,8}-\w{4,4}-\w{4,4}-\w{4,4}-\w{12,12})(\s*,\s*|))+$/;
