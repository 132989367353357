import { getApi, getApiError } from '@/common';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'editing',
  totalUpdated: 0,
  error: '',
};

const floatCashReviewSlice = createSlice({
  name: 'floatCashReview',
  initialState: initialState,
  reducers: {
    setResult: (state, { payload }) => {
      state.status = 'editing';
      state.error = '';
      state.totalUpdated = payload;
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
      state.totalUpdated = 0;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const floatCashReviewSave = (data) => (dispatch) => {
  dispatch(setLoading());

  getApi()
    .patch(`/api/float-cash/request/bulk/review`, data)
    .then((response) => {
      dispatch(setResult(response.data?.totalUpdated ?? 0));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const resetState = () => (dispatch) => dispatch(setResult([]));

export const { setResult, setLoading, setError } = floatCashReviewSlice.actions;

export const floatCashReviewReducer = floatCashReviewSlice.reducer;
