import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const sampleNotifications = [
  {
    type: 'firebase',
    title: 'Second title',
    message: 'Test message 2',
    status: 'pending',
    isRead: false,
    createdAt: '2023-12-04 10:17:59.197669',
  },
  {
    type: 'firebase',
    title: 'First title',
    message: 'Test message 1',
    status: 'pending',
    isRead: true,
    createdAt: '2023-11-04 08:17:59.197669',
  },
];

const initialState = {
  pushNotificationList: [],
  isLoading: false,
  lastFilter: null,
  error: '',
};

const pushNotificationListSlice = createSlice({
  name: 'pushNotificationList',
  initialState: initialState,
  reducers: {
    setPushNotifications: (state, { payload }) => {
      const { lastFilter, pushNotifications } = payload;
      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.pushNotificationList = pushNotifications;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const pushNotificationsFetchUsingApi = (filters) =>
  getApi().get('/api/notifications/me', { params: filters });

export const markPushNotificationRead = (uuid, filters) => (dispatch) => {
  getApi()
    .patch(`/api/notifications/me/${uuid}`, { isRead: true })
    .then((response) => {
      console.log('>>> Read response: ', response);
      pushNotificationsFetchUsingApi(filters)
        .then((response) => {
          dispatch(
            setPushNotifications({
              lastFilter: filters?.toString(),
              pushNotifications: response?.data?.data ?? [],
            })
          );
        })
        .catch((err) => {
          dispatch(setError(getApiError(err)));
        });
    });
};

export const pushNotificationsFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  // setPushNotifications({
  //     lastFilter: filters?.toString(),
  //     pushNotifications: sampleNotifications
  // })
  pushNotificationsFetchUsingApi(filters)
    .then((response) => {
      dispatch(
        setPushNotifications({
          lastFilter: filters?.toString(),
          pushNotifications: response?.data?.data ?? [],
        })
      );
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { setError, setLoading, setPushNotifications } =
  pushNotificationListSlice.actions;

export const pushNotificationListReducer = pushNotificationListSlice.reducer;
