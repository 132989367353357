import React from 'react';
import { createRoot } from 'react-dom/client';

import './css/index.css';
import { App } from './App';
import * as I from './instrumentation';
// import { register } from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// Prevent number field scroll
document.addEventListener('wheel', function (event) {
  if (document.activeElement.type === 'number') {
    document.activeElement.blur();
  }
});

// To work offline and load faster, you can change to register() below
// register();
