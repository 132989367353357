import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import {
  loanDurationListFetch,
  setError as setListError,
} from './loanDurationListSlice';

const initialState = {
  loanDuration: null,
  isLoading: false,
  error: '',
};

const loanDurationSlice = createSlice({
  name: 'loanDuration',
  initialState: initialState,
  reducers: {
    setLoanDuration: (state, { payload }) => {
      state.loanDuration = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.loanDuration = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const loanDurationFetch = (id) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/loans/durations/${id}`)
    .then((response) => dispatch(setLoanDuration(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const loanDurationDelete = (id) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .delete(`/api/loans/durations/${id}`)
    .then((response) => dispatch(loanDurationListFetch(response.data)))
    .catch((err) => {
      dispatch(setError(getApiError(err)));
      dispatch(setListError(err.message));
    });
};

export const { setLoanDuration, resetState, setLoading, setError } =
  loanDurationSlice.actions;

export const loanDurationReducer = loanDurationSlice.reducer;
