import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  fieldAgentTerritories: [],
  isLoading: false,
  lastFilter: null,
  error: '',
};

export const fieldAgentTerritoriesSlice = createSlice({
  name: 'fieldAgentTerritories',
  initialState: initialState,
  reducers: {
    setFieldAgentTerritories: (state, { payload }) => {
      const { lastFilter, fieldAgentTerritories } = payload;
      state.fieldAgentTerritories = fieldAgentTerritories;
      state.lastFilter = lastFilter;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.fieldAgentTerritories = [];
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setFilter: (state, { payload }) => {
      state.filter = { ...payload, startAt: 0 };
      state.fieldAgentTerritories = [];
    },
    moveNext: (state, { payload }) => {
      state.filter = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const fieldAgentTerritoriesFetchUsingApi = (filters) =>
  getApi().get('/api/field-agents/territories', { params: filters });

export const fieldAgentTerritoriesFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  fieldAgentTerritoriesFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setFieldAgentTerritories({
          lastFilter: filters?.toString(),
          fieldAgentTerritories: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const {
  moveNext,
  resetState,
  setFieldAgentTerritories,
  setError,
  setLoading,
} = fieldAgentTerritoriesSlice.actions;

export const fieldAgentTerritoriesReducer = fieldAgentTerritoriesSlice.reducer;
