import { Typography } from '@mui/material';
import {
  AppBar,
  debounce,
  Error,
  getColumnVisibility,
  getSortOrder,
  Loading,
  setColumnVisibility,
  useFilters,
  useInitial,
} from '@/common';
import Button from '@mui/material/Button';
import { Fragment, useCallback, useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import {
  agentsSearch,
  clearAddingError,
  dailyReportAdd,
  dailyReportsFetch,
  fetchCurrentExchangeRate,
  fetchSimAccounts,
  setLoadingAgent,
  setLoadingSimAccounts,
} from '@/dailyReport/dailyReportSlice';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  getAccountDisplayName,
  getCdfClosingBalance,
  getCdfOpeningBalance,
  getShopName,
  getUsdClosingBalance,
  getUsdOpeningBalance,
} from '@/dailyReport/utils';
import { useNavigate } from 'react-router-dom';

export const DailyReportList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);
  const [addingDailyReport, setAddingDailyReport] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const {
    dailyReports,
    isLoading,
    agents,
    isLoadingAgent,
    error,
    lastFilter,
    isAdding,
  } = useSelector((state) => state.dailyReportList);

  const w = (callback) => (param) => callback(param.row);
  const columns = [
    {
      field: 'day',
      headerName: 'Day',
      sortingOrder: getSortOrder(),
      type: 'dateTime',
    },
    {
      field: 's.phoneNumber',
      headerName: 'Account',
      type: 'none',
      valueGetter: w(getAccountDisplayName),
    },
    {
      field: 's2a.agent.agentName',
      headerName: 'Shop',
      type: 'text',
      filterable: false,
      valueGetter: w(getShopName),
    },
    {
      field: 'reports.usd.openingBalance',
      headerName: 'USD Opening Balance',
      type: 'number',
      filterable: false,
      valueGetter: w(getUsdOpeningBalance),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'reports.cdf.openingBalance',
      headerName: 'CDF Opening Balance',
      type: 'number',
      filterable: false,
      valueGetter: w(getCdfOpeningBalance),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'reports.usd.closingBalance',
      headerName: 'USD Closing Balance',
      type: 'number',
      filterable: false,
      valueGetter: w(getUsdClosingBalance),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'reports.cdf.closingBalance',
      headerName: 'CDF Closing Balance',
      type: 'number',
      filterable: false,
      valueGetter: w(getCdfClosingBalance),
      sortingOrder: getSortOrder(),
    },
  ];

  useEffect(() => {
    if (isAdding) {
      setRefreshList(true);
    } else if (refreshList) {
      setRefreshList(false);
      const currentFilters = getCurrentFilters(filters);
      dispatch(dailyReportsFetch(currentFilters));
    }
  }, [isAdding, addingDailyReport]);

  useEffect(() => {
    fetchData();
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error]);

  const fetchData = () => {
    const currentFilters = getCurrentFilters(filters);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-day');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(dailyReportsFetch(currentFilters));
      setFilters(currentFilters);
    }
  };
  const addDailyReport = () => {
    setAddingDailyReport(!addingDailyReport);
  };
  return (
    <>
      <AppBar
        actions={
          <Button variant="contained" onClick={() => addDailyReport()}>
            Add Daily Report
          </Button>
        }
      >
        Daily Reports
      </AppBar>
      <DailyReportFormDialog
        addingDailyReport={addingDailyReport}
        setAddingDailyReport={setAddingDailyReport}
        agents={agents}
        isLoadingAgent={isLoadingAgent}
        filters={filters}
      />
      <OparetaTable
        getRowId={(transaction) => transaction.uuid}
        rows={dailyReports ?? []}
        columns={columns}
        onCellClick={(param, e) => {
          const report = param.row;
          if (e.ctrlKey) {
            window.open(
              `/float-cash/daily-reports/details?day=${report?.day}&phoneNumber=${report?.account?.phoneNumber}`
            );
          } else {
            navigate(
              `/float-cash/daily-reports/details?day=${report?.day}&phoneNumber=${report?.account?.phoneNumber}`
            );
          }
        }}
        onColumnVisibilityModelChange={(visible) =>
          setColumnVisibility('DisbursementList', visible)
        }
        loading={isLoading}
        page={serverPage - 1}
        onFilterModelChange={getOnFilterModelChange(
          filters,
          setFilters,
          serverPerPage
        )}
        onSortModelChange={getOnSortModelChange(
          filters,
          setFilters,
          serverPerPage
        )}
        onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
        onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
        initialState={{
          sorting: getInitialSortModel(filters, columns),
          filter: getInitialFilterModel(filters, columns),
          columns: {
            columnVisibilityModel: getColumnVisibility('DisbursementList', {
              currency: false,
            }),
          },
        }}
      />
    </>
  );
};

const DailyReportFormDialog = (props) => {
  const { setAddingDailyReport, addingDailyReport, filters } = props;
  const dispatch = useDispatch();
  const currencyCode = import.meta.env?.VITE_CURRENCY_CODE || 'UGX';
  const {
    agents,
    currentExchangeRate,
    isLoadingAgent,
    isAdding,
    simAccounts,
    isLoadingSimAccounts,
    addingError,
  } = useSelector((state) => state.dailyReportList);
  const [usdOpeningBalance, setUsdOpeningBalance] = useState('');
  const [usdClosingBalance, setUsdClosingBalance] = useState('');
  const [cdfOpeningBalance, setCdfOpeningBalance] = useState('');
  const [cdfClosingBalance, setCdfClosingBalance] = useState('');
  const [simAccount, setSimAccount] = useState(null);
  const [agent, setAgent] = useState(undefined);
  const [agentNameInputValue, setAgentNameInputValue] = useState('');
  const [simAccountNameInputValue, setSimAccountNameInputValue] = useState('');
  const [usdNetCashFlow, setUsdNetCashFlow] = useState(0);
  const [cdfNetCashFlow, setCdfNetCashFlow] = useState(0);

  const handleSearch = useCallback(
    debounce((value) => dispatch(agentsSearch(value)), 1000),
    []
  );
  const handleSimAccountsSearch = useCallback(
    debounce(
      (value) =>
        handleFetchSimAccounts(new URLSearchParams(`agentUuid=${agent?.uuid}`)),
      1000
    ),
    []
  );

  useInitial(() => {
    dispatch(
      fetchCurrentExchangeRate({
        currencyPair: `USD:${currencyCode}`,
      })
    );
  });

  useEffect(() => {
    dispatch(setLoadingAgent(true));
    // noinspection JSCheckFunctionSignatures
    handleSearch(agentNameInputValue);
  }, [dispatch, handleSearch, agentNameInputValue]);

  useEffect(() => {
    if (agent) {
      handleFetchSimAccounts(new URLSearchParams(`agentUuid=${agent.uuid}`));
    }
    setSimAccount(null);
  }, [agent, setAgent]);

  useEffect(() => {
    handleSimAccountsSearch(simAccountNameInputValue);
  }, [dispatch, handleSimAccountsSearch, simAccountNameInputValue]);

  useEffect(() => {
    setUsdNetCashFlow(+usdClosingBalance - +usdOpeningBalance);
  }, [usdOpeningBalance, usdClosingBalance]);

  useEffect(() => {
    setCdfNetCashFlow(+cdfClosingBalance - +cdfOpeningBalance);
  }, [cdfOpeningBalance, cdfClosingBalance]);

  useEffect(() => {
    if (!addingDailyReport) {
      resetForm();
    }
  }, [addingDailyReport]);

  const handleFetchSimAccounts = (filters) => {
    if (agent) {
      dispatch(setLoadingSimAccounts(true));
      return dispatch(fetchSimAccounts(filters));
    }
  };

  const dismissModal = () => {
    setAddingDailyReport(false);
  };

  const handleSimAccountSelect = (simAccount) => setSimAccount(simAccount);
  const handleSave = (event) => {
    event.preventDefault();
    const day = dayjs().format('YYYY-MM-DD');
    const usdAccount = getAgentSimAccountByCurrency('USD');
    const cdfAccount = getAgentSimAccountByCurrency('CDF');
    const usdReport = {
      day,
      currency: 'USD',
      openingBalance: +usdOpeningBalance,
      closingBalance: +usdClosingBalance,
      simNumberUuid: usdAccount?.uuid,
    };
    const cdfReport = {
      day,
      currency: 'CDF',
      openingBalance: +cdfOpeningBalance,
      closingBalance: +cdfClosingBalance,
      simNumberUuid: cdfAccount?.uuid,
    };
    const payload = {
      dailyReports: [usdReport, cdfReport].filter(
        (item) => !!item.simNumberUuid
      ),
    };
    dispatch(dailyReportAdd(payload, filters, () => dismissModal()));
  };

  const getAgentSimAccountByCurrency = (currency) => {
    if (!simAccount?.accounts) return undefined;
    return simAccount.accounts.find((account) => account.currency === currency);
  };

  const resetForm = () => {
    setAgent(null);
    setSimAccount(null);
    setUsdOpeningBalance('');
    setUsdClosingBalance('');
    setCdfOpeningBalance('');
    setCdfClosingBalance('');
    setUsdNetCashFlow(0);
    setCdfNetCashFlow(0);
  };

  const w = (callback) => (e) => callback(e.target.value);
  return (
    <>
      <Dialog open={addingDailyReport} fullWidth={true}>
        <DialogTitle id="daily-report">Add Daily Report</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSave} id="daily-report-form">
            {addingError && (
              <Error
                error={addingError}
                onClose={() => dispatch(clearAddingError())}
              />
            )}
            <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
              <Autocomplete
                getOptionLabel={(agent) =>
                  typeof agent == 'string' ? agent : agent?.agentName
                }
                filterOptions={(x) => x}
                options={agents}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={agent}
                isOptionEqualToValue={(option, value) =>
                  option.uuid === value.uuid
                }
                onChange={(event, newValue) => {
                  setAgent(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setAgentNameInputValue(newInputValue);
                }}
                renderOption={(props, agent) => {
                  return (
                    <li {...props} key={agent.uuid}>
                      {agent.agentName}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Agent/Shop"
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {isLoadingAgent ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 3 }}>
              <Autocomplete
                getOptionLabel={(simAccount) => simAccount?.phoneNumber}
                filterOptions={(x) => x}
                options={simAccounts}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={simAccount}
                isOptionEqualToValue={(option, value) =>
                  option.phoneNumber === value.phoneNumber
                }
                onChange={(event, newValue) => {
                  handleSimAccountSelect(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setSimAccountNameInputValue(newInputValue);
                }}
                renderOption={(props, simAccount) => {
                  return (
                    <li {...props} key={simAccount.uuid}>
                      {simAccount.phoneNumber}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Account"
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {isLoadingSimAccounts ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                id="usdOpeningBalance"
                label="USD opening cash balance"
                variant="outlined"
                type="number"
                required
                value={usdOpeningBalance}
                onChange={(e) => setUsdOpeningBalance(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                id="cdfOpeningBalance"
                label="CDF opening cash balance"
                variant="outlined"
                type="number"
                required
                value={cdfOpeningBalance}
                onChange={(e) => setCdfOpeningBalance(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                id="usdClosingBalance"
                label="USD closing cash balance"
                variant="outlined"
                type="number"
                required
                value={usdClosingBalance}
                onChange={(e) => setUsdClosingBalance(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                id="cdfClosingBalance"
                label="CDF closing cash balance"
                variant="outlined"
                type="number"
                required
                value={cdfClosingBalance}
                onChange={(e) => setCdfClosingBalance(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                id="usdCdfExchangeRate"
                label="USD to CDF exchange rate"
                variant="outlined"
                type="text"
                required
                disabled
                value={currentExchangeRate?.rate}
              />
            </FormControl>
            <Box my={2} display="flex" flexDirection="column">
              <Typography>USD net cash flow: {usdNetCashFlow}</Typography>
              <Typography>CDF net cash flow: {cdfNetCashFlow}</Typography>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            my={2}
            variant="outlined"
            onClick={() => setAddingDailyReport(false)}
          >
            Cancel
          </Button>
          {isAdding ? (
            <Loading size={20} />
          ) : (
            <Button
              my={2}
              variant="contained"
              form="daily-report-form"
              type="submit"
              color="primary"
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
