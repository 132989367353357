import {
  AppBar,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { flagListFetch } from './flagListSlice';
import { flagDelete, setFlag } from './flagSlice';

export const FlagList = ({ embedded }) => {
  // console.log('RXD:FlagList');
  const dispatch = useDispatch();
  const { flagList, isLoading, error, lastFilter } = useSelector((state) => {
    return state.flagList;
  });

  const navigate = useNavigate();
  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);
  const [anchorElDelete, setAnchorElDelete] = useState(new Map());

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, ['agentUuid']);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', 'parameter');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(flagListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'parameter',
      headerName: 'Parameter',
      minWidth: 200,
      flex: 1,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'audience',
      headerName: 'Audience',
      type: 'number',
      minWidth: 100,
      flex: 0.3,
    },
    {
      field: 'isJson',
      headerName: 'Is JSON',
      type: 'boolean',
      minWidth: 100,
      flex: 0.3,
    },
    {
      field: 'value',
      headerName: 'Value',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'defaultValue',
      headerName: 'Default Value',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      sx: { padding: 0 },
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          anchorElDelete.set(params.row.parameter, e.currentTarget);
          setAnchorElDelete(new Map(anchorElDelete));
        };

        const onDelete = (e) => {
          e.stopPropagation();
          dispatch(flagDelete(params.row.parameter));
        };

        const handleClose = (e) => {
          anchorElDelete.set(params.row.parameter, null);
          setAnchorElDelete(new Map(anchorElDelete));
        };

        return (
          <>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={onClick}
              sx={{ padding: 0, margin: 0, height: 32 }}
            >
              Delete
            </Button>
            <Popover
              id={params.row.parameter}
              open={Boolean(anchorElDelete.get(params.row.parameter))}
              anchorEl={anchorElDelete.get(params.row.parameter)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Button
                sx={{ m: 2 }}
                variant="contained"
                size="small"
                color="error"
                onClick={onDelete}
              >
                Permanently delete {params.row.parameter}?
              </Button>
            </Popover>
          </>
        );
      },
    },
  ];

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            <Button
              disableElevation
              sx={{ textTransform: 'none' }}
              variant="contained"
              border="2px solid"
              size="medium"
              component={RouterLink}
              to={`/flags/add`}
            >
              Create Flag
            </Button>
          }
        >
          Experimental Flags
        </AppBar>
      )}

      {error && (
        <Error error={error} onRetry={() => dispatch(flagListFetch(filters))} />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(flag) => flag.parameter}
          rows={flagList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const flag = param.row;
            if (e.ctrlKey) {
              window.open(`/flags/${flag.parameter}`);
            } else {
              dispatch(setFlag(flag));
              navigate(`/flags/${flag.parameter}`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('FlagList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns, [
              { field: 'parameter', sort: 'desc' },
            ]),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('FlagList', {}),
            },
          }}
        />
      </div>
    </>
  );
};
