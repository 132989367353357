import { getApi, getApiError } from '@/common';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isLoadingAgent: false,
  isLoadingFieldAgents: false,
  isSubmitting: false,
  fieldAgentThresholds: [],
  thresholds: [],
  agents: [],
  fieldAgents: [],
  lastFilter: null,
  error: '',
};

const floatCashThresholdListSlice = createSlice({
  name: 'floatCashThresholdList',
  initialState: initialState,
  reducers: {
    setResult: (state, { payload }) => {
      const { type, lastFilter, thresholds } = payload;
      if (type === 'fieldAgent') state.fieldAgentThresholds = thresholds;
      else state.thresholds = thresholds;
      state.lastFilter = lastFilter;
      state.isLoading = false;
      console.log('>>> Setting thresholds:  ', thresholds);
    },
    setAgents: (state, { payload }) => {
      const { agents } = payload;
      state.isLoadingAgent = false;
      state.agents = agents;
    },
    setFieldAgents: (state, { payload }) => {
      const { fieldAgents } = payload;
      state.isLoadingFieldAgents = false;
      state.fieldAgents = fieldAgents;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setSubmitting: (state, { payload }) => {
      state.isSubmitting = payload;
    },
    setLoadingAgent: (state, { payload }) => {
      state.error = '';
      state.isLoadingAgent = payload;
    },
    setLoadingFieldAgents: (state, { payload }) => {
      state.error = '';
      state.isLoadingFieldAgents = payload;
    },
    setError: (state, { payload }) => {
      state.isLoading = false;
      state.isSubmitting = false;
      state.isLoadingAgent = false;
      state.error = payload;
    },
  },
});

export const floatCashThresholdsFetchUsingApi = (filters) =>
  getApi().get('/api/float-cash/thresholds', { params: filters });

export const fieldAgentFloatCashThresholdsFetchUsingApi = (filters) =>
  getApi().get('/api/float-cash/field-agent-thresholds', { params: filters });

export const agentsFetchUsingApi = (filters) =>
  getApi().get('/api/agents', { params: filters });

export const fieldAgentsFetchUsingApi = (filters) =>
  getApi().get('/api/field-agents', { params: filters });

export const floatCashThresholdsFetch = (filters, type) => (dispatch) => {
  dispatch(setLoading(true));
  const query =
    type === 'fieldAgent'
      ? fieldAgentFloatCashThresholdsFetchUsingApi(filters)
      : floatCashThresholdsFetchUsingApi(filters);
  query
    .then((response) =>
      dispatch(
        setResult({
          type: type,
          lastFilter: filters?.toString(),
          thresholds: response.data.data,
        })
      )
    )
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const agentsFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  agentsFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setAgents({
          agents: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const agentsSearch = (value) => async (dispatch) => {
  try {
    dispatch(setLoadingAgent(true));
    let agents = [];
    const nameResult = await agentsFetchUsingApi(
      new URLSearchParams(
        `agentName=contains:${value}&oo=agentName&e=1&pp=1:10`
      )
    );
    agents = agents.concat(...nameResult.data);
    if (value && value.length > 0) {
      const phoneResult = await agentsFetchUsingApi(
        new URLSearchParams(
          `phoneNumber=contains:${encodeURIComponent(
            value
          )}&oo=agentName&e=1&pp=1:10`
        )
      );
      agents = agents.concat(...phoneResult.data);
    }
    dispatch(
      setAgents({
        agents,
      })
    );
  } catch (err) {
    dispatch(setError(getApiError(err)));
  }
};

export const fieldAgentsSearch = (value) => async (dispatch) => {
  try {
    dispatch(setLoadingFieldAgents(true));
    let fieldAgents = [];
    const nameResult = await fieldAgentsFetchUsingApi(
      new URLSearchParams(`name=contains:${value}&oo=name&e=1&pp=1:10`)
    );
    fieldAgents = fieldAgents.concat(...nameResult.data);
    if (value && value.length > 0) {
      const phoneResult = await fieldAgentsFetchUsingApi(
        new URLSearchParams(
          `phoneNumber=contains:${encodeURIComponent(
            value
          )}&oo=phoneNumber&e=1&pp=1:10`
        )
      );
      fieldAgents = fieldAgents.concat(...phoneResult.data);
    }
    dispatch(
      setFieldAgents({
        fieldAgents,
      })
    );
  } catch (err) {
    dispatch(setError(getApiError(err)));
  }
};

export const addFloatThreshold = (data, filters, type) => (dispatch) => {
  dispatch(setSubmitting(true));

  getApi()
    .post(`/api/float-cash/thresholds/bulk`, data)
    .then((response) => {
      console.log('>>> Added float threshold: ', response);
      dispatch(setSubmitting(false));
      dispatch(floatCashThresholdsFetch(filters, type));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const addFieldAgentFloatThreshold =
  (data, filters, type) => (dispatch) => {
    dispatch(setSubmitting(true));

    getApi()
      .post(`/api/float-cash/field-agent-thresholds/bulk`, data)
      .then((response) => {
        dispatch(setSubmitting(false));
        dispatch(floatCashThresholdsFetch(filters, type));
      })
      .catch((err) => {
        dispatch(setError(getApiError(err)));
      });
  };

export const {
  setLoading,
  setLoadingAgent,
  setLoadingFieldAgents,
  setSubmitting,
  setResult,
  setFieldAgents,
  setError,
  setAgents,
} = floatCashThresholdListSlice.actions;
export const floatCashThresholdListReducer =
  floatCashThresholdListSlice.reducer;
