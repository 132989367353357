import { agentFetch } from '@/agent';
import { captureEvent } from '@/firebase';
import { loanFetchById, loanUpdate } from '@/loan/loanSlice';
import { getRepaymentLoanAmount, getRepaymentLoanCurrency } from '@/loan/utils';
import { TextareaAutosize as Textarea } from '@mui/base';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const EditLoanRepaymentDialog = (props) => {
  // input field style
  const inputStyle = { padding: '8px 12px' };
  // initial form state
  const initialFormState = {
    phone: '',
    loanId: '',
    comment: '',
  };
  const dispatch = useDispatch();
  const { handleClose, open, loanRepaymentUuid } = props;
  const [formData, setFormData] = useState(initialFormState);
  const [isLoanRepayment, setIsLoanRepayment] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
  let { loan } = useSelector((state) => state.loan);
  let { agent } = useSelector((state) => state.agent);

  let phones = agent?.simNumbers?.map((phone) => phone?.phoneNumber) ?? [];

  const handleSelectPhoneNumber = (e) => setSelectedPhoneNumber(e.target.value);
  const handleToggleIsLoanRepayment = () =>
    setIsLoanRepayment(!isLoanRepayment);
  const handleOnChange = (title) => (e) =>
    setFormData({ ...formData, [title]: e.target.value });
  const handleEditRepayment = () => {
    if (agent && loan && selectedPhoneNumber) {
      const payload = {
        uuid: loanRepaymentUuid,
        loanId: loan.loanId,
        comment: formData.comment,
        status: isLoanRepayment ? 'rejected' : 'reconciled',
      };
      const _agent = {
        uuid: agent.uuid,
        agentName: agent.agentName,
      };

      Object.assign(payload, { agent: _agent });

      // call API to save data
      dispatch(loanUpdate(payload));
      captureEvent('EDIT_LOAN_REQUEST_SAVE', { ...payload });
      // close modal
      handleClose();
    }
  };

  useEffect(() => {
    // get loan by ID
    if (formData.loanId.length > 3) {
      dispatch(loanFetchById(formData.loanId));
    }
    // fetch all associated phone numbers
    if (!!loan?.agent?.uuid) {
      dispatch(agentFetch(loan?.agent?.uuid));
    }
  }, [formData.loanId, loan?.agent?.uuid, dispatch]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Edit Loan Repayment</DialogTitle>
      <DialogContent>
        <Box my={1}>
          <Typography mb={1}>Loan ID</Typography>
          <TextField
            inputProps={{ style: inputStyle }}
            sx={{ width: '100%' }}
            onChange={handleOnChange('loanId')}
            type="text"
          />
        </Box>

        <Box my={1}>
          <Typography mb={1}>Loan Amount</Typography>
          <TextField
            disabled
            type="text"
            inputProps={{ style: inputStyle }}
            sx={{ width: '100%', backgroundColor: '#efefef' }}
            value={`${getRepaymentLoanCurrency(loan)} ${getRepaymentLoanAmount(
              loan
            )}`}
          />
        </Box>

        <Box my={1}>
          <Typography mb={1}>Agent name</Typography>
          <TextField
            disabled
            type="text"
            value={agent?.agentName}
            inputProps={{ style: inputStyle }}
            sx={{ width: '100%', backgroundColor: '#efefef' }}
          />
        </Box>

        <Box my={1}>
          <PhoneNumberInput
            phones={phones}
            selectedPhoneNumber={selectedPhoneNumber}
            handleSelectPhoneNumber={handleSelectPhoneNumber}
          />
        </Box>

        <Box my={1}>
          <Typography mb={1}>
            Comment <span style={{ color: 'green' }}>(Reason for edit)</span>{' '}
            Optional
          </Typography>
          <Textarea
            placeholder="Comment..."
            minRows={5}
            spellCheck={true}
            sx={{ width: '100%' }}
            onChange={handleOnChange('comment')}
            style={{
              width: '100%',
              border: '0.5px solid gray',
              borderRadius: '5px',
              padding: '8px',
            }}
          />
        </Box>

        <FormGroup my={1}>
          <FormControlLabel
            onChange={handleToggleIsLoanRepayment}
            control={<Checkbox />}
            label="This is not a loan repayment"
          />
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ borderColor: 'gray', borderWidth: '1px', fontWeight: 'normal' }}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disableElevation
          variant="contained"
          onClick={handleEditRepayment}
        >
          Edit Repayment
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PhoneNumberInput = (props) => {
  const { phones, selectedPhoneNumber, handleSelectPhoneNumber } = props;

  const sims = Array.from(new Set(phones ?? []));
  return (
    <FormControl variant="outlined" sx={{ width: '100%' }}>
      <InputLabel id="edit-loan-repayment-phone">Phone Number</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={selectedPhoneNumber}
        onChange={handleSelectPhoneNumber}
        label="Phone Number"
        style={{ padding: '0' }}
      >
        {sims?.map((phone, index) => (
          <MenuItem sx={{ padding: '5px' }} key={index} value={phone}>
            {phone}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EditLoanRepaymentDialog;
