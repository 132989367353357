import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { AppBar, dateFormat, Error, Info as InfoBase, Loading } from '@/common';
import {
  deleteTransaction,
  floatCashAuditHistoryFetch,
  floatCashTransactionFetch,
  setDeleting,
} from '@/float-cash/floatCashTransactionDetailsSlice';
import { hasAnyRoles } from '@/auth/helpers';
import { useEffect, useState } from 'react';
import {
  getAgentSignature,
  getFieldAgentSignature,
  getFloatCashAgentName,
  getFloatCashAmount,
  getFloatCashBankName,
  getFloatCashFieldAgentName,
  getFloatCashSubmittedBy,
  getFloatCashTransactionTypeTitle,
  getFloatCashTypeText,
  isNullOrEmpty,
} from '@/float-cash/util';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { Grid, ListItem, Modal } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import closeIcon from '@/images/icons8_close.png';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import List from '@mui/material/List';

export const FloatCashTransactionDetails = ({ embedded }) => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [deleteCashDialogOpen, setDeleteCashDialogOpen] = useState(false);
  const [fullScreenUrl, setFullScreenUrl] = useState('');
  const { user } = useSelector((state) => state.auth);
  const { transaction, history, isLoading, isDeleting, error } = useSelector(
    (state) => state.floatCashTransaction
  );

  const handleClose = () => setFullScreenUrl('');

  useEffect(() => {
    if (!isDeleting && deleteCashDialogOpen) {
      handleDeleteCashDialogClose();
    }
  }, [isDeleting]);

  const getGridWeight = () => {
    return transaction?.transactionType === 'DEPOSIT' || history.length > 0
      ? 6
      : 12;
  };
  useEffect(() => {
    setDeleteCashDialogOpen(false);
    dispatch(floatCashTransactionFetch(uuid));
    dispatch(floatCashAuditHistoryFetch(uuid));
  }, []);

  useEffect(() => {
    if (!deleteCashDialogOpen) {
      console.log('>>> Setting deleting. Open? ', deleteCashDialogOpen);
      dispatch(setDeleting(false));
    }
  }, [deleteCashDialogOpen]);

  const showActionButtons = () => {
    return (
      hasAnyRoles(user.role, 'RECONCILIATION_ADMIN') && !transaction?.deletedAt
    );
  };
  const handleDeleteCashDialogClose = () => setDeleteCashDialogOpen(false);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <DeleteTransactionModel
            isOpen={deleteCashDialogOpen}
            isDeleting={isDeleting}
            transaction={transaction}
            handleDeleteCashDialogClose={handleDeleteCashDialogClose}
          />
          <AppBar
            actions={
              showActionButtons() ? (
                <>
                  <Button
                    disableElevation
                    sx={{ textTransform: 'none' }}
                    variant="contained"
                    color="error"
                    border="2px solid"
                    size="medium"
                    component={RouterLink}
                    disabled={
                      transaction?.isDeletable !== true ||
                      !!transaction.deletedAt
                    }
                    onClick={(e) => setDeleteCashDialogOpen(true)}
                  >
                    Delete
                  </Button>
                  <Button
                    disableElevation
                    sx={{ textTransform: 'none' }}
                    variant="contained"
                    border="2px solid"
                    size="medium"
                    component={RouterLink}
                    disabled={transaction?.isEditable !== true}
                    to={`/float-cash/cash/${uuid}/edit`}
                  >
                    Edit
                  </Button>
                </>
              ) : null
            }
          >
            {transaction
              ? getFloatCashTransactionTypeTitle(transaction)
              : 'Transaction'}{' '}
            : {uuid}
          </AppBar>
          {error && (
            <Error
              error={error}
              onRetry={() => dispatch(floatCashTransactionFetch(uuid))}
            />
          )}
          {transaction ? (
            <>
              <Modal
                open={Boolean(fullScreenUrl)}
                onClose={handleClose}
                onClick={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  sx={{
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    component="img"
                    sx={{ maxWidth: '100%', maxHeight: '100%' }}
                    src={fullScreenUrl}
                    alt={transaction?.uuid}
                    style={{ cursor: 'pointer' }}
                  />
                </Grid>
              </Modal>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item md={getGridWeight()}>
                  <TransactionDetail transaction={transaction} />
                </Grid>
                <Grid item md={6} direction="column">
                  {history.length ? (
                    <Box mb={3}>
                      <AuditHistory history={history}></AuditHistory>
                    </Box>
                  ) : null}
                  {(transaction.attachments || []).length > 0 ? (
                    <AttachmentInfo
                      setFullScreenUrl={setFullScreenUrl}
                      transaction={transaction}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

const AttachmentInfo = (props) => {
  const { transaction, setFullScreenUrl } = props;
  return (
    <Card>
      <CardContent>
        <Typography>Deposit slip</Typography>
        <Stack direction="column">
          {transaction.attachments.map((value) => {
            return (
              <img
                src={value}
                height={400}
                width="100%"
                style={{ objectFit: 'cover', cursor: 'pointer' }}
                alt=""
                onClick={(e) => setFullScreenUrl(e.target.src)}
              />
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};

const TransactionDetail = (props) => {
  const { transaction } = props;
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!isNullOrEmpty(getFloatCashSubmittedBy(transaction)) ? (
              <Info label="Submitted by TDR:">
                {getFloatCashSubmittedBy(transaction)}
              </Info>
            ) : (
              <></>
            )}
            {transaction?.agent && (
              <Info label="Agent name:">
                {getFloatCashAgentName(transaction)}
              </Info>
            )}
            {transaction?.fieldAgent && (
              <Info label="Field agent name:">
                {getFloatCashFieldAgentName(transaction)}
              </Info>
            )}

            {!!transaction.phoneNumber ? (
              <Info label="Phone number:">{transaction.phoneNumber}</Info>
            ) : (
              <></>
            )}
            <Info label="Amount:">
              {transaction.currency} {getFloatCashAmount(transaction)}
            </Info>
            {!isNullOrEmpty(getFloatCashBankName(transaction)) ? (
              <Info label="Bank:">{getFloatCashBankName(transaction)}</Info>
            ) : (
              <></>
            )}
            {!isNullOrEmpty(getAgentSignature(transaction)) ? (
              <Stack direction="row" sx={{ py: 2 }}>
                <Typography>Agent Signature:</Typography>
                <img
                  src={getAgentSignature(transaction)}
                  alt=""
                  width={100}
                  height={100}
                  style={{
                    objectFit: 'cover',
                    borderRadius: 4,
                    borderColor: 'gray',
                    borderWidth: '1px',
                  }}
                />
              </Stack>
            ) : (
              <></>
            )}

            <Divider />

            {!isNullOrEmpty(getFieldAgentSignature(transaction)) ? (
              <Stack direction="row">
                <Typography>TDR Signature:</Typography>
                <img
                  src={getFieldAgentSignature(transaction)}
                  alt=""
                  width={100}
                  height={100}
                  style={{
                    objectFit: 'cover',
                    borderRadius: 4,
                    borderColor: 'gray',
                    borderWidth: '1px',
                  }}
                />
              </Stack>
            ) : (
              <></>
            )}
            <Divider />
            {!isNullOrEmpty(getFloatCashFieldAgentName(transaction)) ? (
              <Info label="Collected from:">
                {getFloatCashFieldAgentName(transaction)}
              </Info>
            ) : (
              <></>
            )}
            {(transaction.comments || 0).length > 0 ? (
              <Info label="Comments:">{transaction.comments.join(',')}</Info>
            ) : (
              <></>
            )}
            <Info label="Date:">{dateFormat(transaction?.createdAt)}</Info>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Info = ({ label, children, border }) => (
  <InfoBase sx={{ py: 2 }} label={label} border={border ?? true}>
    {children}
  </InfoBase>
);

const AuditHistory = (props) => {
  const { history } = props;
  useEffect(() => {
    history.forEach((historyItem) => {
      console.log('>>> History item: ', historyItem);
    });
  }, [history]);
  return (
    <Card>
      <CardContent>
        <Typography fontWeight="bold" fontSize="large">
          Edit History
        </Typography>
        <List>
          {history.map((historyItem) => {
            return (
              <ListItem sx={{ pl: 0, pr: 0 }}>
                <Grid container>
                  <Grid item md={4}>
                    <Typography>
                      {dateFormat(historyItem.createdAt, 'D MMM hh:mm:ss a')}:{' '}
                    </Typography>
                  </Grid>
                  <Grid item md>
                    <Typography>
                      {(historyItem.changeSummary || []).join(',')}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};

const DeleteTransactionModel = (props) => {
  const { isOpen, transaction, isDeleting, handleDeleteCashDialogClose } =
    props;
  const dispatch = useDispatch();
  return (
    <Dialog open={isOpen}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Stack direction="row" flex justifyContent="end">
          <Box>
            <Button
              variant="text"
              onClick={() => handleDeleteCashDialogClose()}
            >
              <img src={closeIcon} alt="" width="30px" height="30px" />
            </Button>
          </Box>
        </Stack>
        <Stack flex justifyContent="center" alignItems="center" p={12}>
          <Typography align="center">
            Are you sure you wish to delete cash{' '}
            {getFloatCashTypeText(transaction?.transactionType)?.toLowerCase()}{' '}
            of {transaction?.currency} {getFloatCashAmount(transaction)} from{' '}
            {dateFormat(transaction?.createdAt)}
          </Typography>
          <Button
            sx={{ mt: 8, width: '50%' }}
            variant="contained"
            color="error"
            disabled={isDeleting}
            onClick={() => dispatch(deleteTransaction(transaction.uuid))}
          >
            <span>Delete</span>{' '}
            {isDeleting ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <></>
            )}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
