import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  loanContractList: [],
  isLoading: false,
  lastFilter: null,
  error: '',
};

const loanContractListSlice = createSlice({
  name: 'loanContractList',
  initialState: initialState,
  reducers: {
    setLoanContracts: (state, { payload }) => {
      const { lastFilter, loanContractList } = payload;

      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.loanContractList = loanContractList;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const loanContractListFetchUsingApi = (filters) =>
  getApi().get('/api/loans/contracts', { params: filters });

export const loanContractListFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  loanContractListFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setLoanContracts({
          lastFilter: filters?.toString(),
          loanContractList: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setError, setLoading, setLoanContracts } =
  loanContractListSlice.actions;

export const loanContractsReducer = loanContractListSlice.reducer;
