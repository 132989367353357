import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError, updateInList } from '@/common';
import { setAgent } from './agentSlice';

const initialState = {
  status: 'editing',
  error: '',
};

const simNumberFormSlice = createSlice({
  name: 'simNumberForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
      state.simNumber = null;
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
    setSimNumber: (state, { payload }) => {
      state.simNumber = payload;
      state.status = 'editing';
    },
  },
});

export const simNumberSave = (uuid, data) => (dispatch) => {
  dispatch(setLoading(true));
  const { agent } = data;
  data = { ...data, agent: { uuid: agent.uuid } };

  const action = Boolean(uuid)
    ? getApi().patch(`/api/sims/${uuid}`, data)
    : getApi().post(`/api/sims`, data);

  action
    .then((response) => {
      dispatch(
        setAgent({
          ...agent,
          simNumbers: updateInList([response.data], agent.simNumbers, 'uuid'),
        })
      );
      dispatch(resetState());
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const fetchSimById = (uuid) => (dispatch) => {
  dispatch(setLoading());

  getApi()
    .get(`/api/sims/${uuid}`)
    .then((response) => {
      dispatch(setSimNumber(response.data));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { resetState, setLoading, setError, setSimNumber } =
  simNumberFormSlice.actions;

export const simNumberFormReducer = simNumberFormSlice.reducer;
