import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';

const initialState = {
  status: 'editing',
  error: '',
};

const customerContractFormSlice = createSlice({
  name: 'customerContractForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const customerContractSave = (uuid, data) => (dispatch) => {
  dispatch(setLoading());

  const action = getApi().patch(`/api/customer/configuration`, data);

  action
    .then((response) => {
      dispatch(resetState());
      dispatch(push(`/settings`));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { resetState, setLoading, setError } =
  customerContractFormSlice.actions;

export const customerContractFormReducer = customerContractFormSlice.reducer;
