import Button from '@mui/material/Button';
import {
  AppBar,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import { paginationValue } from '@/common/filter';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { actionsFetch } from './actionListSlice';
import { setAction } from './actionSlice';
import { getActionTelcosMap } from './utils';

export const ActionList = ({ embedded }) => {
  // console.log('RXD:ActionList');
  const dispatch = useDispatch();
  const { actionList, isLoading, error, lastFilter } = useSelector((state) => {
    return state.actionList;
  });

  const navigate = useNavigate();

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  const actionTelcosMap = getActionTelcosMap();
  const translateHni = (params) => {
    return params.value.map((v) => actionTelcosMap.get(v) ?? v);
  };

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, 'actionUuid');
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', 'name');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(actionsFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 190,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'hnis',
      headerName: 'Network',
      minWidth: 190,
      sortingOrder: getSortOrder(),
      valueFormatter: translateHni,
    },
    {
      field: 'externalId',
      headerName: 'Hover ID',
      minWidth: 190,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'path',
      headerName: 'USSD Path',
      minWidth: 190,
      flex: 1,
      sortingOrder: getSortOrder(),
    },
  ];

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            <Button
              disableElevation
              sx={{ textTransform: 'none' }}
              variant="contained"
              border="2px solid"
              size="medium"
              component={RouterLink}
              to="/ussd/actions/add"
            >
              Add Action
            </Button>
          }
        >
          USSD Actions
        </AppBar>
      )}

      {error && (
        <Error error={error} onRetry={() => dispatch(actionsFetch(filters))} />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(action) => action.id}
          rows={actionList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const action = param.row;
            if (e.ctrlKey) {
              window.open(`/ussd/actions/${action.id}`);
            } else {
              dispatch(setAction(action));
              navigate(`/ussd/actions/${action.id}`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('ActionList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('ActionList', {}),
            },
          }}
        />
      </div>
    </>
  );
};
