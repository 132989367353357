import {
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fieldAgentManagerLogListFetch } from './fieldAgentManagerLogListSlice';
import { getFieldAgentName, getLoggedAt, getManagerName } from './utils';

export const FieldAgentManagerLogList = ({ embedded }) => {
  // console.log('RXD:FieldAgentManagerLogList');
  const dispatch = useDispatch();
  const { fieldAgentManagerLogList, isLoading, error, lastFilter } =
    useSelector((state) => {
      return state.fieldAgentManagerLogList;
    });
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'm.uuid',
      'fa.uuid',
    ]);
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(fieldAgentManagerLogListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'loggedAt',
      headerName: 'Date',
      flex: 1,
      valueGetter: w(getLoggedAt),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'm.name',
      headerName: 'Manager',
      flex: 1,
      valueGetter: w(getManagerName),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'fa.name',
      headerName: 'Field Agent',
      flex: 1,
      valueGetter: w(getFieldAgentName),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 1,
      sortingOrder: getSortOrder(),
    },
  ];
  return (
    <>
      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(fieldAgentManagerLogListFetch(filters))}
        />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(log) => log.uuid}
          rows={fieldAgentManagerLogList ?? []}
          columns={columns}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('FieldAgentManagerLogList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility(
                'FieldAgentManagerLogList',
                { 'fa.name': false }
              ),
            },
          }}
        />
      </div>
    </>
  );
};
