export const capitalize = (value) => {
  if (value && typeof value === 'string') {
    return value.substring(0, 1).toUpperCase() + value.substring(1);
  }
  return value;
};

export const titleCase = (value) => {
  if (value && typeof value === 'string') {
    return value.split(' ').map(capitalize).join(' ');
  }
  return value;
};

export const pluralize = (count, word) => {
  return count === 1 ? word : word + 's';
};
