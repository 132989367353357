import { getApi } from '@/common';

// Exchange rates
export const apiFetchRateHistory = (filters) => {
  return getApi().get('api/exchange-rates', {
    params: getFilterParams(filters),
  });
};
export const apiGetCurrentExchangeRate = (filters) => {
  return getApi().get('api/exchange-rates/current', {
    params: getFilterParams(filters),
  });
};

export const apiAddExchangeRate = (data) => {
  return getApi().post('api/exchange-rates', data);
};

// Agents
export const apiFetchAgents = (filters) => {
  return getApi().get('/api/agents', {
    params: getFilterParams(filters),
  });
};

// Sim accounts
export const apiFetchSimAccounts = (filters) => {
  return getApi().get('/api/sims/accounts', {
    params: getFilterParams(filters),
  });
};
const getFilterParams = (filters, params) => {
  return filters;
};
