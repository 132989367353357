import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';

const initialState = {
  status: 'editing',
  error: '',
};

const actionFormSlice = createSlice({
  name: 'actionForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const actionSave = (id, data) => (dispatch) => {
  dispatch(setLoading());

  const action = !!id
    ? getApi().patch(`/api/ussd/actions/${id}`, data)
    : getApi().post(`/api/ussd/actions`, data);

  action
    .then((response) => {
      dispatch(resetState());
      dispatch(push(`/ussd/actions`));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { resetState, setLoading, setError } = actionFormSlice.actions;

export const actionFormReducer = actionFormSlice.reducer;
