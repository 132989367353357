import { getApi, getApiError } from '@/common';
import { createSlice } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';

const initialState = {
  status: 'editing',
  error: '',
};

const expenseDeleteSlice = createSlice({
  name: 'expenseDelete',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

const generateSuccessUrl = (mode, agentUuid, fieldAgentUuid) => {
  if (mode === 'agents') {
    return `/agents/${agentUuid}?tab=8`;
  }
  if (mode === 'field-agents') {
    return `/field-agents/${fieldAgentUuid}?tab=6`;
  }
  return '';
};

export const expenseRemove =
  ({ mode, agentUuid, fieldAgentUuid }, uuid) =>
  (dispatch) => {
    dispatch(setLoading(true));

    getApi()
      .delete(`/api/expenses/${uuid}`)
      .then((response) => {
        dispatch(resetState());
        dispatch(push(generateSuccessUrl(mode, agentUuid, fieldAgentUuid)));
      })
      .catch((err) => {
        dispatch(setError(getApiError(err)));
      });
  };

export const { resetState, setLoading, setError } = expenseDeleteSlice.actions;

export const expenseDeleteReducer = expenseDeleteSlice.reducer;
