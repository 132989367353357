import {
  AppBar,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { loanDurationListFetch } from './loanDurationListSlice';
import { loanDurationDelete, setLoanDuration } from './loanDurationSlice';

export const LoanDurationList = ({ embedded }) => {
  // console.log('RXD:LoanDurationList');
  const dispatch = useDispatch();
  const { loanDurationList, isLoading, lastFilter, error } = useSelector(
    (state) => {
      return state.loanDurationList;
    }
  );

  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);
  const [anchorElDelete, setAnchorElDelete] = useState(new Map());

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, []);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-duration');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      // console.log('RXD:LoanDurationList.fetching');
      dispatch(loanDurationListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'duration',
      headerName: 'Duration',
      minWidth: 150,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'durationUnit',
      headerName: 'Unit',
      align: 'left',
      headerAlign: 'left',
      minWidth: 200,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          anchorElDelete.set(params.row.id, e.currentTarget);
          setAnchorElDelete(new Map(anchorElDelete));
        };

        const onDelete = (e) => {
          e.stopPropagation();
          dispatch(loanDurationDelete(params.row.id));
        };

        const handleClose = (e) => {
          anchorElDelete.set(params.row.id, null);
          setAnchorElDelete(new Map(anchorElDelete));
        };

        return (
          <>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={onClick}
            >
              Delete
            </Button>
            <Popover
              id={params.row.id}
              open={Boolean(anchorElDelete.get(params.row.id))}
              anchorEl={anchorElDelete.get(params.row.id)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Button
                sx={{ m: 2 }}
                variant="contained"
                size="small"
                color="error"
                onClick={onDelete}
              >
                Permanently delete {params.row.duration}{' '}
                {params.row.durationUnit}
              </Button>
            </Popover>
          </>
        );
      },
    },
  ];

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            <Button
              disableElevation
              sx={{ textTransform: 'none' }}
              variant="contained"
              border="2px solid"
              size="medium"
              component={RouterLink}
              to="/loans/durations/add"
            >
              Add Loan Duration
            </Button>
          }
        >
          Loan Durations
        </AppBar>
      )}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(loanDurationListFetch(filters))}
        />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(loan) => loan.id}
          rows={loanDurationList ?? []}
          columns={columns}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('LoanDurationList', visible)
          }
          onCellClick={w((loanDuration) => {
            dispatch(setLoanDuration(loanDuration));
            navigate(`/loans/durations/${loanDuration.id}/edit`);
          })}
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility(
                'LoanDurationList',
                {}
              ),
            },
          }}
        />
      </div>
    </>
  );
};
