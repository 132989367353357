import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { flagListFetch, setError as setListError } from './flagListSlice';

const initialState = {
  flag: null,
  isLoading: false,
  error: '',
};

const flagSlice = createSlice({
  name: 'flag',
  initialState: initialState,
  reducers: {
    setFlag: (state, { payload }) => {
      state.flag = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.flag = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const flagFetch = (parameter) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/flags/${parameter}`)
    .then((response) => dispatch(setFlag(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const flagFetchMine = (parameter) => {
  return getApi()
    .get(`/api/flags/mine/${parameter}`)
    .then((response) => response.data)
    .catch((err) => getApiError(err));
};

export const flagDelete = (parameter) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .delete(`/api/flags/${parameter}`)
    .then((response) =>
      dispatch(flagListFetch(new URLSearchParams('pp=1:20&oo=parameter')))
    )
    .catch((err) => {
      dispatch(setError(getApiError(err)));
      dispatch(setListError(err.message));
    });
};

export const { setFlag, resetState, setLoading, setError } = flagSlice.actions;

export const flagReducer = flagSlice.reducer;
