import { getApi, getApiError, isNotEmpty } from '@/common';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'editing',
  error: '',
  totalUpdated: 0,
  isLoadingThresholds: false,
  agentThresholds: [],
  failedUpdates: [],
};

const floatCashDisburseSlice = createSlice({
  name: 'floatCashDisburse',
  initialState: initialState,
  reducers: {
    setResult: (state, { payload }) => {
      state.error = '';
      state.status = 'editing';
      state.failedUpdates = payload.failedUpdates;
      state.totalUpdated = payload.totalUpdated;
    },
    setAgentThresholds: (state, { payload }) => {
      state.agentThresholds = payload;
      state.isLoadingThresholds = false;
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
      state.failedUpdates = [];
      state.totalUpdated = 0;
    },
    setLoadingThresholds: (state, { payload }) => {
      state.error = '';
      state.isLoadingThresholds = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
      state.failedUpdates = [];
      state.totalUpdated = 0;
    },
    resetState: (state) => {
      state.agentThresholds = [];
      state.isLoadingThresholds = false;
      state.failedUpdates = [];
      state.totalUpdated = 0;
      state.status = 'editing';
      state.error = '';
    },
  },
});

export const floatCashDisburseSave = (data) => (dispatch) => {
  dispatch(setLoading());

  getApi()
    .patch(`/api/float-cash/request/bulk/disburse`, data)
    .then((response) => {
      dispatch(setResult(response.data));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const agentsThresholdFetch = (uuids) => (dispatch) => {
  dispatch(setLoadingThresholds(true));
  getApi()
    .get(`/api/float-cash/thresholds`, {
      params: {
        'a.uuid': `isAnyOf:${uuids.join(',')}`,
      },
    })
    .then((response) => dispatch(setAgentThresholds(response.data?.data || [])))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const {
  setResult,
  setAgentThresholds,
  setLoading,
  setLoadingThresholds,
  resetState,
  setError,
} = floatCashDisburseSlice.actions;

export const floatCashDisburseReducer = floatCashDisburseSlice.reducer;
