import { authRefresh } from '@/auth';
import axios from 'axios';

let store;

/**
 * Adds default configurations based on the API to Axios
 *
 * @returns {import('axios').AxiosInstance}
 */
export const getApi = () => {
  let instance = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
  });
  addLogoutInterceptor(instance);
  addJsonHeaders(instance);
  addAuthorizationHeaders(instance);
  return instance;
};
export const makeDownload = (response) => {
  const name = /filename="(.*?)"/.exec(
    response.headers['content-disposition']
  )[1];
  const url = window.URL.createObjectURL(response.data);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

/**
 * Add store in this scope
 * @param {*} _store
 */
export const injectStore = (_store) => {
  store = _store;
};

const addJsonHeaders = (instance) =>
  (instance.defaults.headers.post['Content-Type'] = 'application/json');

const addLogoutInterceptor = (instance) => {
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        // console.log('RXD: addLogoutInterceptor() 401');
        return authRefresh(store.dispatch);
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

const addAuthorizationHeaders = (instance) => {
  let idToken = localStorage.getItem('idToken');
  if (idToken) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
  }
  return instance;
};

export const getApiError = (err) => {
  const message = [err.message];
  if (Array.isArray(err?.response?.data?.message)) {
    message.push(err?.response?.data?.message.join('. '));
  }
  return message.join(': ');
};
