import {
  AppBar,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { usersFetch } from './userListSlice';
import { setUser } from './userSlice';

export const UserList = ({ embedded }) => {
  // console.log('RXD:UserList');
  const dispatch = useDispatch();
  const { userList, isLoading, error, lastFilter } = useSelector((state) => {
    return state.userList;
  });

  const navigate = useNavigate();

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, 'userUuid');
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', 'email');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(usersFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 190,
      flex: 1,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      minWidth: 190,
      flex: 1,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'externalId',
      headerName: 'UID',
      align: 'center',
      headerAlign: 'center',
      minWidth: 190,
      flex: 1,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'authenticatedBy',
      headerName: 'Authenticated By',
      align: 'center',
      headerAlign: 'center',
      minWidth: 80,
      flex: 0.8,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      align: 'center',
      type: 'date',
      headerAlign: 'center',
      minWidth: 190,
      flex: 1,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      align: 'center',
      type: 'date',
      headerAlign: 'center',
      minWidth: 190,
      flex: 1,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
    },
  ];

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            <Button
              disableElevation
              sx={{ textTransform: 'none' }}
              variant="contained"
              border="2px solid"
              size="medium"
              component={RouterLink}
              to="/users/add"
            >
              Add User
            </Button>
          }
        >
          Users
        </AppBar>
      )}

      {error && (
        <Error error={error} onRetry={() => dispatch(usersFetch(filters))} />
      )}

      <div
        style={{
          display: 'flex',
          height: 'calc(100vh - 190px)',
          minHeight: 300,
        }}
      >
        <OparetaTable
          getRowId={(user) => user.uuid}
          rows={userList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const user = param.row;
            if (e.ctrlKey) {
              window.open(`/users/${user.uuid}`);
            } else {
              dispatch(setUser(user));
              navigate(`/users/${user.uuid}`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('UserList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns, [
              { field: 'email', sort: 'asc' },
            ]),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('UserList', {}),
            },
          }}
        />
      </div>
    </>
  );
};
