import { simNumberListFetch } from '@/agent/simNumberListSlice';
import {
  AppBar,
  BooleanBubble,
  Bubble,
  Error,
  Info as InfoBase,
  Loading,
  TabPanel,
  useFilters,
} from '@/common';
import { a11yProps } from '@/common/TabPanel';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { loanRequestSave } from './loanRequestFormSlice';
import { loanRequestFetch } from './loanRequestSlice';
import {
  getPaymentPlanExpectedAt,
  getPaymentPlanInterest,
  getPaymentPlanPaidAt,
  getPaymentPlanPrincipal,
  getPaymentPlanTotal,
  getRequestAgentFeedback,
  getRequestCreatedAt,
  getRequestCurrency,
  getRequestInterest,
  getRequestInterestRate,
  getRequestLoanProduct,
  getRequestMessage,
  getRequestPaymentInterval,
  getRequestPrincipal,
  getRequestPrincipalHoliday,
  getRequestRequestedAmount,
  getRequestRequestedTerm,
  getRequestSignedAt,
  getRequestStatusBackground,
  getRequestStatusColor,
  getRequestTableauUrl,
  getRequestTerm,
  getRequestTermUnits,
  getRequestTotal,
  reviewMessages,
} from './utils';
import { captureEvent } from '@/firebase';

export const LoanRequestDetail = () => {
  // console.log('RXD:LoanRequestDetail');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { loanRequest, isLoading, error } = useSelector(
    (state) => state.loanRequest
  );

  const [isReviewing, setIsReviewing] = useState(false);
  const [isDisbursing, setIsDisbursing] = useState(false);

  useEffect(() => {
    if (
      (uuid !== loanRequest?.uuid && !isLoading) ||
      (loanRequest?.agent && !('uuid' in loanRequest?.agent) && !isLoading)
    ) {
      dispatch(loanRequestFetch(uuid));
    }
  }, [dispatch, loanRequest, uuid, isLoading]);

  const [filters, setFilters] = useFilters();
  const handleTabChange = (e, index) => {
    setFilters(new URLSearchParams(`tab=${index}`));
    captureEvent('TAB_CHANGE_LOAN_REQUEST_DETAIL', {
      index,
      tab: e?.target?.innerText || '',
    });
  };
  let tab = filters.get('tab');
  tab = tab === null ? 0 : +tab;

  return (
    <>
      <AppBar
        actions={
          <>
            {loanRequest?.status === 'pending' && (
              <Button
                disableElevation
                sx={{ textTransform: 'none' }}
                variant="contained"
                border="2px solid"
                size="medium"
                onClick={() => setIsReviewing(true)}
              >
                Review Request
              </Button>
            )}
            {(loanRequest?.status === 'agent-signed' ||
              loanRequest?.status === 'approved') && (
              <Button
                disableElevation
                sx={{ textTransform: 'none' }}
                variant="contained"
                border="2px solid"
                size="medium"
                onClick={() => setIsReviewing(true)}
              >
                Undo Review
              </Button>
            )}
            {loanRequest?.status === 'agent-signed' && (
              <Button
                disableElevation
                sx={{ textTransform: 'none' }}
                variant="contained"
                border="2px solid"
                size="medium"
                onClick={() => setIsDisbursing(true)}
              >
                Disburse Loan
              </Button>
            )}
          </>
        }
      >
        Loan Request:{' '}
        {loanRequest?.uuid === uuid ? loanRequest?.agent?.agentName : uuid}
      </AppBar>

      {error ? (
        <Error error={error} />
      ) : !loanRequest || isLoading ? (
        <Loading />
      ) : (
        <>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            sx={{ background: 'white' }}
            aria-label="loan request detail tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Overview" value={0} {...a11yProps(0)} />
            <Tab label="Payment Plan" value={1} {...a11yProps(1)} />
            <Tab label="Contract" value={2} {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <OverviewSection loanRequest={loanRequest} />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <PaymentPlan loanRequest={loanRequest} />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Contract loanRequest={loanRequest} dispatch={dispatch} />
          </TabPanel>
          <Drawer
            anchor="bottom"
            open={isReviewing}
            onClose={() => setIsReviewing(false)}
          >
            <ReviewForm
              loanRequest={loanRequest}
              setIsReviewing={setIsReviewing}
            />
          </Drawer>
          <Drawer
            anchor="bottom"
            open={isDisbursing}
            onClose={() => setIsDisbursing(false)}
          >
            <DisburseForm
              loanRequest={loanRequest}
              setIsDisbursing={setIsDisbursing}
            />
          </Drawer>
        </>
      )}
    </>
  );
};

const Info = ({ label, children, border }) => (
  <InfoBase sx={{ py: 1 }} label={label} border={border ?? true}>
    {children}
  </InfoBase>
);

const OverviewSection = ({ loanRequest }) => (
  <Grid container spacing={2} sx={{ mt: 1 }}>
    <Grid item md={3} xs={12}>
      <AgentDetail
        agent={loanRequest.agent}
        simNumber={loanRequest.disburseToSimNumber}
      />
      <LoanDetail loan={loanRequest?.loan} />
    </Grid>
    <Grid item md={9} xs={12}>
      <MainDetail loanRequest={loanRequest} />
    </Grid>
  </Grid>
);

const MainDetail = ({ loanRequest }) => (
  <>
    <Card sx={{ mb: 2 }}>
      <Grid container sx={{ px: 2, pb: 1 }}>
        <Grid item md={3} xs={12}>
          <Info label="Eligibility" border={false}>
            <BooleanBubble value={loanRequest?.eligible} />
          </Info>
        </Grid>
        <Grid item md={6} xs={12}>
          <Info label="Status" border={false}>
            <Bubble
              label={loanRequest?.status}
              color={getRequestStatusColor(loanRequest)}
              background={getRequestStatusBackground(loanRequest)}
            />
          </Info>
        </Grid>
        <Grid item md={3} xs={12}>
          <Info label="Tableau" border={false}>
            <Button
              component="a"
              target="_blank"
              href={getRequestTableauUrl(loanRequest)}
              size="small"
              variant="outlined"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              View
            </Button>
          </Info>
        </Grid>
      </Grid>
      {!loanRequest.eligible && (
        <>
          <Divider />
          <Alert severity="error">{loanRequest?.eligibleMessage ?? '-'}</Alert>
        </>
      )}
      <Divider />
      <Grid container sx={{ px: 2, pb: 1 }}>
        <Grid item md={3} xs={12}>
          <Info label="Signed At" border={false}>
            {getRequestSignedAt(loanRequest)}
          </Info>
        </Grid>
      </Grid>
      <Grid container sx={{ px: 2, pb: 1 }}>
        <Grid item md={3} xs={12}>
          <Info label="Currency" border={false}>
            {getRequestCurrency(loanRequest) ?? '-'}
          </Info>
        </Grid>
        <Grid item md={5} xs={12}>
          <Info label="Loan Product" border={false}>
            {getRequestLoanProduct(loanRequest) ?? '-'}
          </Info>
        </Grid>
      </Grid>
      <Grid container sx={{ px: 2, pb: 1 }}>
        <Grid item md={3} xs={12}>
          <Info label="Requested Principal" border={false}>
            {getRequestRequestedAmount(loanRequest) ?? '-'}
          </Info>
        </Grid>
        <Grid item md={3} xs={12}>
          <Info label="Requested Term" border={false}>
            {getRequestRequestedTerm(loanRequest)}
          </Info>
        </Grid>
      </Grid>
      <Grid container sx={{ px: 2, pb: 1 }}>
        <Grid item md={3} xs={12}>
          <Info label="Principal" border={false}>
            {getRequestPrincipal(loanRequest)}
          </Info>
        </Grid>
        <Grid item md={3} xs={12}>
          <Info label="Term" border={false}>
            {getRequestTerm(loanRequest)}
          </Info>
        </Grid>
        <Grid item md={3} xs={12}>
          <Info label="Interest" border={false}>
            {getRequestInterest(loanRequest)}
          </Info>
        </Grid>
        <Grid item md={3} xs={12}>
          <Info label="Total" border={false}>
            {getRequestTotal(loanRequest)}
          </Info>
        </Grid>
      </Grid>
      <Grid container sx={{ px: 2, pb: 1 }}>
        <Grid item md={3} xs={12}>
          <Info label="Interest Rate" border={false}>
            {getRequestInterestRate(loanRequest)}
          </Info>
        </Grid>
        <Grid item md={3} xs={12}>
          <Info label="Payment Interval" border={false}>
            {getRequestPaymentInterval(loanRequest)}
          </Info>
        </Grid>
        <Grid item md={3} xs={12}>
          <Info label="Principal Holiday" border={false}>
            {getRequestPrincipalHoliday(loanRequest)}
          </Info>
        </Grid>
        <Grid item md={3} xs={12}>
          <Info label="Requested At" border={false}>
            {getRequestCreatedAt(loanRequest)}
          </Info>
        </Grid>
      </Grid>
      <Grid container sx={{ px: 2, pb: 1 }}>
        <Info label="Message" border={false}>
          {getRequestMessage(loanRequest)
            ?.split('\n')
            ?.map((c) => (
              <>
                {c}
                <br />
              </>
            ))}
        </Info>
      </Grid>
      <Grid container sx={{ px: 2, pb: 1 }}>
        <Info label="Feedback" border={false}>
          {getRequestAgentFeedback(loanRequest)}
        </Info>
      </Grid>
    </Card>
  </>
);

const AgentDetail = ({ agent, simNumber, header }) => (
  <Card>
    {header ? (
      header
    ) : (
      <CardHeader
        title="Agent Details"
        action={
          <Button
            size="small"
            component={RouterLink}
            variant="contained"
            to={agent?.uuid ? `/agents/${agent?.uuid}` : '#'}
          >
            VIEW
          </Button>
        }
      />
    )}
    {!('uuid' in agent) ? (
      <Loading />
    ) : (
      <CardContent>
        <Divider />
        <Info label="Name">{agent.agentName}</Info>
        <Info label="Line Name">{simNumber?.lineName ?? '-'}</Info>
        <Info label={`Line (${simNumber?.category ?? '-'})`}>
          {simNumber?.phoneNumber ?? '-'}
        </Info>
        <Info label="Agent ID">{simNumber?.agentId ?? '-'}</Info>
      </CardContent>
    )}
  </Card>
);

const LoanDetail = ({ loan }) => (
  <Card sx={{ mt: 2 }}>
    <CardHeader
      title="Loan Details"
      action={
        <Button
          size="small"
          component={RouterLink}
          variant="contained"
          to={loan?.uuid ? `/loans/${loan?.uuid}` : '#'}
        >
          VIEW
        </Button>
      }
    />
    <CardContent>
      <Divider />
      <Info label="ID">{loan?.loanId ?? '-'}</Info>
      <Info label="Status">{loan?.status ?? '-'}</Info>
    </CardContent>
  </Card>
);

const PaymentPlan = ({ loanRequest }) => (
  <TableContainer sx={{ mt: 2 }} component={Paper}>
    <Table aria-label="repayment schedule table">
      <TableHead sx={{ backgroundColor: '#E6E8F0', color: '#2a2a2a' }}>
        <TableRow>
          <TableCell>Repayment Date</TableCell>
          <TableCell>Currency</TableCell>
          <TableCell>Total</TableCell>
          <TableCell>Principal</TableCell>
          <TableCell>Interest</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Date Paid</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[]
          .concat(loanRequest.paymentPlans)
          .sort((a, b) => (a.date < b.date ? -1 : 1))
          .map((row) => (
            <TableRow key={row.uuid}>
              <TableCell>{getPaymentPlanExpectedAt(row)}</TableCell>
              <TableCell>{row.currency}</TableCell>
              <TableCell>{getPaymentPlanTotal(row)}</TableCell>
              <TableCell>{getPaymentPlanPrincipal(row)}</TableCell>
              <TableCell>{getPaymentPlanInterest(row)}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{getPaymentPlanPaidAt(row)}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const Contract = ({ loanRequest, dispatch }) => (
  <Card>
    <CardContent>
      {loanRequest.contractPdfUrl ? (
        <>
          <Button
            variant="contained"
            component={RouterLink}
            to={loanRequest.contractPdfUrl}
            target="_blank"
            rel="noreferrer"
            download={`${loanRequest.agent.agentName
              .replace(/[^A-Za-z]+/g, '-')
              .toLowerCase()}-${loanRequest.uuid}.pdf`}
            sx={{ mb: 2 }}
          >
            Download
          </Button>
          <Box
            component="embed"
            src={loanRequest.contractPdfUrl}
            type="application/pdf"
            frameBorder="0"
            scrolling="auto"
            minHeight="600px"
            height="100%"
            width="100%"
          />
        </>
      ) : (
        <Alert severity="info">
          <Box>Currently no contract generated!</Box>
          {(loanRequest.status === 'approved' ||
            loanRequest.status === 'agent-signed') && (
            <Box>
              <Divider sx={{ my: 2 }} />
              <Box>It may take up to a minute to generate this contract.</Box>
              <Button
                variant="contained"
                size="medium"
                sx={{ my: 2, textTransform: 'none' }}
                onClick={() => dispatch(loanRequestFetch(loanRequest.uuid))}
              >
                Refresh Now
              </Button>
            </Box>
          )}
        </Alert>
      )}
    </CardContent>
  </Card>
);

const ReviewForm = ({ loanRequest, setIsReviewing }) => {
  const { error: formError, status: formStatus } = useSelector(
    (state) => state.loanRequestForm
  );
  const dispatch = useDispatch();
  const [principal, setPrincipal] = useState(
    getRequestPrincipal(loanRequest).replace(/,/g, '')
  );
  const [term, setTerm] = useState(loanRequest?.term ?? '');
  const [termUnit, setTermUnit] = useState(loanRequest?.termUnit ?? '');
  const [message, setMessage] = useState(loanRequest?.message ?? '');
  const [status, setStatus] = useState(loanRequest?.status ?? '');
  const statusChanges = useRef(formStatus);

  const w = (callback) => (e) => callback(e.target.value);

  useEffect(() => {
    if (formStatus === 'loading' || formError) {
      statusChanges.current = formStatus;
    } else if (
      formStatus === 'editing' &&
      statusChanges.current === 'loading'
    ) {
      setIsReviewing(false);
    }
  }, [dispatch, setIsReviewing, formStatus, formError]);

  const handleSave = (e) => {
    e.preventDefault();

    // console.log('RXD:LoanRequestDetail', { status });
    // console.log('RXD:LoanRequestDetail', { principal });
    // console.log('RXD:LoanRequestDetail', { term });
    // console.log('RXD:LoanRequestDetail', { termUnit });
    // console.log('RXD:LoanRequestDetail', { message });

    dispatch(
      loanRequestSave(loanRequest.uuid, {
        status,
        principal,
        term,
        termUnit,
        message,
      })
    );
  };

  return (
    <Box sx={{ p: 3 }} component="form" method="POST" onSubmit={handleSave}>
      {formError && <Error error={formError} />}
      <Grid container sx={{ mb: 1 }} spacing={2}>
        <Grid item md={6}>
          <FormControl fullWidth>
            <TextField
              id="principal"
              label="Principal"
              variant="outlined"
              type="number"
              min="0"
              value={principal}
              onChange={w(setPrincipal)}
            />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <Grid container sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="term"
                  label="Requested Term"
                  variant="outlined"
                  type="number"
                  min="0"
                  value={term}
                  onChange={w(setTerm)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Select
                  id="termUnit"
                  variant="outlined"
                  value={termUnit}
                  onChange={w(setTermUnit)}
                >
                  {getRequestTermUnits().map((unit) => (
                    <MenuItem key={unit} value={unit}>
                      {unit}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <Autocomplete
          options={reviewMessages}
          autoComplete
          value={message}
          groupBy={(option) => option.group}
          getOptionLabel={(option) => option?.message ?? option}
          onChange={(e, newValue) => {
            setMessage(newValue?.message ?? newValue);
          }}
          onInputChange={(e, v) => setMessage(v)}
          minRows={3}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.message} sx={{ borderBottom: 1 }}>
                {option.message}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={
                principal !==
                  getRequestPrincipal(loanRequest).replace(/,/g, '') ||
                term !== loanRequest.term ||
                termUnit !== loanRequest.termUnit
              }
              id="message"
              label="Message"
              variant="outlined"
            />
          )}
        />
      </FormControl>

      {formStatus === 'loading' ? (
        <Loading />
      ) : (
        <Grid container>
          <Grid item md={1} xs={12}>
            {loanRequest.status === 'pending' && (
              <Button
                disableElevation
                type="submit"
                sx={{
                  textTransform: 'none',
                  width: { xs: '100%' },
                  mb: { xs: 2 },
                }}
                variant="contained"
                size="medium"
                onClick={(e) => setStatus('approved')}
              >
                Approve
              </Button>
            )}
          </Grid>
          <Grid item md={10}></Grid>
          <Grid item md={1} xs={12} sx={{ textAlign: 'right' }}>
            <Button
              disableElevation
              type="submit"
              sx={{ textTransform: 'none', width: { xs: '100%' } }}
              variant="contained"
              size="medium"
              color="error"
              onClick={(e) => setStatus('rejected')}
            >
              Reject
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const DisburseForm = ({ loanRequest, setIsDisbursing }) => {
  const dispatch = useDispatch();

  const { status: formStatus, error: formError } = useSelector(
    (state) => state.loanRequestForm
  );

  const hasFetched = useRef(false);
  const [simNumber, setSimNumber] = useState(
    loanRequest.disburseToSimNumber ?? {}
  );
  const statusChanges = useRef(formStatus);

  const {
    simNumberList,
    isLoading: simNumberListIsLoading,
    error: simNumberListError,
  } = useSelector((state) => state.simNumberList);

  useEffect(() => {
    if (!simNumberListIsLoading && !simNumberListError && !hasFetched.current) {
      hasFetched.current = true;
      dispatch(
        simNumberListFetch(
          new URLSearchParams(
            `e=1&pp=1:100&agentUuid=${loanRequest?.agent?.uuid}`
          )
        )
      );
    }
  }, [
    dispatch,
    simNumberListError,
    simNumberListIsLoading,
    loanRequest?.agent?.uuid,
  ]);

  useEffect(() => {
    if (formStatus === 'loading' || formError) {
      statusChanges.current = formStatus;
    } else if (
      formStatus === 'editing' &&
      statusChanges.current === 'loading'
    ) {
      setIsDisbursing(false);
    }
  }, [dispatch, setIsDisbursing, formStatus, formError]);

  const handleSave = (e) => {
    e.preventDefault();
    if (simNumber?.uuid) {
      dispatch(
        loanRequestSave(loanRequest.uuid, {
          status: 'disbursed',
          disburseToSimNumber: {
            uuid: simNumber.uuid,
          },
        })
      );
    }
  };

  return (
    <Box
      component="form"
      method="POST"
      sx={{ p: 3 }}
      onSubmit={handleSave}
      autoComplete="off"
    >
      {formError && <Error error={formError} />}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <AgentDetail
            agent={loanRequest.agent}
            simNumber={simNumber}
            header={
              <CardHeader
                title={`Disburse ${getRequestPrincipal(loanRequest)} to line`}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="disburseSimNumber">Disburse to line</InputLabel>
            <Select
              label="Disburse to line"
              id="disburseSimNumber"
              variant="outlined"
              value={simNumber?.uuid ?? ''}
              onChange={(e) => {
                const ss = simNumberList.filter(
                  (s) => s.uuid === e.target.value
                );
                if (ss.length === 1) {
                  setSimNumber(ss[0]);
                }
              }}
            >
              {simNumberList.map((s) => (
                <MenuItem key={s.uuid} value={s.uuid}>
                  {`${s?.phoneNumber}${s?.agentId ? ` - ${s.agentId}` : ''}${
                    s?.lineName ? ` (${s.lineName})` : ''
                  }`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {formStatus === 'loading' ? (
            <Loading />
          ) : (
            <Button
              disableElevation
              variant="contained"
              sx={{ mb: 2, textTransform: 'none' }}
              type="submit"
            >
              Save
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
