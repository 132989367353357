import { AppBar } from '@/common';
import { useDispatch } from 'react-redux';

export const FloatRequestForm = () => {
  // console.log('RXD:FloatRequestForm');
  const dispatch = useDispatch();

  return (
    <>
      <AppBar>Add Float Request</AppBar>
    </>
  );
};
