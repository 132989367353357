import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';
import { apiFetchAgents, apiFetchSimAccounts } from '@/api/api-util';

const initialState = {
  fieldAgents: [],
  agents: [],
  simAccounts: [],
  banks: [],
  isLoadingFieldAgents: false,
  isLoadingAgents: false,
  isLoadingBanks: false,
  isLoadingSimAccounts: false,
  isSubmitting: false,
  attachmentUrl: null,
  isUploadingFiles: false,
  error: '',
  closeDepositFormModal: false,
};

export const cashDepositFormSlice = createSlice({
  name: 'cashDepositForm',
  initialState,
  reducers: {
    setFieldAgents: (state, { payload }) => {
      const { fieldAgents } = payload;
      state.isLoadingFieldAgents = false;
      state.fieldAgents = fieldAgents;
    },
    setAgents: (state, { payload }) => {
      const { agents } = payload;
      state.isLoadingFieldAgents = false;
      state.agents = agents;
    },
    setSimAccounts: (state, { payload }) => {
      const { simAccounts } = payload;
      state.isLoadingSimAccounts = false;
      state.simAccounts = simAccounts;
    },
    setBanks: (state, { payload }) => {
      const { banks } = payload;
      state.isLoadingBanks = false;
      state.banks = banks;
    },
    setLoadingFieldAgents: (state) => {
      state.error = '';
      state.isLoadingFieldAgents = true;
    },
    setLoadingAgents: (state, { payload }) => {
      state.error = '';
      state.isLoadingAgents = payload;
    },
    setLoadingBanks: (state) => {
      state.error = '';
      state.isLoadingBanks = true;
    },
    setLoadingSimAccounts: (state, { payload }) => {
      state.error = '';
      state.isLoadingSimAccounts = payload;
    },
    setSubmitting: (state) => {
      state.error = '';
      state.isSubmitting = true;
    },
    stopLoading: (state) => {
      state.isLoadingFieldAgents = false;
      state.isSubmitting = false;
      state.isLoadingBanks = false;
    },
    setUploadFile: (state, { payload }) => {
      state.attachmentUrl = payload.url;
      state.isUploadingFiles = false;
    },
    removeUploadFile: (state) => {
      state.attachmentUrl = null;
    },
    setUploadingFiles: (state) => {
      state.error = '';
      state.isUploadingFiles = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      stopLoading();
    },
    closeDepositFormModal: (state) => {
      state.closeDepositFormModal = true;
    },
    clearState: (state) => {
      Object.assign(state, initialState);
    },
    clearErrorState: (state) => {
      state.error = '';
    },
  },
});

export const fieldAgentsFetchUsingApi = (filters) =>
  getApi().get('/api/field-agents', { params: filters });

export const fetchBanksFromNetwork = (filters) =>
  getApi().get('/api/float-cash/accounts', { params: filters });

export const fieldAgentsFetch = (filters) => (dispatch) => {
  dispatch(setLoadingFieldAgents(true));
  fieldAgentsFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setFieldAgents({
          fieldAgents: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const agentsSearch = (value) => async (dispatch) => {
  try {
    console.log('>>> Fetching agents with value:', value);
    dispatch(setLoadingAgents(true));
    let agents = [];
    const nameResult = await apiFetchAgents(
      new URLSearchParams(
        `agentName=contains:${value}&oo=agentName&e=1&pp=1:10`
      )
    );
    agents = agents.concat(...nameResult.data);
    if (value && value.length > 0) {
      const phoneResult = await apiFetchAgents(
        new URLSearchParams(
          `phoneNumber=contains:${encodeURIComponent(
            value
          )}&oo=agentName&e=1&pp=1:10`
        )
      );
      agents = agents.concat(...phoneResult.data);
    }
    dispatch(
      setAgents({
        agents,
      })
    );
    dispatch(setLoadingAgents(false));
  } catch (err) {
    dispatch(setError(getApiError(err)));
  }
};

export const submitForm = (formData) => (dispatch) => {
  dispatch(setSubmitting(true));
  getApi()
    .post('/api/float-cash/transaction', formData)
    .then((res) => {
      stopLoading();
      console.log('>>> Result ok. Closing form modal');
      dispatch(closeDepositFormModal());
      dispatch(push('/float-cash/cash'));
      console.log('>>> All closed');
    })
    .catch((err) => dispatch(setError(getApiError(err))))
    .finally(() => dispatch(stopLoading()));
};

export const uploadAttachmentsFile = (file) => (dispatch) => {
  dispatch(setUploadingFiles(true));
  getApi()
    .post('/api/storage/upload', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      dispatch(setUploadFile(res.data));
    })
    .catch((err) => dispatch(setError(getApiError(err))))
    .finally(() => dispatch(stopLoading()));
};

export const fetchSimAccounts = (filters) => (dispatch) => {
  dispatch(setLoadingSimAccounts(true));
  apiFetchSimAccounts(filters)
    .then((response) => {
      const simAccounts = (response?.data?.data || [])
        .map((simAccount) => {
          const accounts = (simAccount.accounts || []).filter(
            (item) => !!item.currency
          );
          simAccount.accounts = accounts;
          simAccount.hasAccounts = accounts.length > 0;
          return simAccount;
        })
        .filter((item) => item.accounts.length > 0);
      dispatch(
        setSimAccounts({
          simAccounts,
        })
      );
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const fetchBanks = (filters) => (dispatch) => {
  dispatch(setLoadingBanks(true));
  fetchBanksFromNetwork(filters)
    .then((response) =>
      dispatch(
        setBanks({
          banks: response.data?.data || [],
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const {
  setError,
  setLoadingFieldAgents,
  setLoadingAgents,
  setLoadingBanks,
  setFieldAgents,
  setSimAccounts,
  setAgents,
  setSubmitting,
  setUploadingFiles,
  setLoadingSimAccounts,
  removeUploadFile,
  setUploadFile,
  stopLoading,
  setBanks,
  clearState,
  clearErrorState,
  closeDepositFormModal,
} = cashDepositFormSlice.actions;

export const cashDepositFormReducer = cashDepositFormSlice.reducer;
