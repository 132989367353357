import { hasAnyRoles } from '@/auth/helpers';
import {
  AppBar,
  BooleanBubble,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
  useInitial,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  fieldAgentListExport,
  fieldAgentListFetch,
} from './fieldAgentListSlice';
import { fieldAgentPositionListFetch } from './fieldAgentPositionListSlice';
import { setFieldAgent } from './fieldAgentSlice';
import { createdAt } from './utils';

export const FieldAgentList = ({ embedded }) => {
  // console.log('RXD:FieldAgentList');
  const dispatch = useDispatch();
  const { fieldAgentList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.fieldAgentList;
    }
  );

  const { fieldAgentPositionList } = useSelector((state) => {
    return state.fieldAgentPositionList;
  });

  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useInitial(() => {
    dispatch(
      fieldAgentPositionListFetch(new URLSearchParams('pp=1:200&oo=name'))
    );
  });

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'fieldAgentUuid',
      'managerUuid',
      'm.uuid',
    ]);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', 'name');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(fieldAgentListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'name',
      headerName: 'Full Name',
      minWidth: 200,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'totalOnboardedAgents',
      headerName: 'Agents Onboarded',
      minWidth: 150,
      align: 'center',
      type: 'number',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'totalAgents',
      headerName: 'Total Agents',
      minWidth: 100,
      align: 'center',
      type: 'number',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'isActive',
      headerName: 'Active',
      minWidth: 100,
      type: 'boolean',
      sortingOrder: getSortOrder(),
      renderCell: w((fieldAgent) => (
        <BooleanBubble value={fieldAgent?.isActive} />
      )),
    },
    {
      field: 'category',
      headerName: 'Position',
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      type: 'singleSelect',
      valueOptions: (fieldAgentPositionList ?? []).map((position) => ({
        value: position.name,
        label: position.name,
      })),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'm.uuid',
      headerName: 'Manager',
      filterable: false,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.manager?.name,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      minWidth: 175,
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'email',
      headerName: 'Email Address',
      minWidth: 300,
      align: 'left',
      headerAlign: 'left',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'phoneNumber2',
      headerName: 'Phone Alternative 1',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'phoneNumber3',
      headerName: 'Phone Alternative 2',
      minWidth: 160,

      sortingOrder: getSortOrder(),
    },
    {
      field: 'gender',
      headerName: 'Gender',
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'totalTerritories',
      headerName: 'Territories',
      minWidth: 150,
      align: 'right',
      type: 'number',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'totalTeamMembers',
      headerName: 'Team Members',
      minWidth: 150,
      align: 'right',
      type: 'number',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'createdAt',
      headerName: 'Start Date',
      valueGetter: w(createdAt),
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      type: 'dateTime',
      sortingOrder: getSortOrder(),
      valueFormatter: w((fieldAgent) => createdAt(fieldAgent)),
    },
    {
      field: 'uuid',
      headerName: 'UUID',
      minWidth: 150,
      align: 'right',
      headerAlign: 'center',
      sortingOrder: false,
    },
  ];

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            hasAnyRoles(user.role, 'FIELD_AGENT_MANAGER') ? (
              <>
                <Button
                  disableElevation
                  variant="contained"
                  component={RouterLink}
                  to="/field-agents/add"
                  // onClick={
                  //   (() => logButtonEvents('FIELD_AGENT_ADD_MEMBER'), {})
                  // }
                >
                  Add New Team Member
                </Button>
              </>
            ) : null
          }
        >
          Team Members
        </AppBar>
      )}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(fieldAgentListFetch(filters))}
        />
      )}

      <div
        style={{
          height: 'calc(100vh - 190px)',
          minHeight: 300,
        }}
      >
        <OparetaTable
          getRowId={(fieldAgent) => fieldAgent.uuid}
          rows={fieldAgentList ?? []}
          columns={columns}
          onExportClick={() => dispatch(fieldAgentListExport())}
          onCellClick={(param, e) => {
            const fieldAgent = param.row;
            if (e.ctrlKey) {
              window.open(`/field-agents/${fieldAgent.uuid}`);
            } else {
              dispatch(setFieldAgent(fieldAgent));
              navigate(`/field-agents/${fieldAgent.uuid}`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('FieldAgentList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('FieldAgentList', {
                phoneNumber2: false,
                phoneNumber3: false,
                gender: false,
                uuid: false,
              }),
            },
          }}
        />
      </div>
    </>
  );
};
