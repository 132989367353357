import { AppBar, Error, Loading } from '@/common';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loanProductListFetch } from './loanProductListSlice';
import { loanRuleSave } from './loanRuleFormSlice';
import { loanRuleFetch, resetState } from './loanRuleSlice';
import { getEditDate, getUserSegmentations } from './utils';
import { captureEvent } from '@/firebase';

export const LoanRuleForm = () => {
  // console.log('RXD:LoanRuleForm');
  const dispatch = useDispatch();
  const { uuid } = useParams();

  const { loanRule, isLoading, error } = useSelector((state) => state.loanRule);
  const { error: formError, status } = useSelector(
    (state) => state.loanRuleForm
  );
  const { loanProductList } = useSelector((state) => state.loanProductList);
  const [name, setName] = useState('');
  const [preference, setPreference] = useState('1');
  const [segmentations, setSegmentations] = useState([]);
  const [minimumPastLoanCount, setMinimumPastLoanCount] = useState('0');
  const [maximumPastLoanCount, setMaximumPastLoanCount] = useState('');
  const [firstLoanAfter, setFirstLoanAfter] = useState('');
  const [firstLoanBefore, setFirstLoanBefore] = useState('');
  const [lastLoanAfter, setLastLoanAfter] = useState('');
  const [lastLoanBefore, setLastLoanBefore] = useState('');
  const [productUuid, setProductUuid] = useState('');

  useEffect(() => {
    dispatch(loanProductListFetch(new URLSearchParams('e=1&oo=name')));
  }, [dispatch]);

  useEffect(() => {
    if (uuid && uuid !== loanRule?.uuid && !isLoading && !error) {
      dispatch(resetState());
      setName('');
      setPreference('1');
      setSegmentations([]);
      setMinimumPastLoanCount('0');
      setMaximumPastLoanCount('');
      setFirstLoanAfter('');
      setFirstLoanBefore('');
      setLastLoanAfter('');
      setLastLoanBefore('');
      setProductUuid('');
      dispatch(loanRuleFetch(uuid));
    }
  }, [dispatch, loanRule, uuid, isLoading, error]);

  // initial values
  useEffect(() => {
    if (uuid) {
      setName(loanRule?.name ?? '');
      setPreference(loanRule?.preference ?? '1');
      setSegmentations(loanRule?.segmentations ?? []);
      setMinimumPastLoanCount(loanRule?.minimumPastLoanCount ?? '0');
      setMaximumPastLoanCount(loanRule?.maximumPastLoanCount ?? '');
      setFirstLoanAfter(getEditDate(loanRule?.firstLoanAfter) ?? '');
      setFirstLoanBefore(getEditDate(loanRule?.firstLoanBefore) ?? '');
      setLastLoanAfter(getEditDate(loanRule?.lastLoanAfter) ?? '');
      setLastLoanBefore(getEditDate(loanRule?.lastLoanBefore) ?? '');
      setProductUuid(loanRule?.product?.uuid ?? '');
    }
  }, [loanRule, uuid]);

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      uuid,
      name,
      preference,
      segmentations: Boolean(segmentations) ? segmentations : null,
      minimumPastLoanCount,
      maximumPastLoanCount: Boolean(maximumPastLoanCount)
        ? maximumPastLoanCount
        : null,
      firstLoanAfter: firstLoanAfter ? firstLoanAfter : null,
      firstLoanBefore: firstLoanBefore ? firstLoanBefore : null,
      lastLoanAfter: lastLoanAfter ? lastLoanAfter : null,
      lastLoanBefore: lastLoanBefore ? lastLoanBefore : null,
      product: { uuid: productUuid },
    };
    dispatch(loanRuleSave(uuid, data));

    captureEvent('LOAN_RULE_FORM_SAVED', {});
  };

  // console.log('RXD:LoanRuleForm', 'uuid', uuid, 'status', status);

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <>
      <AppBar>
        {uuid ? `Edit Loan Rule ${loanRule?.name ?? uuid}` : 'Add Loan Rule'}
      </AppBar>

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <Box
                    component="form"
                    method="post"
                    sx={{ p: 1 }}
                    onSubmit={handleSave}
                    autoComplete="off"
                  >
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                        id="name"
                        label="Name"
                        variant="outlined"
                        value={name}
                        onChange={w(setName)}
                        required
                      />
                    </FormControl>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <TextField
                            id="preference"
                            label="Preference"
                            helperText="Incase of any tie higher preference wins"
                            variant="outlined"
                            type="number"
                            maximum="0"
                            step="1"
                            value={preference}
                            onChange={w(setPreference)}
                            required
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel id="productUuid">Loan Product</InputLabel>
                          <Select
                            id="productUuid"
                            label="Loan Product"
                            variant="outlined"
                            value={productUuid}
                            onChange={w(setProductUuid)}
                            required
                          >
                            {loanProductList &&
                              loanProductList?.map((product) => (
                                <MenuItem
                                  key={product.uuid}
                                  value={product.uuid}
                                >
                                  {product.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel id="segmentations">
                            User segmentations
                          </InputLabel>
                          <Select
                            id="segmentations"
                            label="User segmentations"
                            variant="outlined"
                            value={segmentations}
                            onChange={w(setSegmentations)}
                            multiple
                          >
                            {getUserSegmentations().map((segment) => (
                              <MenuItem key={segment} value={segment}>
                                {segment}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <TextField
                            id="minimumPastLoanCount"
                            label="Minimum Loans"
                            variant="outlined"
                            type="number"
                            minimum="0"
                            step="1"
                            value={minimumPastLoanCount}
                            onChange={w(setMinimumPastLoanCount)}
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <TextField
                            id="maximumPastLoanCount"
                            label="Maximum Loans"
                            variant="outlined"
                            type="number"
                            maximum="0"
                            step="1"
                            value={maximumPastLoanCount}
                            onChange={w(setMaximumPastLoanCount)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Divider sx={{ my: 2 }} />

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <TextField
                            id="firstLoanAfter"
                            helperText="First Loan Start After"
                            variant="outlined"
                            type="date"
                            value={firstLoanAfter}
                            onChange={w(setFirstLoanAfter)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <TextField
                            id="firstLoanBefore"
                            helperText="First Loan Start Before"
                            variant="outlined"
                            type="date"
                            value={firstLoanBefore}
                            onChange={w(setFirstLoanBefore)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <TextField
                            id="lastLoanAfter"
                            helperText="Last Loan Start After"
                            variant="outlined"
                            type="date"
                            value={lastLoanAfter}
                            onChange={w(setLastLoanAfter)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <TextField
                            id="lastLoanBefore"
                            helperText="Last Loan Start Before"
                            variant="outlined"
                            type="date"
                            value={lastLoanBefore}
                            onChange={w(setLastLoanBefore)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Divider sx={{ my: 2 }} />

                    {status === 'loading' ? (
                      <Loading />
                    ) : (
                      <Button variant="contained" sx={{ mb: 1 }} type="submit">
                        Save
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
