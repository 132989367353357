import {
  AppBar,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { loanContractListFetch } from './loanContractListSlice';
import { setLoanContract } from './loanContractSlice';
import { getContractName, getContractSignature } from './utils';

export const LoanContractList = ({ embedded }) => {
  // console.log('RXD:LoanContractList');
  const dispatch = useDispatch();

  const { loanContractList, isLoading, lastFilter, error } = useSelector(
    (state) => {
      return state.loanContractList;
    }
  );
  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, []);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-name');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      // console.log('RXD:LoanContractList.fetching');
      dispatch(loanContractListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 160,
      flex: 2,
      sortingOrder: getSortOrder(),
      valueGetter: w(getContractName),
    },
    {
      field: 'signaturePhotoUrl',
      headerName: 'Signature',
      align: 'center',
      headerAlign: 'center',
      minWidth: 160,
      flex: 1,
      sortingOrder: getSortOrder(),
      renderCell: w((loanContract) => (
        <Box
          component="img"
          src={getContractSignature(loanContract)}
          sx={{ width: 100 }}
        ></Box>
      )),
    },
  ];

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            <Button
              disableElevation
              sx={{ textTransform: 'none' }}
              variant="contained"
              border="2px solid"
              size="medium"
              component={RouterLink}
              to={`/loans/contracts/add`}
            >
              Add Contract Template
            </Button>
          }
        >
          Loan Contracts
        </AppBar>
      )}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(loanContractListFetch(filters))}
        />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(loan) => loan.uuid}
          rows={loanContractList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const loanContract = param.row;
            const url = `/loans/contracts/${loanContract.uuid}/edit`;
            if (e.ctrlKey) {
              window.open(url);
            } else {
              dispatch(setLoanContract(loanContract.loan));
              navigate(url);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('LoanContractList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility(
                'LoanContractList',
                {}
              ),
            },
          }}
        />
      </div>
    </>
  );
};
