import {
  AppBar,
  Bubble,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import { paginationValue } from '@/common/filter';
import {
  getCurrentFilters,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { transactionListFetch } from '@/transaction/transactionListSlice';
import { setTransaction } from '@/transaction/transactionSlice';
import {
  getAndroidVersion,
  getAppVersion,
  getBalance,
  getCategoryColor,
  getCategoryLabel,
  getCategoryText,
  getOutboundStatus,
  getOutboundStatusColor,
  getOutboundStatusText,
  getRequestTime,
  getSource,
  getSourceColor,
  getSourceText,
  getStatus,
  getStatusColor,
  getStatusText,
  getTelcoColor,
  getTelcoName,
  getTelcoText,
  getTransactionAmount,
  getTransactionFee,
  getUpdateTime,
  getUser,
} from '../utils';

export const FloatExchangeList = ({ embedded }) => {
  // console.log('RXD:TransactionList');
  const dispatch = useDispatch();
  const { transactionList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.transactionList;
    }
  );

  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'transactionUuid',
      'agentUuid',
      'fieldAgentUuid',
      'userUuid',
    ]);
    if (!currentFilters.has('category')) {
      currentFilters.set('category', 'exchange');
      // currentFilters.set('status', 'succeeded');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      // console.log('RXD:TransactionList.fetching');
      dispatch(transactionListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  return (
    <>
      {!embedded && <AppBar>Float Exchange</AppBar>}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(transactionListFetch(filters))}
        />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(transaction) => transaction.uuid}
          rows={transactionList ?? []}
          columns={[
            {
              field: 'requestTimestamp',
              headerName: 'Transaction Time',
              valueGetter: w(getRequestTime),
              type: 'dateTime',
              sortingOrder: getSortOrder(),
            },
            {
              field: 'phoneNumber',
              headerName: 'Agent Number',
              minWidth: 160,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              valueGetter: w(getUser),
              sortingOrder: getSortOrder(),
            },
            {
              field: 'telco',
              headerName: 'Network',
              minWidth: 100,
              flex: 1,
              align: 'center',
              headerAlign: 'center',
              sortingOrder: getSortOrder(),
              type: 'singleSelect',
              valueOptions: [
                { value: 'UG_MTN', label: 'MTN' },
                { value: 'UG_AIRTEL', label: 'Airtel' },
              ],
              renderCell: w((transaction) => (
                <Bubble
                  label={getTelcoName(transaction)}
                  background={getTelcoColor(transaction)}
                  color={getTelcoText(transaction)}
                  borderRadius="0"
                  fontWeight="normal"
                />
              )),
            },
            {
              field: 'amount',
              headerName: 'Amount',
              minWidth: 100,
              flex: 1,
              align: 'center',
              type: 'number',
              headerAlign: 'center',
              sortingOrder: getSortOrder(),
              valueGetter: w(getTransactionAmount),
            },
            {
              field: 'balance',
              headerName: 'Balance',
              minWidth: 160,
              flex: 1,
              align: 'center',
              type: 'number',
              headerAlign: 'center',
              sortingOrder: getSortOrder(),
              valueGetter: w(getBalance),
            },
            {
              field: 'statuses',
              headerName: 'Inbound',
              minWidth: 100,
              flex: 1,
              sortingOrder: getSortOrder(),
              align: 'center',
              headerAlign: 'center',
              renderCell: (param) => (
                <Bubble
                  align={'center'}
                  label={getStatus(param.row)}
                  background={getStatusColor(param.row)}
                  color={getStatusText(param.row)}
                  sx={{ textAlign: 'center' }}
                />
              ),
            },
            {
              field: 'status',
              headerName: 'Outbound',
              minWidth: 100,
              flex: 1,
              sortingOrder: getSortOrder(),
              align: 'center',
              headerAlign: 'center',
              renderCell: (param) => (
                <Bubble
                  align={'center'}
                  label={getOutboundStatus(param.row)}
                  color={getOutboundStatusText(param.row)}
                  background={getOutboundStatusColor(param.row)}
                  sx={{ textAlign: 'center' }}
                />
              ),
            },
            {
              field: 'fee',
              headerName: 'Fee',
              minWidth: 100,
              flex: 1,
              type: 'number',
              align: 'center',
              headerAlign: 'center',
              sortingOrder: getSortOrder(),
              valueGetter: w(getTransactionFee),
            },
            {
              field: 'clientName',
              headerName: 'Client Name',
              minWidth: 160,
              flex: 1,
              sortingOrder: getSortOrder(),
              align: 'center',
              headerAlign: 'center',
            },
            {
              field: 'category',
              headerName: 'Category',
              minWidth: 100,
              flex: 1,
              align: 'center',
              headerAlign: 'center',
              type: 'singleSelect',
              sortingOrder: getSortOrder(),
              valueOptions: [
                { value: 'airtime', label: 'Airtime' },
                { value: 'balance', label: 'Balance' },
                { value: 'deposit', label: 'Deposit' },
                { value: 'float_transfer', label: 'Float Transfer' },
                { value: 'loan_repayment', label: 'Loan Repayment' },
                { value: 'paybill', label: 'Paybill' },
                { value: 'withdrawal', label: 'Withdrawal' },
                { value: 'exchange', label: 'Exchange' },
              ],
              renderCell: w((transaction) => (
                <Bubble
                  label={getCategoryLabel(transaction)}
                  background={getCategoryColor(transaction)}
                  color={getCategoryText(transaction)}
                  borderRadius="0"
                  fontWeight="normal"
                />
              )),
            },
            {
              field: 'androidVersion',
              headerName: 'Android',
              minWidth: 160,
              flex: 1,
              sortingOrder: getSortOrder(),
              valueGetter: w(getAndroidVersion),
            },
            {
              field: 'appVersion',
              headerName: 'App Version',
              minWidth: 160,
              flex: 1,
              sortingOrder: getSortOrder(),
              valueGetter: w(getAppVersion),
            },
            {
              field: 'updateTimestamp',
              headerName: 'Completion Time',
              valueGetter: w(getUpdateTime),
              width: 200,
              type: 'dateTime',
              sortingOrder: getSortOrder(),
            },
            {
              field: 'source',
              headerName: 'Source',
              minWidth: 160,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              sortingOrder: getSortOrder(),
              type: 'singleSelect',
              valueOptions: [
                { value: 'in_app', label: 'In-App' },
                { value: 'sms', label: 'SMS' },
              ],
              renderCell: w((transaction) => (
                <Bubble
                  label={getSource(transaction)}
                  background={getSourceColor(transaction)}
                  color={getSourceText(transaction)}
                  borderRadius="0"
                  fontWeight="normal"
                />
              )),
            },
          ]}
          onCellClick={(param, e) => {
            const transaction = param.row;
            if (e.ctrlKey) {
              window.open(`/exchange/${transaction.uuid}`);
            } else {
              dispatch(setTransaction(transaction));
              navigate(`/exchange/${transaction.uuid}`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('FloatExchangeList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            columns: {
              columnVisibilityModel: getColumnVisibility('FloatExchangeList', {
                clientName: false,
                category: false,
                balance: false,
                appVersion: false,
                androidVersion: false,
                updateTimestamp: false,
                source: false,
              }),
            },
          }}
        />
      </div>
    </>
  );
};
