import { hasAnyRoles, rolesToSet } from '@/auth/helpers';
import { AppBar, Error, Loading, useInitial, useUpdated } from '@/common';
import AddIcon from '@mui/icons-material/Add';
import QuestionMarkRounded from '@mui/icons-material/QuestionMarkRounded';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { useDispatch, useSelector } from 'react-redux';
import {
  fieldAgentPositionDelete,
  fieldAgentPositionSave,
} from './fieldAgentPositionFormSlice';
import { fieldAgentPositionListFetch } from './fieldAgentPositionListSlice';
import {
  getPositionDropdown,
  limitHierarchyToMe,
  makeHierarchy,
} from './utils';
import { captureEvent, logButtonEvents } from '@/firebase';

export const FieldAgentPositionForm = () => {
  // console.log('RXD:FieldAgentPositionForm');
  const dispatch = useDispatch();

  const { fieldAgentPositionList, isLoading, error } = useSelector((state) => {
    return state.fieldAgentPositionList;
  });

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState({
    current: null,
    parent: null,
  });

  // initial data
  useInitial(() => {
    // console.log('RXD:FieldAgentPositionForm: initial');
    dispatch(
      fieldAgentPositionListFetch(new URLSearchParams('pp=1:200&oo=name'))
    );
  });

  useUpdated(() => {
    const [root] = makeHierarchy(fieldAgentPositionList);
    setHierarchy(root);
  }, [fieldAgentPositionList]);

  const [hierarchy, setHierarchy] = useState({});
  const [scale, setScale] = useState(1);

  const handleClose = () => setOpen(false);

  const handleOpenModal = (data) => (e) => {
    setEditData(data);
    setOpen(true);
  };

  const handlePostSave = () => {
    setHierarchy(hierarchy);
  };

  const handlePostDelete = () => {
    dispatch(
      fieldAgentPositionListFetch(new URLSearchParams('pp=1:200&oo=name'))
    );
    logButtonEvents('FIELD_AGENT_POSITION_DELETE', {});
  };

  const handleOpenDelete = () => {
    setOpen(false);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => setOpenDelete(false);

  const getTreeNodes = (children) => {
    return (children ?? []).map((hierarchy) => (
      <TreeNode
        key={hierarchy?.name}
        label={<Position onClick={handleOpenModal} hierarchy={hierarchy} />}
      >
        {getTreeNodes(hierarchy?.children)}
      </TreeNode>
    ));
  };

  const myHierarchy = limitHierarchyToMe(hierarchy);

  return (
    <>
      <AppBar>Manage Positions</AppBar>

      <Form
        open={open}
        data={editData}
        hierarchy={hierarchy}
        handlePostSave={handlePostSave}
        handleClose={handleClose}
        handleOpenDelete={handleOpenDelete}
      />

      <DeleteForm
        open={openDelete}
        data={editData}
        handlePostDelete={handlePostDelete}
        hierarchy={hierarchy}
        handleClose={handleCloseDelete}
      />

      {error ? (
        <Error error={error} />
      ) : isLoading ? (
        <Loading />
      ) : (
        <Grid
          container
          spacing={2}
          sx={{
            '& .MuiCardActionArea-root': {
              padding: '8px',
              paddingBottom: '16px',
            },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              position: 'relative',
            }}
          >
            <Box sx={{ position: 'absolute', top: 30, left: 30, zIndex: 2 }}>
              <Button
                variant="contained"
                color="inherit"
                disabled={scale >= 2}
                onClick={(e) => setScale((w) => w + 0.1)}
              >
                +
              </Button>
              <Button
                variant="contained"
                color="inherit"
                disabled={scale <= 0.2}
                onClick={(e) => setScale((w) => w - 0.1)}
              >
                -
              </Button>
              <Button
                variant="contained"
                color="inherit"
                onClick={(e) => setScale(1)}
              >
                ↺
              </Button>
            </Box>
            <Card
              sx={{
                overflow: 'auto',
                height: 'calc(100vh - 200px)',
                width: 'calc(100vw - 50px)',
              }}
            >
              <CardContent
                sx={{
                  transform: `scale(${scale})`,
                  transformOrigin: `left top`,
                  // height: 'calc(100vh - 200px)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Tree
                  label={
                    myHierarchy === null ? (
                      <Error
                        sx={{
                          display: 'inline-block',
                          position: 'relative',
                          overflow: 'visible',
                          minWidth: '130px',
                          maxWidth: '160px',
                        }}
                        error="Failed to load positions. Check if this user is used by a member of the team"
                      />
                    ) : (
                      <Position
                        onClick={handleOpenModal}
                        hierarchy={myHierarchy}
                      />
                    )
                  }
                  lineHeight="40px"
                  nodePadding="70px"
                >
                  {getTreeNodes(myHierarchy?.children)}
                </Tree>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const Position = ({ onClick, hierarchy }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <Card
      sx={{
        display: 'inline-block',
        position: 'relative',
        overflow: 'visible',
        minWidth: '130px',
        maxWidth: '160px',
      }}
    >
      <CardActionArea
        onClick={
          typeof onClick == 'function'
            ? onClick({ current: hierarchy, parent: null })
            : null
        }
        sx={{
          paddingTop: '16px',
        }}
      >
        <Typography variant="h6" sx={{ fontSize: '1rem' }}>
          {hierarchy?.name}
        </Typography>
        <Typography variant="overline" sx={{ fontSize: '0.7rem' }}>
          {hierarchy?.description}
        </Typography>
      </CardActionArea>
      {/* <Fab
        size="small"
        aria-label="reassign"
        sx={{
          position: 'absolute',
          top: '-10px',
          right: 'calc(50% - 10px)',
          width: '20px',
          height: '20px',
          minHeight: '20px',
        }}
        disabled={!hierarchy.canEdit}
      >
        <ReassignIcon sx={{ width: '10px', height: '10px' }} />
      </Fab> */}
      {hasAnyRoles(user.role, 'FIELD_AGENT_MANAGER') && (
        <Fab
          size="small"
          aria-label="add"
          sx={{
            position: 'absolute',
            bottom: '-10px',
            right: 'calc(50% - 10px)',
            width: '20px',
            height: '20px',
            minHeight: '20px',
          }}
          disabled={!(hierarchy?.canAddChild ?? false)}
          onClick={
            typeof onClick == 'function'
              ? onClick({ current: null, parent: hierarchy })
              : null
          }
        >
          <AddIcon sx={{ width: '10px', height: '10px' }} />
        </Fab>
      )}
    </Card>
  );
};

const Form = ({
  open,
  handleClose,
  hierarchy,
  handleOpenDelete,
  data,
  handlePostSave,
}) => {
  const { current, parent } = data;

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const getRoles = () => {
    const roles = Array.from(rolesToSet(user?.role));
    roles.sort();
    return roles;
  };

  const formStatus = useRef('');
  const currentData = useRef(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [newParent, setNewParent] = useState('');
  const [role, setRole] = useState([]);
  const [canManageAgents, setCanManageAgents] = useState(false);
  const [canProspectAgents, setCanProspectAgents] = useState(false);
  const [canSeeAllAgents, setCanSeeAllAgents] = useState(false);
  const [allPositions, setAllPositions] = useState([]);

  const canEdit = current?.canEdit ?? true;
  const canDelete = current?.canDelete ?? true;

  const { error, status, fieldAgentPosition } = useSelector(
    (state) => state.fieldAgentPositionForm
  );

  const w = (callback) => (e) => callback(e.target.value);

  useEffect(() => {
    setName(current?.name ?? '');
    setDescription(current?.description ?? '');
    setNewParent('');
    setRole(current?.role ? current.role.split(',') : []);
    setCanManageAgents(current?.canManageAgents ?? false);
    setCanProspectAgents(current?.canProspectAgents ?? false);
    setCanSeeAllAgents(current?.canSeeAllAgents ?? false);
  }, [current, open]);

  useEffect(() => {
    const list = [];
    getPositionDropdown(false, list, current?.id, [hierarchy], '');
    // console.log(`setAllPositions`, { list });
    let found = false;
    let acceptable = [];
    for (const item of list) {
      found = item.value.parentId === current?.id;
      if (found && item.value.parentId !== current?.parentId) {
        continue;
      }
      acceptable.push(item);
    }
    setAllPositions(acceptable);
    for (const value of list) {
      if (current?.parentId === value?.value?.id) {
        setNewParent(value);
        break;
      }
    }
  }, [hierarchy, current]);

  useEffect(() => {
    if (formStatus.current === 'loading' && status === 'loading') {
      currentData.current.id = fieldAgentPosition.id;
    }

    if (formStatus.current !== status) {
      if (formStatus.current === 'loading' && status === 'editing' && !error) {
        if (parent) {
          currentData.current.id = fieldAgentPosition.id;
          currentData.current.canEdit = true;
          currentData.current.canAddChild = true;
          currentData.current.children = [];
          parent.children.push(currentData.current);
        }
        handlePostSave();
        handleClose();
      }
      formStatus.current = status;
    }
  }, [
    error,
    parent,
    formStatus,
    status,
    fieldAgentPosition,
    handleClose,
    handlePostSave,
  ]);

  const handleSave = (e) => {
    e.preventDefault();
    const data = current ?? {};
    data.name = name;
    data.canManageAgents = canManageAgents;
    data.canProspectAgents = canProspectAgents;
    data.canSeeAllAgents = canSeeAllAgents;
    data.description = description;
    data.role = role.join(',');
    if (
      newParent &&
      newParent?.value?.id &&
      newParent?.value?.id !== current?.parentId
    ) {
      data.parent = { id: newParent?.value?.id };
    }
    if (parent) {
      data.parent = { id: parent.id };
    }
    currentData.current = data;
    dispatch(fieldAgentPositionSave(current?.id, data));
    captureEvent(
      parent
        ? 'ADD_FIELD_AGENT_POSITION_FORM'
        : 'EDIT_FIELD_AGENT_POSITION_FORM',
      {}
    );
  };

  // console.log({ parent, newParent, current, allPositions });

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose}>
      <Box component="form" onSubmit={handleSave}>
        <DialogTitle>
          {parent ? 'Add Position' : `Position: ${current?.name}`}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {error && <Error error={error} />}

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              required
              value={name}
              onChange={w(setName)}
              disabled={!canEdit}
              autoComplete="off"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              id="description"
              label="Description"
              variant="outlined"
              required
              value={description}
              onChange={w(setDescription)}
              disabled={!canEdit}
              autoComplete="off"
            />
          </FormControl>
          {!parent && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Autocomplete
                id="new-positions"
                value={newParent}
                options={allPositions}
                required
                fullWidth
                isOptionEqualToValue={(option, value) =>
                  option?.value?.id === value?.value?.id
                }
                getOptionLabel={(option) => option?.label ?? ''}
                onChange={(event, newValue) => {
                  setNewParent(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Parent"
                    helperText="Switch position and hierarchy to a different parent"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          )}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Autocomplete
              id="role"
              value={role}
              options={getRoles()}
              multiple
              required
              fullWidth
              disableCloseOnSelect
              disabled={!canEdit}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => setRole(newValue)}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Roles" fullWidth />
              )}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={canManageAgents}
                  onChange={(e) => setCanManageAgents(e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  disabled={!canEdit}
                />
              }
              label="Holders of this position can be an agent's direct support contact"
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={canProspectAgents}
                  onChange={(e) => setCanProspectAgents(e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  disabled={!canEdit}
                />
              }
              label="Holders of this position can onboard/prospect agents"
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={canSeeAllAgents}
                  onChange={(e) => setCanSeeAllAgents(e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  disabled={!canEdit}
                />
              }
              label="Holders of this position can view all agents of all field agents in positions below them"
            />
          </FormControl>
        </DialogContent>
        {status === 'loading' ? (
          <Loading />
        ) : (
          canEdit &&
          hasAnyRoles(user.role, 'FIELD_AGENT_MANAGER') && (
            <>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 2,
                }}
              >
                {current ? (
                  <Box>
                    <Button
                      color="error"
                      variant="outlined"
                      onClick={handleOpenDelete}
                      disabled={!canDelete}
                    >
                      Delete
                    </Button>
                    {!canDelete && (
                      <Tooltip title="This can not be delete because it is currently assigned to field agents">
                        <QuestionMarkRounded
                          sx={{
                            border: '1px solid',
                            color: 'grey',
                            borderRadius: '50%',
                            width: '16px',
                            height: '16px',
                            margin: '5px 0 -5px 5px',
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                ) : (
                  <Box></Box>
                )}
                <Box>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                </Box>
              </Box>
            </>
          )
        )}
      </Box>
    </Dialog>
  );
};

const DeleteForm = ({
  open,
  handleClose,
  hierarchy,
  data,
  handlePostDelete,
}) => {
  const { current } = data;

  const dispatch = useDispatch();
  const formStatus = useRef('');
  const [newPosition, setNewPosition] = useState('');
  const [deleteCheck, setDeleteCheck] = useState('');
  const [allPositions, setAllPositions] = useState([]);

  const { error, status } = useSelector(
    (state) => state.fieldAgentPositionForm
  );

  const w = (callback) => (e) => callback(e.target.value);

  const handleSave = (e) => {
    e.preventDefault();
    dispatch(fieldAgentPositionDelete(current?.id, newPosition.value.id));
    logButtonEvents('FIELD_AGENT_POSITION_DELETE', {
      oldPosition: current?.id,
      newPosition: newPosition?.value?.id,
    });
  };

  const onClose = useCallback(() => {
    handleClose();
    setNewPosition(null);
    setDeleteCheck(null);
  }, [handleClose]);

  useEffect(() => {
    const list = [];
    getPositionDropdown(false, list, current?.id, [hierarchy], '');
    // console.log(`setAllPositions`, { list });
    setAllPositions(list);
  }, [hierarchy, current]);

  useEffect(() => {
    if (formStatus.current !== status) {
      if (formStatus.current === 'loading' && status === 'editing' && !error) {
        onClose();
        handlePostDelete();
      }
      formStatus.current = status;
    }
  }, [error, formStatus, status, handlePostDelete, onClose]);

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onClose}>
      <Box component="form" onSubmit={handleSave}>
        <DialogTitle>{`Delete Position: ${current?.name}`}</DialogTitle>
        <Divider />
        <DialogContent>
          {error && <Error error={error} />}

          <FormControl fullWidth sx={{ mb: 2 }}>
            <Autocomplete
              id="new-positions"
              value={newPosition}
              options={allPositions}
              required
              fullWidth
              isOptionEqualToValue={(option, value) =>
                option?.value?.id === value?.value?.id
              }
              getOptionLabel={(option) => option?.label ?? ''}
              onChange={(event, newValue) => {
                setNewPosition(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Set the new position existing field agents will get"
                  fullWidth
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              id="check"
              label="Enter 'DELETE' to confirm action"
              variant="outlined"
              required
              value={deleteCheck}
              onChange={w(setDeleteCheck)}
              autoComplete="off"
            />
          </FormControl>
        </DialogContent>
        {status === 'loading' ? (
          <Loading />
        ) : (
          <>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                padding: 2,
              }}
            >
              <Button
                variant="contained"
                onClick={handleSave}
                color="error"
                type="submit"
                disabled={
                  !(
                    Boolean(newPosition) &&
                    deleteCheck?.toLocaleUpperCase() === 'DELETE'
                  )
                }
              >
                Delete
              </Button>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};
