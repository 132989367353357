import {
  AppBar,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { loanProductListFetch } from './loanProductListSlice';
import { setLoanProduct } from './loanProductSlice';
import {
  getProductDescription,
  getProductInterestRate,
  getProductInterestType,
  getProductName,
  getProductPaymentInterval,
  getProductPrincipalHoliday,
} from './utils';

export const LoanProductList = ({ embedded }) => {
  // console.log('RXD:LoanProductList');
  const dispatch = useDispatch();
  const { loanProductList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.loanProductList;
    }
  );

  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'productUuid',
    ]);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', 'name');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(loanProductListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'name',
      headerName: 'Product Name',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getProductName),
    },
    {
      field: 'interestType',
      headerName: 'Interest Type',
      align: 'left',
      headerAlign: 'left',
      minWidth: 120,
      sortingOrder: getSortOrder(),
      valueGetter: w(getProductInterestType),
    },
    {
      field: 'interestRate',
      headerName: 'Interest Rate',
      headerAlign: 'left',
      align: 'left',
      minWidth: 150,
      sortingOrder: getSortOrder(),
      valueGetter: w(getProductInterestRate),
    },
    {
      field: 'paymentInterval',
      headerName: 'Payment Interval',
      align: 'left',
      headerAlign: 'left',
      minWidth: 160,
      sortingOrder: getSortOrder(),
      valueGetter: w(getProductPaymentInterval),
    },
    {
      field: 'principalHoliday',
      headerName: 'Principal Holiday',
      align: 'center',
      headerAlign: 'left',
      minWidth: 150,

      sortingOrder: getSortOrder(),
      valueGetter: w(getProductPrincipalHoliday),
    },
    {
      field: 'description',
      headerName: 'Description',
      align: 'left',
      headerAlign: 'left',
      minWidth: 300,
      sortingOrder: getSortOrder(),
      valueGetter: w(getProductDescription),
    },
  ];

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            <Button
              disableElevation
              sx={{ textTransform: 'none' }}
              variant="contained"
              border="2px solid"
              size="medium"
              component={RouterLink}
              to="/loans/products/add"
            >
              Add Loan Product
            </Button>
          }
        >
          Loan Products
        </AppBar>
      )}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(loanProductListFetch(filters))}
        />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(loanProduct) => loanProduct.uuid}
          rows={loanProductList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const loanProduct = param.row;
            if (e.ctrlKey) {
              window.open(`/loans/products/${loanProduct.uuid}/edit`);
            } else {
              dispatch(setLoanProduct(loanProduct));
              navigate(`/loans/products/${loanProduct.uuid}/edit`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('LoanProductList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('LoanProductList', {}),
            },
          }}
        />
      </div>
    </>
  );
};
