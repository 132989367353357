import {
  AppBar,
  Info as InfoBase,
  Loading,
  TabPanel,
  useFilters,
} from '@/common';
import { a11yProps } from '@/common/TabPanel';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { LoanPaymentList } from './LoanPaymentList';
import { loanFetch } from './loanSlice';
import {
  getAmount,
  getAmountDue,
  getAmountOutstanding,
  getAmountPaid,
  getDuration,
  getEndDate,
  getInterest,
  getPaymentPlanExpectedAt,
  getPaymentPlanInterest,
  getPaymentPlanPaidAt,
  getPaymentPlanPrincipal,
  getPaymentPlanTotal,
  getPaymentSchedule,
  getStartDate,
  getTotalInterest,
  getWeeklyPayment,
} from './utils';
import { captureEvent } from '@/firebase';

export const LoanDetail = () => {
  // console.log('RXD:LoanDetail');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { loan, isLoading } = useSelector((state) => state.loan);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      (uuid !== loan?.uuid && !isLoading) ||
      (loan?.agent && !('uuid' in loan?.agent) && !isLoading)
    ) {
      dispatch(loanFetch(uuid));
    }
  }, [dispatch, loan, uuid, isLoading]);

  const [filters, setFilters] = useFilters();
  const handleTabChange = (e, index) => {
    setFilters(new URLSearchParams(`tab=${index}`));
    captureEvent('TAB_CHANGE_LOAN_DETAIL', {
      index,
      tab: e?.target?.innerText || '',
    });
  };
  let tab = filters.get('tab');
  tab = tab === null ? 0 : +tab;

  return (
    <>
      <AppBar
        actions={
          Boolean(loan?.request?.uuid) && (
            <>
              <Button disableElevation variant="contained">
                View Agent
              </Button>
              <Button
                disableElevation
                sx={{ textTransform: 'none' }}
                variant="contained"
                border="2px solid"
                size="medium"
                component={RouterLink}
                to={`/loans/requests/${loan.request?.uuid}`}
              >
                View Request
              </Button>
            </>
          )
        }
      >
        Loan: {loan?.uuid === uuid ? loan?.agent?.agentName : uuid}
      </AppBar>

      {!loan || isLoading ? (
        <Loading />
      ) : (
        <>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            sx={{ background: 'white' }}
            aria-label="loan detail tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Overview" value={0} {...a11yProps(0)} />
            <Tab label="Payments" value={1} {...a11yProps(1)} />
            <Tab label="Repayment Schedule" value={2} {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <OverviewSection loan={loan} />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Box sx={{ mt: 1 }}>
              <LoanPaymentList embedded={{ loanUuid: loan.uuid }} />
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <RepaymentSchedule loan={loan} />
          </TabPanel>
        </>
      )}
    </>
  );
};

const Info = ({ label, children, border }) => (
  <InfoBase sx={{ py: 1 }} label={label} border={border ?? true}>
    {children}
  </InfoBase>
);

const OverviewSection = ({ loan }) => (
  <Grid container spacing={2} sx={{ mt: 1 }}>
    <Grid item md={3} xs={12}>
      <AgentDetail agent={loan.agent} />
    </Grid>
    <Grid item md={9} xs={12}>
      <MainDetail loan={loan} />
    </Grid>
  </Grid>
);

const MainDetail = ({ loan }) => (
  <>
    <Card sx={{ mb: 2 }}>
      <Grid container sx={{ px: 2, pb: 1 }}>
        <Grid item md={4} xs={12}>
          <Info label="Loan ID" border={false}>
            {loan.loanId ?? '-'}
          </Info>
        </Grid>
        <Grid item md={4} xs={12}>
          <Info label="Status" border={false}>
            {loan.status ?? '-'}
          </Info>
        </Grid>
      </Grid>
      <Divider />
      <Grid container sx={{ px: 2, pb: 1 }}>
        <Grid item md={4} xs={12}>
          <Info label="Principal" border={false}>
            {getAmount(loan) ?? '-'}
          </Info>
        </Grid>
        <Grid item md={4} xs={12}>
          <Info label="Total" border={false}>
            {getAmountDue(loan)}
          </Info>
        </Grid>
        <Grid item md={4} xs={12}>
          <Info label="Total Paid" border={false}>
            {getAmountPaid(loan)}
          </Info>
        </Grid>
        <Grid item md={4} xs={12}>
          <Info label="Total Outstanding" border={false}>
            {getAmountOutstanding(loan)}
          </Info>
        </Grid>
        <Grid item md={4} xs={12}>
          <Info label="Weekly Payment" border={false}>
            {getWeeklyPayment(loan) ?? '-'}
          </Info>
        </Grid>
      </Grid>
      <Grid container sx={{ px: 2, pb: 1 }}>
        <Grid item md={4} xs={12}>
          <Info label="Duration" border={false}>
            {getDuration(loan)}
          </Info>
        </Grid>
        <Grid item md={4} xs={12}>
          <Info label="Starts" border={false}>
            {getStartDate(loan)}
          </Info>
        </Grid>
        <Grid item md={4} xs={12}>
          <Info label="Ends" border={false}>
            {getEndDate(loan)}
          </Info>
        </Grid>
      </Grid>
      <Grid container sx={{ px: 2, pb: 1 }}>
        <Grid item md={4} xs={12}>
          <Info label="Interest" border={false}>
            {getInterest(loan)}
          </Info>
        </Grid>
        <Grid item md={4} xs={12}>
          <Info label="Total Interest" border={false}>
            {getTotalInterest(loan)}
          </Info>
        </Grid>
      </Grid>
    </Card>
  </>
);

const AgentDetail = ({ agent }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (agent?.uuid) {
      navigate(`/agents/${agent.uuid}`);
    }
  };
  return (
    <Card>
      <CardHeader
        title="Agent Details"
        action={
          <Button size="small" variant="contained" onClick={handleClick}>
            VIEW
          </Button>
        }
      />
      {!('uuid' in agent) ? (
        <Loading />
      ) : (
        <CardContent>
          <Info label="Name">{agent.agentName}</Info>
          <Info label="Gender">{agent.gender}</Info>
          <Info label="National ID">{agent.nationalId}</Info>
        </CardContent>
      )}
    </Card>
  );
};

const RepaymentSchedule = ({ loan }) => (
  <TableContainer sx={{ mt: 2 }} component={Paper}>
    <Table aria-label="repayment schedule table">
      <TableHead sx={{ backgroundColor: '#E6E8F0', color: '#2a2a2a' }}>
        <TableRow>
          <TableCell>Repayment Date</TableCell>
          <TableCell>Currency</TableCell>
          <TableCell>Total</TableCell>
          <TableCell>Principal</TableCell>
          <TableCell>Interest</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Date Paid</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[]
          .concat(getPaymentSchedule(loan))
          .sort((a, b) => (a.date < b.date ? -1 : 1))
          .map((row) => (
            <TableRow key={row.uuid}>
              <TableCell>{getPaymentPlanExpectedAt(row)}</TableCell>
              <TableCell>{row.currency}</TableCell>
              <TableCell>{getPaymentPlanTotal(row)}</TableCell>
              <TableCell>{getPaymentPlanPrincipal(row)}</TableCell>
              <TableCell>{getPaymentPlanInterest(row)}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{getPaymentPlanPaidAt(row)}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </TableContainer>
);
