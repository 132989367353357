import {
  AppBar,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { expenseListFetch } from './expenseListSlice';
import { getAmount, getCreatedAt, getExpensedAt, getKind } from './utils';
import { setExpense } from './expenseSlice';
import { useNavigate } from 'react-router-dom';

export const ExpenseList = ({ mode, embedded }) => {
  // console.log('RXD:ExpenseList');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { expenseList, isLoading, error, lastFilter } = useSelector((state) => {
    return state.expenseList;
  });

  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'a.uuid',
      'fa.uuid',
    ]);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', 'name');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(expenseListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'type',
      headerName: 'Expense name',
      minWidth: 200,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 200,
      valueGetter: w(getAmount),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'kind',
      headerName: 'Schedule',
      minWidth: 200,
      valueGetter: w(getKind),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'expensedAt',
      headerName: 'First occurrence',
      minWidth: 200,
      valueGetter: w(getExpensedAt),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      minWidth: 200,
      valueGetter: w(getCreatedAt),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'comment',
      headerName: 'Comments',
      minWidth: 200,
      sortingOrder: getSortOrder(),
    },
  ];

  return (
    <>
      {!embedded && <AppBar>Expenses</AppBar>}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(expenseListFetch(filters))}
        />
      )}

      <div
        style={{
          height: 'calc(100vh - 190px)',
          minHeight: 300,
        }}
      >
        <OparetaTable
          getRowId={(expense) => expense.uuid}
          rows={expenseList ?? []}
          columns={columns}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('ExpenseList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onCellClick={(param, e) => {
            const expense = param.row;
            const modeUuid = embedded['a.uuid']
              ? embedded['a.uuid']
              : embedded['fa.uuid']
                ? embedded['fa.uuid']
                : null;
            if (modeUuid) {
              const url = `/${mode}/${modeUuid}/expenses/${expense.uuid}`;
              if (e.ctrlKey) {
                window.open(url);
              } else {
                dispatch(setExpense(expense));
                navigate(url);
              }
            }
          }}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('ExpenseList', {}),
            },
          }}
        />
      </div>
    </>
  );
};
