import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  status: 'editing',
  error: '',
};

const agentContractFormSlice = createSlice({
  name: 'agentContractForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const agentRegenerateContract = (uuid) => (dispatch) => {
  dispatch(setLoading(true));

  getApi()
    .post(`/api/agents/${uuid}/contract`)
    .then((response) => {
      dispatch(resetState());
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { resetState, setLoading, setError } =
  agentContractFormSlice.actions;

export const agentContractFormReducer = agentContractFormSlice.reducer;
