import { numberFormat } from '@/common';

export const getShopName = (report) => {
  return report?.account?.agent?.agentName;
};

export const getUsdOpeningBalance = (report) => {
  const amount = (report.reports || []).find(
    (item) => item.currency === 'USD'
  )?.openingBalance;
  return numberFormat(amount);
};

export const getCdfOpeningBalance = (report) => {
  const amount = (report.reports || []).find(
    (item) => item.currency === 'CDF'
  )?.openingBalance;
  return numberFormat(amount);
};

export const getUsdClosingBalance = (report) => {
  const amount = (report.reports || []).find(
    (item) => item.currency === 'USD'
  )?.closingBalance;
  return numberFormat(amount);
};

export const getCdfClosingBalance = (report) => {
  const amount = (report.reports || []).find(
    (item) => item.currency === 'CDF'
  )?.closingBalance;
  return numberFormat(amount);
};

export const getAccountDisplayName = (report) => {
  return report?.account?.phoneNumber;
};
