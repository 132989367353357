import { AppBar, Bubble, Error, getSortOrder, useFilters } from '@/common';
import {
  getColumnVisibility,
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
  setColumnVisibility,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { FEATURES, hasFeature } from '@/common/features';
import { paginationValue } from '@/common/filter';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { transactionListFetch } from './transactionListSlice';
import { setTransaction } from './transactionSlice';
import {
  getAmount,
  getAndroidVersion,
  getAppVersion,
  getBalance,
  getCategoryColor,
  getCategoryLabel,
  getCategoryText,
  getCategoryTextLabel,
  getCommission,
  getExternalId,
  getParserCategories,
  getParserTelcos,
  getRecordTime,
  getRequestTime,
  getSource,
  getSourceColor,
  getSourceLabel,
  getSourceText,
  getSources,
  getStatus,
  getStatusColor,
  getStatusText,
  getSuperAgentCommission,
  getTelcoColor,
  getTelcoName,
  getTelcoNameLabel,
  getTelcoText,
  getUpdateTime,
  getUser,
} from './utils';

export const TransactionList = ({ embedded }) => {
  // console.log('RXD:TransactionList');
  const dispatch = useDispatch();
  const { transactionList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.transactionList;
    }
  );

  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'transactionUuid',
      'agentUuid',
      'fieldAgentUuid',
      'userUuid',
    ]);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-requestTimestamp');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      // console.log('RXD:TransactionList.fetching');
      dispatch(transactionListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'requestTimestamp',
      headerName: 'Transaction Time',
      valueGetter: w(getRequestTime),
      width: 200,
      type: 'dateTime',
      sortingOrder: getSortOrder(),
      range: true,
    },
    {
      field: 'phoneNumber',
      headerName: 'Agent Number',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      valueGetter: w(getUser),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'telco',
      headerName: 'Network',
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      type: 'singleSelect',
      valueOptions: getParserTelcos().map((telco) => ({
        value: telco,
        label: getTelcoNameLabel(telco),
      })),
      renderCell: w((transaction) => (
        <Bubble
          label={getTelcoName(transaction)}
          background={getTelcoColor(transaction)}
          color={getTelcoText(transaction)}
          borderRadius="0"
          fontWeight="normal"
        />
      )),
    },
    {
      field: 'externalId',
      headerName: 'External ID',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      valueGetter: w(getExternalId),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 160,
      flex: 1,
      align: 'right',
      type: 'number',
      headerAlign: 'right',
      sortingOrder: getSortOrder(),
      valueGetter: w(getAmount),
    },
    {
      field: 'balance',
      headerName: 'Balance',
      minWidth: 160,
      flex: 1,
      align: 'right',
      type: 'number',
      headerAlign: 'right',
      sortingOrder: getSortOrder(),
      valueGetter: w(getBalance),
    },
    {
      field: 'category',
      headerName: 'Category',
      minWidth: 160,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'singleSelect',
      sortingOrder: getSortOrder(),
      valueOptions: getParserCategories().map((category) => ({
        value: category,
        label: getCategoryTextLabel(category),
      })),
      renderCell: w((transaction) => (
        <Bubble
          label={getCategoryLabel(transaction)}
          background={getCategoryColor(transaction)}
          color={getCategoryText(transaction)}
          borderRadius="0"
          fontWeight="normal"
        />
      )),
    },
    {
      field: 'commission',
      headerName: 'Commission',
      minWidth: 160,
      flex: 1,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      sortingOrder: getSortOrder(),
      valueGetter: w(getCommission),
    },
    {
      field: 'superAgentCommission',
      headerName: 'Master Commission',
      minWidth: 160,
      flex: 1,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      sortingOrder: getSortOrder(),
      valueGetter: w(getSuperAgentCommission),
    },
    {
      field: 'mobile',
      headerName: 'Client Number',
      minWidth: 160,
      flex: 1,
      sortingOrder: getSortOrder(),
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'clientName',
      headerName: 'Client Name',
      minWidth: 160,
      flex: 1,
      sortingOrder: getSortOrder(),
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      flex: 1,
      sortingOrder: getSortOrder(),
      renderCell: w((transaction) => (
        <Bubble
          label={getStatus(transaction)}
          background={getStatusColor(transaction)}
          color={getStatusText(transaction)}
          borderRadius="0"
          fontWeight="normal"
        />
      )),
    },
    {
      field: 'androidVersion',
      headerName: 'Android',
      minWidth: 160,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      valueGetter: w(getAndroidVersion),
    },
    {
      field: 'appVersion',
      headerName: 'App Version',
      minWidth: 160,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      valueGetter: w(getAppVersion),
    },
    {
      field: 'updateTimestamp',
      headerName: 'Completion Time',
      valueGetter: w(getUpdateTime),
      width: 200,
      type: 'dateTime',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'recordTimestamp',
      headerName: 'Recorded Time',
      valueGetter: w(getRecordTime),
      width: 200,
      type: 'dateTime',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'source',
      headerName: 'Source',
      minWidth: 160,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortingOrder: getSortOrder(),
      type: 'singleSelect',
      valueOptions: getSources().map((source) => ({
        value: source,
        label: getSourceLabel(source),
      })),
      renderCell: w((transaction) => (
        <Bubble
          label={getSource(transaction)}
          background={getSourceColor(transaction)}
          color={getSourceText(transaction)}
          borderRadius="0"
          fontWeight="normal"
        />
      )),
    },
  ].filter((column) => {
    if (
      (column.field === 'telco' && !hasFeature(FEATURES.TRANSACTION_TELCO)) ||
      (column.field === 'externalId' &&
        !hasFeature(FEATURES.TRANSACTION_EXTERNAL_ID))
    ) {
      return false;
    }
    return true;
  });

  return (
    <>
      {!embedded && <AppBar>Transactions</AppBar>}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(transactionListFetch(filters))}
        />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(transaction) => transaction?.uuid}
          rows={transactionList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const transaction = param.row;
            if (e.ctrlKey) {
              window.open(`/transactions/${transaction.uuid}`);
            } else {
              dispatch(setTransaction(transaction));
              navigate(`/transactions/${transaction.uuid}`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('TransactionList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns, [
              { field: 'requestTimestamp', sort: 'desc' },
            ]),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('TransactionList', {
                clientName: false,
                status: false,
                balance: false,
                superAgentCommission: false,
                appVersion: false,
                androidVersion: false,
                updateTimestamp: false,
                recordTimestamp: false,
                source: false,
              }),
            },
          }}
        />
      </div>
    </>
  );
};
