import { dateFormat, numberFormat } from '@/common';

const DATE_FORMAT = 'MMM DD, YYYY';

const _totalPayment = (loan) =>
  loan.loanAmount * (1 + _totalInterest(loan) / 100);

const _totalInterest = (loan) => loan.monthlyInterest * loan.durationInMonths;

export const getAgent = (loan) => loan?.agent?.agentName;

export const getAmount = (loan) => numberFormat(loan?.loanAmount);

export const getAmountDue = (loan) => numberFormat(loan?.amountDue);

export const getAmountPaid = (loan) => numberFormat(loan?.amountPaid);

export const getAmountOutstanding = (loan) =>
  numberFormat(loan.amountOutstanding);

export const getWeeklyPayment = (loan) =>
  numberFormat(_totalPayment(loan) / (4 * loan.durationInMonths));

export const getDuration = (loan) =>
  `${numberFormat(loan.durationInMonths)} month${
    +loan.durationInMonths === 1 ? '' : 's'
  }`;

export const getInterest = (loan) =>
  `${numberFormat(loan.monthlyInterest)}% per month`;

export const getTotalInterest = (loan) => `${_totalInterest(loan)}%`;

export const getStartDate = (loan) => dateFormat(loan?.startDate, DATE_FORMAT);

export const getEndDate = (loan) => dateFormat(loan?.endDate, DATE_FORMAT);

export const getStatusColor = (loan) => {
  switch (loan.status) {
    case 'on-time':
      return '#317159';
    case 'written-off':
      return '#ffcec9';
    case 'completed':
      return '#eafded';
    case 'delinquent':
      return '#bf1100';
    default:
      return 'white';
  }
};

export const getStatusBackground = (loan) => {
  switch (loan.status) {
    case 'on-time':
      return '#DCF2EA';
    case 'written-off':
      return '#bf1100';
    case 'completed':
      return '#51af5d';
    case 'delinquent':
      return '#ffcec9';
    default:
      return 'darkgrey';
  }
};

export const getStatuses = () => [
  'on-time',
  'delinquent',
  'completed',
  'written-off',
];

export const getPaymentAmount = (payment) => numberFormat(payment?.amount ?? 0);
export const getRepaymentLoanAmount = (loan) =>
  numberFormat(loan?.loanAmount ?? 0);
export const getRepaymentLoanCurrency = (loan) => loan?.currency ?? 'UGX';

export const getPaymentDate = (payment) => dateFormat(payment?.date);

export const getPaymentLoanId = (payment) => payment.loan?.loanId ?? '-';

export const getPaymentAgent = (payment) => payment.loan?.agent?.agentName;

export const getPaymentType = (payment) => payment?.type?.toUpperCase();

export const getPaymentStatuses = () => [
  'pending',
  'reconciled',
  'orphaned',
  'rejected',
];

export const getPaymentStatusColor = (status) => {
  switch (status) {
    case 'pending':
      return '#DCF2EA';
    case 'reconciled':
      return '#D6E0FF';
    case 'orphaned':
      return '#E0B0FF';
    case 'rejected':
      return '#E7E4F9';
    default:
      return '#edeff5';
  }
};

export const getPaymentStatusText = (status) => {
  switch (status) {
    case 'pending':
      return '#317159';
    case 'reconciled':
      return '#2952CC';
    case 'orphaned':
      return '#673147';
    case 'rejected':
      return '#6E62B6';
    default:
      return '#474d66';
  }
};

export const getPaymentSources = () => ['sms', 'sheet', 'manual'];

export const getPaymentSourceLabel = (source) => {
  switch (source) {
    case 'sms':
      return 'SMS';
    case 'sheet':
    case 'manual':
    default:
      return source;
  }
};

export const getPaymentSourceColor = (source) => {
  switch (source) {
    case 'sms':
      return '#DCF2EA';
    case 'sheet':
      return '#D6E0FF';
    case 'manual':
      return '#E0B0FF';
    default:
      return '#edeff5';
  }
};

export const getPaymentSourceText = (source) => {
  switch (source) {
    case 'sms':
      return '#317159';
    case 'sheet':
      return '#2952CC';
    case 'manual':
      return '#673147';
    default:
      return '#474d66';
  }
};

export const getPaymentSchedule = (loan) => loan?.paymentPlans ?? [];

export const getRequestTermUnits = () => ['week', 'month', 'year'];

export const getRequestCurrencies = () => [
  import.meta.env?.VITE_CURRENCY_CODE ?? 'UGX',
];

export const getRequestAgentName = (loanRequest) =>
  loanRequest?.agent?.agentName;

export const getRequestRequestedAmount = (loanRequest) =>
  numberFormat(loanRequest?.requestedAmount);

export const getRequestRequestedTerm = (loanRequest) =>
  `${loanRequest.requestedTerm} ${loanRequest.requestedTermUnit}`;

export const getRequestTerm = (loanRequest) =>
  `${loanRequest.term} ${loanRequest.termUnit}`;

export const getRequestPrincipal = (loanRequest) =>
  numberFormat(loanRequest.principal);

export const getRequestInterest = (loanRequest) =>
  numberFormat(loanRequest.interest);

export const getRequestTotal = (loanRequest) => numberFormat(loanRequest.total);

export const getRequestInterestRate = (loanRequest) =>
  `${numberFormat(loanRequest.interestRate)}% per ${
    loanRequest.interestRateIntervalUnit
  }`;

export const getRequestPaymentInterval = (loanRequest) =>
  `${loanRequest.paymentInterval} ${loanRequest.paymentIntervalUnit}`;

export const getRequestPrincipalHoliday = (loanRequest) =>
  `${loanRequest.principalHoliday} ${loanRequest.principalHolidayUnit}`;

export const getRequestDescription = (loanRequest) => loanRequest?.description;

export const getRequestCurrency = (loanRequest) => loanRequest?.currency;

export const getRequestLoanProduct = (loanRequest) =>
  loanRequest?.product?.name ?? '-';

export const getRequestEligible = (loanRequest) => loanRequest.eligible;

export const getRequestMessage = (loanRequest) => loanRequest?.message ?? '-';

export const getRequestAgentFeedback = (loanRequest) =>
  loanRequest?.agentFeedback ?? '-';

export const getRequestStatus = (loanRequest) => loanRequest?.status;

export const getRequestCreatedAt = (loanRequest) =>
  dateFormat(loanRequest?.createdAt) ?? '-';

export const getRequestSignedAt = (loanRequest) =>
  dateFormat(loanRequest?.signedAt) ?? '-';

export const getRequestStatusColor = (loanRequest) => {
  switch (loanRequest.status) {
    case 'requested':
      return '#2952CC';
    case 'pending':
      return '#4a4a4a';
    case 'approved':
      return '#317159';
    case 'disbursed':
      return '#DCF2EA';
    case 'agent-signed':
      return '#eafded';
    case 'rejected':
    case 'agent-rejected':
      return '#bf1100';
    default:
      return 'white';
  }
};

export const getRequestStatusBackground = (loanRequest) => {
  switch (loanRequest.status) {
    case 'requested':
      return '#D6E0FF';
    case 'pending':
      return '#ffcf44';
    case 'approved':
      return '#DCF2EA';
    case 'disbursed':
      return '#317159';
    case 'agent-signed':
      return '#51af5d';
    case 'rejected':
    case 'agent-rejected':
      return '#ffcec9';
    default:
      return 'darkgrey';
  }
};

export const getRequestStatuses = () => [
  'requested',
  'pending',
  'approved',
  'agent-signed',
  'disbursed',
  'rejected',
  'agent-rejected',
];

export const getRequestTableauUrl = (loanRequest) =>
  encodeURI(
    `https://prod-uk-a.online.tableau.com/#/site/opareta/views/LoanApprovalDashboard/LoanApprovalDashboard?agentName=${getRequestAgentName(
      loanRequest
    )}`
  );

export const getProductName = (loanProduct) => loanProduct?.name;

export const getProductInterestType = (loanProduct) =>
  loanProduct?.interestType;

export const getProductInterestRate = (loanProduct) =>
  `${numberFormat(loanProduct.interestRate)}% per ${
    loanProduct.interestRateIntervalUnit
  }`;

export const getProductPaymentInterval = (loanProduct) =>
  `${loanProduct.paymentInterval} ${loanProduct.paymentIntervalUnit}`;

export const getProductPrincipalHoliday = (loanProduct) =>
  `${loanProduct.principalHoliday} ${loanProduct.principalHolidayUnit}`;

export const getProductDescription = (loanProduct) => loanProduct?.description;

export const getPaymentPlanExpectedAt = (plan) =>
  dateFormat(plan.expectedAt, 'MMM DD, YYYY');

export const getPaymentPlanPaidAt = (plan) =>
  dateFormat(plan.paidAt, 'MMM DD, YYYY');

export const getPaymentPlanTotal = (plan) => numberFormat(plan?.total);

export const getPaymentPlanPrincipal = (plan) => numberFormat(plan?.principal);

export const getPaymentPlanInterest = (plan) => numberFormat(plan?.interest);

export const getContractName = (loanContract) => loanContract?.name;

export const getContractSignature = (loanContract) =>
  loanContract?.signaturePhotoUrl;

export const getEditDate = (date) => dateFormat(date, 'YYYY-MM-DD');

export const getRuleFirstAfter = (rule) =>
  dateFormat(rule.firstLoanAfter, 'MMM DD, YYYY');

export const getRuleFirstBefore = (rule) =>
  dateFormat(rule.firstLoanBefore, 'MMM DD, YYYY');

export const getRuleLastAfter = (rule) =>
  dateFormat(rule.lastLoanAfter, 'MMM DD, YYYY');

export const getRuleLastBefore = (rule) =>
  dateFormat(rule.lastLoanBefore, 'MMM DD, YYYY');

export const getContractVariables = () => [
  ['{agent.name}', "The agent's name"],
  ['{agent.address}', "The agent's address"],
  ['{agent.email}', "The agent's email"],
  ['{agent.national_id}', "The agent's national ID number"],
  ['{agent.line}', 'The phone number of the line disbursed to'],
  ['{agent.line_name}', 'The name of the line disbursed to'],
  ['{agent.agent_id}', 'The agent ID of the line disbursed to'],
  ['{loan.currency}', "The loan's currency"],
  ['{loan.signed_date}', 'The date the agent signed the loan contract'],
  ['{loan.created_date}', 'The date the request or offer was made'],
  ['{loan.principal}', "The loan's principal"],
  ['{loan.interest}', "The loan's interest"],
  ['{loan.interest_rate}', "The loan's interest rate"],
  ['{loan.interest_interval}', "The loan's interest interval e.g. month"],
  ['{loan.term}', "The loan's term or duration"],
  ['{loan.term_unit}', "The loan's term or duration unit e.g. month"],
  ['{loan.cycle_payment}', 'The amount paid for payment cycle'],
  ['{loan.cycle_interval}', 'The interval for each payment cycle'],
  ['{loan.payment_dates}', "The list of the loan's payment dates"],
  ['{loan.payment_plan}', "The table of the loan's payments & dates"],
  ['{signature.lender}', 'The signature of the lender'],
  ['{signature.borrower}', 'The signature of the borrow'],
];

export const getUserSegmentations = () => [
  'Platinum',
  'Gold',
  'Silver',
  'Bronze',
  'LPA',
  'Inactive',
  'Unclassified',
];

export const reviewMessages = [
  {
    group: 'Approve',
    message:
      'Your loan has been approved. Thank you for doing business with Opareta!',
  },
  {
    group: 'Approve',
    message:
      'We have adjusted the size of your loan based on your current level of transaction activity. We can increase the size of your loan(s) once we see activity indicating your ability to repay a larger loan. Thank you for your continued business with Opareta!',
  },
  {
    group: 'Offer',
    message:
      'Your downsized loan has been approved. Thank you for continuing to do business with Opareta!',
  },
  {
    group: 'Offer',
    message:
      'We have made adjustments to your loan request based on input from your ABA. Thank you for continuing to do business with Opareta!',
  },
  {
    group: 'Reject',
    message:
      'You still have an outstanding balance with us. We invite you to apply for another loan with Opareta once you have paid off your balance.',
  },
  {
    group: 'Reject',
    message:
      'We need to see more business activity from you before we can approve you for this loan. Increase engagement with the Opareta App to receive a loan soon! Please reach out to your ABA if you are having any issues with the app.',
  },
  {
    group: 'Reject',
    message:
      'We need to get to know you better before we can approve this loan. Please keep using the Opareta App for at least a week so we can quickly offer you a loan! We encourage you to apply for this loan after at least 7 days of activity.',
  },
  {
    group: 'Reject',
    message:
      'We would like to see better repayment behavior before approving a new loan. Please continue transacting through the app to build up your credit profile, and we will be monitoring to evaluate your ability to repay a future loan.\nWe cannot approve you for a larger loan if there are challenges in repaying a smaller loan.\nAlternatively, you can reach out to your ABA if you would like to request a downsized loan.',
  },
];

export const getReviewMessage = (
  loanRequest,
  principal,
  term,
  termUnit,
  status
) => {
  const fewTransactions = ['unclassified', 'inactive', 'lpa'];

  if (status === 'approved') {
    if (
      principal === loanRequest?.requestedPrincipal ||
      term === loanRequest?.requestedTerm ||
      termUnit === loanRequest?.requestedTermUnit
    ) {
      return reviewMessages[0];
    } else {
      return reviewMessages[1];
    }
  } else if (status === 'rejected') {
    if (loanRequest?.totalLoans !== loanRequest?.totalCompleteLoans) {
      return reviewMessages[4];
    } else if (fewTransactions.indexOf(loanRequest?.classification) !== -1) {
      return reviewMessages[6];
    }
  }
  return '';
};

export const getTransaction = (loan) => loan?.transaction?.telco;

export const getTelcoName = ({ transaction }) => {
  if (transaction?.telco && transaction.telco.indexOf('_') > -1) {
    return transaction.telco.split('_').reverse().join(' ');
  }
  return transaction?.telco;
};

export const getTelcoColor = ({ transaction }) => {
  if (transaction?.telco === 'UG_MTN') {
    return '#ffcf44 ';
  } else if (transaction?.telco === 'UG_AIRTEL') {
    return '#ffcec9';
  }
};

export const getTelcoText = ({ transaction }) => {
  if (transaction?.telco === 'UG_MTN') {
    return '#4a4a4a';
  } else if (transaction?.telco === 'UG_AIRTEL') {
    return '#bf1100';
  }
};

export const getUser = ({ transaction }) => {
  if (transaction?.user) {
    return (
      transaction.user.displayName ??
      transaction.user.phoneNumber ??
      transaction.user.email
    );
  }
  return transaction.userUuid;
};
