import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from '@/common';
import { useCallback, useEffect, useState } from 'react';

const AutoCompleteFilterInput = (props) => {
  const { column, applyValue, value } = props;
  const [inputValue, setInputValue] = useState('');
  let fetchOptions = (v) => null;
  let getOptionValue = (v) => v?.uuid ?? v;
  let getOptionLabel = (v) => v.name ?? '';

  fetchOptions = column?.fetchOptions ? column?.fetchOptions : fetchOptions;
  getOptionLabel = column?.getOptionLabel
    ? column?.getOptionLabel
    : getOptionLabel;
  getOptionValue = column?.getOptionValue
    ? column?.getOptionValue
    : getOptionValue;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => fetchOptions(value), 1000),
    [column.field]
  );

  useEffect(() => handleSearch(inputValue), [handleSearch, inputValue]);

  const handleFilterChange = (event, newValue) => {
    applyValue(getOptionValue(newValue));
  };

  return (
    <Autocomplete
      getOptionLabel={getOptionLabel}
      options={column?.options ?? []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => {
        return getOptionValue(option) === getOptionValue(value);
      }}
      value={value}
      onChange={handleFilterChange}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => <TextField {...params} variant="standard" />}
    />
  );
};

const parseObjectValue = (value) => {
  if (value == null || !value?.value) {
    return value;
  }
  return value.value;
};

export const getAutoCompleteOperations = () => [
  {
    value: 'is',
    getApplyFilterFn: (filterItem) => {
      if (filterItem.value == null || filterItem.value === '') {
        return null;
      }
      return ({ value }) =>
        parseObjectValue(value) === parseObjectValue(filterItem.value);
    },
    InputComponent: AutoCompleteFilterInput,
  },
];
