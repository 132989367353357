// noinspection JSValidateTypes,JSCheckFunctionSignatures

import { hasAnyRoles } from '@/auth/helpers';
import {
  AppBar,
  BooleanBubble,
  Error,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { captureEvent } from '@/firebase';
import {
  addFieldAgentFloatThreshold,
  addFloatThreshold,
  agentsSearch,
  fieldAgentsSearch,
  floatCashThresholdsFetch,
  setLoadingAgent,
  setLoadingFieldAgents,
} from '@/float-cash/floatCashThresholdListSlice';
import {
  amountToNumberOrZero,
  getFloatCashAgentName,
  getFloatCashAgentNumberFromSimNumbers,
  getFloatCashComments,
  getFloatCashCurrency,
  getFloatCashDebtThreshold,
  getFloatCashFieldAgentName,
  getFloatCashFieldAgentNumber,
  getFloatCashRequestCashInHandThreshold,
  getFloatCashRequestLimitThreshold,
  getFloatCashRequestThresholdAutoApprove,
  getUpdatedAt,
} from '@/float-cash/util';
import closeIcon from '@/images/icons8_close.png';
import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import { debounce } from '@/common';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

function a11yProps(index) {
  return {
    id: `threshold-tab-${index}`,
    'aria-controls': `threshold-tabpanel-${index}`,
  };
}

export const FloatCashThresholdList = ({ embedded }) => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  const [filters, setFilters] = useFilters();
  const { user } = useSelector((state) => state.auth);
  const [type, setType] = useState('agent');
  const {
    isSubmitting,
    isLoading,
    error,
    thresholds,
    fieldAgentThresholds,
    lastFilter,
  } = useSelector((state) => state.floatCashThresholdList);

  const [addThresholdModalOpen, setAddThresholdModalOpen] = useState(false);
  const [addFieldAgentThresholdModalOpen, setAddFieldAgentThresholdModalOpen] =
    useState(false);

  useEffect(() => {
    if (
      !isSubmitting &&
      (addThresholdModalOpen || addFieldAgentThresholdModalOpen)
    ) {
      handleAddThresholdModalClose();
    }
  }, [isSubmitting]);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded);
    currentFilters.set('type', type);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-updatedAt');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(floatCashThresholdsFetch(currentFilters, type));
    }
  }, [dispatch, filters, setFilters, isLoading, error, embedded, type]);

  const handleOpenAddThresholdModal = () => {
    if (type === 'fieldAgent') {
      setAddFieldAgentThresholdModalOpen(true);
    } else {
      setAddThresholdModalOpen(true);
    }
  };
  const handleAddThresholdModalClose = () => {
    setAddThresholdModalOpen(false);
    setAddFieldAgentThresholdModalOpen(false);
  };

  const w = (callback) => (param) => callback(param.row);

  const handleChange = (e, tabIndex) => {
    const type = tabIndex === 0 ? 'agent' : 'fieldAgent';
    setCurrentTab(tabIndex);
    setType(type);
    setFilters(new URLSearchParams(`type=${type}`));
    captureEvent('TAB_CHANGE_THRESHOLD_LIST', {
      index: tabIndex,
      tab: e?.target?.innerText || '',
    });
  };
  const agentColumns = [
    {
      field: 'updatedAt',
      headerName: 'Last updated',
      type: 'dateTime',
      valueGetter: w(getUpdatedAt),
      minWidth: 200,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'a.agentName',
      headerName: 'Agent Name',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getFloatCashAgentName),
    },
    {
      field: 'a.phoneNumber',
      headerName: 'Agent Number',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getFloatCashAgentNumberFromSimNumbers),
    },
    {
      field: 'currency',
      headerName: 'Currency',
      minWidth: 200,
      valueGetter: w(getFloatCashCurrency),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'dailyDebtThreshold',
      headerName: 'Debt Threshold',
      minWidth: 200,
      valueGetter: w(getFloatCashDebtThreshold),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'dailyFloatRequestLimit',
      headerName: 'Float request max/day',
      minWidth: 200,
      valueGetter: w(getFloatCashRequestLimitThreshold),
      sortingOrder: getSortOrder(),
      type: 'number',
    },
    {
      field: 'autoApproveFloatRequest',
      headerName: 'Auto approve',
      minWidth: 200,
      valueGetter: w(getFloatCashRequestThresholdAutoApprove),
      sortingOrder: getSortOrder(),
      type: 'boolean',
      renderCell: w((transaction) => (
        <BooleanBubble value={transaction?.autoApproveFloatRequest} />
      )),
    },
  ];

  const fieldAgentColumns = [
    {
      field: 'updatedAt',
      headerName: 'Last updated',
      type: 'dateTime',
      valueGetter: w(getUpdatedAt),
      minWidth: 200,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'fa.name',
      headerName: 'TDR Name',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getFloatCashFieldAgentName),
    },
    {
      field: 'fa.phoneNumber',
      headerName: 'Phone',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getFloatCashFieldAgentNumber),
    },
    {
      field: 'currency',
      headerName: 'Currency',
      minWidth: 200,
      valueGetter: w(getFloatCashCurrency),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'dailyDebtThreshold',
      headerName: 'Debt Threshold',
      minWidth: 200,
      valueGetter: w(getFloatCashDebtThreshold),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'cashThreshold',
      headerName: 'Cash in hand threshold',
      minWidth: 200,
      valueGetter: w(getFloatCashRequestCashInHandThreshold),
      sortingOrder: getSortOrder(),
      type: 'number',
    },
    {
      field: 'comments',
      headerName: 'Comments',
      minWidth: 200,
      filterable: false,
      valueGetter: w(getFloatCashComments),
    },
  ];
  return (
    <>
      <AddThresholdModal
        isOpen={addThresholdModalOpen}
        handleAddThresholdModalClose={handleAddThresholdModalClose}
        filters={filters}
        type={type}
      />
      <AddFieldAgentThresholdModal
        isOpen={addFieldAgentThresholdModalOpen}
        handleAddThresholdModalClose={handleAddThresholdModalClose}
        filters={filters}
        type={type}
      />
      {!embedded && (
        <AppBar
          actions={
            hasAnyRoles(user.role, 'RECONCILIATION_ADMIN') ? (
              <>
                <Button
                  disableElevation
                  sx={{ textTransform: 'none' }}
                  variant="contained"
                  border="2px solid"
                  size="medium"
                  component={RouterLink}
                  onClick={() => handleOpenAddThresholdModal()}
                >
                  Add Threshold
                </Button>
              </>
            ) : null
          }
        >
          Balance Thresholds
        </AppBar>
      )}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(floatCashThresholdsFetch(filters))}
        />
      )}

      <Box
        marginBottom={2}
        bgcolor={'white'}
        borderRadius={2}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs value={currentTab} onChange={handleChange}>
          <Tab label="By Agent" {...a11yProps(0)} />
          <Tab label="By TDR" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <Box>
        <TabPanel value={currentTab} index={0} marginBottom={2}>
          <ThresholdList
            type="agent"
            filters={filters}
            setFilters={setFilters}
            thresholds={thresholds}
            isLoading={isLoading}
            columns={agentColumns}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1} marginBottom={2}>
          <ThresholdList
            type="fieldAgent"
            filters={filters}
            setFilters={setFilters}
            thresholds={fieldAgentThresholds}
            isLoading={isLoading}
            columns={fieldAgentColumns}
          />
        </TabPanel>
      </Box>
    </>
  );
};

const ThresholdList = (props) => {
  const { type, filters, setFilters, thresholds, columns, isLoading } = props;
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);
  const navigate = useNavigate();

  const getDetailsLink = (transaction) => {
    return type === 'fieldAgent'
      ? `/float-cash/field-agent-thresholds/${transaction.uuid}`
      : `/float-cash/thresholds/${transaction.uuid}`;
  };

  // const thresholds = () => {
  //   if (type === 'fieldAgent') return fieldAgentThresholds;
  //   else if (type === 'agent') return agentThresholds;
  // };
  return (
    <div>
      <OparetaTable
        getRowId={(threshold) => threshold?.uuid}
        rows={thresholds}
        columns={columns}
        onCellClick={(param, e) => {
          const transaction = param.row;
          if (e.ctrlKey) {
            window.open(getDetailsLink(transaction));
          } else {
            navigate(getDetailsLink(transaction));
          }
        }}
        onColumnVisibilityModelChange={(visible) =>
          setColumnVisibility('FloatCashThresholdList', visible)
        }
        loading={isLoading}
        page={serverPage - 1}
        onFilterModelChange={getOnFilterModelChange(
          filters,
          setFilters,
          serverPerPage
        )}
        onSortModelChange={getOnSortModelChange(
          filters,
          setFilters,
          serverPerPage
        )}
        onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
        onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
      />
    </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`threshold-tab-${index}`}
      aria-labelledby={`threshold-tabpanel-${index}`}
      style={{ height: 'calc(100vh - 290px)', minHeight: 300 }}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};

const AddThresholdModal = (props) => {
  const currencyCode = import.meta.env?.VITE_CURRENCY_CODE || 'UGX';
  let initialFormState = {
    dailyDebtThreshold: 0,
    dailyFloatRequestLimit: 0,
    currency: currencyCode,
    autoApproveFloatRequest: false,
    comment: '',
  };
  const [formData, setFormData] = useState(initialFormState);
  const { isOpen, handleAddThresholdModalClose, filters, type } = props;
  const { isLoadingAgent, isSubmitting, agents } = useSelector(
    (state) => state.floatCashThresholdList
  );
  const dispatch = useDispatch();
  const [canSave, setCanSave] = useState(false);
  const [agent, setAgent] = useState(null);
  const [agentNameInputValue, setAgentNameInputValue] = useState('');
  const [autoApproveRequests, setAutoApproveRequests] = useState(false);
  const [multiple, setMultiple] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const handleSearch = useCallback(
    debounce((value) => dispatch(agentsSearch(value)), 1000),
    []
  );
  useEffect(() => {
    dispatch(setLoadingAgent(true));
    // noinspection JSCheckFunctionSignatures,JSIgnoredPromiseFromCall
    handleSearch(agentNameInputValue);
  }, [handleSearch, agentNameInputValue]);

  useEffect(() => {
    setCanSave(hasValidAgent());
  }, [agent, selectedAgents, multiple]);

  const hasValidAgent = () => {
    if (multiple) return selectedAgents.length > 0;
    else return agent != null;
  };
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const handleOnChange = (title) => (e) => {
    let value = e.target.value;
    if (title === 'dailyDebtThreshold' || title === 'dailyFloatRequestLimit') {
      setFormData({ ...formData, [title]: amountToNumberOrZero(value) });
    } else {
      setFormData({ ...formData, [title]: value });
    }
  };

  const setAgents = (value) => {
    console.log('>>> Setting agents: ', value);
    if (multiple && typeof value?.pop === 'function') setSelectedAgents(value);
    else if (!multiple) setAgent(value);
  };
  const handleSubmit = () => {
    captureEvent('FLOAT_CASH_THRESHOLD_ADD', {});
    let _formData = formData;
    _formData.autoApproveFloatRequest = autoApproveRequests;
    _formData.agents = multiple
      ? selectedAgents.map((value) => {
          return {
            uuid: value.uuid,
            agentName: value.agentName,
          };
        })
      : [
          {
            uuid: agent?.uuid,
            agentName: agent?.agentName,
          },
        ];
    dispatch(addFloatThreshold(_formData, filters, type));
  };

  const resetForm = () => {
    setAgent(null);
    setSelectedAgents([]);
    setFormData(initialFormState);
    setAgentNameInputValue('');
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Stack direction="row" flex justifyContent="end">
          <Box>
            <Button
              variant="text"
              onClick={() => handleAddThresholdModalClose()}
            >
              <img src={closeIcon} alt="" width="30px" height="30px" />
            </Button>
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item md={6}>
            <Stack direction="column">
              <Stack direction="row" sx={{ mb: 2 }}>
                <FormControl fullWidth sx={{ mr: 2 }}>
                  <Autocomplete
                    getOptionLabel={(agent) =>
                      typeof agent == 'string' ? agent : agent?.agentName
                    }
                    filterOptions={(x) => x}
                    options={agents}
                    autoComplete
                    filterSelectedOptions
                    multiple={multiple}
                    value={multiple ? selectedAgents : agent}
                    isOptionEqualToValue={(option, value) =>
                      option.uuid === value.uuid
                    }
                    onChange={(event, newValue) => {
                      setAgents(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setAgentNameInputValue(newInputValue);
                    }}
                    renderOption={(props, agent) => {
                      return (
                        <li {...props} key={agent.uuid}>
                          {agent.agentName} -{' '}
                          {agent.simNumbers
                            ?.map((v) => v.phoneNumber)
                            .join(',')}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={multiple ? 'Agents' : 'Agent'}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {isLoadingAgent ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControlLabel
                  control={<Switch checked={multiple} />}
                  label="Multiple agents"
                  onChange={(e) => setMultiple(!multiple)}
                />
              </Stack>

              <TextField
                sx={{ width: '100%', mt: 3 }}
                type="number"
                label="Debt threshold amount"
                required
                helperText="Amount that can be held overnight / indefinitely in float or cash"
                onChange={handleOnChange('dailyDebtThreshold')}
              />

              <TextField
                sx={{ width: '100%', mt: 3 }}
                type="number"
                label="Float request threshold amount"
                required
                helperText="Maximum float that can be requested per day"
                onChange={handleOnChange('dailyFloatRequestLimit')}
              />

              <FormControlLabel
                control={<Switch checked={autoApproveRequests} />}
                label="Auto approval of requests below threshold"
                onChange={(e) => {
                  setAutoApproveRequests(!autoApproveRequests);
                }}
              />

              <TextField
                autoFocus
                margin="dense"
                id="comments"
                label="Comments"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                minRows={3}
                sx={{ mt: 3 }}
                value={formData.comment}
                onChange={handleOnChange('comment')}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          disabled={!canSave || isLoadingAgent || isSubmitting}
          onClick={handleSubmit}
        >
          <span>Save</span>{' '}
          {isSubmitting ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <></>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddFieldAgentThresholdModal = (props) => {
  const currencyCode = import.meta.env?.VITE_CURRENCY_CODE || 'UGX';
  let initialFormState = {
    dailyDebtThreshold: 0,
    cashThreshold: 0,
    currency: currencyCode,
    comment: '',
  };
  const [formData, setFormData] = useState(initialFormState);
  const { isOpen, handleAddThresholdModalClose, filters, type } = props;
  const { isLoadingFieldAgents, isSubmitting, fieldAgents } = useSelector(
    (state) => state.floatCashThresholdList
  );
  const dispatch = useDispatch();
  const [fieldAgent, setFieldAgent] = useState(null);
  const [multiple, setMultiple] = useState(false);
  const [selectedFieldAgents, setSelectedFieldAgents] = useState([]);
  const [fieldAgentNameInputValue, setFieldAgentNameInputValue] = useState('');
  const [canSave, setCanSave] = useState(false);
  const handleSearch = useCallback(
    debounce((value) => dispatch(fieldAgentsSearch(value)), 1000),
    []
  );
  useEffect(() => {
    dispatch(setLoadingFieldAgents(true));
    // noinspection JSCheckFunctionSignatures,JSIgnoredPromiseFromCall
    handleSearch(fieldAgentNameInputValue);
  }, [handleSearch, fieldAgentNameInputValue]);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    setCanSave(hasValidFieldAgent());
  }, [fieldAgent, selectedFieldAgents, multiple]);

  const hasValidFieldAgent = () => {
    if (multiple) return selectedFieldAgents.length > 0;
    else return fieldAgent != null;
  };

  const handleOnChange = (title) => (e) => {
    let value = e.target.value;
    if (title === 'dailyDebtThreshold' || title === 'cashThreshold') {
      setFormData({ ...formData, [title]: amountToNumberOrZero(value) });
    } else {
      setFormData({ ...formData, [title]: value });
    }
  };

  const setFieldAgents = (value) => {
    console.log('>>> Setting agents: ', value);
    if (multiple && typeof value?.pop === 'function')
      setSelectedFieldAgents(value);
    else if (!multiple) setFieldAgent(value);
  };
  const handleSubmit = () => {
    captureEvent('FLOAT_CASH_FIELD_AGENT_THRESHOLD_ADD', {});
    let _formData = formData;
    _formData.fieldAgents = multiple
      ? selectedFieldAgents.map((value) => {
          return {
            uuid: value.uuid,
            name: value.name,
          };
        })
      : [
          {
            uuid: fieldAgent?.uuid,
            name: fieldAgent?.name,
          },
        ];
    dispatch(addFieldAgentFloatThreshold(_formData, filters, type));
  };

  const resetForm = () => {
    setFieldAgent(null);
    setSelectedFieldAgents([]);
    setFormData(initialFormState);
    setFieldAgentNameInputValue('');
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle>Add Field Agent Threshold</DialogTitle>
      <DialogContent>
        <Stack direction="row" flex justifyContent="end">
          <Box>
            <Button
              variant="text"
              onClick={() => handleAddThresholdModalClose()}
            >
              <img src={closeIcon} alt="" width="30px" height="30px" />
            </Button>
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item md={6}>
            <Stack direction="column">
              <Stack direction="row" sx={{ mb: 2 }}>
                <FormControl fullWidth sx={{ mr: 2 }}>
                  <Autocomplete
                    getOptionLabel={(fieldAgent) =>
                      typeof fieldAgent === 'string'
                        ? fieldAgent
                        : fieldAgent?.name
                    }
                    filterOptions={(x) => x}
                    options={fieldAgents}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    multiple={multiple}
                    value={multiple ? selectedFieldAgents : fieldAgent}
                    isOptionEqualToValue={(option, value) =>
                      option.uuid === value.uuid
                    }
                    onChange={(event, newValue) => {
                      setFieldAgents(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setFieldAgentNameInputValue(newInputValue);
                    }}
                    renderOption={(props, fieldAgent) => {
                      return (
                        <li {...props} key={fieldAgent.uuid}>
                          {fieldAgent.name} - {fieldAgent.phoneNumber}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="TDR"
                        helperText="Any existing threshold will be updated"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {isLoadingFieldAgents ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControlLabel
                  control={<Switch checked={multiple} />}
                  label="Multiple field agents"
                  onChange={(e) => setMultiple(!multiple)}
                />
              </Stack>

              <TextField
                sx={{ width: '100%', mt: 3 }}
                type="number"
                label="Debt threshold amount"
                required
                helperText="Amount that can be held overnight / indefinitely in float or cash"
                onChange={handleOnChange('dailyDebtThreshold')}
              />

              <TextField
                sx={{ width: '100%', mt: 3 }}
                type="number"
                label="Cash in hand threshold"
                required
                helperText="Maximum cash to be held before team member receives notification to deposit"
                onChange={handleOnChange('cashThreshold')}
              />

              <TextField
                autoFocus
                margin="dense"
                id="comments"
                label="Comments"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                minRows={3}
                sx={{ mt: 3 }}
                value={formData.comment}
                onChange={handleOnChange('comment')}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          disabled={!canSave || isLoadingFieldAgents || isSubmitting}
          onClick={handleSubmit}
        >
          <span>Save</span>{' '}
          {isSubmitting ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <></>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
