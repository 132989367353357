import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { apiGetCurrentExchangeRate } from '@/api/api-util';

const initialState = {
  dailyReports: [],
  agents: [],
  simAccounts: [],
  currentExchangeRate: null,
  isLoading: false,
  isLoadingAgent: false,
  isLoadingSimAccounts: false,
  isAdding: false,
  lastFilter: null,
  error: '',
  addingError: '',
};
export const dailyReportListSlice = createSlice({
  name: 'dailyReportList',
  initialState: initialState,
  reducers: {
    setDailyReports: (state, { payload }) => {
      const { lastFilter, dailyReports } = payload;
      state.lastFilter = lastFilter;
      state.dailyReports = dailyReports;
      state.isLoading = false;
      state.error = '';
    },
    setAgents: (state, { payload }) => {
      const { agents } = payload;
      state.agents = agents;
    },
    setSimAccounts: (state, { payload }) => {
      const { simAccounts } = payload;
      state.isLoadingSimAccounts = false;
      state.simAccounts = simAccounts;
    },
    setCurrentExchangeRate: (state, { payload }) => {
      state.currentExchangeRate = payload;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
      state.error = '';
    },
    setAdding: (state, { payload }) => {
      state.isAdding = payload;
      state.error = '';
    },
    setLoadingAgent: (state, { payload }) => {
      state.isLoadingAgent = payload;
    },
    setLoadingSimAccounts: (state, { payload }) => {
      state.error = '';
      state.isLoadingSimAccounts = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setAddingError: (state, { payload }) => {
      state.addingError = payload;
      state.isAdding = false;
    },
  },
});

export const dailyReportsFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  return getApi()
    .get('/api/float-cash/daily-reports/grouped', { params: filters })
    .then((res) => {
      const response = res?.data?.data || [];
      dispatch(
        setDailyReports({
          lastFilter: filters?.toString(),
          dailyReports: response,
        })
      );
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const agentsFetchUsingApi = (filters) =>
  getApi().get('/api/agents', { params: filters });

export const fetchSimAccountsFromNetwork = (filters) =>
  getApi().get('/api/sims/accounts', { params: filters });
export const agentsSearch = (value) => async (dispatch) => {
  try {
    dispatch(setLoadingAgent(true));
    let agents = [];
    const nameResult = await agentsFetchUsingApi(
      new URLSearchParams(
        `agentName=contains:${value}&oo=agentName&e=1&pp=1:10`
      )
    );
    agents = agents.concat(...nameResult.data);
    if (value && value.length > 0) {
      const phoneResult = await agentsFetchUsingApi(
        new URLSearchParams(
          `phoneNumber=contains:${encodeURIComponent(
            value
          )}&oo=agentName&e=1&pp=1:10`
        )
      );
      agents = agents.concat(...phoneResult.data);
    }
    dispatch(
      setAgents({
        agents,
      })
    );
    dispatch(setLoadingAgent(false));
  } catch (err) {
    dispatch(setError(getApiError(err)));
  }
};
export const fetchSimAccounts = (filters) => (dispatch) => {
  dispatch(setLoadingSimAccounts(true));
  fetchSimAccountsFromNetwork(filters)
    .then((response) => {
      const simAccounts = (response?.data?.data || [])
        .map((simAccount) => {
          const accounts = (simAccount.accounts || []).filter(
            (item) => !!item.currency
          );
          simAccount.accounts = accounts;
          simAccount.hasAccounts = accounts.length > 0;
          return simAccount;
        })
        .filter((item) => item.accounts.length > 0);
      dispatch(
        setSimAccounts({
          simAccounts,
        })
      );
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const fetchCurrentExchangeRate = (filters) => (dispatch) => {
  apiGetCurrentExchangeRate(filters)
    .then((response) => {
      dispatch(setCurrentExchangeRate(response.data));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const dailyReportAdd =
  (data, lastFilter, dismissCallback) => (dispatch) => {
    dispatch(setAdding(true));
    getApi()
      .post('/api/float-cash/daily-reports/bulk', data)
      .then(() => {
        dispatch(setAdding(false));
        if (dismissCallback) dismissCallback();
      })
      .catch((err) => {
        dispatch(setAddingError(getApiError(err)));
        dispatch(dailyReportsFetch(lastFilter));
      });
  };

export const clearAddingError = () => (dispatch) => {
  dispatch(setAddingError(''));
};
export const {
  setDailyReports,
  setAgents,
  setSimAccounts,
  setCurrentExchangeRate,
  setLoading,
  setAdding,
  setLoadingSimAccounts,
  setLoadingAgent,
  setError,
  setAddingError,
} = dailyReportListSlice.actions;

export const dailyReportListReducer = dailyReportListSlice.reducer;
