import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  action: null,
  isLoading: false,
  error: '',
};

const actionSlice = createSlice({
  name: 'action',
  initialState: initialState,
  reducers: {
    setAction: (state, { payload }) => {
      state.action = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.action = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const actionFetch = (id) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/ussd/actions/${id}`)
    .then((response) => dispatch(setAction(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setAction, resetState, setLoading, setError } =
  actionSlice.actions;

export const actionReducer = actionSlice.reducer;
