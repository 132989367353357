import dayjs from 'dayjs';
import { FORMAT } from './constants';

export const numberFormat = (number, summarize) => {
  let suffix = '';
  if (summarize) {
    const digits = Math.max(Math.floor(Math.log10(Math.abs(number))), 0) + 1;
    const divisor = digits > 9 ? 9 : digits > 6 ? 6 : digits > 3 ? 3 : 0;
    suffix = digits > 9 ? 'B' : digits > 6 ? 'M' : digits > 3 ? 'k' : '';
    number = number / Math.pow(10, divisor);
  }

  if (isNaN(number)) {
    return null;
  }

  return new Intl.NumberFormat({ style: 'currency' }).format(number) + suffix;
};

export const dateFormat = (input, format) => {
  if (typeof input === 'undefined' || input === null) {
    return null;
  } else {
    return dayjs(input).format(format ? format : FORMAT);
  }
};
