import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  fieldAgent: null,
  isLoading: false,
  error: '',
};

const fieldAgentSlice = createSlice({
  name: 'fieldAgent',
  initialState: initialState,
  reducers: {
    setFieldAgent: (state, { payload }) => {
      state.fieldAgent = payload;
      state.isLoading = false;
      state.error = '';
    },
    resetState: (state) => {
      state.fieldAgent = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const fieldAgentFetch = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/field-agents/${uuid}`)
    .then((response) => dispatch(setFieldAgent(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setFieldAgent, resetState, setLoading, setError } =
  fieldAgentSlice.actions;

export const fieldAgentReducer = fieldAgentSlice.reducer;
