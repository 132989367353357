import { AppBar, Error, Loading } from '@/common';
import { DynamicAutoComplete } from '@/common/DynamicAutoComplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fieldAgentSave } from './fieldAgentFormSlice';
import { fieldAgentListFetch } from './fieldAgentListSlice';
import { fieldAgentFetch, resetState } from './fieldAgentSlice';
import { captureEvent } from '@/firebase';

export const FieldAgentTeamForm = () => {
  // console.log('RXD:FieldAgentTeamForm');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [team, setTeam] = useState([]);

  const { error: formError, status: formStatus } = useSelector(
    (state) => state.fieldAgentForm
  );

  const {
    fieldAgent,
    isLoading: fieldAgentIsLoading,
    error: fieldAgentError,
  } = useSelector((state) => state.fieldAgent);

  const {
    fieldAgentList,
    isLoading: fieldAgentListIsLoading,
    error: fieldAgentListError,
  } = useSelector((state) => state.fieldAgentList);

  const handleSave = (e) => {
    e.preventDefault();
    dispatch(fieldAgentSave(uuid, { ...fieldAgent, team }));
    captureEvent('FIELD_AGENT_TEAM_FORM_SAVE', {});
  };

  useEffect(() => {
    if (
      uuid &&
      (uuid !== fieldAgent?.uuid || !fieldAgent.team) &&
      !fieldAgentIsLoading &&
      !fieldAgentError
    ) {
      dispatch(resetState());
      dispatch(fieldAgentFetch(uuid));
    }
  }, [dispatch, fieldAgent, uuid, fieldAgentIsLoading, fieldAgentError]);

  const filterFieldAgents = useCallback(
    (value) => {
      console.log('RXD:FieldAgentReassignForm', { value, uuid });
      const query = new URLSearchParams(
        `pp=1:6&oo=name&isActive=true&ff=fa.uuid,fa.name,fa.category&positionsBelow=${uuid}`
      );
      if (value) {
        query.set('name', `contains:${value}`);
      }
      dispatch(fieldAgentListFetch(query));
    },
    [dispatch, uuid]
  );

  useEffect(() => {
    filterFieldAgents();
  }, [uuid]);

  useEffect(() => {
    setTeam(fieldAgent?.team ?? []);
  }, [fieldAgent]);

  // console.log('RXD:FieldAgentTeamForm', { fieldAgentList });

  return (
    <>
      <AppBar>
        {fieldAgent?.uuid === uuid ? `${fieldAgent?.name}'s` : uuid} Team
      </AppBar>

      {fieldAgentError && <Error error={fieldAgentError} />}
      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box
                component="form"
                method="POST"
                sx={{ p: 1 }}
                onSubmit={handleSave}
                autoComplete="off"
              >
                <DynamicAutoComplete
                  multiple={true}
                  value={team}
                  options={[
                    ...team,
                    ...fieldAgentList.filter((f) => f.uuid !== uuid),
                  ]}
                  isOptionEqualToValue={(option, value) => {
                    return option?.uuid === value?.uuid;
                  }}
                  onChange={(newValue) => {
                    setTeam(newValue);
                  }}
                  handleSearch={filterFieldAgents}
                  isLoading={fieldAgentListIsLoading}
                  error={fieldAgentListError}
                  required
                  getOptionLabel={(option) =>
                    `${option?.name} (${option?.category})`
                  }
                  renderInput={(value) => (params) => (
                    <TextField
                      {...params}
                      value={value}
                      variant="standard"
                      label="Team"
                      placeholder="Team"
                    />
                  )}
                />

                {formStatus === 'loading' ? (
                  <Loading />
                ) : (
                  <Button variant="contained" sx={{ my: 2 }} type="submit">
                    Save
                  </Button>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
