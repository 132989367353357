import { capitalize, dateFormat } from '@/common';

export const getCustomerName = ({ row: customer }) => {
  let fullname = '';
  const source = customer?.kyc;
  if (!!customer?.firstName && !!customer?.lastName) {
    fullname = `${customer.firstName} ${customer.lastName}`;
  } else if (!!source?.firstName && !!source?.lastName) {
    fullname = `${source?.firstName} ${source?.lastName}`;
  } else {
    fullname = '...';
  }
  return fullname;
};

export const getCustomerKycName = ({ row: customer }) => {
  const kyc = customer.kyc;
  return `${kyc?.firstName} ${kyc?.lastName}`;
};

export const getCustomerNumber = ({ row: customer }) =>
  !!customer?.phoneNumber ? customer?.phoneNumber : '...';

export const getCustomerUpdatedAt = ({ row: customer }) =>
  dateFormat(customer?.kyc?.updatedAt) ?? '...';

export const getCustomerGender = ({ row: customer }) => {
  const gender = customer?.kyc?.gender || customer?.gender;
  if (!gender || gender.length < 1) return '...';
  return `${capitalize(gender)}`;
};

export const getKYCAgentName = ({ row: customer }) =>
  !!customer?.createdBy?.agentName ? customer?.createdBy?.agentName : '...';

export const getKYCStatusTextLabel = (customer) =>
  toTitleCase(customer?.kyc?.status || '...');

export const getKYCStatusTextColor = (customer) => {
  console.log('>>> KYC Status: ', customer.kyc?.status);
  switch (customer?.kyc?.status?.toLowerCase()) {
    case 'pending':
      return '#4A4A4A';

    case 'approved':
      return '#21CE99';

    case 'rejected':
      return '#D14343';

    default:
      return '#4A4A4A';
  }
};
export const getKYCStatusBgColor = (customer) => {
  switch (customer?.kyc?.status?.toLowerCase()) {
    case 'pending':
      return '#FFCF44';

    case 'approved':
      return '#E8FFFB';

    case 'rejected':
      return '#FFA4A4';

    default:
      return '#FFCF44';
  }
};

export const getExternalId = (customer) =>
  !!customer?.externalId ? customer?.externalId : '...';

export const getDateOfBirth = (date) => dateFormat(date, 'MMM DD, YYYY');
export const getFullDate = (date) => dateFormat(date);

export const toTitleCase = (value) => {
  if (!value || value.length < 1) return value;
  return `${value.substring(0, 1).toUpperCase()}${value
    .substring(1)
    .toLowerCase()}`;
};

export const getKycStatuses = () => ['APPROVED', 'PENDING', 'REJECTED'];

export const getContractVariables = () => [
  ['{customer.signature}', "The customer's signature"],
  ['{customer.name}', "The customer's full name"],
  ['Date: {today}', "Today's full date e.g., 14 October, 2023"],
];
