import { Error as ErrorAlert, getApi } from '@/common';
import { GridLoad } from '@/common/Loading';
import { fetchDashboardConfiguration } from '@/dashboardConfiguration/dashboardSlice';
import logo from '@/images/logo.svg';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { LoggedInRedirect } from './LoggedInRedirect';
import { setLoggedIn } from './authSlice';

export const DebugLogin = () => {
  // console.log('RXD:DebugLogin');
  const dispatch = useDispatch();
  const [fakeUser, setFakeUser] = useState(null);
  const [error, setError] = useState(null);
  const { uuid } = useParams();

  useEffect(() => {
    if (import.meta.env?.VITE_FAKE_LOGIN && fakeUser === null && uuid) {
      getApi()
        .get('/api/users/me', {
          headers: { Authorization: `Bearer ${uuid}` },
        })
        .then((res) => {
          if (!res?.data) {
            throw new Error(
              `Error could not impersonate user. Are you sure backend is correctly configured?`
            );
          }
          setFakeUser(res?.data);
          const payload = { user: res?.data, idToken: uuid };
          // console.log('RXD:DebugLogin: setFakeUser', payload);
          dispatch(setLoggedIn(payload));
          dispatch(fetchDashboardConfiguration());
        })
        .catch((error) => {
          setError(`${error}`);
        });
    }
  }, [dispatch, uuid, fakeUser]);

  if (!import.meta.env?.VITE_FAKE_LOGIN) {
    // console.log('RXD:DebugLogin: Login');
    return <Navigate to="/" />;
  }

  if (fakeUser) {
    // console.log('RXD:DebugLogin: LoggedInRedirect');
    return <LoggedInRedirect defaultTo="/profile" />;
  }

  return (
    <>
      <Grid container spacing={0} direction="column" alignItems="center">
        <img alt="Opareta" src={logo} height={150} />
        <Typography variant="subtitle2">
          {error ? (
            <ErrorAlert error={error} />
          ) : (
            'Loading application state . . .'
          )}
        </Typography>
        <GridLoad />
      </Grid>
    </>
  );
};
