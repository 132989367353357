import { Error, Loading, TabPanel } from '@/common';
import { a11yProps } from '@/common/TabPanel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { agentConfigurationSave } from './agentConfigurationFormSlice';
import { agentConfigurationFetch } from './agentConfigurationSlice';
import { getContractVariables } from './utils';

const HtmlEditor = lazy(() => import('@/common/HtmlEditor'));

export const AgentConfiguration = () => {
  // console.log('RXD:AgentConfiguration');
  const dispatch = useDispatch();
  const { agentConfiguration, isLoading, error } = useSelector(
    (state) => state.agentConfiguration
  );
  const { status, error: formError } = useSelector(
    (state) => state.agentConfigurationForm
  );
  const ckEditor = useRef(null);
  const ckEditorApplication = useRef(null);

  const [gracePeriod, setGracePeriod] = useState('');
  const [kycPrompt, setKycPrompt] = useState('');
  const [contract, setContract] = useState('');
  const [application, setApplication] = useState('');
  const [tab, setTab] = useState(0);

  const handleTabChange = useCallback(
    (event, index) => {
      setTab(index);
    },
    [setTab]
  );

  useEffect(() => {
    if (!(isLoading && error) && !agentConfiguration) {
      dispatch(agentConfigurationFetch());
    }
  }, [dispatch, agentConfiguration, isLoading, error]);

  // initial values
  useEffect(() => {
    setGracePeriod(agentConfiguration?.gracePeriod ?? '0');
    setKycPrompt(agentConfiguration?.kycPrompt ?? '');
    setContract(agentConfiguration?.contract ?? '');
    setApplication(agentConfiguration?.application ?? '');
    ckEditor.current?.setData(agentConfiguration?.contract ?? '');
    ckEditorApplication.current?.setData(agentConfiguration?.application ?? '');
    // return () => dispatch(agentConfigurationFetch());
  }, [agentConfiguration]);

  const handleSave = (e) => {
    e.preventDefault();
    dispatch(
      agentConfigurationSave({
        gracePeriod,
        kycPrompt,
        contract,
        application,
      })
    );
  };

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={{ pt: 1, pb: 2 }}
      >
        <Box sx={{ marginRight: 'auto' }}></Box>
      </Box>

      {error && <Error error={error} />}

      {formError && <Error error={formError} />}
      <Card>
        <CardContent>
          {isLoading ? (
            <Loading />
          ) : (
            <Box
              component="form"
              method="post"
              sx={{ p: 1 }}
              onSubmit={handleSave}
              autoComplete="off"
            >
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  id="grace-period"
                  label="Grace period"
                  variant="outlined"
                  type="number"
                  helperText="Time in days during which the agent can use the app as an agent while waiting for compliance to review their application."
                  value={gracePeriod}
                  onChange={w(setGracePeriod)}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  id="grace-period"
                  label="KYC Prompt"
                  variant="outlined"
                  helperText="The text prompt to complete KYC after a transaction."
                  value={kycPrompt}
                  onChange={w(setKycPrompt)}
                />
              </FormControl>

              <Tabs
                value={tab}
                onChange={handleTabChange}
                sx={{ background: 'white' }}
                aria-label="agent detail tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="Agent Contract Template"
                  value={0}
                  {...a11yProps(0)}
                />
                <Tab
                  label="Agent Application Template"
                  value={1}
                  {...a11yProps(1)}
                />
              </Tabs>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <Box
                  sx={{
                    '& .DraftEditor-root': {
                      border: '1px solid gray',
                      minHeight: 'calc(100vh - 200px)',
                    },
                  }}
                >
                  <Suspense fallback={<Loading />}>
                    <TabPanel value={tab} index={0}>
                      <HtmlEditor
                        data={contract}
                        onReady={(editor) => {
                          ckEditor.current = editor;
                          editor.setData(contract ?? '');
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              'min-height',
                              'calc(100vh - 400px)',
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          setContract(editor.getData());
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                      <HtmlEditor
                        data={application}
                        onReady={(editor) => {
                          ckEditorApplication.current = editor;
                          editor.setData(application ?? '');
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              'min-height',
                              'calc(100vh - 400px)',
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          setApplication(editor.getData());
                        }}
                      />
                    </TabPanel>
                  </Suspense>
                </Box>
              </FormControl>
              <Accordion sx={{ mb: 2 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Contract Variables</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Variable</TableCell>
                          <TableCell>Meaning</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getContractVariables().map(([variable, meaning]) => (
                          <TableRow key={variable}>
                            <TableCell>
                              <Button
                                size="small"
                                variant="outlined"
                                onClick={(e) =>
                                  navigator.clipboard
                                    .writeText(variable)
                                    .catch(console.error)
                                }
                              >
                                COPY
                              </Button>
                            </TableCell>
                            <TableCell>{variable}</TableCell>
                            <TableCell>{meaning}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>

              <Divider sx={{ my: 2 }} />

              {status === 'loading' ? (
                <Loading />
              ) : (
                <Button variant="contained" sx={{ mb: 1 }} type="submit">
                  Save
                </Button>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};
