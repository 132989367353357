import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError, numberFormat } from '@/common';
import { push } from 'redux-first-history';
import { closeDepositFormModal } from '@/float-cash/cashDepositFormSlice';

const initialState = {
  policeReport: null,
  fieldAgents: [],
  agents: [],
  agentBalances: [],
  isLoading: false,
  isLoadingAgent: false,
  isUploadingFiles: false,
  isSubmitting: false,
  attachmentUrls: [],
  error: '',
  clearForm: false,
};

export const varianceFormSlice = createSlice({
  name: 'varianceForm',
  initialState: initialState,
  reducers: {
    setFieldAgents: (state, { payload }) => {
      const { fieldAgents } = payload;
      state.isLoadingAgent = false;
      state.fieldAgents = fieldAgents;
    },
    setAgents: (state, { payload }) => {
      const { agents } = payload;
      state.isLoadingAgent = false;
      state.agents = agents;
    },
    setAgentBalances: (state, { payload }) => {
      const { agentBalances } = payload;
      state.isLoading = false;
      state.agentBalances = agentBalances;
    },
    setPoliceReport: (state, { payload }) => {
      state.policeReport = payload.url;
      state.isUploadingFiles = false;
    },
    setUploadFile: (state, { payload }) => {
      let url = payload.url;
      state.attachmentUrls.push(url);
      state.isUploadingFiles = false;
    },
    updateAttachmentUrls: (state, { payload }) => {
      const { attachmentUrls } = payload;
      console.log('>>> Updating attachment urls: ', attachmentUrls);
      state.attachmentUrls = attachmentUrls;
    },
    updatePoliceReport: (state, { payload }) => {
      const { policeReport } = payload;
      console.log('>>> Updating police report url: ', policeReport);
      state.policeReport = policeReport;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setSubmitting: (state) => {
      state.error = '';
      state.isSubmitting = true;
    },
    stopLoading: (state) => {
      state.error = '';
      state.isLoading = false;
      state.isLoadingAgent = false;
      state.isUploadingFiles = false;
      state.isSubmitting = false;
    },
    setLoadingAgent: (state) => {
      state.error = '';
      state.isLoadingAgent = true;
    },
    setUploadingFiles: (state) => {
      state.error = '';
      state.isUploadingFiles = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.isLoadingAgent = false;
      state.isUploadingFiles = false;
    },
    clearForm: (state) => {
      state.clearForm = true;
    },
    clearState: (state) => {
      state.policeReport = null;
      state.fieldAgents = [];
      state.agents = [];
      state.agentBalances = [];
      state.isLoading = false;
      state.isLoadingAgent = false;
      state.isUploadingFiles = false;
      state.isSubmitting = false;
      state.attachmentUrls = [];
      state.error = '';
      state.clearForm = false;
    },
  },
});

export const fieldAgentsFetchUsingApi = (filters) =>
  getApi().get('/api/field-agents', { params: filters });

export const agentsFetchUsingApi = (filters) =>
  getApi().get('/api/agents', { params: filters });

export const reconciliationAgentBalancesFetchUsingApi = (filter) =>
  getApi().get('/api/float-cash/reconciliation/agent', {
    params: filter,
  });

export const fieldAgentsFetch = (filters) => (dispatch) => {
  dispatch(setLoadingAgent(true));
  fieldAgentsFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setFieldAgents({
          fieldAgents: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const uploadAttachmentsFile = (file, attachmentType) => (dispatch) => {
  if (!file || !attachmentType) {
    dispatch(stopLoading());
    return;
  }
  dispatch(setUploadingFiles(true));
  getApi()
    .post('/api/storage/upload', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      if (attachmentType === 'police_report') {
        dispatch(setPoliceReport(res.data));
      } else {
        dispatch(setUploadFile(res.data));
      }
    })
    .catch((err) => dispatch(setError(getApiError(err))))
    .finally(() => dispatch(stopLoading()));
};

export const agentsFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  agentsFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setAgents({
          agents: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const fieldAgentsSearch = (value) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let fieldAgents = [];
    const nameResult = await fieldAgentsFetchUsingApi(
      new URLSearchParams(`name=contains:${value}&oo=name&e=1&pp=1:10`)
    );
    fieldAgents = fieldAgents.concat(...nameResult.data);
    if (value && value.length > 0) {
      const phoneResult = await fieldAgentsFetchUsingApi(
        new URLSearchParams(
          `phoneNumber=contains:${encodeURIComponent(
            value
          )}&oo=phoneNumber&e=1&pp=1:10`
        )
      );
      fieldAgents = fieldAgents.concat(...phoneResult.data);
    }
    dispatch(
      setFieldAgents({
        fieldAgents,
      })
    );
    dispatch(stopLoading());
  } catch (err) {
    dispatch(setError(getApiError(err)));
  }
};

export const agentsSearch = (value) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let agents = [];
    const nameResult = await agentsFetchUsingApi(
      new URLSearchParams(
        `agentName=contains:${value}&oo=agentName&e=1&pp=1:10`
      )
    );
    agents = agents.concat(...nameResult.data);
    if (value && value.length > 0) {
      const phoneResult = await agentsFetchUsingApi(
        new URLSearchParams(
          `phoneNumber=contains:${encodeURIComponent(
            value
          )}&oo=agentName&e=1&pp=1:10`
        )
      );
      agents = agents.concat(...phoneResult.data);
    }
    dispatch(
      setAgents({
        agents,
      })
    );
    dispatch(stopLoading());
  } catch (err) {
    dispatch(setError(getApiError(err)));
  }
};

export const reconciliationAgentBalancesFetch = (filter) => (dispatch) => {
  dispatch(setLoading(true));
  reconciliationAgentBalancesFetchUsingApi(filter)
    .then((response) =>
      dispatch(
        setAgentBalances({
          agentBalances: response.data?.data || [],
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const submitForm = (formData) => (dispatch) => {
  dispatch(setSubmitting(true));
  getApi()
    .post('/api/float-cash/reconciliation/variance', formData)
    .then((res) => {
      dispatch(stopLoading());
      dispatch(clearForm());
      dispatch(push('/float-cash/variances'));
    })
    .catch((err) => dispatch(setError(getApiError(err))))
    .finally(() => dispatch(setSubmitting(false)));
};

export const getAgentBalanceAmount = (balance) => numberFormat(balance);

export const {
  setError,
  setLoading,
  setLoadingAgent,
  setFieldAgents,
  setSubmitting,
  setAgents,
  setAgentBalances,
  setPoliceReport,
  setUploadFile,
  setUploadingFiles,
  stopLoading,
  updateAttachmentUrls,
  updatePoliceReport,
  clearForm,
  clearState,
} = varianceFormSlice.actions;

export const varianceFormReducer = varianceFormSlice.reducer;
