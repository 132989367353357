import Button from '@mui/material/Button';
import {
  AppBar,
  Bubble,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { parsersFetch } from './parserListSlice';
import { setParser } from './parserSlice';
import {
  getCategoryColor,
  getCategoryLabel,
  getCategoryText,
  getCategoryTextLabel,
  getParserCategories,
  getParserStatuses,
  getParserTargets,
  getParserTelcos,
  getTelcoColor,
  getTelcoName,
  getTelcoNameLabel,
  getTelcoText,
} from './utils';

export const ParserList = ({ embedded }) => {
  // console.log('RXD:ParserList');
  const dispatch = useDispatch();
  const { parserList, isLoading, error, lastFilter } = useSelector((state) => {
    return state.parserList;
  });
  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, 'parserId');
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', 'category');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(parsersFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 190,
      sortingOrder: getSortOrder(),
      valueGetter: (params) => params?.row?.action?.name,
    },
    {
      field: 'category',
      headerName: 'Category',
      minWidth: 190,
      align: 'center',
      headerAlign: 'center',
      type: 'singleSelect',
      sortingOrder: getSortOrder(),
      valueOptions: getParserCategories().map((category) => ({
        value: category,
        label: getCategoryTextLabel(category),
      })),
      renderCell: w((parser) => (
        <Bubble
          label={getCategoryLabel(parser)}
          background={getCategoryColor(parser)}
          color={getCategoryText(parser)}
          borderRadius="0"
          fontWeight="normal"
        />
      )),
    },
    {
      field: 'sender',
      headerName: 'Sender',
      minWidth: 190,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: getParserStatuses().map((status) => ({
        value: status,
        label: status,
      })),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'step',
      headerName: 'Step',
      type: 'number',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'target',
      headerName: 'Target',
      type: 'singleSelect',
      align: 'center',
      headerAlign: 'center',
      valueOptions: getParserTargets().map((target) => ({
        value: target,
        label: target,
      })),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'telco',
      headerName: 'Telco',
      align: 'center',
      headerAlign: 'center',
      type: 'singleSelect',
      valueOptions: getParserTelcos().map((telco) => ({
        value: telco,
        label: getTelcoNameLabel(telco),
      })),
      renderCell: w((parser) => (
        <Bubble
          label={getTelcoName(parser)}
          background={getTelcoColor(parser)}
          color={getTelcoText(parser)}
          borderRadius="0"
          fontWeight="normal"
        />
      )),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'regex',
      headerName: 'Regex',
      minWidth: 190,
      flex: 1,
      sortingOrder: getSortOrder(),
    },
  ];

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            <Button
              disableElevation
              sx={{ textTransform: 'none' }}
              variant="contained"
              border="2px solid"
              size="medium"
              component={RouterLink}
              to="/ussd/parsers/add"
            >
              Add Parser
            </Button>
          }
        >
          USSD Parsers
        </AppBar>
      )}

      {error && (
        <Error error={error} onRetry={() => dispatch(parsersFetch(filters))} />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(parser) => parser.uuid}
          rows={parserList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const parser = param.row;
            if (e.ctrlKey) {
              window.open(`/ussd/parsers/${parser.id}`);
            } else {
              dispatch(setParser(parser));
              navigate(`/ussd/parsers/${parser.id}`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('ParserList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('ParserList', {
                step: false,
              }),
            },
          }}
        />
      </div>
    </>
  );
};
