import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export const Info = ({ label, border, sx, children }) => (
  <>
    <Box sx={sx}>
      <Typography variant="subtitle2" component="div">
        {label}
      </Typography>
      <Typography variant="body1" component="div">
        {children}
      </Typography>
    </Box>
    {border && <Divider />}
  </>
);
