import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError, updateInList } from '@/common';

const initialState = {
  floatManagerAccountList: [],
  isLoading: false,
  lastFilter: null,
  error: '',
};

export const floatManagerAccountListSlice = createSlice({
  name: 'floatManagerAccountListSlice',
  initialState: initialState,
  reducers: {
    setFloatManagerAccountList: (state, { payload }) => {
      const { lastFilter, floatManagerAccountList } = payload;

      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.floatManagerAccountList = floatManagerAccountList;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const floatManagerAccountListFetchUsingApi = (filters) =>
  getApi().get('/api/float-cash/accounts', { params: filters });

export const floatManagerAccountListFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  floatManagerAccountListFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setFloatManagerAccountList({
          lastFilter: filters?.toString(),
          floatManagerAccountList: response.data?.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setError, setLoading, setFloatManagerAccountList } =
  floatManagerAccountListSlice.actions;

export const floatManagerAccountListReducer =
  floatManagerAccountListSlice.reducer;
