import {
  AppBar,
  BooleanBubble,
  Error,
  Info as InfoBase,
  Loading,
  TabPanel,
  useFilters,
} from '@/common';
import { a11yProps } from '@/common/TabPanel';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { floatRequestFetch } from './floatRequestSlice';
import { getCreatedAt } from './util';
import { captureEvent } from '@/firebase';

export const FloatRequestDetail = () => {
  // console.log('RXD:FloatRequestDetail');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { request, isLoading, error } = useSelector(
    (state) => state.floatRequest
  );

  useEffect(() => {
    if (uuid !== request?.uuid && !isLoading && !error) {
      dispatch(floatRequestFetch(uuid));
    }
  }, [dispatch, request, uuid, isLoading, error]);

  const [filters, setFilters] = useFilters();
  const handleTabChange = (e, index) => {
    setFilters(new URLSearchParams(`tab=${index}`));
    captureEvent('TAB_CHANGE_FLOAT_REQUEST_DETAIL', {
      index,
      tab: e?.target?.innerText || '',
    });
  };
  let tab = filters.get('tab');
  tab = tab === null ? 0 : +tab;

  return (
    <>
      <AppBar>Float Request: {request?.uuid}</AppBar>

      {error ? (
        <Error error={error} />
      ) : !request || isLoading ? (
        <Loading />
      ) : (
        <>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            sx={{ background: 'white' }}
            aria-label="field agent detail tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Overview" value={0} {...a11yProps(0)} />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Overview request={request} />
          </TabPanel>
        </>
      )}
    </>
  );
};

const Info = ({ label, children, border }) => (
  <InfoBase sx={{ py: 1 }} label={label} border={border ?? true}>
    {children}
  </InfoBase>
);

const Overview = ({ request }) => (
  <Grid container spacing={2} sx={{ mt: 1 }}>
    <Grid item xs={12} md={3}>
      <Info label="Active" border={false}>
        <BooleanBubble value={request?.isActive} />
      </Info>
    </Grid>
    <Grid item xs={12} md={3}>
      <Info label="Started At" border={false}>
        {getCreatedAt(request)}
      </Info>
    </Grid>
    <Grid item xs={12} md={3}>
      <Info label="Gender" border={false}>
        {request.gender ?? '-'}
      </Info>
    </Grid>
    <Grid item xs={12} md={3}>
      <Info label="Phone Number" border={false}>
        {request.phoneNumber ?? '-'}
      </Info>
    </Grid>
  </Grid>
);
