import {
  AppBar,
  Bubble,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { notificationListFetch } from './notificationListSlice';
import { notificationDelete, setNotification } from './notificationSlice';
import {
  getCreatedAt,
  getScheduledAt,
  getStatusBackground,
  getStatusColor,
  getStatuses,
  getTypeBackground,
  getTypeColor,
  getTypeLabel,
  getTypes,
  getUpdatedAt,
} from './utils';

export const NotificationList = ({ embedded }) => {
  // console.log('RXD:NotificationList');
  const dispatch = useDispatch();
  const { notificationList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.notificationList;
    }
  );

  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);
  const [anchorElDelete, setAnchorElDelete] = useState(new Map());

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, ['agentUuid']);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', 'scheduledAt');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(notificationListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'scheduledAt',
      headerName: 'Scheduled',
      minWidth: 200,
      flex: 1,
      type: 'dateTime',
      sortingOrder: getSortOrder(),
      valueGetter: w(getScheduledAt),
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 200,
      flex: 1,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 200,
      flex: 1,
      type: 'singleSelect',
      valueOptions: getTypes()?.map((type) => ({
        value: type,
        label: type,
      })),
      renderCell: w((notificationSchedule) => (
        <Bubble
          label={getTypeLabel(notificationSchedule?.type)}
          background={getTypeBackground(notificationSchedule?.type)}
          color={getTypeColor(notificationSchedule?.type)}
          borderRadius="0"
          fontWeight="normal"
        />
      )),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 200,
      flex: 1,
      type: 'singleSelect',
      valueOptions: getStatuses().map((status) => ({
        value: status,
        label: status,
      })),
      renderCell: w((notificationSchedule) => (
        <Bubble
          label={notificationSchedule?.status}
          color={getStatusColor(notificationSchedule)}
          background={getStatusBackground(notificationSchedule)}
        />
      )),
    },
    {
      field: 'audience',
      headerName: 'Audience',
      type: 'number',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'dateTime',
      minWidth: 200,
      flex: 1,
      valueGetter: w(getCreatedAt),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      type: 'dateTime',
      minWidth: 200,
      flex: 1,
      valueGetter: w(getUpdatedAt),
    },
    {
      field: 'message',
      headerName: 'Message',
      minWidth: 200,
      maxWidth: 300,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      maxWidth: 80,
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      sx: { padding: 0 },
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          anchorElDelete.set(params.row.uuid, e.currentTarget);
          setAnchorElDelete(new Map(anchorElDelete));
        };

        const onDelete = (e) => {
          e.stopPropagation();
          dispatch(notificationDelete(params.row.uuid));
        };

        const handleClose = (e) => {
          anchorElDelete.set(params.row.uuid, null);
          setAnchorElDelete(new Map(anchorElDelete));
        };

        return (
          <>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={onClick}
              sx={{ padding: 0, margin: 0, height: 32 }}
            >
              Delete
            </Button>
            <Popover
              id={params.row.uuid}
              open={Boolean(anchorElDelete.get(params.row.uuid))}
              anchorEl={anchorElDelete.get(params.row.uuid)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Button
                sx={{ m: 2 }}
                variant="contained"
                size="small"
                color="error"
                onClick={onDelete}
              >
                Permanently delete {params.row.title}?
              </Button>
            </Popover>
          </>
        );
      },
    },
  ];

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            <Button
              disableElevation
              sx={{ textTransform: 'none' }}
              variant="contained"
              border="2px solid"
              size="medium"
              component={RouterLink}
              to={`/notifications/add`}
            >
              Schedule Notification
            </Button>
          }
        >
          Notification Center
        </AppBar>
      )}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(notificationListFetch(filters))}
        />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(notification) => notification.uuid}
          rows={notificationList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const notification = param.row;
            if (e.ctrlKey) {
              window.open(`/notifications/${notification.uuid}`);
            } else {
              dispatch(setNotification(notification));
              navigate(`/notifications/${notification.uuid}`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('NotificationList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns, [
              { field: 'scheduledAt', sort: 'desc' },
            ]),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('NotificationList', {
                message: false,
              }),
            },
          }}
        />
      </div>
    </>
  );
};
