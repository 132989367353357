import { captureEvent } from '@/firebase';
import { loanRepaymentsCreate } from '@/loan/loanRepaymentListSlice';
import { transactionFetch } from '@/transaction/transactionSlice';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const AddLoanRepayment = (props) => {
  // input field style
  const inputStyle = { padding: '8px 12px' };
  // initial form state
  const initialFormState = {
    phone: '',
    name: '',
    amount: 0,
    date: '',
    transactionUuid: '',
  };
  const dispatch = useDispatch();
  const { handleClose, open } = props;
  const [formData, setFormData] = useState(initialFormState);
  let { transaction } = useSelector((state) => state.transaction);

  const handleOnChange = (title) => (e) =>
    setFormData({ ...formData, [title]: e.target.value });
  const handleAddRepayment = () => {
    const { date, name, phone, amount, transactionUuid } = formData;
    // TODO: validate data
    if (!(date && name && phone && amount && transactionUuid && transaction)) {
      // console.log('Data is incomplete!');
      return;
    }
    Object.assign(formData, { transaction });
    // call API to save data
    dispatch(loanRepaymentsCreate(formData));
    captureEvent('CREATE_LOAN_REPAYMENT_FORM_SAVE', {});
    // restore form before closing modal
    setFormData(initialFormState);

    // close modal
    handleClose();
  };

  useEffect(() => {
    // get transaction
    if (formData.transactionUuid.length === 36) {
      dispatch(transactionFetch(formData.transactionUuid));
    }
  }, [formData.transactionUuid, dispatch]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Add Loan Repayment</DialogTitle>
      <DialogContent>
        <Box my={1}>
          <Typography mb={1}>Agent Phone Number</Typography>
          <TextField
            inputProps={{ style: inputStyle }}
            sx={{ width: '100%' }}
            type="tel"
            placeholder="+256 655385243"
            onChange={handleOnChange('phone')}
          />
        </Box>

        <Box my={1}>
          <Typography mb={1}>Agent name</Typography>
          <TextField
            inputProps={{ style: inputStyle }}
            sx={{ width: '100%' }}
            type="text"
            placeholder="Norman Ope"
            onChange={handleOnChange('name')}
          />
        </Box>

        <Box my={1}>
          <Typography mb={1}>Payment Amount</Typography>
          <TextField
            inputProps={{ style: inputStyle }}
            sx={{ width: '100%' }}
            type="number"
            placeholder={`${import.meta.env?.VITE_CURRENCY_CODE ?? 'UGX'} 20,000`}
            onChange={handleOnChange('amount')}
          />
        </Box>

        <Box my={1}>
          <Typography mb={1}>Transaction ID</Typography>
          <TextField
            type="text"
            sx={{ width: '100%' }}
            inputProps={{ style: inputStyle }}
            placeholder="1234-5456-5538-5243"
            onChange={handleOnChange('transactionUuid')}
          />
        </Box>

        <Box my={1}>
          <Typography mb={1}>Time and date</Typography>
          <TextField
            sx={{ padding: '1', width: '100%' }}
            type="datetime-local"
            placeholder="Apr 26, 2023 08:45pm"
            onChange={handleOnChange('date')}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ borderColor: 'gray', borderWidth: '1px', fontWeight: 'normal' }}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disableElevation
          variant="contained"
          onClick={handleAddRepayment}
        >
          Add Repayment
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLoanRepayment;
