import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { setAgent } from './agentSlice';

const initialState = {
  status: 'editing',
  error: '',
};

const agentFormSlice = createSlice({
  name: 'agentForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const agentSave = (uuid, data) => (dispatch) => {
  dispatch(setLoading(true));

  getApi()
    .patch(`/api/agents/${uuid}`, data)
    .then((response) => {
      dispatch(setAgent(response.data));
      dispatch(resetState());
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { resetState, setLoading, setError } = agentFormSlice.actions;

export const agentFormReducer = agentFormSlice.reducer;
