import { AppBar, Error, Loading } from '@/common';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { userSave } from './userFormSlice';
import { userFetch } from './userSlice';
import { getRoles } from './utils';
import { captureEvent } from '@/firebase';

export const UserForm = () => {
  // console.log('RXD:UserForm');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { error: formError, status: formStatus } = useSelector(
    (state) => state.userForm
  );
  const {
    user,
    error: fetchError,
    isLoading: fetchIsLoading,
  } = useSelector((state) => state.user);

  const [email, setEmail] = useState('');
  const [role, setRole] = useState([]);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    if (uuid !== user?.uuid && !fetchIsLoading && !fetchError) {
      setEmail('');
      setRole([]);
      setPassword('');
      if (uuid) {
        dispatch(userFetch(uuid));
      }
    }
  }, [dispatch, user, uuid, fetchIsLoading, fetchError]);

  useEffect(() => {
    setPasswordError(password !== password2 ? 'Passwords must match' : '');
  }, [password, password2]);

  // initial values
  useEffect(() => {
    if (uuid === user?.uuid) {
      setEmail(user?.email ?? '');
      setRole(user?.role?.split(',').map((role) => role.trim()) ?? []);
    }
  }, [dispatch, user, uuid]);

  const handleSave = (e) => {
    e.preventDefault();
    dispatch(
      userSave(uuid, {
        email,
        role: role.join(','),
        password,
      })
    );
    captureEvent(uuid ? 'UPDATE_USER_FORM' : 'ADD_USER_FORM_SAVE', {});
  };

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <>
      <AppBar>{uuid ? 'Edit User' : 'Add User'}</AppBar>

      {fetchError && <Error error={fetchError} />}
      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <>
                <Box
                  component="form"
                  method="post"
                  sx={{ p: 1 }}
                  onSubmit={handleSave}
                >
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      type="email"
                      value={email}
                      onChange={w(setEmail)}
                      required
                      disabled={!!uuid}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <Autocomplete
                      id="role"
                      value={role}
                      options={getRoles()}
                      multiple
                      required
                      fullWidth
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => setRole(newValue)}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip label={option} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Roles" fullWidth />
                      )}
                    />
                  </FormControl>

                  {!uuid && (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={password}
                        onChange={w(setPassword)}
                        required
                      />
                    </FormControl>
                  )}

                  {!uuid && (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                        label="Retype Password"
                        variant="outlined"
                        type="password"
                        value={password2}
                        onChange={w(setPassword2)}
                        required
                        error={passwordError}
                        helperText={passwordError}
                      />
                    </FormControl>
                  )}

                  <Divider sx={{ mb: 2 }} />

                  {formStatus === 'loading' ? (
                    <Loading />
                  ) : (
                    <Button variant="contained" sx={{ mb: 1 }} type="submit">
                      Save
                    </Button>
                  )}
                </Box>
              </>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
