import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { AppBar } from '@/common';
import {
  getImportsByFile,
  resetState,
  stopLoading,
  uploadLoanRepaymentFile,
} from '@/loan/loanRepaymentListSlice';
import infoIcon from '../../images/info.png';
import bulkImage from '../../images/operata-csv.png';
import sampleSheet from '@/images/bulk-payment-upload.csv';
import { captureEvent } from '@/firebase';

const FILE_EXTENSION = ['csv', 'xslx'];

const BulkLoanRepayment = () => {
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const loanRepaymentList = useSelector((state) => state.loanRepaymentList);
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpenFinalModal = () => setOpen(true);
  const handleCloseFinalModal = () => setOpen(false);

  const handleOnChange = (e) => {
    const file = e.target?.files[0];
    if (FILE_EXTENSION.includes(getFileExtension(file?.type ?? ''))) {
      setSelectedFile(file);
    }
  };

  const handleDropDown = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (FILE_EXTENSION.includes(getFileExtension(file?.type ?? ''))) {
      setSelectedFile(file);
    }
  };

  const handleSubmit = () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);
    dispatch(uploadLoanRepaymentFile(formData));
    captureEvent('BULK_LOAN_REPAYMENT_FORM_SAVE', {});
  };

  const handleReset = () => {
    dispatch(resetState());
  };

  useEffect(() => {
    if (loanRepaymentList?.uploadedFileUrl) {
      const payload = {
        status: 'pending',
        sources: ['sheet'],
        file: loanRepaymentList?.uploadedFileUrl,
      };
      dispatch(getImportsByFile(payload));
    }
  }, [dispatch, loanRepaymentList?.uploadedFileUrl]);

  useEffect(() => {
    if (!!loanRepaymentList?.bulkLoanRepayment) {
      handleOpenFinalModal();
      dispatch(stopLoading());
    }
  }, [dispatch, loanRepaymentList?.bulkLoanRepayment]);

  return (
    <Box bgColor="#F8F9FA" mt={'20px'}>
      <AppBar sxSecondary={{ display: 'none' }}>Add Bulk Repayment</AppBar>

      <Card>
        <CardContent p={8}>
          <Grid
            container
            sx={{
              bgcolor: '#F8F9FA',
              borderRadius: '10px',
            }}
          >
            <Grid item md={6} xs={12} sx={{ borderRadius: '12px', p: '12px' }}>
              <UploadSelectedFile
                isLoading={!!loanRepaymentList?.isLoading}
                handleSubmit={handleSubmit}
                fileInputRef={fileInputRef}
                selectedFile={selectedFile}
                handleDropDown={handleDropDown}
                handleOnChange={handleOnChange}
                setSelectedFile={setSelectedFile}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 500,
                  lineHeight: '1.25rem',
                  textAlign: 'center',
                  marginY: '10px',
                }}
              >
                Repayment Template
              </Typography>
              <img
                src={bulkImage}
                alt="Sheet screenshot"
                sx={{ maxWidth: '100%' }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <FinalStep
        open={open}
        handleReset={handleReset}
        onClose={handleCloseFinalModal}
        loanRepaymentList={loanRepaymentList}
      />
    </Box>
  );
};

const UploadSelectedFile = (props) => {
  const {
    selectedFile,
    handleDropDown,
    setSelectedFile,
    handleSubmit,
    fileInputRef,
    handleOnChange,
    isLoading,
  } = props;

  return (
    <>
      <Box mb={'4'}>
        <Box
          component="a"
          href={sampleSheet}
          sx={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            pl: '20px',
            marginTop: '5px',
            marginBottom: '20px',
            gap: '8px',
            color: '#35D2A2',
          }}
        >
          <SaveAltIcon />
          <Typography
            sx={{
              fontSize: '1.25rem',
              fontWeight: 500,
              lineHeight: '1.25rem',
              textAlign: 'center',
            }}
          >
            Download Repayment Template
          </Typography>
        </Box>
      </Box>

      <Box bgcolor={'white'} borderRadius={'12px'} p={'20px'}>
        <Typography>
          <span style={{ color: 'red' }}>*</span> Upload File
        </Typography>
        <Typography
          fontSize={'12px'}
          color={'#B5B5B5'}
          fontWeight={'300'}
          variant="caption"
          mb={'12px'}
        >
          You can only upload 1 file, supported formats are csv, xslx
        </Typography>
        <Stack justifyContent={'space-between'} marginY={'3'} gap={'2'}>
          <Stack
            bgcolor={'white'}
            p={'8px'}
            border={'3px dashed #DDE2E5'}
            borderRadius={'5px'}
            height={'150px'}
            width={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ backgroundColor: '#F8F9FA' }}
            position={'relative'}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDropDown}
          >
            {selectedFile ? (
              <>
                <IconButton
                  p={1}
                  sx={{
                    backgroundColor: '#DDE2E5',
                  }}
                >
                  <InsertDriveFileIcon fontSize="large" fill="#fff" />
                </IconButton>

                <Box>
                  <Typography textAlign={'center'}>
                    {selectedFile?.name}
                  </Typography>
                  <Typography
                    fontWeight={500}
                    fontSize={'12px'}
                    color={'#D3D4D7'}
                    textAlign={'center'}
                  >
                    {getFileSize(selectedFile?.size ?? 0)}
                  </Typography>
                </Box>
                <IconButton
                  p={1}
                  sx={{
                    color: '#000',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                  }}
                  onClick={() => setSelectedFile(null)}
                >
                  <DeleteIcon fontSize="medium" />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  p={1}
                  sx={{
                    backgroundColor: '#DDE2E5',
                    color: 'white',
                  }}
                >
                  <ArrowCircleUpOutlinedIcon fontSize="large" fill="#000" />
                </IconButton>

                <Box>
                  <Button
                    sx={{
                      ':hover': { background: 'transparent' },
                    }}
                    variant="text"
                    onClick={() => fileInputRef?.current?.click()}
                  >
                    Browse or drag a .csv file here
                  </Button>
                </Box>

                <input
                  type="file"
                  hidden
                  ref={fileInputRef}
                  onChange={handleOnChange}
                />
              </>
            )}
          </Stack>
          {!!selectedFile ? (
            <Box py={2}>
              <Button
                disableElevation
                variant="contained"
                onClick={handleSubmit}
              >
                {isLoading ? 'Loading...' : 'Proceed'}
              </Button>
            </Box>
          ) : null}
        </Stack>
      </Box>
    </>
  );
};

const FinalStep = (props) => {
  const { loanRepaymentList, handleReset, open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ minWidth: '300px' }}
          gap={'5px'}
          py={'4'}
        >
          <Box
            component={'img'}
            src={infoIcon}
            height={'60px'}
            width={'60px'}
            my={'4'}
            p={'1'}
            mx={'auto'}
          />
          <Typography my={'2'}>
            {loanRepaymentList?.bulkLoanRepayment?.duplicated ?? 0} duplicated
          </Typography>
          <Typography my={'2'}>
            {loanRepaymentList?.bulkLoanRepayment?.succeeded ?? 0} succeeded
          </Typography>
          <Typography my={'2'}>
            {loanRepaymentList?.bulkLoanRepayment?.total ?? 0} Total
          </Typography>
          <NavLink to={'/loans/repayments'}>
            <Button
              disableElevation
              variant="contained"
              px={'5px'}
              onClick={handleReset}
              fullWidth
            >
              {!!loanRepaymentList?.isLoading ? 'Loading...' : 'Proceed'}
            </Button>
          </NavLink>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const getFileExtension = (type) => type.split('/')[1];
const getFileSize = (_size) => {
  let size = _size;
  let ext = 'bytes';

  if (_size >= 1_000 && _size < 1_000_000) {
    size = Number(_size / 1_000).toFixed(2);
    ext = 'KB';
  } else if (_size >= 1_000_000) {
    size = Number(_size / 1_000_000).toFixed(2);
    ext = 'MB';
  }

  return `${size} ${ext}`;
};

export default BulkLoanRepayment;
