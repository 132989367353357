import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  loanProduct: null,
  isLoading: false,
  error: '',
};

const loanProductSlice = createSlice({
  name: 'loanProduct',
  initialState: initialState,
  reducers: {
    setLoanProduct: (state, { payload }) => {
      state.loanProduct = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.loanProduct = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const loanProductFetch = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/loans/products/${uuid}`)
    .then((response) => dispatch(setLoanProduct(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setLoanProduct, resetState, setLoading, setError } =
  loanProductSlice.actions;

export const loanProductReducer = loanProductSlice.reducer;
