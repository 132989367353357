import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError, updateInList } from '@/common';

const initialState = {
  floatRequestList: [],
  agent: null,
  isLoading: false,
  isLoadingAgent: false,
  isSubmitting: false,
  lastFilter: null,
  error: '',
};

export const floatRequestListSlice = createSlice({
  name: 'floatRequestList',
  initialState: initialState,
  reducers: {
    setFloatRequests: (state, { payload }) => {
      const { lastFilter, floatRequests } = payload;

      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.floatRequestList = floatRequests;
    },
    updateFloatRequests: (state, { payload }) => {
      const { rows, key } = payload;
      state.floatRequestList = updateInList(rows, state.floatRequestList, key);
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setLoadingAgent: (state, { payload }) => {
      state.error = '';
      state.isLoadingAgent = payload;
    },
    setSubmitting: (state, { payload }) => {
      state.error = '';
      state.isSubmitting = payload;
    },
    setAgent: (state, { payload }) => {
      const { agent } = payload;
      state.isLoadingAgent = false;
      state.agent = agent;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.isLoadingAgent = false;
      state.isSubmitting = false;
    },
  },
});

export const floatRequestsFetchUsingApi = (filters) =>
  getApi().get('/api/float-cash/request', { params: filters });

export const agentFetchUsingApi = (filters) =>
  getApi().get('/api/agents', { params: filters });

export const floatRequestListFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  floatRequestsFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setFloatRequests({
          lastFilter: filters?.toString(),
          floatRequests: response.data?.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const agentSearch = (value) => (dispatch) => {
  dispatch(setLoadingAgent(true));

  agentFetchUsingApi(
    new URLSearchParams(
      `phoneNumber=contains:${encodeURIComponent(
        value
      )}&oo=agentName&e=1&pp=1:10`
    )
  )
    .then((response) => {
      let agent = response.data[0];
      dispatch(
        setAgent({
          agent,
        })
      );
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const submitRequest = (data) => (dispatch) => {
  dispatch(setSubmitting(true));
  getApi()
    .post('/api/float-cash/request/bulk', data)
    .then((response) => {
      dispatch(floatCashApprove(response.data));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const floatCashApprove = (result) => (dispatch) => {
  const floatRequests = result.map((value) => {
    return {
      uuid: value.uuid,
      status: 'APPROVED',
    };
  });
  dispatch(setSubmitting(true));

  getApi()
    .patch(`/api/float-cash/request/bulk/review`, { floatRequests })
    .then((response) => {
      dispatch(floatRequestListFetch(initialState.lastFilter));
      dispatch(setSubmitting(false));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const {
  setError,
  setLoading,
  setFloatRequests,
  setLoadingAgent,
  setAgent,
  setSubmitting,
  updateFloatRequests,
} = floatRequestListSlice.actions;

export const floatRequestListReducer = floatRequestListSlice.reducer;
