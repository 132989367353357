import { Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import {
  dateFormat,
  getColumnVisibility,
  getSortOrder,
  Loading,
  setColumnVisibility,
  useFilters,
} from '@/common';
import { paginationValue } from '@/common/filter';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import {
  currentRateFetch,
  exchangeRateAdd,
  fetchExchangeRateHistory,
} from '@/exchange/exchangeRateSettingsSlice';
import { getExchangeRate, getExchangeRateFixed } from '@/exchange/utils';

export const ExchangeRateSettings = ({ embedded }) => {
  const currency = import.meta.env.VITE_CURRENCY_CODE;
  const dispatch = useDispatch();
  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);
  const {
    currentExchangeRate,
    rateHistory,
    isLoading,
    isLoadingCurrentExchangeRate,
    lastFilter,
    error,
  } = useSelector((state) => state.exchangeRateSettings);
  const [usdToCdfRate, setUsdToCdfRate] = useState('');
  const w = (callback) => (e) => callback(e.target.value);

  const cellTransform = (callback) => (param) => callback(param.row);

  const addExchangeRate = () => {
    const payload = {
      baseCurrency: 'USD',
      targetCurrency: currency,
      rate: Number(usdToCdfRate),
      effectiveDate: new Date().toISOString(),
    };
    dispatch(
      exchangeRateAdd(payload, {
        currencyPair: `USD:${currency}`,
      })
    );
  };

  const columns = [
    {
      field: 'rate',
      headerName: 'Rate',
      type: 'number',
      sortingOrder: getSortOrder(),
      valueGetter: cellTransform(getExchangeRate),
    },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      type: 'dateTime',
      minWidth: 200,
      valueGetter: (params) =>
        dateFormat(params.row.effectiveDate, 'DD MMM, YYYY'),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'createdAt',
      headerName: 'CreatedAt At',
      type: 'dateTime',
      minWidth: 200,
      valueGetter: (params) => dateFormat(params.row.createdAt),
      sortingOrder: getSortOrder(),
    },
  ];

  useEffect(() => {
    if (currentExchangeRate) {
      setUsdToCdfRate(getExchangeRateFixed(currentExchangeRate.rate));
    }
  }, [currentExchangeRate]);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, []);
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(
        currentRateFetch({
          currencyPair: `USD:${currency}`,
        })
      );
      dispatch(fetchExchangeRateHistory(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  return (
    <Box sx={{ pt: 1, pb: 2 }} display="flex" flexDirection="column">
      <Card>
        <CardContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Set and manage USD to CDF exchange rates
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
            <FormControl sx={{ mr: 2 }}>
              <TextField
                id="exchange"
                label="Current Rate"
                variant="outlined"
                value={usdToCdfRate}
                onChange={w(setUsdToCdfRate)}
                disabled={isLoadingCurrentExchangeRate}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography fontWeight="bold">1 USD=</Typography>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <Typography fontWeight="bold">{currency}</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            {isLoadingCurrentExchangeRate ? (
              <Loading size={20} />
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{ alignSelf: 'stretch' }}
                disabled={usdToCdfRate === '' || isNaN(Number(usdToCdfRate))}
                onClick={() => addExchangeRate()}
              >
                Save
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>

      <Box sx={{ mt: 4 }}>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
          Exchange Rate History
        </Typography>
        <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
          <OparetaTable
            getRowId={(rate) => rate?.uuid}
            rows={rateHistory ?? []}
            columns={columns}
            onColumnVisibilityModelChange={(visible) =>
              setColumnVisibility('RateHistoryList', visible)
            }
            loading={isLoading}
            page={serverPage - 1}
            onFilterModelChange={getOnFilterModelChange(
              filters,
              setFilters,
              serverPerPage
            )}
            onSortModelChange={getOnSortModelChange(
              filters,
              setFilters,
              serverPerPage
            )}
            onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
            onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
            initialState={{
              sorting: getInitialSortModel(filters, columns, [
                { field: 'createdAt', sort: 'desc' },
              ]),
              filter: getInitialFilterModel(filters, columns),
              columns: {
                columnVisibilityModel: getColumnVisibility(
                  'RateHistoryList',
                  {}
                ),
              },
            }}
          />
        </div>
      </Box>
    </Box>
  );
};
