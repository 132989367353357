import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';
import { fetchMyFieldAgent, getLoggedInUser } from '@/float-cash/util';

const initialState = {
  meFieldAgent: null,
  fieldAgents: [],
  agent: null,
  isLoadingFieldAgents: false,
  isLoading: false,
  isSubmitting: false,
  closeCashCollectionModal: false,
  user: null,
};
export const floatCashCollectionFormSlice = createSlice({
  name: 'floatCashCollectionForm',
  initialState: initialState,
  reducers: {
    setMeFieldAgent: (state, { payload }) => {
      const { fieldAgent } = payload;
      state.isLoading = false;
      state.meFieldAgent = fieldAgent;
    },
    setFieldAgents: (state, { payload }) => {
      const { fieldAgents } = payload;
      state.isLoadingFieldAgents = false;
      state.fieldAgents = fieldAgents;
    },
    setAgent: (state, { payload }) => {
      console.log('>>> Setting agent in slice: ', payload);
      const { agent } = payload;
      state.isLoading = false;
      state.agent = agent;
    },
    setLoadingFieldAgents: (state) => {
      state.error = '';
      state.isLoadingisLoadingFieldAgents = true;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setSubmitting: (state) => {
      state.error = '';
      state.isSubmitting = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },

    stopLoading: (state) => {
      state.isLoading = false;
      state.isLoadingFieldAgents = false;
      state.isSubmitting = false;
    },
    closeCashCollectionModal: (state) => {
      state.closeCashCollectionModal = true;
    },
    clearState: (state) => {
      state.closeCashCollectionModal = false;
      state.fieldAgents = [];
      state.agent = null;
      state.isLoadingFieldAgents = false;
      state.isLoading = false;
      state.isSubmitting = false;
      state.closeCashCollectionModal = false;
    },
  },
});

export const resetAgent = () => (dispatch) => {
  dispatch(setAgent({ agent: null }));
};

export const fieldAgentsFetchUsingApi = (filters) =>
  getApi().get('/api/field-agents', { params: filters });
export const agentFetchUsingApi = (filters) =>
  getApi().get('/api/agents', { params: filters });

export const fetchMyFieldAgentProfile = () => (dispatch) => {
  fetchMyFieldAgent()
    .then((fieldAgent) => {
      dispatch(setMeFieldAgent({ fieldAgent }));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const fieldAgentsFetch = (filters) => (dispatch) => {
  dispatch(setLoadingFieldAgents(true));
  fieldAgentsFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setFieldAgents({
          fieldAgents: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const agentFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  agentFetchUsingApi(filters)
    .then((response) => {
      let agent = response.data[0];
      dispatch(
        setAgent({
          agent,
        })
      );
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const submitForm = (formData) => (dispatch) => {
  dispatch(setSubmitting(true));
  console.log('>>> Dispatching submit');
  getApi()
    .post('/api/float-cash/transaction', formData)
    .then((res) => {
      console.log('>>> Dispatching close');
      dispatch(closeCashCollectionModal());
      dispatch(push('/float-cash/cash'));
    })
    .catch((err) => dispatch(setError(getApiError(err))))
    .finally(() => dispatch(stopLoading()));
};

export const {
  setLoading,
  setLoadingFieldAgents,
  stopLoading,
  setFieldAgents,
  setAgent,
  setError,
  setSubmitting,
  closeCashCollectionModal,
  clearState,
  setMeFieldAgent,
} = floatCashCollectionFormSlice.actions;

export const floatCashCollectionFormReducer =
  floatCashCollectionFormSlice.reducer;
