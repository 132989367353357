import { Fragment, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { debounce } from '@/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  fieldAgentsFetch,
  agentFetch,
  resetAgent,
  setLoading,
  setLoadingFieldAgents,
  submitForm,
  setAgent,
  clearState,
  fetchMyFieldAgentProfile,
} from '@/float-cash/cashCollectionFormSlice';
import Button from '@mui/material/Button';
import { amountToNumberOrZero, getLoggedInUser } from '@/float-cash/util';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { captureEvent } from '@/firebase';

export const CashCollectionForm = (props) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const dialingCode = import.meta.env?.VITE_DIALING_CODE || '+256';
  const currencyCode = import.meta.env?.VITE_CURRENCY_CODE || 'UGX';
  let initialFormState = {
    transactionType: 'CASH_IN',
    currency: currencyCode,
    toAccountId: '',
    fromAccountId: '',
    fromAccountSignatureUrl: '',
    toAccountSignatureUrl: '',
    amount: 0,
    extraInfo: {},
    comments: [],
  };
  const [formData, setFormData] = useState(initialFormState);
  const [tdrNameInputValue, setTdrNameInputValue] = useState('');
  const [agentPhoneNumberValue, setAgentPhoneNumberValue] = useState(null);
  const [commentValue, setCommentValue] = useState('');
  const [canSave, setCanSave] = useState(false);
  const [fieldAgent, setFieldAgent] = useState(null);
  const {
    meFieldAgent,
    fieldAgents,
    agent,
    isLoadingFieldAgents,
    isLoading,
    isSubmitting,
    closeCashCollectionModal,
  } = useSelector((state) => state.floatCashCollectionForm);

  const handleOnChange = (title) => (e) => {
    let value = e.target.value;
    if (title === 'phoneNumber') {
      dispatch(resetAgent());
      setAgentPhoneNumberValue(value);
    } else if (title === 'comment') {
      setCommentValue(value);
    } else {
      setFormData({ ...formData, [title]: value });
    }
  };

  const handleFieldAgentSelect = (fieldAgent) => {
    setFieldAgent(fieldAgent);
  };
  const handleSubmit = () => {
    formData.toAccountId = fieldAgent?.uuid;
    formData.fromAccountId = agent?.uuid;
    formData.amount = Number(formData.amount);
    formData.phoneNumber = fieldAgent?.phoneNumber;
    if ((commentValue?.length || 0) > 0) formData.comments = [commentValue];
    formData.extraInfo = {
      source: 'dashboard',
      submittedBy: meFieldAgent,
      agentTdr: agent?.fieldAgent?.name,
      agentPhoneNumber: dialingCode + agentPhoneNumberValue,
      agents: [
        {
          uuid: agent.uuid,
          name: agent.agentName,
          currency: currencyCode,
        },
      ],
    };
    dispatch(submitForm(formData));
    captureEvent('CASH_COLLECTION_FORM_SAVE', {});
  };

  const resetForm = () => {
    dispatch(clearState());
    setFieldAgent(null);
    initialFormState.toAccountId = '';
    initialFormState.fromAccountId = '';
    initialFormState.amount = 0;
    initialFormState.extraInfo = {};
    setCommentValue('');
    initialFormState.comments = [];
  };

  const handleTDRSearch = useCallback(
    debounce(
      (value) =>
        dispatch(
          fieldAgentsFetch(
            new URLSearchParams(`name=contains:${value}&oo=name&e=1&pp=1:10`)
          )
        ),
      1000
    ),
    []
  );

  // noinspection JSCheckFunctionSignatures
  const handleSearch = useCallback(
    debounce(
      (value) =>
        dispatch(
          agentFetch(
            new URLSearchParams(
              `pp=1:12&oo=-transactionVolume&phoneNumber=equals:${encodeURIComponent(
                `${dialingCode}${value}`
              )}`
            )
          )
        ),
      2000
    )
  );

  useEffect(() => {
    dispatch(fetchMyFieldAgentProfile());
  }, []);

  useEffect(() => {
    dispatch(fieldAgentsFetch());
  }, []);

  useEffect(() => {
    dispatch(setLoadingFieldAgents(true));
    // noinspection JSCheckFunctionSignatures
    handleTDRSearch(tdrNameInputValue);
  }, [handleTDRSearch, tdrNameInputValue]);

  useEffect(() => {
    if (!!agentPhoneNumberValue && agentPhoneNumberValue.length >= 5) {
      dispatch(setLoading(true));
      // noinspection JSCheckFunctionSignatures
      handleSearch(agentPhoneNumberValue);
    }
  }, [agentPhoneNumberValue]);

  useEffect(() => {
    setCanSave(
      !!fieldAgent &&
        !!agent &&
        amountToNumberOrZero(formData.amount) > 0 &&
        commentValue.trim().length > 0
    );
  }, [fieldAgent, agent, formData, commentValue]);

  useEffect(() => {
    console.log(
      '>>> Close cash collection changed. Close? ',
      closeCashCollectionModal
    );
    if (closeCashCollectionModal === true) {
      console.log(
        '>>> Closing cash collection. Close? ',
        closeCashCollectionModal
      );
      handleClose();
    }
  }, [closeCashCollectionModal]);

  useEffect(() => {
    if (open === false) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Collect cash from agent</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 3 }}>
          <Autocomplete
            getOptionLabel={(fieldAgent) =>
              typeof fieldAgent == 'string' ? fieldAgent : fieldAgent?.name
            }
            filterOptions={(x) => x}
            options={fieldAgents}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={fieldAgent}
            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
            onChange={(event, newValue) => {
              handleFieldAgentSelect(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setTdrNameInputValue(newInputValue);
            }}
            renderOption={(props, fieldAgent) => {
              return (
                <li {...props} key={fieldAgent.uuid}>
                  {fieldAgent.name} - {fieldAgent.phoneNumber}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="TDR"
                placeholder="Search TDR by name"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isLoadingFieldAgents ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        </FormControl>

        <TextField
          sx={{ width: '100%', mt: 3 }}
          type="text"
          label="Agent phone number *"
          onChange={handleOnChange('phoneNumber')}
          InputProps={{
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <></>
                )}
              </Fragment>
            ),
            startAdornment: (
              <InputAdornment position="start">{dialingCode}</InputAdornment>
            ),
          }}
        />
        <TextField
          sx={{ width: '100%', mt: 3 }}
          type="number"
          label="Amount collected *"
          onChange={handleOnChange('amount')}
        />
        <TextField
          sx={{ width: '100%', mt: 3 }}
          id="agent-text"
          label="Agent name"
          variant="outlined"
          required
          value={agent?.agentName || ''}
          disabled
          autoComplete="off"
        />
        <TextField
          sx={{ width: '100%', mt: 3 }}
          id="field-agent-text"
          label="Agent's TDR"
          variant="outlined"
          required
          value={agent?.fieldAgent?.name || ''}
          disabled
          autoComplete="off"
        />
        <TextField
          autoFocus
          margin="dense"
          id="comments"
          label="Comments"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          minRows={3}
          sx={{ mt: 3 }}
          value={commentValue}
          required
          onChange={handleOnChange('comment')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            borderColor: 'gray',
            borderWidth: '1px',
            fontWeight: 'normal',
            color: 'gray',
          }}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disableElevation
          variant="contained"
          disabled={!canSave || isSubmitting}
          onClick={handleSubmit}
        >
          <span>Save</span>{' '}
          {isSubmitting ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <></>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
