import { AppBar, Error, Loading } from '@/common';
import { FirebaseContext } from '@/firebaseContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { Suspense, lazy, useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { loanContractSave } from './loanContractFormSlice';
import { loanContractFetch, resetState } from './loanContractSlice';
import { getContractVariables } from './utils';
import { captureEvent } from '@/firebase';

export const LoanContractForm = () => {
  // console.log('RXD:LoanContractForm');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const ckEditor = useRef(null);
  const firebaseApp = useContext(FirebaseContext);
  const storage = getStorage(firebaseApp);

  const { loanContract, isLoading, error } = useSelector(
    (state) => state.loanContract
  );
  const { error: formError, status } = useSelector(
    (state) => state.loanContractForm
  );
  const [canSubmit, setCanSubmit] = useState(true);
  const [newUuid, setNewUuid] = useState('');
  const [name, setName] = useState('');
  const [template, setTemplate] = useState('');
  const [signaturePhotoUrl, setSignaturePhotoUrl] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxFiles: 1,
    accept: { 'image/*': [] },
    onDrop: (files) => {
      files.forEach((file) => {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setSignaturePhotoUrl(reader.result)
        );
        const fileUuid = uuid ?? newUuid;
        const extension = file.type.split('/').pop();
        const storageRef = ref(
          storage,
          `contract/signature-${fileUuid}.${extension}`
        );
        setCanSubmit(false);
        uploadBytes(storageRef, file)
          .then((snapshot) => getDownloadURL(snapshot.ref))
          .catch((err) => {
            console.error(err);
            setSignaturePhotoUrl('');
          })
          .finally(() => {
            setCanSubmit(true);
          })
          .then(setSignaturePhotoUrl);
        reader.readAsDataURL(file);
      });
    },
  });

  useEffect(() => {
    if (uuid && uuid !== loanContract?.uuid && !isLoading && !error) {
      dispatch(resetState());
      setNewUuid(uuid);
      setName('');
      setSignaturePhotoUrl('');
      setTemplate('');
      dispatch(loanContractFetch(uuid));
    }
  }, [dispatch, loanContract, uuid, isLoading, error]);

  // initial values
  useEffect(() => {
    if (uuid) {
      if (uuid === loanContract?.uuid) {
        setName(loanContract?.name ?? '');
        setSignaturePhotoUrl(loanContract?.signaturePhotoUrl ?? '');
        setTemplate(loanContract?.template ?? '');
        ckEditor.current?.setData(loanContract?.template ?? '');
      }
    } else {
      setNewUuid(uuidv4());
    }
  }, [loanContract, uuid]);

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      uuid,
      name,
      template,
      signaturePhotoUrl,
    };
    dispatch(loanContractSave(uuid, data));
    captureEvent(
      uuid ? 'EDIT_LOAN_CONTRACT_FORM_SAVE' : 'CREATE_LOAN_CONTRACT_FORM_SAVE'
    );
  };

  // console.log('RXD:LoanContractForm', 'uuid', uuid, 'status', status);

  const w = (callback) => (e) => callback(e.target.value);

  const HtmlEditor = lazy(() => import('@/common/HtmlEditor'));

  return (
    <>
      <AppBar>{uuid ? 'Edit Loan Contract' : 'Add Loan Contract'}</AppBar>

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <Box
                    component="form"
                    method="post"
                    sx={{ p: 1 }}
                    onSubmit={handleSave}
                    autoComplete="off"
                  >
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                        id="name"
                        label="Name"
                        variant="outlined"
                        value={name}
                        onChange={w(setName)}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <Typography variant="body1">Contract Template</Typography>
                      <Box
                        sx={{
                          '& .DraftEditor-root': {
                            border: '1px solid gray',
                            minHeight: 'calc(100vh - 320px)',
                          },
                        }}
                      >
                        <Suspense fallback={<Loading />}>
                          <HtmlEditor
                            data={template}
                            onReady={(editor) => {
                              ckEditor.current = editor;
                              editor.editing.view.change((writer) => {
                                writer.setStyle(
                                  'min-height',
                                  '300px',
                                  editor.editing.view.document.getRoot()
                                );
                              });
                            }}
                            onChange={(event, editor) => {
                              setTemplate(editor.getData());
                            }}
                          />
                        </Suspense>
                      </Box>
                    </FormControl>

                    <Accordion sx={{ mb: 2 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Contract Variables</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Variable</TableCell>
                                <TableCell>Meaning</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {getContractVariables().map(
                                ([variable, meaning]) => (
                                  <TableRow key={variable}>
                                    <TableCell>
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={(e) =>
                                          navigator.clipboard
                                            .writeText(variable)
                                            .catch(console.error)
                                        }
                                      >
                                        COPY
                                      </Button>
                                    </TableCell>
                                    <TableCell>{variable}</TableCell>
                                    <TableCell>{meaning}</TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>

                    <Typography variant="body1">Signature</Typography>
                    <Box
                      sx={{
                        height: 80,
                        width: 300,
                        border: 'solid',
                        borderWidth: 3,
                        borderStyle: 'dashed',
                        borderColor: '#eee',
                      }}
                      {...getRootProps({ className: 'dropzone' })}
                    >
                      <input {...getInputProps()} />
                      <Box
                        sx={{
                          backgroundImage: `url(${signaturePhotoUrl})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          height: '100%',
                        }}
                      ></Box>
                    </Box>
                    <Typography variant="subtitle2">
                      Drag and drop signature here to upload
                    </Typography>

                    <Divider sx={{ my: 2 }} />

                    {status === 'loading' ? (
                      <Loading />
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ mb: 1 }}
                        type="submit"
                        disabled={!canSubmit}
                      >
                        Save
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
