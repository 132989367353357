import { GridLoad } from './Loading';
import { paginationValue } from './filter';

export const GridLoading = () => (
  <div style={{ position: 'absolute', top: 0, width: '100%' }}>
    <GridLoad />
  </div>
);

export const getSortOrder = () => ['asc', 'desc', null];

export const weeklyTickLength = (data) => {
  if (Array.isArray(data) && data.length) {
    const min = data[0].timestamp;
    const max = data[data.length - 1].timestamp;
    const number = (max - min) / 604800000;
    return number < 4 ? 4 : number > 52 ? 52 : number;
  }
  return 0;
};

export const setColumnVisibility = (name, visible) => {
  const preferencesString = localStorage.getItem('columnVisibility') ?? '{}';
  const preferences = JSON.parse(preferencesString);
  preferences[name] = visible;
  localStorage.setItem('columnVisibility', JSON.stringify(preferences));
  return preferences;
};

export const refreshData = (isLoading, error, lastFilter, currentFilters) => {
  const a = new URLSearchParams(lastFilter);
  const b = new URLSearchParams(currentFilters);
  return !isLoading && !error && a.toString() !== b.toString();
};

export const getCurrentFilters = (filters, embedded, embeddedKey) => {
  const currentFilters = new URLSearchParams(filters);
  if (embedded) {
    (Array.isArray(embeddedKey) ? embeddedKey : [embeddedKey]).forEach((key) =>
      currentFilters.set(key, embedded?.[key])
    );
  }
  if (!currentFilters.has('pp')) {
    currentFilters.set('pp', '1:15');
  }
  return currentFilters;
};

export const getColumnVisibility = (name, defaultVisible) => {
  const preferencesString = localStorage.getItem('columnVisibility') ?? '{}';
  const preferences = JSON.parse(preferencesString);
  if (!(name in preferences)) {
    return setColumnVisibility(name, defaultVisible)[name];
  }
  return preferences[name];
};

export const getOnFilterModelChange =
  (filters, setFilters, perPage) => (model) => {
    const newFilters = new URLSearchParams(filters);
    filters.set('pp', `1:${perPage}`);
    if (filters.has('oo')) {
      newFilters.set('oo', filters.get('oo'));
    }
    model.columns.forEach((column) => {
      newFilters.delete(column.field);
    });
    model.items.forEach((item) => {
      const { columnField, value, operatorValue } = item;
      if (
        value ||
        operatorValue === 'isEmpty' ||
        operatorValue === 'isNotEmpty'
      ) {
        newFilters.set(columnField, `${operatorValue}:${value}`);
      }
    });
    setFilters(newFilters);
  };

export const getOnSortModelChange =
  (filters, setFilters, perPage) => (model) => {
    filters.set('pp', `1:${perPage}`);
    filters.delete('oo');
    if (model.length > 0) {
      filters.append(
        'oo',
        model
          .map((item) => {
            const { field, sort } = item;
            return `${sort === 'asc' ? '+' : '-'}${field}`;
          })
          .join(',')
      );
    }
    setFilters(filters);
  };

export const getOnPageChange = (filters, setFilters, perPage) => (page) => {
  filters.set('pp', `${page + 1}:${perPage}`);
  setFilters(filters);
};

export const getOnPageSizeChange = (filters, setFilters) => (perPage) => {
  const [page] = paginationValue(filters, 1, 15);
  const pp = `${page}:${perPage}`;
  filters.set('pp', pp);
  setFilters(filters);
};

export const getInitialFilterModel = (filters, columns) => ({
  filterModel: {
    items: columns
      .filter((column) => filters.has(column.field))
      .map((column, id) => {
        const g = /^(?<op>.*?)(|(:(?<value>.*?)))$/.exec(
          filters.get(column.field)
        )?.groups;
        if (g.value === undefined) {
          g.value = g.op;
          g.op = '=';
        }
        const item = {
          id,
          field: column.field,
          operator: g?.operator,
          value: g?.value,
        };
        return item;
      }),
  },
});

export const getInitialSortModel = (filters, columns, defaultSort) => {
  const fields = (filters.has('oo') ? filters.get('oo').split(',') : []).map(
    (field) => {
      const firstChar = field.substring(0, 1);
      const sort =
        firstChar === '-' ? 'desc' : firstChar === '+' ? 'asc' : null;
      return [sort, sort === null ? field : field.substring(1)];
    }
  );
  const defaultMap = new Map(
    (defaultSort ?? []).map((column) => [column.field, column])
  );
  const columnSet = new Set(columns.map((column) => column.field));
  const sortModel = fields
    .filter(([sort, field]) => columnSet.has(field))
    .map(([sort, field]) => {
      defaultMap.delete(field);
      return {
        field,
        sort,
      };
    });
  (defaultSort ?? [])
    .filter((column) => defaultMap.has(column.field))
    .forEach((column) => sortModel.push(column));
  const model = { sortModel: Array.from(sortModel) };
  return model;
};
