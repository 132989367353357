import {
  AppBar,
  Error,
  IS_MULTI_PHONE,
  IS_MULTI_UUID,
  Loading,
  makeUnique,
} from '@/common';
import { DynamicAutoComplete } from '@/common/DynamicAutoComplete';
import { fieldAgentListFetch } from '@/fieldAgent/fieldAgentListSlice';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { agentAssignSave } from './agentAssignFormSlice';
import { agentFetch, resetState } from './agentSlice';
import { getFieldAgent } from './utils';

export const AgentAssignForm = () => {
  // console.log('RXD:AgentAssignForm');
  const dispatch = useDispatch();
  const { uuid } = useParams();

  const { agent, isLoading, error } = useSelector((state) => state.agent);

  const {
    fieldAgentList,
    isLoading: fieldAgentListIsLoading,
    error: fieldAgentListError,
  } = useSelector((state) => state.fieldAgentList);

  const { error: formError, status } = useSelector(
    (state) => state.agentAssignForm
  );

  const [comment, setComment] = useState('');
  const [emptySelectionError, setEmptySelectionError] = useState('');
  const [fieldAgents, setFieldAgents] = useState([]);

  useEffect(() => {
    if (uuid && uuid !== agent?.uuid && !isLoading && !error) {
      dispatch(resetState());
      dispatch(agentFetch(uuid));
    }
  }, [dispatch, agent, uuid, isLoading, error]);

  useEffect(() => {
    const query = new URLSearchParams(
      'pp=1:300&oo=name&isActive=true&ff=fa.uuid,fa.name'
    );
    dispatch(fieldAgentListFetch(query));
  }, []);

  const filterFieldAgents = useCallback(
    (value) => {
      // console.log('RXD:filterFieldAgents', { value, fieldAgentUuid });
      const query = new URLSearchParams('pp=1:300&oo=name&ff=fa.uuid,fa.name');
      if (value) {
        const isMultiPhone = IS_MULTI_PHONE.test(value);
        const isMultiUuid = IS_MULTI_UUID.test(value);
        if (isMultiPhone || isMultiUuid) {
          const label = isMultiPhone ? 'phoneNumber' : 'uuid';
          query.set(label, `in:${value}`);
        } else {
          query.set('name', `contains:${value}`);
        }
      }
      dispatch(fieldAgentListFetch(query));
    },
    [dispatch]
  );

  const handleSave = (e) => {
    e.preventDefault();

    if ((fieldAgents?.length ?? 0) === 0 || !fieldAgents[0]?.uuid) {
      const error = 'Please select field agent';
      setEmptySelectionError(error);
      return;
    }

    dispatch(
      agentAssignSave({ uuid, fieldAgentUuid: fieldAgents[0]?.uuid, comment })
    );
  };

  // console.log('RXD:AgentAssignForm', 'uuid', uuid, 'status', status);

  if (!uuid) {
    return <Navigate to="/agents" />;
  }

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <>
      <AppBar>
        Assign Field Agent for{' '}
        {agent?.uuid === uuid ? agent?.agentName ?? uuid : uuid}
      </AppBar>

      {error && (
        <Error
          error={`Agent: ${error}`}
          onRetry={() => dispatch(agentFetch(uuid))}
        />
      )}

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <Alert severity="info" sx={{ p: 1 }}>
                    {agent?.fieldAgent
                      ? `Current Field Agent is: ${getFieldAgent(agent)}`
                      : 'No Field Agent currently assigned'}
                  </Alert>
                  <Box sx={{ p: 1 }} component="form" onSubmit={handleSave}>
                    <DynamicAutoComplete
                      controlProps={{ sx: { mb: 2 } }}
                      options={makeUnique(
                        [...fieldAgentList, ...fieldAgents],
                        (x) => x?.uuid
                      )}
                      multiple={false}
                      isOptionEqualToValue={(option, value) =>
                        option?.uuid === value?.uuid
                      }
                      getOptionLabel={(option) => option?.name ?? ''}
                      handleSearch={filterFieldAgents}
                      renderOption={(props, fieldAgent) => (
                        <li {...props} key={fieldAgent?.uuid}>
                          {fieldAgent?.name ?? ''}
                        </li>
                      )}
                      value={fieldAgents[0] ?? ''}
                      onChange={(value) => {
                        setEmptySelectionError('');
                        setFieldAgents(Array.isArray(value) ? value : [value]);
                      }}
                      isLoading={fieldAgentListIsLoading}
                      required={true}
                      error={
                        fieldAgentListError
                          ? fieldAgentListError
                          : emptySelectionError
                      }
                      renderInput={(value) => (params) => {
                        // console.log('RXD:ExpenseForm:renderInput', { params });
                        return (
                          <TextField
                            {...params}
                            value={value}
                            label="Field Agent"
                          />
                        );
                      }}
                    />

                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                        id="comment"
                        label="Comment"
                        variant="outlined"
                        type="text"
                        value={comment}
                        onChange={w(setComment)}
                        multiline
                        rows={2}
                      />
                    </FormControl>
                    {status === 'loading' ? (
                      <Loading />
                    ) : (
                      <Button type="submit" variant="contained" sx={{ mb: 1 }}>
                        Save
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
