import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  transaction: null,
  isLoading: false,
  isSubmitting: false,
  error: '',
};

const floatCashThresholdDetailsSlice = createSlice({
  name: 'floatCashThreshold',
  initialState: initialState,
  reducers: {
    setResult: (state, { payload }) => {
      state.transaction = payload;
      state.isLoading = false;
    },
    setLoading: (state, { payload }) => {
      state.error = '';
      state.isLoading = payload;
    },
    setSubmitting: (state, { payload }) => {
      console.log('>>> Setting submitting: ', payload);
      state.error = '';
      state.isSubmitting = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
  },
});

export const thresholdFetch = (uuid) => (dispatch) => {
  console.log('>>> Getting threshold: ', uuid);
  dispatch(setLoading(true));
  getApi()
    .get(`/api/float-cash/thresholds/${uuid}`)
    .then((response) => dispatch(setResult(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const fieldAgentThresholdFetch = (uuid) => (dispatch) => {
  console.log('>>> Getting field agent threshold: ', uuid);
  dispatch(setLoading(true));
  getApi()
    .get(`/api/float-cash/field-agent-thresholds/${uuid}`)
    .then((response) => dispatch(setResult(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const editFieldAgentThreshold = (uuid, payload) => (dispatch) => {
  console.log('>>> Editing field agent threshold: ', uuid);
  dispatch(setSubmitting(true));
  getApi()
    .patch(`/api/float-cash/field-agent-thresholds/${uuid}`, payload)
    .then(() => {
      dispatch(setSubmitting(false));
      dispatch(fieldAgentThresholdFetch(uuid));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const editThreshold = (uuid, payload) => (dispatch) => {
  console.log('>>> Editing threshold: ', uuid);
  dispatch(setSubmitting(true));
  getApi()
    .patch(`/api/float-cash/thresholds/${uuid}`, payload)
    .then(() => {
      dispatch(setSubmitting(false));
      dispatch(thresholdFetch(uuid));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setResult, setLoading, setSubmitting, setError } =
  floatCashThresholdDetailsSlice.actions;

export const floatCashThresholdDetailsReducer =
  floatCashThresholdDetailsSlice.reducer;
