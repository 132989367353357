import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import {
  AppBar,
  dateFormat,
  Error,
  Info as InfoBase,
  Loading,
  numberFormat,
} from '@/common';
import {
  editTransaction,
  fetchBanks,
  fetchRepayments,
  setLoadingBanks,
  setSubmitting,
  uploadAttachmentsFile,
  varianceTransactionFetch,
} from '@/float-cash/varianceTransactionDetailsSlice';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  amountToNumberOrZero,
  getFloatCashAmount,
  getFloatCashBankName,
  getVarianceAmount,
  getVarianceAmountPaid,
  getVarianceCashPaidAccount,
  getVariancePaymentStatus,
  isNullOrEmpty,
  splitFloatCashAgentsName,
} from '@/float-cash/util';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { Grid, ListItem, Modal } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import closeIcon from '@/images/icons8_close.png';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { debounce } from '@/common';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import { captureEvent } from '@/firebase';
import { hasAnyRoles } from '@/auth/helpers';
import List from '@mui/material/List';

export const VarianceTransactionDetail = ({ embedded }) => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [editVarianceDialogOpen, setEditVarianceDialogOpen] = useState(false);
  const [fullScreenUrl, setFullScreenUrl] = useState('');
  const { user } = useSelector((state) => state.auth);
  const { transaction, isLoading, isSubmitting, error, repayments } =
    useSelector((state) => state.varianceTransaction);

  const handleClose = () => setFullScreenUrl('');

  useEffect(() => {
    if (!isSubmitting && editVarianceDialogOpen) {
      handleEditVarianceDialogClose();
    }
  }, [isSubmitting]);

  useEffect(() => {
    setEditVarianceDialogOpen(false);
    dispatch(varianceTransactionFetch(uuid));
  }, []);

  useEffect(() => {
    if (!editVarianceDialogOpen) {
      console.log('>>> Setting submitting. Open? ', editVarianceDialogOpen);
      dispatch(setSubmitting(false));
    }
  }, [editVarianceDialogOpen]);

  const handleEditVarianceDialogClose = () => setEditVarianceDialogOpen(false);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <EditVarianceModal
            isOpen={editVarianceDialogOpen}
            isSubmitting={isSubmitting}
            transaction={transaction}
            setFullScreenUrl={setFullScreenUrl}
            handleEditVarianceDialogClose={handleEditVarianceDialogClose}
          />
          <AppBar
            actions={
              hasAnyRoles(user.role, 'RECONCILIATION_ADMIN') ? (
                <>
                  <Button
                    disableElevation
                    sx={{ textTransform: 'none' }}
                    variant="contained"
                    border="2px solid"
                    size="medium"
                    component={RouterLink}
                    disabled={transaction?.paymentStatus === 'paid'}
                    onClick={() => setEditVarianceDialogOpen(true)}
                  >
                    Add Repayment
                  </Button>
                </>
              ) : null
            }
          >
            {'View variance'}: {uuid}
          </AppBar>
          {error && (
            <Error
              error={error}
              onRetry={() => dispatch(varianceTransactionFetch(uuid))}
            />
          )}
          {transaction ? (
            <>
              <Modal
                open={Boolean(fullScreenUrl)}
                onClose={handleClose}
                onClick={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  sx={{
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    component="img"
                    sx={{ maxWidth: '100%', maxHeight: '100%' }}
                    src={fullScreenUrl}
                    alt={transaction?.uuid}
                    style={{ cursor: 'pointer' }}
                  />
                </Grid>
              </Modal>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item md={6} direction="row">
                  <TransactionDetail transaction={transaction} />
                  <Box mt={3}></Box>
                  {repayments.length > 0 ? (
                    <RepaymentsList mt={3} repayments={repayments} />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item md={6} direction="column">
                  {transaction?.policeReport ? (
                    <AttachmentInfo
                      setFullScreenUrl={setFullScreenUrl}
                      attachments={[transaction?.policeReport]}
                      title="Police Report"
                      elevated={false}
                    />
                  ) : (
                    <></>
                  )}
                  {(transaction?.attachments || []).length > 0 ? (
                    <AttachmentInfo
                      setFullScreenUrl={setFullScreenUrl}
                      attachments={transaction?.attachments}
                      elevated={false}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

const RepaymentsList = (props) => {
  const { repayments } = props;
  return (
    <Card>
      <CardContent>
        <Typography fontWeight="bold" fontSize="large">
          Repayments
        </Typography>
        <List>
          {repayments.map((repayment) => {
            return (
              <ListItem sx={{ pl: 0, pr: 0 }}>
                <Grid container>
                  <Grid item md={4}>
                    <Stack direction="column">
                      <Typography>Date</Typography>
                      <Typography fontSize="large">
                        {dateFormat(
                          repayment?.createdAt,
                          'DD MMM yyyy, hh:mma'
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={4}>
                    <Stack direction="column">
                      <Typography>Amount</Typography>
                      <Typography fontSize="large">
                        {getVarianceAmount(repayment)}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={4}>
                    <Stack direction="column">
                      <Typography>Cash paid to account</Typography>
                      <Typography fontSize="large">
                        {getVarianceCashPaidAccount(repayment)}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider />
              </ListItem>
            );
          })}
          <ListItem></ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

const AttachmentInfo = (props) => {
  const { setFullScreenUrl, elevated, title, attachments } = props;
  return (
    <Card
      elevation={
        elevated === null || elevated === undefined || elevated === true ? 1 : 0
      }
    >
      <CardContent>
        <Typography>{title ? title : 'Attachment'}</Typography>
        <Grid container spacing={2}>
          {(attachments || []).map((value) => {
            return (
              <Grid item md={6}>
                <img
                  src={value}
                  height="100%"
                  width="100%"
                  style={{ objectFit: 'cover', cursor: 'pointer' }}
                  alt=""
                  onClick={(e) => setFullScreenUrl(e.target.src)}
                />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

const TransactionDetail = (props) => {
  const { transaction, elevated } = props;
  return (
    <Card
      elevation={
        elevated === null || elevated === undefined || elevated === true ? 1 : 0
      }
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!isNullOrEmpty(transaction?.varianceType) ? (
              <Info label="Credit/Debit">{transaction?.varianceType}</Info>
            ) : (
              <></>
            )}

            <Info label="Original variance amount:">
              {transaction.currency} {getFloatCashAmount(transaction)}
            </Info>

            <Info label="Amount repaid:">
              {getVarianceAmountPaid(transaction)}
            </Info>

            {!isNullOrEmpty(transaction?.fieldAgent?.name) ? (
              <Info label="Field Agent">{transaction?.fieldAgent?.name}</Info>
            ) : (
              <></>
            )}

            {splitFloatCashAgentsName(transaction).length > 0 ? (
              <Info label="Agent(s)">
                {splitFloatCashAgentsName(transaction)}
              </Info>
            ) : (
              <></>
            )}

            {!!transaction.phoneNumber ? (
              <Info label="Phone number:">{transaction.phoneNumber}</Info>
            ) : (
              <></>
            )}
            {!isNullOrEmpty(getFloatCashBankName(transaction)) ? (
              <Info label="Bank:">{getFloatCashBankName(transaction)}</Info>
            ) : (
              <></>
            )}

            {(transaction?.comments || []).length > 0 ? (
              <Info label="Comments:">{transaction?.comments?.join(',')}</Info>
            ) : (
              <></>
            )}
            {!isNullOrEmpty(transaction?.paymentStatus) ? (
              <Info label="Paid">{getVariancePaymentStatus(transaction)}</Info>
            ) : (
              <></>
            )}
            {!!transaction?.createdAt ? (
              <Info label="Date:">{dateFormat(transaction?.createdAt)}</Info>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Info = ({ label, children, border }) => (
  <InfoBase sx={{ py: 2 }} label={label} border={border ?? true}>
    {children}
  </InfoBase>
);

const EditVarianceModal = (props) => {
  const {
    isOpen,
    transaction,
    setFullScreenUrl,
    isSubmitting,
    handleEditVarianceDialogClose,
  } = props;
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [comment, setComment] = useState('');
  const [bankNameInputValue, setBankNameInputValue] = useState('');
  const [bank, setBank] = useState(null);
  const [canSave, setCanSave] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState(null);
  const { isLoadingBanks, isUploadingFiles, banks, attachmentUrls } =
    useSelector((state) => state.varianceTransaction);

  const handleBanksSearch = useCallback(
    debounce(
      (value) =>
        dispatch(
          fetchBanks(
            new URLSearchParams(
              `bankName=contains:${value}&oo=name&e=1&pp=1:50`
            )
          )
        ),
      1000
    ),
    []
  );

  useEffect(() => {
    dispatch(fetchBanks());
    dispatch(fetchRepayments(uuid));
    concatUrls();
  }, []);

  useEffect(() => {
    const outstanding =
      amountToNumberOrZero(transaction?.amount) -
      amountToNumberOrZero(transaction?.amountPaid);
    const _amount = amountToNumberOrZero(amount);
    if (_amount > outstanding)
      setAmountError(
        `Amount should be less than ${numberFormat(outstanding)} the outstanding left on this variance`
      );
    else setAmountError(null);
    console.log('>>> Outstanding: ', outstanding);
  }, [amount]);

  useEffect(() => {
    dispatch(setLoadingBanks(true));
    // noinspection JSCheckFunctionSignatures
    handleBanksSearch(bankNameInputValue);
  }, [handleBanksSearch, bankNameInputValue]);

  useEffect(() => {
    setCanSave(
      !!bank && comment.trim().length > 0 && amountToNumberOrZero(amount) > 0
    );
  }, [attachments, bank, comment, amount]);

  useEffect(() => {
    concatUrls();
  }, [attachmentUrls]);

  useEffect(() => {
    if (!selectedFile) return;
    const fileFormData = new FormData();
    fileFormData.append('file', selectedFile);
    dispatch(uploadAttachmentsFile(fileFormData));
  }, [selectedFile]);

  const concatUrls = () => {
    const urls = [].concat(
      ...(transaction?.attachments || []).concat(...(attachmentUrls || []))
    );
    console.log('>>> Concated urls: ', urls);
    setAttachments(urls);
  };

  const handleOnChange = (title) => (e) => {
    let value = e.target.value;
    if (title === 'comment') {
      setComment(value);
    } else if (title === 'amount') {
      setAmount(value);
    }
  };

  const handleBankSelect = (bank) => setBank(bank);

  const handleFileChange = (e) => {
    const file = e.target?.files[0];
    setSelectedFile(file);
  };
  const handleSubmit = () => {
    const formData = {};
    formData.attachments = attachmentUrls;
    formData.accountUuid = bank.uuid;
    formData.paidAt = new Date();
    formData.amount = amountToNumberOrZero(amount);
    if ((comment?.length || 0) > 0) formData.comment = comment;
    dispatch(editTransaction(uuid, formData));

    captureEvent('VARIANCE_TRANSACTION_FORM_UPDATE', {});
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Stack direction="row" flex justifyContent="end">
          <Box>
            <Button
              variant="text"
              onClick={() => handleEditVarianceDialogClose()}
            >
              <img src={closeIcon} alt="" width="30px" height="30px" />
            </Button>
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item md={6} direction={'column'}>
            <FormControl fullWidth sx={{ mt: 3 }}>
              <Autocomplete
                getOptionLabel={(bank) => bank?.bankName}
                filterOptions={(x) => x}
                options={banks}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={bank}
                isOptionEqualToValue={(option, value) =>
                  option.uuid === value.uuid
                }
                onChange={(event, newValue) => {
                  handleBankSelect(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setBankNameInputValue(newInputValue);
                }}
                renderOption={(props, bank) => {
                  return (
                    <li {...props} key={bank.uuid}>
                      {bank.accountHolderName} - {bank.bankName}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Repaid to cash account"
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {isLoadingBanks ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>

            <TextField
              sx={{ width: '100%', mt: 3 }}
              type="number"
              label="Amount of repayment"
              required
              onChange={handleOnChange('amount')}
            />
            <Typography>{amountError}</Typography>

            <Divider />

            <TransactionDetail
              elevated={false}
              transaction={{
                transactionType: transaction?.transactionType,
                fieldAgent: transaction?.fieldAgent,
                agents: transaction?.agents,
                currency: transaction?.currency,
                amount: transaction?.amount,
                amountPaid: transaction?.amountPaid,
              }}
            />

            <TextField
              autoFocus
              margin="dense"
              id="comments"
              label="Update comments"
              type="text"
              fullWidth
              variant="outlined"
              multiline
              minRows={2}
              required
              sx={{ mt: 1 }}
              value={comment}
              onChange={handleOnChange('comment')}
            />

            {!!transaction?.createdAt ? (
              <Info label="Date:">{dateFormat(transaction?.createdAt)}</Info>
            ) : (
              <></>
            )}
          </Grid>

          <Grid item md={6} direction={'column'}>
            {transaction?.policeReport ? (
              <AttachmentInfo
                setFullScreenUrl={setFullScreenUrl}
                attachments={[transaction?.policeReport]}
                title="Police Report"
                elevated={false}
              />
            ) : (
              <></>
            )}
            <input
              type="file"
              id="file_input"
              hidden
              onChange={handleFileChange}
            />
            <Button
              disableElevation
              disabled={isUploadingFiles}
              variant={'contained'}
              onClick={() => document.getElementById('file_input').click()}
            >
              Add attachment
            </Button>
            {(attachments || []).length > 0 ? (
              <AttachmentInfo
                elevated={false}
                setFullScreenUrl={setFullScreenUrl}
                attachments={attachments}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          disabled={!canSave || isSubmitting || amountError}
          onClick={handleSubmit}
        >
          <span>Mark variance repaid</span>{' '}
          {isSubmitting ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <></>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
