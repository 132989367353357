import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '@/theme';

export const DeleteModal = ({
  open,
  loading,
  handleDelete,
  primaryText,
  handleClose,
  title,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack direction="row" flex justifyContent="end">
          <IconButton
            onClick={handleClose}
            variant="outlined"
            sx={{
              borderRadius: '50%',
              border: '2px solid',
              borderColor: colors.light_green,
              width: '24px',
              height: '24px',
            }}
          >
            <CloseIcon sx={{ color: colors.light_green, fontSize: '17px' }} />
          </IconButton>
        </Stack>
        <Stack flex justifyContent="center" alignItems="center" p={12}>
          <Typography align="center">{primaryText}</Typography>
          <Button
            sx={{ mt: 8, width: '50%' }}
            variant="contained"
            color="error"
            disabled={loading}
            onClick={handleDelete}
          >
            <span>Delete</span>{' '}
            {loading ? <CircularProgress color="inherit" size={20} /> : <></>}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
