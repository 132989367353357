import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  commission: {},
  commissionList: [],
  isLoading: false,
  lastFilter: null,
  error: '',
  create: false,
};

export const commissionSlice = createSlice({
  name: 'commission',
  initialState,
  reducers: {
    setCommission: (state, { payload }) => {
      state.commission = payload;
      state.isLoading = false;
      state.create = false;
    },
    setGenerateCommission: (state, { payload }) => {
      state.commission = payload;
      state.isLoading = false;
      state.create = true;
    },
    setCommissions: (state, { payload }) => {
      const { commissions, lastFilter } = payload;
      state.commissionList = commissions;
      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.create = false;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
      state.create = false;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.create = false;
    },
  },
});

export const fetchCommissionById = (id) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/commissions/${id}`)
    .then((res) => dispatch(setCommission(res.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const createCommission = (commissionData) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .post('/api/commissions', commissionData)
    .then((res) => dispatch(setGenerateCommission(res.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const updateCommission = (id, commissionData) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .patch(`/api/commissions/${id}`, commissionData)
    .then((res) => dispatch(setGenerateCommission(res.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const deleteCommission = (id) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .delete(`/api/commissions/${id}`)
    .then(() => {
      dispatch(setCommission({}));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const commissionsListFetchApi = (filters) =>
  getApi().get('/api/commissions', { params: filters });

export const commissionListFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  commissionsListFetchApi(filters)
    .then((res) =>
      dispatch(
        setCommissions({
          lastFilter: filters?.toString(),
          commissions: res.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const {
  setCommission,
  setGenerateCommission,
  setCommissions,
  setError,
  setLoading,
} = commissionSlice.actions;

export const commissionReducer = commissionSlice.reducer;
