import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';

const initialState = {
  status: 'editing',
  error: '',
};

const fieldAgentAssignFormSlice = createSlice({
  name: 'fieldAgentAssignForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const fieldAgentAssignSave = (data) => (dispatch) => {
  dispatch(setLoading(true));

  getApi()
    .post(`/api/field-agents/territories`, data)
    .then((response) => {
      dispatch(resetState());
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { resetState, setLoading, setError } =
  fieldAgentAssignFormSlice.actions;

export const fieldAgentAssignFormReducer = fieldAgentAssignFormSlice.reducer;
