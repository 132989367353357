import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { makeDownload } from '@/common/api';

const initialState = {
  loanRepayments: null,
  bulkLoanRepayment: null,
  uploadedFile: false,
  uploadedFileUrl: '',
  isLoading: false,
  error: '',
  lastFilter: null,
};

const loanRepaymentsSlice = createSlice({
  name: 'loanRepayments',
  initialState: initialState,
  reducers: {
    setLoanRepayments: (state, { payload }) => {
      const { lastFilter, loanRepayments } = payload;

      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.loanRepayments = loanRepayments;
    },
    setBulkLoanRepayments: (state, { payload }) => {
      state.bulkLoanRepayment = payload;
      state.isLoading = false;
    },
    setUploadFile: (state, { payload }) => {
      console.log('>>> Uploaded file: ', payload);
      state.uploadedFile = payload?.url != null;
      state.uploadedFileUrl = payload?.url;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.loanRepayments = null;
      state.bulkLoanRepayment = null;
      state.uploadedFile = false;
      state.uploadedFileUrl = '';
      state.isLoading = false;
      state.error = '';
      // state.lastFilter = null;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.error = '';
      state.isLoading = false;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const loanRepaymentsFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get('/api/loans/payments', { params: filters })
    .then((response) =>
      dispatch(
        setLoanRepayments({
          lastFilter: filters?.toString(),
          loanRepayments: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const loanRepaymentsCreate = (body) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .post('/api/loans/payments', JSON.stringify(body))
    .then(() => dispatch(loanRepaymentsFetch()))
    .catch((err) => dispatch(setError(getApiError(err))))
    .finally(() => dispatch(setLoading(false)));
};

export const uploadLoanRepaymentFile = (file) => (dispatch) => {
  if (!file) {
    setUploadFile(false);
    return;
  }
  dispatch(setLoading(true));
  getApi()
    .post('/api/storage/upload', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      dispatch(setUploadFile(res.data));
    })
    .catch((err) => dispatch(setError(getApiError(err))))
    .finally(() => dispatch(setLoading(false)));
};

export const getImportsByFile = (payload) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .post('/api/loans/payments/import', payload)
    .then((res) => {
      const data = {
        duplicated: res.data?.duplicated,
        succeeded: res.data?.succeeded,
        total: res.data?.total,
      };
      dispatch(setBulkLoanRepayments(data));
    })
    .catch((err) => dispatch(setError(getApiError(err))))
    .finally(() => dispatch(setLoading(false)));
};

export const loanRepaymentsExport = (filter) => (dispatch) => {
  getApi()
    .get('/api/loans/payments/export', { params: filter, responseType: 'blob' })
    .then(makeDownload)
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const {
  resetState,
  setError,
  setFilter,
  setLoading,
  stopLoading,
  setUploadFile,
  setLoanRepayments,
  setBulkLoanRepayments,
} = loanRepaymentsSlice.actions;

export const loanRepaymentsReducer = loanRepaymentsSlice.reducer;
