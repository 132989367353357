import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCurrentFilters, refreshData } from '@/common/GridLoading';
import { AppBar, Error, useFilters } from '@/common';
import {
  markPushNotificationRead,
  pushNotificationsFetch,
} from '@/pushNotification/pushNotificationListSlice';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import { ListItem, ListItemButton } from '@mui/material';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';
import { Image } from '@mui/icons-material';
import emptyIcon from '../images/undraw_no_data_re_kwbl-2.png';

const getTimeAgo = (date) => {
  dayjs.extend(relativeTime);
  return dayjs(date).fromNow();
};

const handleNotificationClick = (notification, navigate, dispatch, filters) => {
  switch (notification.message?.notification?.tag) {
    case 'FLOAT_CASH_REQUEST_APPROVAL':
    case 'FLOAT_CASH_REQUEST':
      navigate(`/float-cash/requests`);
      break;
  }
  dispatch(markPushNotificationRead(notification.uuid, filters));
};

const NotificationRow = ({ notification, navigate, dispatch, filters }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    sx={{ display: 'flex', width: '100%' }}
    onClick={() =>
      handleNotificationClick(notification, navigate, dispatch, filters)
    }
  >
    <Stack direction="row">
      <Box
        sx={{ mr: '16px' }}
        style={{
          width: '16px',
          height: '16px',
          backgroundColor: notification.isRead ? 'gray' : '#21CE99',
          borderRadius: 50,
        }}
      ></Box>

      <Stack direction="column">
        <Typography fontWeight="bold" sx={{ mt: -0.4 }}>
          {notification.message?.notification?.title || '...'}
        </Typography>

        <Typography sx={{ mt: -0.4 }}>
          {notification.message?.notification?.content || '...'}
        </Typography>
      </Stack>
    </Stack>

    <Box>
      <Typography>{getTimeAgo(notification.createdAt)}</Typography>
    </Box>
  </Stack>
);

const EmptyView = () => (
  <Card>
    <Stack
      direction="column"
      gap={2}
      mt={3}
      p={3}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <img
        src={emptyIcon}
        alt="Empty Icon"
        style={{ width: 200, height: 200 }}
      />
      <Typography fontWeight="bold" sx={{ mt: -0.4 }}>
        {'All caught up!'}
      </Typography>
    </Stack>
  </Card>
);

export const configureFilters = (currentFilters) => {
  if (!currentFilters.has('oo')) {
    currentFilters.set('oo', '-createdAt');
  }
  if (!currentFilters.has('isRead')) {
    currentFilters.set('isRead', 'false');
  }
  return currentFilters;
};

export const PushNotificationList = ({ embedded }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useFilters();
  const navigate = useNavigate();
  const { pushNotificationList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.pushNotificationList;
    }
  );

  useEffect(() => {
    const currentFilters = configureFilters(
      getCurrentFilters(filters, embedded, 'userUuid')
    );

    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(pushNotificationsFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, pushNotificationList]);

  return (
    <>
      {!embedded && <AppBar>Notifications</AppBar>}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(pushNotificationsFetch(filters))}
        />
      )}

      {isLoading ? (
        <Box></Box>
      ) : pushNotificationList.length === 0 ? (
        EmptyView()
      ) : (
        <Card variant="outlined" sx={{ borderRadius: 3 }}>
          <List sx={{ pl: 0, ml: 0 }}>
            {pushNotificationList.map((value, index) => {
              return (
                <ListItem key={index} divider={true} sx={{ p: 0 }}>
                  {' '}
                  <ListItemButton sx={{ p: 2, borderRadius: 1 }}>
                    {' '}
                    <NotificationRow
                      navigate={navigate}
                      dispatch={dispatch}
                      filters={configureFilters(
                        getCurrentFilters(filters, embedded, 'userUuid')
                      )}
                      notification={value}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Card>
      )}
    </>
  );
};
