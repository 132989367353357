import { AppBar } from '@/common';
import { EmbeddedChart } from '@/common/EmbeddedChart';
import { DASHBOARD_RECONCILIATIONS } from '@/dashboardConfiguration/utils';

export const Reconciliations = () => {
  return (
    <>
      <AppBar>Reconciliations</AppBar>

      <EmbeddedChart theKey={DASHBOARD_RECONCILIATIONS} />
    </>
  );
};
