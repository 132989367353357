import { AppBar, Error, Loading } from '@/common';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loanDurationSave, resetState } from './loanDurationFormSlice';
import { loanDurationFetch } from './loanDurationSlice';
import { captureEvent } from '@/firebase';

export const LoanDurationForm = () => {
  // console.log('RXD:LoanDurationForm');
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loanDuration, status, error } = useSelector(
    (state) => state.loanDuration
  );

  const { error: formError, status: formStatus } = useSelector(
    (state) => state.loanDurationForm
  );

  const [duration, setDuration] = useState('');
  const [durationUnit, setDurationUnit] = useState('');

  useEffect(() => {
    if (id && id !== `${loanDuration?.id}` && status !== 'loading' && !error) {
      dispatch(resetState());
      setDuration('');
      setDurationUnit('');
      dispatch(loanDurationFetch(id));
    }
  }, [dispatch, loanDuration, id, status, error]);

  // initial values
  useEffect(() => {
    if (id === `${loanDuration?.id}`) {
      setDuration(loanDuration?.duration ?? '');
      setDurationUnit(loanDuration?.durationUnit ?? '');
    }
  }, [loanDuration, id]);

  const handleSave = (e) => {
    e.preventDefault();
    dispatch(
      loanDurationSave(id, {
        id,
        duration,
        durationUnit,
      })
    );
    captureEvent(
      id ? 'EDIT_LOAN_DURATION_FORM_SAVED' : 'CREATE_LOAN_DURATION_FORM_SAVED'
    );
  };

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <>
      <AppBar>
        {id
          ? `Edit Loan Duration: ${
              loanDuration?.duration
                ? `${loanDuration?.duration} ${loanDuration?.durationUnit}`
                : `...${id}`
            }`
          : 'Add Loan Duration'}
      </AppBar>

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {error ? (
                <Error
                  error={error}
                  onRetry={() => dispatch(loanDurationFetch(id))}
                />
              ) : status === 'loading' ? (
                <Loading />
              ) : (
                <Box
                  component="form"
                  method="POST"
                  sx={{ p: 1 }}
                  onSubmit={handleSave}
                  autoComplete="off"
                >
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      id="duration"
                      label="Duration"
                      variant="outlined"
                      required
                      value={duration}
                      onChange={w(setDuration)}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="durationUnit">Unit</InputLabel>
                    <Select
                      id="durationUnit"
                      label="Interest Type"
                      variant="outlined"
                      value={durationUnit}
                      onChange={w(setDurationUnit)}
                      required
                    >
                      <MenuItem value="day">day</MenuItem>
                      <MenuItem value="week">week</MenuItem>
                      <MenuItem value="month">month</MenuItem>
                      <MenuItem value="year">year</MenuItem>
                    </Select>
                  </FormControl>

                  {formStatus === 'loading' ? (
                    <Loading />
                  ) : (
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{ mb: 2, textTransform: 'none' }}
                      type="submit"
                    >
                      Save
                    </Button>
                  )}
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
