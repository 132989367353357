import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  status: 'editing',
  error: '',
};

const pinFormSlice = createSlice({
  name: 'pinForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const pinSave = (uuid, data) => (dispatch) => {
  dispatch(setLoading(true));

  getApi()
    .post(`/api/agents/${uuid}/update-pin`, data)
    .then((response) => {
      if (response.data?.status) {
        dispatch(resetState());
      } else {
        dispatch(setError(getApiError({ response })));
      }
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { resetState, setLoading, setError } = pinFormSlice.actions;

export const pinFormReducer = pinFormSlice.reducer;
