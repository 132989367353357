import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import {
  floatRequestListFetch,
  setError as setListError,
} from './floatRequestListSlice';

const initialState = {
  floatRequest: null,
  isLoading: false,
  error: '',
};

const floatRequestSlice = createSlice({
  name: 'floatRequest',
  initialState: initialState,
  reducers: {
    setFloatRequest: (state, { payload }) => {
      state.floatRequest = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.floatRequest = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const floatRequestFetch = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/float-cash/request/${uuid}`)
    .then((response) => dispatch(setFloatRequest(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const floatRequestDelete = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .delete(`/api/float-cash/request/${uuid}`)
    .then((response) =>
      dispatch(floatRequestListFetch(new URLSearchParams('pp=1:20&oo=uuid')))
    )
    .catch((err) => {
      dispatch(setError(getApiError(err)));
      dispatch(setListError(err.message));
    });
};

export const { setFloatRequest, resetState, setLoading, setError } =
  floatRequestSlice.actions;

export const floatRequestReducer = floatRequestSlice.reducer;
