import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';
import { updateParsers } from './parserListSlice';

const initialState = {
  status: 'editing',
  error: '',
};

const parserFormSlice = createSlice({
  name: 'parserForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const parserSave = (id, data) => (dispatch) => {
  dispatch(setLoading());

  const action = !!id
    ? getApi().patch(`/api/ussd/parsers/${id}`, data)
    : getApi().post(`/api/ussd/parsers`, data);

  action
    .then((response) => {
      dispatch(resetState());
      dispatch(push(`/ussd/parsers`));
      dispatch(updateParsers({ rows: [response.data], key: 'id' }));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { resetState, setLoading, setError } = parserFormSlice.actions;

export const parserFormReducer = parserFormSlice.reducer;
