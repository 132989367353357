import { AppBar, Error, Loading } from '@/common';
import { getContractVariables } from '@/customer/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { customerContractSave } from './customerContractFormSlice';
import { customerContractFetch, resetState } from './customerContractSlice';
import { captureEvent } from '@/firebase';

export const CustomerContractForm = () => {
  // console.log('RXD:CustomerContractForm');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const ckEditor = useRef(null);

  const { customerContract, isLoading, error } = useSelector(
    (state) => state.customerContract
  );
  const { error: formError, status } = useSelector(
    (state) => state.customerContractForm
  );
  const [canSubmit] = useState(true);
  const [, setNewUuid] = useState('');
  const [template, setTemplate] = useState('');

  useEffect(() => {
    if (!customerContract && !isLoading && !error) {
      dispatch(resetState());
      setNewUuid(uuid);
      setTemplate('');
      dispatch(customerContractFetch(uuid));
    }
  }, [dispatch, customerContract, uuid, isLoading, error]);

  // initial values
  useEffect(() => {
    if (!!customerContract) {
      console.log('>>> Setting customer contract: ', customerContract);
      setTemplate(customerContract?.data ?? '');
      ckEditor.current?.setData(customerContract?.data ?? '');
    }
  }, [customerContract, uuid]);

  const handleSave = (e) => {
    e.preventDefault();
    dispatch(
      customerContractSave(uuid, {
        data: template,
      })
    );
    captureEvent(
      uuid ? 'EDIT_CUSTOMER_CONTRACT_FORM' : 'ADD_CUSTOMER_CONTRACT_FORM',
      { ...template, uuid }
    );
  };

  // console.log('RXD:CustomerContractForm', 'uuid', uuid, 'status', status);

  const HtmlEditor = lazy(() => import('@/common/HtmlEditor'));

  return (
    <>
      {uuid ? (
        <Box></Box>
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ pt: 1, pb: 2 }}
        >
          <Box sx={{ marginRight: 'auto' }}></Box>
        </Box>
      )}

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <Box
                    component="form"
                    method="post"
                    sx={{ p: 1 }}
                    onSubmit={handleSave}
                    autoComplete="off"
                  >
                    <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                      <Typography variant="body1">Customer Contract</Typography>
                      <Box
                        sx={{
                          '& .DraftEditor-root': {
                            border: '1px solid gray',
                            minHeight: 'calc(100vh - 320px)',
                          },
                        }}
                      >
                        <Suspense fallback={<Loading />}>
                          <HtmlEditor
                            data={template}
                            onReady={(editor) => {
                              if (uuid) {
                                editor.enableReadOnlyMode('1');
                              }
                              ckEditor.current = editor;
                              editor.editing.view.change((writer) => {
                                writer.setStyle(
                                  'min-height',
                                  '300px',
                                  editor.editing.view.document.getRoot()
                                );
                              });
                            }}
                            onChange={(event, editor) => {
                              setTemplate(editor.getData());
                            }}
                          />
                        </Suspense>
                      </Box>
                    </FormControl>

                    <Accordion sx={{ mb: 2 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Contract Variables</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Variable</TableCell>
                                <TableCell>Meaning</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {getContractVariables().map(
                                ([variable, meaning]) => (
                                  <TableRow key={variable}>
                                    <TableCell>
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={(e) =>
                                          navigator.clipboard
                                            .writeText(variable)
                                            .catch(console.error)
                                        }
                                      >
                                        COPY
                                      </Button>
                                    </TableCell>
                                    <TableCell>{variable}</TableCell>
                                    <TableCell>{meaning}</TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>

                    <Divider sx={{ my: 2 }} />

                    {status === 'loading' ? (
                      <Loading />
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ mb: 1 }}
                        type="submit"
                        disabled={!canSubmit}
                      >
                        Save
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
