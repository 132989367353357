import { AppBar, BooleanBubble, Bubble, Error, Info, Loading } from '@/common';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { debounce } from '@/common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { agentListFetch } from './agentListSlice';
import { agentMergeSave } from './agentMergeFormSlice';
import {
  createdAt,
  formattedCount,
  formattedVolume,
  getBirthday,
  getFieldAgent,
  getKycBackgroundColor,
  getKycProgress,
  getOnboardedFieldAgent,
} from './utils';
import { logButtonEvents } from '@/firebase';

export const AgentCompare = () => {
  // console.log('RXD:AgentCompare');
  const dispatch = useDispatch();
  const [agents, setAgents] = useState([{ i: 0, agent: null, isBase: false }]);
  const { status, error: formError } = useSelector(
    (state) => state.agentMergeForm
  );

  const handleAddAgent = (agent, selected) => {
    // console.log('RXD: handleAddAgent', { agent, selected, agents });
    const max = Math.max(...agents.map((a) => a.i));
    const newAgents = agents.map((a) =>
      a.i === agent.i ? { ...agent, agent: selected } : a
    );
    if (max === agent.i) {
      newAgents.push({ i: max + 1, agent: null, isBase: false });
    }

    setAgents(newAgents);
  };

  const handleRemoveAgent = (agent) => {
    setAgents(agents.filter((a) => a.i !== agent.i));
  };

  const handleSetBaseAgent = (agent) => {
    setAgents(agents.map((a) => ({ ...a, isBase: a.i === agent.i })));
  };

  const handleMerge = () => {
    let uuid = null,
      uuids = [];
    for (let agent of agents) {
      if (!agent.agent) {
        continue;
      }
      if (agent.isBase) {
        uuid = agent.agent.uuid;
      } else {
        uuids.push(agent.agent.uuid);
      }
    }
    dispatch(agentMergeSave({ uuid, uuids }));
    logButtonEvents('AGENT_MERGE_PROFILE', {});
  };

  const canRemove = agents.length > 1;

  const canMerge =
    agents.length > 1 &&
    agents.map((a) => a.isBase).reduce((a, b) => a || b, false);

  return (
    <>
      <AppBar
        actions={
          status === 'loading' ? (
            <Loading />
          ) : (
            <Button
              variant="contained"
              disabled={!canMerge}
              onClick={handleMerge}
            >
              Merge Profiles
            </Button>
          )
        }
      >
        Compare Agents
      </AppBar>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {formError && <Error error={formError} />}
          <Grid container spacing={2}>
            {agents.map((agent) => (
              <Grid item xs={3} key={agent.i}>
                <AgentProfile
                  agent={agent}
                  canRemove={canRemove}
                  handleAddAgent={handleAddAgent}
                  handleRemoveAgent={handleRemoveAgent}
                  handleSetBaseAgent={handleSetBaseAgent}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const AgentProfile = ({
  agent,
  canRemove,
  handleAddAgent,
  handleRemoveAgent,
  handleSetBaseAgent,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const { error: agentsError, agentList: agents } = useSelector(
    (state) => state.agentList
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      const search = new URLSearchParams(`pp:1:20&e=1&oo=agentName`);

      if (/\w{8,8}-\w{4,4}-\w{4,4}-\w{4,4}-\w{12,12}/.test(value)) {
        search.set('uuid', value);
      } else {
        search.set('agentName', `contains:${value}`);
      }
      dispatch(agentListFetch(search));
    }, 1000),
    []
  );

  useEffect(() => {
    handleSearch(inputValue);
  }, [handleSearch, inputValue]);

  return (
    <Card>
      <Stack direction="row" justifyContent="end">
        {
          <IconButton
            title="Delete comparison"
            color="error"
            size="large"
            onClick={() => handleRemoveAgent(agent)}
            disabled={!canRemove}
          >
            <CancelOutlinedIcon />
          </IconButton>
        }
      </Stack>
      <CardContent>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Autocomplete
            getOptionLabel={(agent) =>
              (typeof agent == 'string' ? agent : agent?.agentName) ??
              agent.uuid
            }
            filterOptions={(x) => x}
            options={agents ?? []}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={agent.agent}
            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
            onChange={(event, newValue) => {
              handleAddAgent(agent, newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderOption={(props, agent) => {
              return (
                <li {...props} key={agent.uuid}>
                  {agent.agentName}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Agent"
                error={!!agentsError}
                helperText={agentsError}
                required
              />
            )}
          />
        </FormControl>
        {agent.agent && (
          <>
            <FormControlLabel
              control={<Switch checked={agent.isBase} />}
              label="Set Base Profile"
              onChange={(e) => handleSetBaseAgent(agent)}
            />
            <Box display="flex" justifyContent="center" sx={{ p: 3 }}>
              <Avatar
                alt="Profile Photo"
                sx={{ width: 160, height: 160 }}
                src={agent.agent.profilePhotoUrl ?? ''}
              />
            </Box>
            <Info label="Is Verified" border={true} sx={{ mb: 1 }}>
              <BooleanBubble value={agent.agent.isVerified} />
            </Info>
            <Info label="KYC" border={true} sx={{ mb: 1 }}>
              <Bubble
                label={getKycProgress(agent.agent)}
                background={getKycBackgroundColor(agent.agent)}
                color={'black'}
                borderRadius="0"
                fontWeight="normal"
              />
            </Info>
            <Info label="UUID" border={true} sx={{ mb: 1 }}>
              {agent.agent.uuid ?? '-'}
            </Info>
            <Info label="Name" border={true} sx={{ mb: 1 }}>
              {agent.agent.agentName ?? '-'}
            </Info>
            <Info label="Gender" border={true} sx={{ mb: 1 }}>
              {agent.agent.gender ?? '-'}
            </Info>
            <Info label="Birthday" border={true} sx={{ mb: 1 }}>
              {getBirthday(agent)}
            </Info>
            <Info label="National ID" border={true} sx={{ mb: 1 }}>
              {agent.agent.nationalId ?? '-'}
            </Info>
            <Info label="Card Number" border={true} sx={{ mb: 1 }}>
              {agent.agent.cardNumber ?? '-'}
            </Info>
            <Info label="Nationality" border={true} sx={{ mb: 1 }}>
              {agent.agent.nationality ?? '-'}
            </Info>
            <Info
              label={`Referred By: (${agent.agent.referredBy})`}
              border={true}
              sx={{ mb: 1 }}
            >
              {agent.agent.referredDetail ?? '-'}
            </Info>
            <Info label="Created At" border={true} sx={{ mb: 1 }}>
              {createdAt(agent.agent)}
            </Info>
            <Info label="App Version" border={true} sx={{ mb: 1 }}>
              {agent.agent.appVersion ?? '-'}
            </Info>
            <Info label="Transactions" border={true} sx={{ mb: 1 }}>
              {formattedCount(agent.agent)}
            </Info>
            <Info label="Transaction Volume" border={true} sx={{ mb: 1 }}>
              {formattedVolume(agent.agent)}
            </Info>
            <Info label="Onboarded Field Agent" border={true} sx={{ mb: 1 }}>
              {agent.agent.fieldAgent?.uuid ? (
                <Link
                  to={`/field-agents/${agent.agent.fieldAgent?.uuid}`}
                  component={RouterLink}
                >
                  {getOnboardedFieldAgent(agent.agent)}
                </Link>
              ) : (
                '-'
              )}
            </Info>
            <Info label="Field Agent" border={true} sx={{ mb: 1 }}>
              {agent.agent.fieldAgent?.uuid ? (
                <Link
                  to={`/field-agents/${agent.agent.fieldAgent?.uuid}`}
                  component={RouterLink}
                >
                  {getFieldAgent(agent.agent)}
                </Link>
              ) : (
                '-'
              )}
            </Info>
            <Info label="Address" border={true} sx={{ mb: 1 }}>
              {agent.address ?? '-'}
            </Info>
            <Info label="Location" border={true} sx={{ mb: 1 }}>
              {agent.agent.location ?? '-'}
            </Info>
            <Box
              component="img"
              src={agent.agent.nationalIdFrontPhotoUrl}
              alt="Front National ID"
              sx={{ mb: 1, width: '100%' }}
            />
            <Divider />
            <Box
              component="img"
              src={agent.agent.nationalIdBackPhotoUrl}
              alt="Back National ID"
              sx={{ mb: 1, width: '100%' }}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};
