import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const Error = ({
  sx,
  error = 'There was an error processing your request',
  onRetry = null,
  onClose,
}) => (
  <Alert
    severity="error"
    sx={sx}
    action={
      onClose && (
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )
    }
  >
    <AlertTitle>Error</AlertTitle>
    {error}
    {onRetry && (
      <Button appearance="primary" onClick={onRetry}>
        Retry
      </Button>
    )}
  </Alert>
);
