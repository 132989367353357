import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';
import { fetchMyFieldAgent } from '@/float-cash/util';

const initialState = {
  status: 'editing',
  error: '',
  meFieldAgent: null,
};

const cashTransactionFormSlice = createSlice({
  name: 'cashTransactionForm',
  initialState: initialState,
  reducers: {
    setMeFieldAgent: (state, { payload }) => {
      const { fieldAgent } = payload;
      state.isLoading = false;
      state.meFieldAgent = fieldAgent;
    },
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const cashTransactionSave = (data, successUrl) => (dispatch) => {
  dispatch(setLoading(true));

  getApi()
    .post(`/api/float-cash/transaction`, data)
    .then((response) => {
      dispatch(resetState());
      dispatch(push(successUrl));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const fetchMyFieldAgentProfile = () => (dispatch) => {
  fetchMyFieldAgent()
    .then((fieldAgent) => {
      dispatch(setMeFieldAgent({ fieldAgent }));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { resetState, setLoading, setError, setMeFieldAgent } =
  cashTransactionFormSlice.actions;

export const cashTransactionFormReducer = cashTransactionFormSlice.reducer;
