import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  loanContract: null,
  isLoading: false,
  error: '',
};

const loanContractSlice = createSlice({
  name: 'loanContract',
  initialState: initialState,
  reducers: {
    setLoanContract: (state, { payload }) => {
      state.loanContract = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.loanContract = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const loanContractFetch = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/loans/contracts/${uuid}`)
    .then((response) => dispatch(setLoanContract(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setLoanContract, resetState, setLoading, setError } =
  loanContractSlice.actions;

export const loanContractReducer = loanContractSlice.reducer;
