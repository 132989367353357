import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';
import { setFieldAgent } from './fieldAgentSlice';
import {
  setUploadFile,
  setUploadingFiles,
  stopLoading,
} from '@/float-cash/cashDepositFormSlice';

const initialState = {
  status: 'editing',
  error: '',
  uploadedFileUrl: null,
};

const fieldAgentFormSlice = createSlice({
  name: 'fieldAgentForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setUploadedFile: (state, { payload }) => {
      state.uploadedFileUrl = payload.url;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const fieldAgentSave = (uuid, data) => (dispatch) => {
  dispatch(setLoading(true));
  data = Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      value === '' ? null : value,
    ])
  );
  if (uuid) {
    data.uuid = uuid;
  }
  data.manager = {
    uuid: data?.manager?.uuid === '' ? null : data?.manager?.uuid,
  };
  data.position = { id: data?.position?.id === '' ? null : data?.position?.id };

  const action = uuid
    ? getApi().patch(`/api/field-agents/${uuid}`, data)
    : getApi().post(`/api/field-agents`, data);

  action
    .then((response) => {
      dispatch(resetState());
      dispatch(setFieldAgent(response.data));
      dispatch(push(`/field-agents/${uuid ?? response.data.uuid}`));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const uploadAttachmentFile = (file) => (dispatch) => {
  dispatch(setUploadingFiles(true));
  return getApi().post('/api/storage/upload', file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const { resetState, setLoading, setError, setUploadedFile } =
  fieldAgentFormSlice.actions;

export const fieldAgentFormReducer = fieldAgentFormSlice.reducer;
