import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { stringToBool } from '@/float-cash/util';

const initialState = {
  isLoading: false,
  isSaving: false,
  settingItems: [],
  error: '',
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.error = '';
      state.isLoading = payload;
    },
    setSaving: (state, { payload }) => {
      state.error = '';
      state.isSaving = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.isSaving = false;
    },
    setData: (state, { payload }) => {
      console.log('>>> Setting settings: ', payload);
      state.settingItems = (payload.data || []).map((item) => {
        item.value = stringToBool(item.value);
        return item;
      });
      state.isLoading = false;
    },
  },
});

export const settingsFetch = (module) => (dispatch) => {
  console.log('>>> Fetching settings: ', module);
  dispatch(setLoading(true));
  getApi()
    .get('/api/settings', { params: { module: module, oo: 'id' } })
    .then((response) => {
      dispatch(setData(response.data));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const saveSettings = (data, module) => (dispatch) => {
  console.log('>>> Saving settings: ', data);
  dispatch(setSaving(true));
  getApi()
    .post('/api/settings/bulk', data)
    .then((response) => {
      dispatch(setSaving(false));
      dispatch(settingsFetch(module));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const settingFetch = (slug) => {
  return getApi()
    .get(`/api/settings/slug/${slug}`)
    .then((response) => response.data)
    .catch((err) => getApiError(err));
};

export const settingValueFetch = (slug) => {
  return settingFetch(slug).then((data) => {
    return stringToBool(data['value']);
  });
};

export const { setLoading, setSaving, setError, setData } =
  settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
