/**
 * Convert filters to URL query string
 * @param {URLSearchParams} filters
 * @returns {string}
 */
export const queryFilters = (filters) => {
  return filters instanceof URLSearchParams ? `?${filters.toString()}` : '';
};

/**
 * Get pagination values from filters
 * @param {URLSearchParams} filters
 * @param {number} defaultPage
 * @param {number} defaultPerPage
 * @returns [page, perPage]
 */
export const paginationValue = (filters, defaultPage, defaultPerPage) => {
  const value = filters.get('pp');
  const pattern = /^(?<page>\d+)(|:(?<perPage>\d+))$/;

  if (value && pattern.test(value)) {
    const { page, perPage } = pattern.exec(value).groups;
    return [+page, perPage ? +perPage : perPage];
  }

  return [defaultPage ?? 1, defaultPerPage ?? 20];
};
