import { AppBar } from '@/common';
import { EmbeddedChart } from '@/common/EmbeddedChart';
import { DASHBOARD_MAP } from '@/dashboardConfiguration/utils';

export const Map = () => {
  // console.log('RXD:Map');
  return (
    <>
      <AppBar>Map</AppBar>
      <EmbeddedChart theKey={DASHBOARD_MAP} height="2000px" />
    </>
  );
};
