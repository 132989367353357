import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  loanRequest: null,
  isLoading: false,
  error: '',
};

const loanRequestSlice = createSlice({
  name: 'loanRequest',
  initialState: initialState,
  reducers: {
    setLoanRequest: (state, { payload }) => {
      state.loanRequest = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.loanRequest = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const loanRequestFetch = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/loans/requests/${uuid}`)
    .then((response) => dispatch(setLoanRequest(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setLoanRequest, resetState, setLoading, setError } =
  loanRequestSlice.actions;

export const loanRequestReducer = loanRequestSlice.reducer;
