import { AppBar } from '@/common';
// import { useDispatch } from 'react-redux';

export const AgentKnowledgeCenter = ({ embedded }) => {
  // console.log('RXD:AgentKnowledgeCenter');
  // const dispatch = useDispatch();

  return (
    <>
      {!embedded && <AppBar>Agent Knowledge Center</AppBar>}

      <div
        style={{
          display: 'flex',
          height: 'calc(100vh - 190px)',
          minHeight: 300,
        }}
      ></div>
    </>
  );
};
