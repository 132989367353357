import { AppBar, Error, Loading, makeUnique, useInitial } from '@/common';
import { DynamicAutoComplete } from '@/common/DynamicAutoComplete';
import { fieldAgentListFetch } from '@/fieldAgent/fieldAgentListSlice';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cashTransactionSave } from './cashTransactionFormSlice';
import { floatManagerAccountListFetch } from './floatManagerAccountListSlice';
import { FormHelperText } from '@mui/material';

const calcLabel = (fieldAgent) =>
  fieldAgent?.uuid ? `${fieldAgent?.phoneNumber}: ${fieldAgent?.name}` : '';

export const AdvanceCashForm = () => {
  // console.log('RXD:AdvanceCashForm');
  const dispatch = useDispatch();

  // console.log('RXD:AdvanceCashForm', { fieldAgentUuid, agentUuid, uuid });

  const {
    floatManagerAccountList,
    isLoading: floatManagerAccountListIsLoading,
    error: floatManagerAccountListError,
  } = useSelector((state) => state.floatManagerAccountList);

  const {
    fieldAgentList,
    isLoading: fieldAgentListIsLoading,
    error: fieldAgentListError,
  } = useSelector((state) => state.fieldAgentList);

  const { error: formError, status } = useSelector(
    (state) => state.cashTransactionForm
  );

  const currencyCode = import.meta.env?.VITE_CURRENCY_CODE || 'UGX';

  const [currency, setCurrency] = useState('');
  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');
  const [cashAccount, setCashAccount] = useState(null);
  const [fieldAgent, setFieldAgent] = useState(null);

  const filterFieldAgents = useCallback(
    (value) => {
      // console.log('RXD:filterFieldAgents', { value, fieldAgent });
      const query = new URLSearchParams(
        'pp=1:6&oo=name&ff=fa.uuid,fa.name,fa.phoneNumber'
      );

      if (value) {
        const isPhoneNumber = /^\d+$/.test(value);
        if (isPhoneNumber) {
          query.delete('name');
          query.set('phoneNumber', `contains:${value}`);
        } else if (value !== calcLabel(fieldAgent)) {
          query.set('name', `contains:${value}`);
        }
      }
      dispatch(fieldAgentListFetch(query));
    },
    [dispatch]
  );

  const filterCashAccounts = useCallback(
    (value) => {
      // console.log('RXD:filterCashAccounts', { value, cashAccount });
      const query = new URLSearchParams('pp=1:6&oo=bankName');

      if (value && value !== cashAccount?.bankName) {
        query.set('bankName', `contains:${value}`);
      }
      dispatch(floatManagerAccountListFetch(query));
    },
    [dispatch]
  );

  // initial data
  useInitial(() => {
    // console.log('RXD:AdvanceCashForm: initial');
    setCurrency(currencyCode);
    setAmount('');
    setComment('');
    setFieldAgent(null);
    setCashAccount(null);
    filterFieldAgents();
    filterCashAccounts();
  });

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      currency,
      amount: +amount,
      comment: [comment],
      transactionType: 'WITHDRAWAL',
      fromAccountId: cashAccount.uuid,
      toAccountId: fieldAgent.uuid,
      fromAccountSignatureUrl: '',
      toAccountSignatureUrl: '',
      extraInfo: {
        submittedBy: fieldAgent,
      },
      location: { type: 'Point', coordinates: [0, 0] },
      locationAccuracy: null,
    };

    dispatch(cashTransactionSave(data, '/float-cash/cash'));
  };

  const w = (callback) => (e) => callback(e.target.value);

  // console.log({floatManagerAccountList,fieldAgentList,})

  return (
    <>
      <AppBar>Advance / Issue Cash to Field Agent</AppBar>

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {
                <Box
                  component="form"
                  method="POST"
                  sx={{ p: 1 }}
                  onSubmit={handleSave}
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ my: 2 }}>
                        <TextField
                          id="amount"
                          label="Amount"
                          variant="outlined"
                          type="number"
                          required
                          value={amount}
                          onChange={w(setAmount)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {currency}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>

                      <DynamicAutoComplete
                        controlProps={{ sx: { my: 1 } }}
                        options={makeUnique(
                          [
                            ...floatManagerAccountList,
                            ...(cashAccount ? [cashAccount] : []),
                          ],
                          (x) => x?.uuid
                        )}
                        multiple={false}
                        isOptionEqualToValue={(option, value) =>
                          option?.uuid === value?.uuid
                        }
                        getOptionLabel={(option) => option?.bankName ?? ''}
                        handleSearch={filterCashAccounts}
                        renderOption={(props, cashAccount) => (
                          <li {...props} key={cashAccount?.uuid}>
                            {cashAccount?.bankName ?? ''}
                          </li>
                        )}
                        value={cashAccount}
                        onChange={(value) => {
                          setCashAccount(value);
                        }}
                        isLoading={floatManagerAccountListIsLoading}
                        error={floatManagerAccountListError}
                        required
                        renderInput={(value) => (params) => {
                          // console.log('RXD:AdvanceCashForm:renderInput', { params });
                          return (
                            <TextField
                              {...params}
                              value={value}
                              label="Cash Account"
                            />
                          );
                        }}
                        helperText="This is a account for the source of the cash used for reconciliation and tracking"
                      />

                      <DynamicAutoComplete
                        controlProps={{ sx: { mt: 1 } }}
                        options={makeUnique(
                          [
                            ...fieldAgentList,
                            ...(fieldAgent ? [fieldAgent] : []),
                          ],
                          (x) => x?.uuid
                        )}
                        multiple={false}
                        isOptionEqualToValue={(option, value) =>
                          option?.uuid === value?.uuid
                        }
                        getOptionLabel={calcLabel}
                        handleSearch={filterFieldAgents}
                        renderOption={(props, fieldAgent) => (
                          <li {...props} key={fieldAgent?.uuid}>
                            {calcLabel(fieldAgent)}
                          </li>
                        )}
                        value={fieldAgent}
                        onChange={(value) => {
                          setFieldAgent(value);
                        }}
                        isLoading={fieldAgentListIsLoading}
                        error={fieldAgentListError}
                        required
                        renderInput={(value) => (params) => {
                          // console.log('RXD:AdvanceCashForm:renderInput', { params });
                          return (
                            <TextField
                              {...params}
                              value={value}
                              label="Field Agent"
                            />
                          );
                        }}
                      />

                      <FormControl fullWidth sx={{ my: 2 }}>
                        <TextField
                          id="comment"
                          label="Comment"
                          variant="outlined"
                          type="text"
                          required
                          value={comment}
                          rows={3}
                          multiline
                          onChange={w(setComment)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {status === 'loading' ? (
                    <Loading />
                  ) : (
                    <Button variant="contained" sx={{ my: 2 }} type="submit">
                      Save
                    </Button>
                  )}
                </Box>
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
