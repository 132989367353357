import {
  AppBar,
  BooleanBubble,
  Bubble,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  loanPaymentListExport,
  loanPaymentListFetch,
} from './loanPaymentListSlice';
import { setLoan } from './loanSlice';
import {
  getPaymentAgent,
  getPaymentAmount,
  getPaymentDate,
  getPaymentLoanId,
  getPaymentSourceColor,
  getPaymentSourceLabel,
  getPaymentSourceText,
  getPaymentSources,
  getPaymentStatusColor,
  getPaymentStatusText,
  getPaymentStatuses,
  getPaymentType,
} from './utils';

export const LoanPaymentList = ({ embedded }) => {
  // console.log('RXD:LoanPaymentList');
  const dispatch = useDispatch();

  const { loanPaymentList, isLoading, lastFilter, error } = useSelector(
    (state) => {
      return state.loanPaymentList;
    }
  );

  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'loanUuid',
      'a.uuid',
      'fa.uuid',
    ]);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-date,-loanId');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      // console.log('RXD:LoanPaymentList.fetching');
      dispatch(loanPaymentListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'loanId',
      headerName: 'Loan ID',
      minWidth: 75,
      sortingOrder: getSortOrder(),
      valueGetter: w(getPaymentLoanId),
    },
    {
      field: 'externalId',
      headerName: 'Transaction ID',
      align: 'right',
      headerAlign: 'center',
      minWidth: 100,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'date',
      headerName: 'Date Received',
      align: 'left',
      type: 'date',
      headerAlign: 'left',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getPaymentDate),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'left',
      headerAlign: 'left',
      minWidth: 150,
      sortingOrder: getSortOrder(),
      valueGetter: w(getPaymentAmount),
    },
    {
      field: 'type',
      headerName: 'Type',
      align: 'center',
      headerAlign: 'center',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getPaymentType),
    },
    {
      field: 'a.name',
      headerName: 'Agent',
      minWidth: 250,
      sortingOrder: getSortOrder(),
      valueGetter: w(getPaymentAgent),
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      minWidth: 200,
      type: 'singleSelect',
      sortingOrder: getSortOrder(),
      valueOptions: getPaymentStatuses()?.map((status) => ({
        value: status,
        label: status,
      })),
      renderCell: w((payment) => (
        <Bubble
          label={payment?.status}
          background={getPaymentStatusColor(payment?.status)}
          color={getPaymentStatusText(payment?.status)}
          borderRadius="0"
          fontWeight="normal"
        />
      )),
    },
    {
      field: 'transactionUuid',
      headerName: 'Has Transaction?',
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      sortingOrder: getSortOrder(),
      renderCell: w((payment) => (
        <BooleanBubble value={payment?.transaction} />
      )),
    },
    {
      field: 'sources',
      headerName: 'Sources',
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      type: 'singleSelect',
      sortingOrder: getSortOrder(),
      valueOptions: getPaymentSources()?.map((source) => ({
        value: source,
        label: source,
      })),
      renderCell: w((payment) =>
        payment.sources?.map((source) => (
          <Bubble
            label={getPaymentSourceLabel(source)}
            background={getPaymentSourceColor(source)}
            color={getPaymentSourceText(source)}
            borderRadius="0"
            fontWeight="normal"
          />
        ))
      ),
    },
    {
      field: 't.mobile',
      headerName: 'Paying Number',
      align: 'left',
      headerAlign: 'center',
      minWidth: 100,
      sortingOrder: getSortOrder(),
      valueGetter: w((payment) => payment?.transaction?.mobile),
    },
    {
      field: 't.clientName',
      headerName: 'Paying Name',
      align: 'left',
      headerAlign: 'center',
      minWidth: 100,
      sortingOrder: getSortOrder(),
      valueGetter: w((payment) => payment?.transaction?.clientName),
    },
  ];

  return (
    <>
      {!embedded && <AppBar>Loan Collections</AppBar>}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(loanPaymentListFetch(filters))}
        />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(loan) => loan.uuid}
          rows={loanPaymentList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const loanPayment = param.row;
            if (loanPayment?.loan?.uuid) {
              if (e.ctrlKey) {
                window.open(`/loans/${loanPayment.loan.uuid}`);
              } else {
                dispatch(setLoan(loanPayment.loan));
                navigate(`/loans/${loanPayment.loan.uuid}`);
              }
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('LoanPaymentList', visible)
          }
          onExportClick={() => dispatch(loanPaymentListExport())}
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('LoanPaymentList', {
                't.mobile': false,
                't.clientName': false,
              }),
            },
          }}
        />
      </div>
    </>
  );
};
