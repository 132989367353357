import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';
import { setLoanRequest } from './loanRequestSlice';

const initialState = {
  status: 'editing',
  error: '',
};

const loanRequestFormSlice = createSlice({
  name: 'loanRequestForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const loanRequestSave = (uuid, data) => (dispatch) => {
  dispatch(setLoading());
  ['requestedAmount', 'requestedTerm', 'principal', 'term'].forEach((key) => {
    if (key in data) {
      data[key] = +data[key];
    }
  });

  if (!uuid) {
    data.status = 'pending';
  }
  const action = !!uuid
    ? getApi().patch(`/api/loans/requests/${uuid}`, data)
    : getApi().post(`/api/loans/requests`, data);

  action
    .then((response) => {
      dispatch(resetState());
      dispatch(setLoanRequest(response.data));
      if (!uuid) {
        dispatch(push(`/loans/requests/${response.data.uuid}`));
      }
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { resetState, setLoading, setError } =
  loanRequestFormSlice.actions;

export const loanRequestFormReducer = loanRequestFormSlice.reducer;
