import {
  AppBar,
  BooleanBubble,
  Bubble,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import { getAutoCompleteOperations } from '@/common/AutoCompleteFilterInput';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { loanProductListFetch } from './loanProductListSlice';
import { loanRequestListFetch } from './loanRequestListSlice';
import { setLoanRequest } from './loanRequestSlice';
import {
  getRequestAgentFeedback,
  getRequestAgentName,
  getRequestCreatedAt,
  getRequestCurrency,
  getRequestInterest,
  getRequestInterestRate,
  getRequestLoanProduct,
  getRequestMessage,
  getRequestPaymentInterval,
  getRequestPrincipal,
  getRequestPrincipalHoliday,
  getRequestRequestedAmount,
  getRequestRequestedTerm,
  getRequestSignedAt,
  getRequestStatusBackground,
  getRequestStatusColor,
  getRequestStatuses,
  getRequestTableauUrl,
  getRequestTerm,
  getRequestTermUnits,
  getRequestTotal,
} from './utils';

export const LoanRequestList = ({ embedded }) => {
  // console.log('RXD:LoanRequestList');
  const dispatch = useDispatch();
  const { loanRequestList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.loanRequestList;
    }
  );

  const { loanProductList } = useSelector((state) => state.loanProductList);

  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 10);

  const loanProductFetchOptions = (v) => {
    if (v) {
      dispatch(
        loanProductListFetch(
          new URLSearchParams(`name=contains:${v}&pp=1:10&oo=name&e=1`)
        )
      );
    }
  };

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'a.fieldAgentUuid',
      'a.uuid',
    ]);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-createdAt');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      // console.log('RXD:LoanRequestList.fetching');
      dispatch(loanRequestListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'a.agentName',
      headerName: 'Agent',
      minWidth: 160,
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestAgentName),
    },
    {
      field: 'p.uuid',
      headerName: 'Loan Product',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      filterOperators: getAutoCompleteOperations(),
      options: loanProductList ?? [],
      fetchOptions: loanProductFetchOptions,
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestLoanProduct),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      type: 'singleSelect',
      valueOptions: getRequestStatuses().map((type) => ({
        value: type,
        label: type,
      })),
      renderCell: w((request) => (
        <Bubble
          label={request?.status}
          color={getRequestStatusColor(request)}
          background={getRequestStatusBackground(request)}
        />
      )),
    },
    {
      field: 'createdAt',
      headerName: 'Requested At',
      align: 'left',
      headerAlign: 'left',
      type: 'date',
      minWidth: 180,
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestCreatedAt),
    },
    {
      field: 'eligible',
      headerName: 'Eligible',
      align: 'center',
      type: 'boolean',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      renderCell: w((request) => <BooleanBubble value={request?.eligible} />),
    },
    {
      field: 'eligibleMessage',
      headerName: 'Eligibility Message',
      align: 'left',
      sortingOrder: getSortOrder(),
      renderCell: w((request) => (
        <Box title={request?.eligibleMessage}>{request?.eligibleMessage}</Box>
      )),
    },
    {
      field: 'currency',
      headerName: 'Currency',
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestCurrency),
    },
    {
      field: 'requestedTerm',
      headerName: 'Requested Term',
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestRequestedTerm),
    },
    {
      field: 'requestedAmount',
      headerName: 'Requested Principal',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestRequestedAmount),
    },
    {
      field: 'term',
      headerName: 'Loan Term',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestTerm),
      valueSetter: (params) => {
        const regex = /(?<term>\d+)\s+(?<termUnit>\w+)/;
        if (params.value && regex.test(params.value)) {
          let {
            groups: { term, termUnit },
          } = regex.exec(params.value?.toString());
          termUnit =
            getRequestTermUnits().indexOf(termUnit) > -1
              ? termUnit
              : params.row.termUnit;
          return { ...params.row, term, termUnit };
        }
        return params;
      },
      cellClassName: (params) => 'edited-field',
      editable: true,
    },
    {
      field: 'principal',
      headerName: 'Principal',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      sortingOrder: getSortOrder(),
      renderCell: w(getRequestPrincipal),
      valueGetter: w((r) => getRequestPrincipal(r).replace(/,/g, '')),
      cellClassName: (params) => 'edited-field',
      editable: true,
    },
    {
      field: 'interest',
      headerName: 'Interest',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestInterest),
    },
    {
      field: 'total',
      headerName: 'Total',
      minWidth: 100,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestTotal),
    },
    {
      field: 'tableau',
      headerName: 'Tableau',
      minWidth: 100,
      sortable: false,
      filterable: false,
      valueGetter: w(getRequestTableauUrl),
      renderCell: (param) => (
        <Button
          component="a"
          target="_blank"
          href={getRequestTableauUrl(param.row)}
          size="small"
          sx={{ padding: 0, margin: 0, height: 24 }}
          variant="outlined"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          View
        </Button>
      ),
    },
    {
      field: 'interestRate',
      headerName: 'Interest Rate',
      type: 'number',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestInterestRate),
    },
    {
      field: 'paymentInterval',
      headerName: 'Payment Interval',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestPaymentInterval),
    },
    {
      field: 'principalHoliday',
      headerName: 'Principal Holiday',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestPrincipalHoliday),
    },
    {
      field: 'message',
      headerName: 'Message',
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestMessage),
    },
    {
      field: 'agentFeedback',
      headerName: 'Feedback',
      align: 'center',
      headerAlign: 'center',
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestAgentFeedback),
    },
    {
      field: 'signedAt',
      headerName: 'Signed At',
      minWidth: 180,
      type: 'date',
      sortingOrder: getSortOrder(),
      valueGetter: w(getRequestSignedAt),
    },
  ];

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            <Button
              disableElevation
              sx={{ textTransform: 'none' }}
              variant="contained"
              border="2px solid"
              size="medium"
              component={RouterLink}
              to="/loans/requests/add"
            >
              Make Loan Offer
            </Button>
          }
        >
          Loan Requests
        </AppBar>
      )}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(loanRequestListFetch(filters))}
        />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(loanRequest) => loanRequest.uuid}
          rows={loanRequestList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const { row: loanRequest } = param;

            if (e.ctrlKey) {
              window.open(`/loans/requests/${loanRequest.uuid}`);
            } else {
              dispatch(setLoanRequest(loanRequest));
              navigate(`/loans/requests/${loanRequest.uuid}`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('LoanRequestList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('LoanRequestList', {
                currency: false,
                agentFeedback: false,
                eligibleMessage: false,
                requestedAmount: false,
                requestedTerm: false,
                message: false,
                product: false,
                paymentInterval: false,
                principalHoliday: false,
                interestRate: false,
                signedAt: false,
              }),
            },
          }}
        />
      </div>
    </>
  );
};
