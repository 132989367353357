import { AppBar, Bubble, Error, Loading, useInitial } from '@/common';
import EditIcon from '@mui/icons-material/Edit';
import VerifiedIcon from '@mui/icons-material/Verified';
import { captureEvent } from '@/firebase';

import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { hasAnyRoles } from '@/auth/helpers';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  customerFetch,
  customerKYCReviewUpdate,
  customerKYCUpdate,
} from './customerSlice';
import {
  getCustomerKycName,
  getCustomerName,
  getCustomerNumber,
  getDateOfBirth,
  getExternalId,
  getFullDate,
  getKYCStatusBgColor,
  getKYCStatusTextColor,
  getKYCStatusTextLabel,
} from './utils';

function a11yProps(title) {
  return {
    id: `customer-${title}-tab`,
    'aria-controls': `customer-${title}-tabpanel`,
  };
}

const Item = ({ label, value, capitalize = true }) => (
  <Box py={1}>
    <Typography as="h4" py={1} fontWeight={300}>
      {label}
    </Typography>
    <Typography
      fontWeight={600}
      textTransform={capitalize ? 'capitalize' : 'unset'}
    >
      {value}
    </Typography>
  </Box>
);

const CustomerProfile = ({ customer }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Box bgcolor={'white'} px={2} py={4} borderRadius={'10px'} height={'100%'}>
      <Avatar
        alt={getCustomerName({ row: customer?.kyc })}
        src={customer?.kyc?.profilePictureUrl}
        sx={{
          width: 100,
          height: 100,
          marginInline: 'auto',
          marginBottom: 2,
        }}
      />
      <Divider />

      <Item label="UUID" value={customer?.uuid} capitalize={false} />

      <Divider />

      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        py={1}
      >
        <Typography as="h5">Contacts</Typography>

        {hasAnyRoles(user.role, 'CUSTOMER_MANAGER') && (
          <Button variant="outlined" sx={{ color: 'gray' }}>
            Add
          </Button>
        )}
      </Stack>

      <Divider />

      <Box py={1}>
        <Typography>Email Address</Typography>
        <Typography as="b">{customer?.email}</Typography>
      </Box>

      <Divider />
      <Box my="2">
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          py={1}
        >
          <Stack py={0.5} alignItems={'center'} direction={'row'} gap={1}>
            <Icon>
              <VerifiedIcon color="primary" scale={0.8} />
            </Icon>
            <Typography as="b">Africell</Typography>
          </Stack>

          {hasAnyRoles(user.role, 'CUSTOMER_MANAGER') && (
            <Button variant="outlined" sx={{ color: 'gray' }}>
              Edit
            </Button>
          )}
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          py={1}
        >
          <Typography>Name</Typography>
          <Typography>{getCustomerName({ row: customer })}</Typography>
        </Stack>{' '}
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          py={1}
        >
          <Typography>Phone number</Typography>
          <Typography>{getCustomerNumber({ row: customer })}</Typography>
        </Stack>{' '}
      </Box>
    </Box>
  );
};

const KYCImages = ({ customer }) => {
  const kycImageStyle = {
    height: '250px',
    width: '100%',
    borderRadius: '5px',
  };

  return (
    <Stack
      direction="row"
      gap={2}
      mt={3}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Box flex={1} sx={{ bgcolor: 'white', borderRadius: '5px' }} p={2}>
        <Typography mb={1}>
          Front {customer?.kyc?.idType?.replace('_', ' ')} image
        </Typography>

        <Box>
          <img
            height={kycImageStyle.height}
            width={kycImageStyle.width}
            style={{ borderRadius: kycImageStyle.borderRadius }}
            src={customer?.kyc?.idImageFrontUrl}
            alt="Front View"
            loading="lazy"
          />
        </Box>
      </Box>

      <Box flex={1} sx={{ bgcolor: 'white', borderRadius: '5px' }} p={2}>
        <Typography mb={1}>
          Back {customer?.kyc?.idType?.replace('_', ' ')} image
        </Typography>

        <Box>
          <img
            height={kycImageStyle.height}
            width={kycImageStyle.width}
            style={{ borderRadius: kycImageStyle.borderRadius }}
            src={customer?.kyc?.idImageBackUrl}
            alt="Back View"
            loading="lazy"
          />
        </Box>
      </Box>
    </Stack>
  );
};

const TabPanel = (props) => {
  const { children, value, index, title, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`customer-${title}-tab`}
      aria-labelledby={`customer-${title}-tabpanel`}
      style={{ height: 'calc(100vh - 290px)', minHeight: 300 }}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};

const KYCInformation = ({
  customer,
  handleCancelUpdate,
  handleUpdate,
  handleOnChange,
  input,
}) => {
  const [editKycInformation, setEditKycInformation] = useState(false);

  const handleOpenEdit = () => setEditKycInformation(true);
  const { user } = useSelector((state) => state.auth);

  const handleCloseEdit = () => {
    handleCancelUpdate();
    setEditKycInformation(false);
  };

  return (
    <Grid
      container
      gap={2}
      justifyContent={'space-between'}
      sx={{ borderRadius: '10px', p: 2, bgcolor: 'white' }}
    >
      <Grid item xs={5}>
        <Box>
          <Item label="UUID" value={customer?.uuid} capitalize={false} />
          <Divider />
        </Box>

        <Box>
          <Item label="External ID" value={getExternalId(customer)} />
          <Divider />
        </Box>

        <Box>
          <Item label="Compliance Status" />
          <Box mb={'10px'}>
            <Bubble
              label={getKYCStatusTextLabel(customer)}
              background={getKYCStatusBgColor(customer)}
              color={getKYCStatusTextColor(customer)}
              borderRadius="0"
              fontWeight="light"
            />
          </Box>
          <Divider />
        </Box>

        <Box>
          <Item
            label="Gender"
            value={customer?.kyc?.gender || customer?.gender || '...'}
          />
          <Divider />
        </Box>

        <Box>
          <Item
            label="Birthday"
            value={
              getDateOfBirth(
                customer?.dateOfBirth || customer?.kyc?.dateOfBirth
              ) || '...'
            }
          />
          <Divider />
        </Box>

        <Box>
          <Item
            label="National ID Number"
            value={customer?.idNumber || customer?.kyc?.idNumber || '...'}
          />
          <Divider />
        </Box>

        <Box>
          <Item
            label="Expiry date"
            value={
              getFullDate(customer?.expiryDate || customer?.kyc?.expiryDate) ||
              '...'
            }
          />
          <Divider />
        </Box>

        <Box>
          <Item
            label="Nationality"
            value={customer?.nationality || customer?.kyc?.nationality || '...'}
          />
          <Divider />
        </Box>

        <Box>
          <Item
            label="Address"
            value={customer?.address || customer?.kyc?.address || '...'}
          />
          <Divider />
        </Box>

        <Box py={1}>
          <Typography
            fontWeight={300}
            py={1}
          >{`Emergency Contact (${customer?.kyc?.nextOfKinRelationship})`}</Typography>
          <Typography fontWeight={600} py={1}>
            {customer?.kyc?.nextOfKinName}
          </Typography>
          <Typography fontWeight={300} as="a" href="#" variant="primary">
            {customer?.kyc?.nextOfKinPhone}
          </Typography>
          <Divider />
        </Box>
      </Grid>

      <Grid item xs={6}>
        {editKycInformation ? (
          <Stack
            py={3}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
          >
            <Stack gap={4} flex={0.7}>
              <TextField
                my={1}
                label="Customer Name from KYC"
                value={input?.firstName}
                onChange={handleOnChange('firstName')}
              />
              <TextField
                my={1}
                label="Customer Surname from KYC"
                value={input?.lastName}
                onChange={handleOnChange('lastName')}
              />
              <TextField
                my={1}
                label="ID Number from KYC"
                value={input?.idNumber}
                onChange={handleOnChange('idNumber')}
              />
            </Stack>

            {hasAnyRoles(user.role, 'CUSTOMER_MANAGER') && (
              <Box flex={0.2}>
                <Button onClick={handleUpdate} variant="outlined">
                  Update
                </Button>
                <Button
                  onClick={handleCloseEdit}
                  sx={{
                    bgcolor: 'white',
                    border: '1px solid gray',
                    color: 'gray',
                    mt: 2,
                    width: '100%',
                  }}
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Stack>
        ) : (
          <Stack
            direction={'row'}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            py={1}
          >
            <Box flex={0.95}>
              <Box>
                <Item label="Customer Name from KYC" value={input?.firstName} />
                <Divider />
              </Box>
              <Box>
                <Item
                  label="Customer Surname from KYC"
                  value={input?.lastName}
                />
                <Divider />
              </Box>
              <Box>
                <Item label="ID Number from KYC" value={input?.idNumber} />
                <Divider />
              </Box>
            </Box>

            {hasAnyRoles(user.role, 'CUSTOMER_MANAGER') && (
              <Box>
                <Button onClick={handleOpenEdit}>
                  <EditIcon />
                </Button>
              </Box>
            )}
          </Stack>
        )}

        <Box>
          <Item
            label="Customer birthday from KYC"
            value={getDateOfBirth(customer?.kyc?.dateOfBirth) ?? '...'}
          />
          <Divider />
        </Box>
        <Box>
          <Typography fontWeight={300} py={1}>
            KYC collected by
          </Typography>
          <Typography fontWeight={600}>
            {customer?.kyc?.createdBy?.agent?.agentName ?? '...'}
          </Typography>
          <Divider />
        </Box>
        <Box>
          <Item
            label="Updated At"
            value={getFullDate(customer?.kyc?.updatedAt) ?? '...'}
          />
          <Divider />
        </Box>
        <Box>
          <Item
            label="Created At"
            value={getFullDate(customer?.kyc?.createdAt) ?? '...'}
          />
          <Divider />
        </Box>

        <Box>
          <Item
            label="Compliance comments"
            value={customer?.kyc?.rejectionComment ?? '...'}
          />
          <Divider />
        </Box>
      </Grid>
    </Grid>
  );
};

const UndoComplianceDialogue = ({ handleClose, open, customer }) => {
  const customerName = getCustomerName({ row: customer });
  const id = customer?.kyc?.uuid;
  const dispatch = useDispatch();
  const handleUndoCompliance = () => {
    const payload = {
      id,
      status: 'PENDING',
      rejectionComment: '',
    };
    dispatch(customerKYCReviewUpdate(payload));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Undo compliance status for {customerName}</DialogTitle>
      <DialogContent>
        <Chip label="Warning" color="warning" />
        <Typography fontWeight={600}>
          The customer's compliance status will go back to 'pending'. The
          profile details (name, DOB, ID number) will remain as they currently
          appear
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Button onClick={handleClose} variant="contained" color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleUndoCompliance}
          variant="contained"
          color="warning"
        >
          Undo compliance status
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ReviewKYCDialogue = ({ handleClose, open, customer }) => {
  const customerName = getCustomerName({ row: customer });
  const customerKycName = getCustomerKycName({ row: customer });
  const id = customer?.kyc?.uuid;
  const kycStatus = customer?.kyc?.status;
  const [complianceComment, setComplianceComment] = useState('');
  const dispatch = useDispatch();

  const handleRejectKYC = () => {
    const payload = {
      id,
      status: 'REJECTED',
      rejectionComment: complianceComment,
    };
    dispatch(customerKYCReviewUpdate(payload));
    handleClose();
  };

  const handleClearKYC = () => {
    const payload = {
      id,
      status: 'APPROVED',
      rejectionComment: complianceComment,
    };
    dispatch(customerKYCReviewUpdate(payload));
    handleClose();
  };
  const handleOnChange = (e) => setComplianceComment(e.target.value);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Review KYC for {customerName}</DialogTitle>
      <DialogContent>
        {' '}
        {customerKycName !== customerName ? (
          <Chip label="Warning" color="warning" />
        ) : (
          <Box></Box>
        )}
        <Typography fontWeight={600}>
          Customer registration name:{' '}
          <Typography as="span">{customerName}</Typography>
        </Typography>
        <Typography fontWeight={600}>
          Updated KYC name: <Typography as="span">{customerKycName}</Typography>
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          id="complianceComment"
          label="Compliance comments"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          minRows={3}
          sx={{ mt: 3 }}
          onChange={handleOnChange}
        />
        <DialogContentText my={1}>
          Note that customer's KYC information will be saved by Opareta but many
          of these details will not be saved in Comviva. If you decline this
          KYC, an email will be sent to (compliance email)@africell.gm and the
          customer's agent will be notified. Customer will be notified of KYC
          cleared or KYC declined.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        {kycStatus !== 'REJECTED' && (
          <Button onClick={handleRejectKYC} variant="contained" color="error">
            Decline KYC
          </Button>
        )}
        <Button onClick={handleClose} variant="contained" color="inherit">
          Cancel
        </Button>
        {kycStatus !== 'APPROVED' && (
          <Button onClick={handleClearKYC} variant="contained">
            Clear KYC
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const CustomerContract = ({ customer, dispatch }) => {
  const contractPdfUrl = customer?.kyc?.signedDocumentPdfUrl;
  return (
    <Card>
      <CardContent>
        {contractPdfUrl ? (
          <>
            <Button
              variant="contained"
              component={RouterLink}
              to={contractPdfUrl}
              rel="noreferrer"
              target="_blank"
              download={`${(customer?.firstName ?? customer.uuid ?? '')
                .replace(/[^A-Za-z]+/g, '-')
                .toLowerCase()}-${customer.uuid}.pdf`}
              sx={{ mb: 2 }}
            >
              Download
            </Button>
            <Box
              component="embed"
              src={contractPdfUrl}
              type="application/pdf"
              frameBorder="0"
              scrolling="auto"
              minHeight="600px"
              height="100%"
              width="100%"
            />
          </>
        ) : (
          <Alert severity="info">
            <Box>Currently no contract generated!</Box>
            <Box>
              <Divider sx={{ my: 2 }} />
              <Box>It may take up to a minute to generate this contract.</Box>
              <Button
                variant="contained"
                size="medium"
                sx={{ my: 2, textTransform: 'none' }}
                onClick={() => dispatch(customerFetch(customer.uuid))}
              >
                Refresh Now
              </Button>
            </Box>
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

export const CustomerDetail = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();

  const { user } = useSelector((state) => state.auth);
  const [currentTab, setCurrentTab] = useState(0);
  const [input, setInput] = useState({
    firstName: '',
    lastName: '',
    idNumber: '',
  });
  const [openReviewKYC, setOpenReviewKYC] = useState(false);
  const [openUndoCompliance, setOpenUndoCompliance] = useState(false);

  const { customer, isLoading, error } = useSelector((state) => state.customer);

  const handleChange = (e, tabIndex) => {
    setCurrentTab(tabIndex);
    captureEvent('TAB_CHANGE_CUSTOMER_DETAIL', {
      index: tabIndex,
      tab: e?.target?.innerText || '',
      user,
    });
  };

  const handleOnChange = (name) => (e) => {
    setInput((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const handleCancelUpdate = () => {
    setInput({
      firstName: customer?.kyc?.firstName,
      lastName: customer?.kyc?.lastName,
      idNumber: customer?.kyc?.idNumber,
    });
  };

  const handleUpdate = () => {
    const payload = {
      id: customer?.kyc?.uuid,
      lastName: input.lastName,
      firstName: input.firstName,
      idNumber: input.idNumber,
    };

    dispatch(customerKYCUpdate(payload));
  };

  const handleOpenReviewKYC = () => {
    setOpenReviewKYC(true);
  };

  const handleOpenUndoCompliance = () => {
    setOpenUndoCompliance(true);
  };
  const handleCloseReviewKYC = () => {
    setOpenReviewKYC(false);
  };

  const handleCloseUndoCompliance = () => {
    setOpenUndoCompliance(false);
  };

  useLayoutEffect(() => {
    if (uuid !== customer?.uuid && !isLoading) {
      dispatch(customerFetch(uuid));
    }

    if (!!customer?.kyc) {
      setInput({
        firstName: customer?.kyc?.firstName,
        idNumber: customer?.kyc?.idNumber,
        lastName: customer?.kyc?.lastName,
      });
    }
  }, [dispatch, customer, uuid, isLoading]);

  useInitial(() => {
    if (!isLoading) dispatch(customerFetch(uuid));
  });

  return (
    <>
      <AppBar
        actions={
          hasAnyRoles(user.role, 'CUSTOMER_MANAGER') &&
          customer?.kyc?.status === 'APPROVED' ? (
            <Button
              disableElevation
              sx={{ textTransform: 'none' }}
              variant="contained"
              border="2px solid"
              size="small"
              onClick={handleOpenUndoCompliance}
            >
              Undo Compliance Status
            </Button>
          ) : (
            hasAnyRoles(user.role, 'CUSTOMER_MANAGER') && (
              <Button
                disableElevation
                sx={{ textTransform: 'none' }}
                variant="contained"
                border="2px solid"
                size="small"
                onClick={handleOpenReviewKYC}
              >
                Review KYC
              </Button>
            )
          )
        }
      >
        Customer: {getCustomerName({ row: customer })}
      </AppBar>

      {error && (
        <Box my={2}>
          <Error error={error} onRetry={() => dispatch(customerFetch(uuid))} />
        </Box>
      )}

      {!customer || isLoading ? (
        <Loading />
      ) : (
        <>
          <Box
            marginBottom={2}
            bgcolor={'white'}
            borderRadius={2}
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tabs value={currentTab} onChange={handleChange}>
              <Tab label="Overview" {...a11yProps('overview')} />
              <Tab label="Contract" {...a11yProps('contract')} />
            </Tabs>
          </Box>

          <Box>
            <TabPanel value={currentTab} index={0} marginBottom={2}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <CustomerProfile customer={customer} />
                </Grid>
                <Grid item xs={8} mb={3}>
                  <KYCInformation
                    input={input}
                    handleCancelUpdate={handleCancelUpdate}
                    handleOnChange={handleOnChange}
                    customer={customer}
                    handleUpdate={handleUpdate}
                  />
                  <KYCImages customer={customer} />

                  {/* Dialogue Container */}
                  <ReviewKYCDialogue
                    open={openReviewKYC}
                    customer={customer}
                    handleClose={handleCloseReviewKYC}
                  />
                  <UndoComplianceDialogue
                    open={openUndoCompliance}
                    customer={customer}
                    handleClose={handleCloseUndoCompliance}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            {
              <TabPanel value={currentTab} index={1} marginBottom={2}>
                <CustomerContract
                  customer={customer}
                  dispatch={dispatch}
                ></CustomerContract>
              </TabPanel>
            }
          </Box>
        </>
      )}
    </>
  );
};
