import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { AppBar, Error, Loading } from '@/common';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actionSave } from './actionFormSlice';
import { actionFetch } from './actionSlice';
import { getActionTelcos } from './utils';
import { captureEvent } from '@/firebase';

export const ActionForm = () => {
  // console.log('RXD:ActionForm');
  const dispatch = useDispatch();
  const { id: paramId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const id = Boolean(paramId) ? +paramId : null;
  const { error: formError, status: formStatus } = useSelector(
    (state) => state.actionForm
  );
  const {
    action,
    error: fetchError,
    isLoading: fetchIsLoading,
  } = useSelector((state) => state.action);

  const [name, setName] = useState('');
  const [externalId, setExternalId] = useState('');
  const [path, setPath] = useState('');
  const [hnis, setHnis] = useState([]);

  useEffect(() => {
    if (id !== action?.id && !fetchIsLoading && !fetchError) {
      setName('');
      setExternalId('');
      setPath('');
      setHnis([]);
      if (id) {
        dispatch(actionFetch(id));
      }
    }
  }, [dispatch, action, id, fetchIsLoading, fetchError]);

  // initial values
  useEffect(() => {
    if (id === action?.id) {
      setName(action?.name ?? '');
      setExternalId(action?.externalId ?? '');
      setPath(action?.path ?? '');
      setHnis(action?.hnis ?? []);
    }
  }, [dispatch, action, id]);

  const handleSave = (e) => {
    e.preventDefault();
    dispatch(
      actionSave(id, {
        name,
        externalId,
        path,
        hnis,
      })
    );
    captureEvent(id ? 'USSD_ACTION_EDIT' : 'USSD_ACTION_CREATE', {
      user,
      name,
      externalId,
      path,
      hnis,
    });
  };

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <>
      <AppBar>{id ? 'Edit Action' : 'Add Action'}</AppBar>

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <>
                <Box
                  component="form"
                  method="post"
                  sx={{ p: 1 }}
                  onSubmit={handleSave}
                >
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      value={name}
                      onChange={w(setName)}
                      required
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Telcos</InputLabel>
                    <Select
                      label="Telcos"
                      value={hnis}
                      onChange={w(setHnis)}
                      multiple
                      required
                    >
                      {getActionTelcos().map((telco) => (
                        <MenuItem key={telco.hni} value={telco.hni}>
                          {telco.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      label="Hover ID"
                      variant="outlined"
                      value={externalId}
                      onChange={w(setExternalId)}
                      required
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      label="USSD Path"
                      variant="outlined"
                      helperText="E.g. *165#2*phoneNumber*4*pin#"
                      value={path}
                      onChange={w(setPath)}
                      required
                    />
                  </FormControl>

                  <Divider sx={{ mb: 2 }} />

                  {formStatus === 'loading' ? (
                    <Loading />
                  ) : (
                    <Button variant="contained" sx={{ mb: 1 }} type="submit">
                      Save
                    </Button>
                  )}
                </Box>
              </>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
