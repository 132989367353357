import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { setAgentConfiguration } from './agentConfigurationSlice';

const initialState = {
  status: 'editing',
  error: '',
};

const agentConfigurationFormSlice = createSlice({
  name: 'agentConfigurationForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const agentConfigurationSave = (data) => (dispatch) => {
  dispatch(setLoading());

  getApi()
    .patch(`/api/agents/configurations`, data)
    .then((response) => {
      dispatch(resetState());
      dispatch(setAgentConfiguration(response.data));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { resetState, setLoading, setError } =
  agentConfigurationFormSlice.actions;

export const agentConfigurationFormReducer =
  agentConfigurationFormSlice.reducer;
