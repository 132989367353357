import {
  AppBar,
  Error,
  Info as InfoBase,
  Loading,
  TabPanel,
  useFilters,
} from '@/common';
import { a11yProps } from '@/common/TabPanel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  getAmount,
  getCreatedAt,
  getExpensedAt,
  getKind,
  getPageTitle,
} from './utils';
import { expenseFetch } from './expenseSlice';
import { expenseRemove } from './expenseDeleteSlice';
import { captureEvent } from '@/firebase';

export const ExpenseDetail = ({ mode }) => {
  // console.log('RXD:ExpenseDetail');
  const dispatch = useDispatch();
  const { agentUuid, fieldAgentUuid, uuid } = useParams();
  const { user } = useSelector((state) => state.auth);
  const currentUuid = useRef();
  const { expense, isLoading, error } = useSelector((state) => state.expense);
  const { status: deleteStatus, error: deleteError } = useSelector(
    (state) => state.expenseDelete
  );

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filters, setFilters] = useFilters();
  const handleTabChange = (e, index) => {
    setFilters(new URLSearchParams(`tab=${index}`));
    captureEvent('TAB_CHANGE_EXPENSE_DETAIL', {
      index,
      tab: e?.target?.innerText || '',
    });
  };
  let tab = filters.get('tab');
  tab = tab === null ? 0 : +tab;

  const handleOnDelete = (e) => {
    e.preventDefault();
    dispatch(expenseRemove({ mode, agentUuid, fieldAgentUuid }, uuid));
  };

  useEffect(() => {
    if (
      (currentUuid.current !== uuid && !isLoading && !error) ||
      (expense?.uuid && error)
    ) {
      currentUuid.current = uuid;
      dispatch(expenseFetch(uuid));
    }
  }, [dispatch, expense, uuid, currentUuid, isLoading, error]);

  // console.log('RXD:ExpenseDetail', { expense, isLoading, error });

  return (
    <>
      <AppBar
        actions={
          expense?.uuid && (
            <>
              <Button
                variant="contained"
                component={RouterLink}
                to={`/${mode}/${
                  agentUuid ?? fieldAgentUuid
                }/expenses/${uuid}/form`}
              >
                Edit Expense
              </Button>
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                onClick={(e) => setDrawerOpen(true)}
              >
                Delete Expense
              </Button>
            </>
          )
        }
      >
        {getPageTitle(mode)} {expense?.uuid ? expense.type : uuid}
      </AppBar>

      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={(e) => setDrawerOpen(false)}
      >
        <Card>
          <CardContent>
            <Box component="form" onSubmit={(e) => handleOnDelete(e)}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Permanently Delete Expense
              </Typography>

              <Divider />

              {deleteError && <Error error={deleteError} />}

              <p>
                Are you sure you want to delete this expense for all{' '}
                {mode.replace('-', ' ')}?
              </p>
              <Box sx={{ my: 2 }} />
              {deleteStatus === 'loading' ? (
                <Loading />
              ) : (
                <Button variant="contained" color="error" type="submit">
                  Delete
                </Button>
              )}
            </Box>
          </CardContent>
        </Card>
      </Drawer>

      {error ? (
        <Error error={error} />
      ) : !expense || isLoading ? (
        <Loading />
      ) : (
        <>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            sx={{ background: 'white' }}
            aria-label="expense detail tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Overview" value={0} {...a11yProps(0)} />
            {mode === 'agents' && (
              <Tab label="Agents" value={1} {...a11yProps(1)} />
            )}
            {mode === 'field-agents' && (
              <Tab label="Field Agents" value={2} {...a11yProps(2)} />
            )}
            {expense?.kind && expense?.kind !== 'one-time' && (
              <Tab label="Paid Out" value={3} {...a11yProps(3)} />
            )}
          </Tabs>
          <TabPanel value={tab} index={0}>
            <ExpenseOverview expense={expense} />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <AgentList expense={expense} />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <FieldAgentList expense={expense} />
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <PaidOutList expense={expense} />
          </TabPanel>
        </>
      )}
    </>
  );
};

const Info = ({ label, children, border }) => (
  <InfoBase sx={{ py: 1 }} label={label} border={border ?? true}>
    {children}
  </InfoBase>
);

const AgentList = ({ expense }) => (
  <TableContainer component={Paper}>
    <Table aria-label="agents table">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(expense.agents ?? []).map((agent) => (
          <TableRow key={agent.uuid}>
            <TableCell>
              <Link to={`/agents/${agent.uuid}`} component={RouterLink}>
                {agent.agentName}
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const FieldAgentList = ({ expense }) => (
  <TableContainer component={Paper}>
    <Table aria-label="field agents table">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(expense.fieldAgents ?? []).map((fieldAgent) => (
          <TableRow key={fieldAgent.uuid}>
            <TableCell>
              <Link
                to={`/field-agents/${fieldAgent.uuid}`}
                component={RouterLink}
              >
                {fieldAgent.name}
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const PaidOutList = ({ expense }) => {
  const paidOut = Array.from(expense.paidExpenses ?? []);
  paidOut.sort((a, b) => -a?.expensedAt.localeCompare(b?.expensedAt));

  return (
    <TableContainer component={Paper}>
      <Table aria-label="field agents table">
        <TableHead>
          <TableRow>
            <TableCell>Expense name</TableCell>
            <TableCell>Expensed At</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paidOut.map((expense, i) => (
            <TableRow key={`${expense.uuid}-${i}`}>
              <TableCell>{expense.type}</TableCell>
              <TableCell>{getExpensedAt(expense)}</TableCell>
              <TableCell>{getAmount(expense)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ExpenseOverview = ({ expense }) => {
  return (
    expense && (
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Info label="UUID" border={true}>
                {expense.uuid}
              </Info>
              <Info label="Expense name" border={true}>
                {expense.type}
              </Info>
              <Info label="Amount" border={true}>
                {getAmount(expense)}
              </Info>
              <Info label="Schedule" border={true}>
                {getKind(expense)}
              </Info>
              <Info label="First occurrence" border={true}>
                {getExpensedAt(expense)}
              </Info>
              <Info label="Date Created" border={true}>
                {getCreatedAt(expense)}
              </Info>
              <Info label="Comments" border={true}>
                {expense.comment}
              </Info>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  );
};
