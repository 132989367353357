import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';

const initialState = {
  status: 'editing',
  error: '',
};

const loanRuleFormSlice = createSlice({
  name: 'loanRuleForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const loanRuleSave = (uuid, data) => (dispatch) => {
  dispatch(setLoading());

  const action = !!uuid
    ? getApi().patch(`/api/loans/rules/${uuid}`, data)
    : getApi().post(`/api/loans/rules`, data);

  action
    .then((response) => {
      dispatch(resetState());
      dispatch(push(`/loans/rules`));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { resetState, setLoading, setError } = loanRuleFormSlice.actions;

export const loanRuleFormReducer = loanRuleFormSlice.reducer;
