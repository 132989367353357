import { agentFetch, formattedCount, formattedVolume } from '@/agent';
import { getFieldAgent } from '@/agent/utils';
import { AppBar, Bubble, Info as InfoBase, Loading } from '@/common';
import { FEATURES, hasFeature } from '@/common/features';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { transactionFetch } from './transactionSlice';
import {
  getAmount,
  getAppVersion,
  getBalance,
  getCategoryLabel,
  getCommission,
  getExternalId,
  getRecordTime,
  getSource,
  getSourceColor,
  getSourceText,
  getStatus,
  getStatusColor,
  getStatusText,
  getSuperAgentCommission,
  getTelcoName,
  getTransactionExtras,
  getUpdateTime,
} from './utils';

export const TransactionDetail = () => {
  // console.log('RXD:TransactionDetail');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { transaction, isLoading } = useSelector((state) => state.transaction);

  const transactionExtras = getTransactionExtras(transaction);

  useEffect(() => {
    if (uuid !== transaction?.uuid && !isLoading) {
      dispatch(transactionFetch(uuid));
    }
  }, [dispatch, transaction, uuid, isLoading]);

  const LocationDetail = lazy(
    () => import('./TransactionDetailLocationDetail')
  );

  return (
    <>
      <AppBar>Transaction: {uuid}</AppBar>
      {!transaction || isLoading ? (
        <Loading />
      ) : (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item md={4} xs={12}>
            <TransactDetail
              transaction={transaction}
              transactionExtras={transactionExtras}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AgentDetail transaction={transaction} />
          </Grid>
          <Grid item md={4} xs={12}>
            <AppDetail transaction={transaction} />
            <Suspense fallback={<Loading />}>
              <LocationDetail transaction={transaction} />
            </Suspense>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const Info = ({ label, children, border }) => (
  <InfoBase sx={{ py: 2 }} label={label} border={border ?? true}>
    {children}
  </InfoBase>
);

const AppDetail = ({ transaction }) => (
  <Card>
    <CardContent>
      <Typography sx={{ mb: 0 }} variant="h5" component="div">
        Other Details
      </Typography>
      {hasFeature(FEATURES.TRANSACTION_TELCO) && (
        <Info label="Network">{getTelcoName(transaction)}</Info>
      )}
      <Info label="Transaction Category">{getCategoryLabel(transaction)}</Info>
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" component="div">
          App Version
        </Typography>
        <Typography variant="body1" component="div">
          {getAppVersion(transaction)}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

const TransactDetail = ({ transaction, transactionExtras }) => (
  <Card>
    {/* <CardHeader title="Transaction Details"></CardHeader> */}
    <CardContent>
      <Typography sx={{ mb: 0 }} variant="h5" component="div">
        Transaction Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Info label="Status">
            <Bubble
              label={getStatus(transaction)}
              background={getStatusColor(transaction)}
              color={getStatusText(transaction)}
              borderRadius="0"
              fontWeight="normal"
            />
          </Info>
          {hasFeature(FEATURES.TRANSACTION_EXTERNAL_ID) && (
            <Info label="Transaction ID">{getExternalId(transaction)}</Info>
          )}
          <Info label="Client Name">{transaction.clientName}</Info>
          <Info label="Client Number">{transaction.mobile}</Info>
          {transactionExtras?.bundleId && (
            <Info label="Bundle Id">{transactionExtras.bundleId}</Info>
          )}
          {transactionExtras?.bundleDescription && (
            <Info label="Bundle Description">
              {transactionExtras.bundleDescription}
            </Info>
          )}
          {transactionExtras?.bundleValidity && (
            <Info label="Bundle Validity">
              {transactionExtras.bundleValidity}
            </Info>
          )}
          <Info label="Amount">{getAmount(transaction)}</Info>
          <Info label="Commission">{getCommission(transaction)}</Info>
          <Info label="Master Commission">
            {getSuperAgentCommission(transaction)}
          </Info>
          <Info label="Balance">{getBalance(transaction)}</Info>
          <Info label="Source">
            <Bubble
              label={getSource(transaction)}
              background={getSourceColor(transaction)}
              color={getSourceText(transaction)}
              borderRadius="0"
              fontWeight="normal"
            />
          </Info>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" component="div">
              Completed At
            </Typography>
            <Typography variant="body1" component="div">
              {getUpdateTime(transaction)}
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" component="div">
              Recorded At
            </Typography>
            <Typography variant="body1" component="div">
              {getRecordTime(transaction)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const AgentDetail = ({ transaction }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agent, isLoading, error } = useSelector((state) => state.agent);

  useEffect(() => {
    if (!isLoading && !error && transaction?.user?.agentUuid !== agent?.uuid) {
      dispatch(agentFetch(transaction?.user?.agentUuid));
    }
  }, [dispatch, transaction, agent, isLoading, error]);

  const handleClick = () => {
    if (agent) {
      navigate(`/agents/${agent.uuid}`);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography sx={{ mb: 0 }} variant="h5" component="div">
          Agent Details
        </Typography>
        {/* <Info label="Authenticated By">{transaction.user.authenticatedBy}</Info>
        <Info label="External ID">{transaction.user.externalId}</Info> */}
        {isLoading ? (
          <Loading />
        ) : !agent || agent?.uuid !== transaction?.user?.agentUuid ? (
          <Alert severity="warning">No agent found</Alert>
        ) : (
          <>
            <Info label="Name">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleClick}
              >
                {agent.agentName}
              </Link>
            </Info>
            <Info label="Gender">{agent.gender}</Info>
            <Info label="National ID">{agent.nationalId}</Info>
            <Info label="Transaction Count">{formattedCount(agent)}</Info>
            <Info label="Transaction Volume">{formattedVolume(agent)}</Info>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" component="div">
                Field Agent
              </Typography>
              <Typography variant="body1" component="div">
                {getFieldAgent(agent)}
              </Typography>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};
