import {
  AppBar,
  BooleanBubble,
  Bubble,
  Error,
  getColumnVisibility,
  getSortOrder,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
  setColumnVisibility,
} from '@/common/GridLoading';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { captureEvent } from '@/firebase';
import { useNavigate } from 'react-router-dom';
import { AddLoanRepayment, EditLoanRepaymentDialog } from './loan-repayments';
import {
  loanRepaymentsExport,
  loanRepaymentsFetch,
} from './loanRepaymentListSlice';
import { setLoan } from './loanSlice';
import {
  getAmount,
  getAmountDue,
  getPaymentAgent,
  getPaymentAmount,
  getPaymentDate,
  getPaymentLoanId,
  getPaymentSourceColor,
  getPaymentSourceLabel,
  getPaymentSourceText,
  getPaymentSources,
  getPaymentStatusColor,
  getPaymentStatusText,
  getPaymentStatuses,
  getPaymentType,
} from './utils';

function a11yProps(index) {
  return {
    id: `loan-repayment-tab-${index}`,
    'aria-controls': `loan-repayment-tabpanel-${index}`,
  };
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`loan-repayment-tab-${index}`}
      aria-labelledby={`loan-repayment-tabpanel-${index}`}
      style={{ height: 'calc(100vh - 290px)', minHeight: 300 }}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};

const RepaymentList = ({
  loanRepayments,
  type,
  handleOpenEditDialog,
  setLoanRepaymentUuid,
}) => {
  // console.log('RXD:LoanRepaymentList');
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => {
    return state.loanRepaymentList;
  });
  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);
  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const handleEditModal = (repaymentLoan) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoanRepaymentUuid(repaymentLoan?.uuid);
    handleOpenEditDialog();
  };

  const columns = [
    {
      field: 'l.loanId',
      headerName: 'Loan ID',
      minWidth: 75,
      sortingOrder: getSortOrder(),
      valueGetter: w(getPaymentLoanId),
    },
    {
      field: 'externalId',
      headerName: 'Transaction ID',
      align: 'right',
      headerAlign: 'center',
      minWidth: 100,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'date',
      headerName: 'Date Received',
      align: 'left',
      type: 'date',
      headerAlign: 'left',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getPaymentDate),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'right',
      headerAlign: 'center',
      type: 'number',
      minWidth: 150,
      sortingOrder: getSortOrder(),
      valueGetter: w(getPaymentAmount),
    },
    {
      field: 'type',
      headerName: 'Type',
      align: 'center',
      headerAlign: 'center',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getPaymentType),
    },
    {
      field: 'a.name',
      headerName: 'Agent',
      minWidth: 250,
      sortingOrder: getSortOrder(),
      valueGetter: w(getPaymentAgent),
    },
    {
      field: 'l.loanAmount',
      headerName: 'Principal',
      align: 'right',
      headerAlign: 'center',
      minWidth: 75,
      type: 'number',
      sortingOrder: getSortOrder(),
      valueGetter: w((p) => getAmount(p?.loan)),
    },
    {
      field: 'l.amountDue',
      headerName: 'Current Amount Due',
      align: 'right',
      headerAlign: 'center',
      minWidth: 75,
      type: 'number',
      sortingOrder: getSortOrder(),
      valueGetter: w((p) => getAmountDue(p?.loan)),
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      minWidth: 200,
      type: 'singleSelect',
      sortingOrder: getSortOrder(),
      valueOptions: getPaymentStatuses()?.map((status) => ({
        value: status,
        label: status,
      })),
      renderCell: w((payment) => (
        <Bubble
          label={payment?.status}
          background={getPaymentStatusColor(payment?.status)}
          color={getPaymentStatusText(payment?.status)}
          borderRadius="0"
          fontWeight="normal"
        />
      )),
    },
    {
      field: 'transactionUuid',
      headerName: 'Has Transaction?',
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      sortingOrder: getSortOrder(),
      renderCell: w((payment) => (
        <BooleanBubble value={payment?.transaction} />
      )),
    },
    {
      field: 'sources',
      headerName: 'Sources',
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      type: 'singleSelect',
      sortingOrder: getSortOrder(),
      valueOptions: getPaymentSources()?.map((source) => ({
        value: source,
        label: source,
      })),
      renderCell: w((payment) =>
        payment.sources?.map((source) => (
          <Bubble
            label={getPaymentSourceLabel(source)}
            background={getPaymentSourceColor(source)}
            color={getPaymentSourceText(source)}
            borderRadius="0"
            fontWeight="normal"
          />
        ))
      ),
    },
    {
      field: 't.mobile',
      headerName: 'Paying Number',
      align: 'left',
      headerAlign: 'center',
      minWidth: 100,
      sortingOrder: getSortOrder(),
      valueGetter: w((payment) => payment?.transaction?.mobile),
    },
    {
      field: 't.clientName',
      headerName: 'Paying Name',
      align: 'left',
      headerAlign: 'center',
      minWidth: 100,
      sortingOrder: getSortOrder(),
      valueGetter: w((payment) => payment?.transaction?.clientName),
    },
    {
      field: 'actions',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      sortable: false,
      filterable: false,
      renderCell: w((repaymentLoan) => (
        <Button
          component="a"
          disableElevation
          variant="contained"
          size="small"
          sx={{ padding: 0, margin: 0, height: 22, width: '100%' }}
          onClick={handleEditModal(repaymentLoan)}
        >
          Edit
        </Button>
      )),
      disableColumnMenu: true,
    },
  ];

  return (
    <OparetaTable
      loading={isLoading}
      getRowId={(loanPayment) => loanPayment.uuid + '--' + type}
      rows={loanRepayments ?? []}
      columns={columns}
      onCellClick={(param, e) => {
        const loanPayment = param.row;
        if (loanPayment?.loan?.uuid) {
          if (e.ctrlKey) {
            window.open(`/loans/${loanPayment.loan.uuid}?tab=1`);
          } else {
            dispatch(setLoan(loanPayment.loan));
            navigate(`/loans/${loanPayment.loan.uuid}?tab=1`);
          }
        }
      }}
      onColumnVisibilityModelChange={(visible) =>
        setColumnVisibility('LoanRepaymentList', visible)
      }
      onExportClick={() => dispatch(loanRepaymentsExport())}
      page={serverPage - 1}
      onFilterModelChange={getOnFilterModelChange(
        filters,
        setFilters,
        serverPerPage
      )}
      onSortModelChange={getOnSortModelChange(
        filters,
        setFilters,
        serverPerPage
      )}
      onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
      onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
      initialState={{
        sorting: getInitialSortModel(filters, columns),
        filter: getInitialFilterModel(filters, columns),
        columns: {
          columnVisibilityModel: getColumnVisibility('LoanRepaymentList', {
            type: false,
            'l.loanAmount': false,
            'l.amountDue': false,
            't.mobile': false,
            't.clientName': false,
          }),
        },
      }}
    />
  );
};

export const LoanRepaymentList = ({ embedded }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [type, setType] = useState('pending,orphaned');
  const [open, setOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const dispatch = useDispatch();
  const [filters, setFilters] = useFilters();
  const { loanRepayments, isLoading, lastFilter, error } = useSelector(
    (state) => {
      return state.loanRepaymentList;
    }
  );

  const [loanRepaymentUuid, setLoanRepaymentUuid] = useState(null);

  const handleChange = (e, tabIndex) => {
    setCurrentTab(tabIndex);
    switch (tabIndex) {
      case 0:
        setType('pending,orphaned');
        break;
      case 1:
        setType('reconciled,rejected');
        break;
      case 2:
        setType(null);
        break;
    }
    captureEvent('TAB_CHANGE_LOAN_REPAYMENT_LIST', {
      index: tabIndex,
      tab: e?.target?.innerText || '',
    });
  };

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'loanUuid',
      'a.uuid',
      'f.uuid',
    ]);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-date');
    }
    if (type) {
      currentFilters.set('status', `isAnyOf:${type}`);
    } else {
      currentFilters.delete('status');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      // console.log('RXD:LoanPaymentList.fetching');
      dispatch(loanRepaymentsFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [
    dispatch,
    filters,
    isLoading,
    lastFilter,
    setFilters,
    error,
    embedded,
    type,
  ]);

  const handleClose = () => setOpen(false);
  // const handleOpen = () => setOpen(true);
  const handleCloseEditDialog = () => setOpenEditDialog(false);
  const handleOpenEditDialog = () => setOpenEditDialog(true);

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            <>
              {/* <Button disableElevation variant="contained" onClick={handleOpen}>
                Add Repayment
              </Button> */}

              <NavLink to="/loans/repayments/bulk">
                <Button
                  disableElevation
                  variant="contained"
                  sx={{ marginLeft: '10px' }}
                >
                  Add Bulk Repayment
                </Button>
              </NavLink>
            </>
          }
        >
          Loan Repayments
        </AppBar>
      )}

      {/* repayment dialog */}
      <AddLoanRepayment handleClose={handleClose} open={open} />

      {/* edit repayment dialog */}
      <EditLoanRepaymentDialog
        handleClose={handleCloseEditDialog}
        open={openEditDialog}
        loanRepaymentUuid={loanRepaymentUuid}
      />

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(loanRepaymentsFetch(filters))}
        />
      )}

      <Box
        marginBottom={2}
        bgcolor={'white'}
        borderRadius={2}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs value={currentTab} onChange={handleChange}>
          <Tab label="Unresolved" {...a11yProps(0)} />
          <Tab label="Resolved" {...a11yProps(1)} />
          <Tab label="All Repayments" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <Box>
        <TabPanel value={currentTab} index={0} marginBottom={2}>
          <RepaymentList
            type="pending,orphaned"
            loanRepayments={loanRepayments}
            setLoanRepaymentUuid={setLoanRepaymentUuid}
            handleOpenEditDialog={handleOpenEditDialog}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1} marginBottom={2}>
          <RepaymentList
            loanRepayments={loanRepayments}
            type="reconciled,rejected"
            setLoanRepaymentUuid={setLoanRepaymentUuid}
            handleOpenEditDialog={handleOpenEditDialog}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={2} marginBottom={2}>
          <RepaymentList
            type={null}
            loanRepayments={loanRepayments}
            setLoanRepaymentUuid={setLoanRepaymentUuid}
            handleOpenEditDialog={handleOpenEditDialog}
          />
        </TabPanel>
      </Box>
    </>
  );
};
