import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {
  clearState,
  fetchBanks,
  fieldAgentsFetch,
  removeUploadFile,
  setLoadingFieldAgents,
  setLoadingBanks,
  submitForm,
  uploadAttachmentsFile,
  setLoadingAgents,
  agentsSearch,
  setLoadingSimAccounts,
  fetchSimAccounts,
  setError,
} from '@/float-cash/cashDepositFormSlice';
import { debounce, Error, Loading } from '@/common';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import { captureEvent } from '@/firebase';
import { FormLabel, Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FEATURES, hasFeature } from '@/common/features';

const hasAgentDepositDialogFeature = () => {
  return hasFeature(FEATURES.AGENT_DEPOSIT_DIALOG);
};

export const AgentSubSection = (props) => {
  const dispatch = useDispatch();
  const { agent, simAccount, handleAgentSelect, handleSimAccountSelect } =
    props;
  const [agentNameInputValue, setAgentNameInputValue] = useState('');
  const { agents, isLoadingAgents, isLoadingSimAccounts, simAccounts } =
    useSelector((state) => {
      return state.cashDepositForm;
    });
  const handleSearch = useCallback(
    debounce((value) => dispatch(agentsSearch(value)), 1000),
    []
  );
  useEffect(() => {
    dispatch(setLoadingAgents(true));
    // noinspection JSCheckFunctionSignatures
    handleSearch(agentNameInputValue);
  }, [dispatch, handleSearch, agentNameInputValue]);

  useEffect(() => {
    if (agent) {
      handleFetchSimAccounts(new URLSearchParams(`agentUuid=${agent.uuid}`));
    }
    handleSimAccountSelect(null);
  }, [agent]);

  const handleFetchSimAccounts = (filters) => {
    if (agent) {
      dispatch(setLoadingSimAccounts(true));
      return dispatch(fetchSimAccounts(filters));
    }
  };
  return (
    <>
      <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
        <Autocomplete
          getOptionLabel={(agent) =>
            typeof agent == 'string' ? agent : agent?.agentName
          }
          filterOptions={(x) => x}
          options={agents}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={agent}
          isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
          onChange={(event, newValue) => {
            handleAgentSelect(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setAgentNameInputValue(newInputValue);
          }}
          renderOption={(props, agent) => {
            return (
              <li {...props} key={agent.uuid}>
                {agent.agentName} -{' '}
                {agent.simNumbers?.map((v) => v.phoneNumber).join(',')}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Agent"
              required
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {isLoadingAgents ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </FormControl>

      {agent && (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Autocomplete
            getOptionLabel={(simAccount) => simAccount?.phoneNumber}
            filterOptions={(x) => x}
            options={simAccounts}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={simAccount}
            isOptionEqualToValue={(option, value) =>
              option.phoneNumber === value.phoneNumber
            }
            onChange={(event, newValue) => {
              handleSimAccountSelect(newValue);
            }}
            renderOption={(props, simAccount) => {
              return (
                <li {...props} key={simAccount.uuid}>
                  {simAccount.phoneNumber}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Account"
                required
                InputProps={{
                  ...params.InputProps,
                  readOnly: true,
                  endAdornment: (
                    <Fragment>
                      {isLoadingSimAccounts ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
      )}
    </>
  );
};

export const FieldAgentSubSection = (props) => {
  const dispatch = useDispatch();
  const { fieldAgent, handleFieldAgentSelect } = props;
  const [tdrNameInputValue, setTdrNameInputValue] = useState('');
  const { fieldAgents, isLoadingFieldAgents } = useSelector((state) => {
    return state.cashDepositForm;
  });
  const fieldTeamLabel = hasAgentDepositDialogFeature() ? 'Field Agent' : 'TDR';
  const handleTDRSearch = useCallback(
    debounce(
      (value) =>
        dispatch(
          fieldAgentsFetch(
            new URLSearchParams(`name=contains:${value}&oo=name&e=1&pp=1:50`)
          )
        ),
      1000
    ),
    []
  );
  useEffect(() => {
    dispatch(setLoadingFieldAgents(true));
    // noinspection JSCheckFunctionSignatures
    handleTDRSearch(tdrNameInputValue);
  }, [handleTDRSearch, tdrNameInputValue]);

  return (
    <>
      <FormControl fullWidth sx={{ mt: 3 }}>
        <Autocomplete
          getOptionLabel={(fieldAgent) =>
            typeof fieldAgent == 'string' ? fieldAgent : fieldAgent?.name
          }
          filterOptions={(x) => x}
          options={fieldAgents}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={fieldAgent}
          isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
          onChange={(event, newValue) => {
            handleFieldAgentSelect(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setTdrNameInputValue(newInputValue);
          }}
          renderOption={(props, fieldAgent) => {
            return (
              <li {...props} key={fieldAgent.uuid}>
                {fieldAgent.name}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={fieldTeamLabel}
              placeholder="Search TDRs by name"
              required
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {isLoadingFieldAgents ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </>
  );
};

export const CashDepositForm = (props) => {
  const { open, handleClose } = props;
  const currencies = [import.meta.env.VITE_CURRENCY_CODE];
  if (hasAgentDepositDialogFeature()) {
    currencies.push('USD');
  }
  const dispatch = useDispatch();
  let initialFormState = {
    transactionType: 'DEPOSIT',
    currency: currencies[0],
    fromAccountIdType: '',
    fromAccountId: '',
    toAccountId: '',
    amount: 0,
    extraInfo: {
      source: 'dashboard',
      rawTransactionId: null,
    },
    comments: [],
    attachments: [],
  };
  const [formData, setFormData] = useState(initialFormState);
  const [agentType, setAgentType] = useState(
    hasAgentDepositDialogFeature() ? 'Agent' : 'FieldAgent'
  );
  const [fieldAgent, setFieldAgent] = useState(null);
  const [agent, setAgent] = useState(null);
  const [bankUuid, setBankUuid] = useState('');
  const [comment, setComment] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [simAccount, setSimAccount] = useState(null);
  const fileInputRef = useRef(null);
  const [formError, setFormError] = useState('');
  const formRef = useRef(null);
  const {
    banks,
    isLoadingBanks,
    isSubmitting,
    error,
    attachmentUrl,
    closeDepositFormModal,
  } = useSelector((state) => {
    return state.cashDepositForm;
  });

  useEffect(() => {
    if (open) {
      dispatch(fieldAgentsFetch());
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (!selectedFile) return;
    const fileFormData = new FormData();
    fileFormData.append('file', selectedFile);
    dispatch(uploadAttachmentsFile(fileFormData));
  }, [selectedFile]);

  useEffect(() => {
    if (closeDepositFormModal === true) {
      handleClose();
    }
  }, [closeDepositFormModal]);

  useEffect(() => {
    if (open === false) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    dispatch(
      fetchBanks(
        new URLSearchParams(
          `currency=${formData.currency}&oo=bankName&e=1&pp=1:50`
        )
      )
    );
  }, [dispatch, formData.currency]);

  const handleOnChange = (title) => (e) => {
    let value = e.target.value;
    if (title === 'comment') {
      setComment(value);
    } else if (title === 'rawTransactionId') {
      const extraInfo = formData.extraInfo || {};
      extraInfo.rawTransactionId = value;
      setFormData({ ...formData, [extraInfo]: value });
    } else {
      setFormData({ ...formData, [title]: value });
    }
    if (title === 'currency') {
      setBankUuid('');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target?.files[0];
    setSelectedFile(file);
  };

  const handleFieldAgentSelect = (fieldAgent) => {
    setFieldAgent(fieldAgent);
  };

  const handleAgentSelect = (agent) => {
    setAgent(agent);
  };

  const getBankByUuid = (uuid) => {
    return banks?.find((bank) => bank.uuid === uuid);
  };

  const handleRemoveAttachment = () => {
    dispatch(removeUploadFile());
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      handleFileChange({ target: { files: [] } });
    }
  };

  const handleSimAccountSelect = (simAccount) => {
    setSimAccount(simAccount);
  };

  const handleValidation = (event) => {
    if (
      !attachmentUrl ||
      (fileInputRef.current && !fileInputRef.current.files.length)
    ) {
      event.preventDefault();
      handleSetFormError('Please upload a deposit slip.');
      return false;
    }
    setFormError('');
    return true;
  };

  const handleSetFormError = (error) => {
    setFormError(error);
    if (formRef.current && error.length > 0) {
      formRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleSave = (event) => {
    event.preventDefault();
    if (!handleValidation(event)) return;
    formData.fromAccountIdType = agentType;
    formData.fromAccountId =
      agentType === 'Agent' ? agent?.uuid : fieldAgent?.uuid;
    formData.toAccountId = bankUuid;
    formData.amount = Number(formData.amount);
    formData.attachments = [attachmentUrl];
    formData.extraInfo = Object.assign(formData.extraInfo, {
      submittedBy: fieldAgent,
    });
    if ((comment?.length || 0) > 0) formData.comments = [comment];
    if (simAccount && agentType === 'Agent') {
      const account = getAgentSimAccountByCurrency(formData.currency);
      if (!account) {
        handleSetFormError(
          `The selected account does not support the selected currency ${formData.currency}`
        );
        return;
      }
      Object.assign(formData.extraInfo, {
        simNumberUuid: account?.uuid,
        agentPhoneNumber: simAccount.phoneNumber,
      });
    }
    dispatch(submitForm(formData));

    captureEvent('CASH_DEPOSIT_FORM_SAVE', {});
  };
  const resetForm = () => {
    dispatch(clearState());
    setFieldAgent(null);
    setSelectedFile(null);
    setBankUuid('');
    setComment('');
    setFormError('');
    setAgentType(hasAgentDepositDialogFeature() ? 'Agent' : 'FieldAgent');
    initialFormState.fromAccountIdType = '';
    initialFormState.fromAccountId = '';
    initialFormState.toAccountId = '';
    initialFormState.amount = 0;
    initialFormState.currency = currencies[0];
    initialFormState.extraInfo = {
      source: 'dashboard',
    };
    initialFormState.comments = [];
    initialFormState.attachments = [];
  };

  const getAgentSimAccountByCurrency = (currency) => {
    if (!simAccount?.accounts) return undefined;
    return simAccount.accounts.find((account) => account.currency === currency);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add cash deposit</DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSave}
            id="cash-deposit-form"
            onInput={() => setFormError('')}
            ref={formRef}
          >
            {(error || formError) && (
              <Error
                error={error || formError}
                onClose={() => {
                  setFormError('');
                  dispatch(setError(''));
                }}
              />
            )}
            {hasAgentDepositDialogFeature() && (
              <FormControl>
                <FormLabel id="agent-types-buttons-group-label"></FormLabel>
                <RadioGroup
                  row
                  defaultValue="Agent"
                  name="agent-types-group"
                  onChange={(e) => setAgentType(e.target.value)}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Agent or shop deposit"
                    value="Agent"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Field team deposit"
                    value="FieldAgent"
                  />
                </RadioGroup>
              </FormControl>
            )}

            {agentType === 'FieldAgent' && (
              <FieldAgentSubSection
                fieldAgent={fieldAgent}
                handleFieldAgentSelect={handleFieldAgentSelect}
                simAccount={simAccount}
                handleSimAccountSelect={handleSimAccountSelect}
              />
            )}
            {agentType === 'Agent' && (
              <AgentSubSection
                agent={agent}
                handleAgentSelect={handleAgentSelect}
                simAccount={simAccount}
                handleSimAccountSelect={handleSimAccountSelect}
              />
            )}

            <input
              type="file"
              id="file-input"
              name="File Input"
              accept="image/png, image/jpeg"
              onChange={(event) => {
                handleFileChange(event);
                setFormError('');
              }}
              hidden
              ref={fileInputRef}
            />

            {attachmentUrl != null ? (
              <Stack
                direction="column"
                flex
                alignItems="center"
                mt={1}
                p={3}
                border={1}
                style={{
                  objectFit: 'cover',
                  borderRadius: 4,
                  color: 'primary',
                }}
              >
                <img
                  src={attachmentUrl}
                  alt=""
                  style={{
                    borderRadius: 4,
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                />
                <Stack direction="row" flex alignItems="center">
                  <Typography>Deposit Slip</Typography>
                  <Button
                    variant="text"
                    onClick={() => handleRemoveAttachment()}
                  >
                    Remove
                  </Button>
                </Stack>
              </Stack>
            ) : (
              <Stack direction="column">
                <Button
                  variant="contained"
                  sx={{ mt: 3, width: '55%' }}
                  onClick={() => document.getElementById('file-input')?.click()}
                >
                  Upload deposit slip*
                </Button>
                <Typography fontWeight="bold">
                  Accepted file formats are jpg and png.
                </Typography>
              </Stack>
            )}

            <TextField
              sx={{ width: '100%', mt: 3 }}
              type="number"
              label="Amount"
              required
              onChange={handleOnChange('amount')}
            />

            {currencies.length > 0 && (
              <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel required>Currency</InputLabel>
                <Select
                  value={formData.currency}
                  onChange={handleOnChange('currency')}
                  displayEmpty
                  variant="outlined"
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel required>Bank</InputLabel>
              <Select
                value={bankUuid}
                onChange={(e) => setBankUuid(e.target.value)}
                displayEmpty
                variant="outlined"
              >
                {banks.map((bank) => (
                  <MenuItem key={bank.uuid} value={bank.uuid}>
                    {bank.bankName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {hasAgentDepositDialogFeature() && (
              <FormControl fullWidth sx={{ mt: 3 }}>
                <TextField
                  sx={{ width: '100%' }}
                  type="text"
                  label="Deposit reference number"
                  required={getBankByUuid(bankUuid)?.accountType === 'bank'}
                  onChange={handleOnChange('rawTransactionId')}
                />
              </FormControl>
            )}

            <TextField
              autoFocus
              margin="dense"
              id="comments"
              label="Comments"
              placeholder="Additional info about transaction"
              type="text"
              fullWidth
              variant="outlined"
              multiline
              minRows={3}
              sx={{ mt: 3 }}
              value={comment}
              required
              onChange={handleOnChange('comment')}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              borderColor: 'gray',
              borderWidth: '1px',
              fontWeight: 'normal',
              color: 'gray',
            }}
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>

          {isSubmitting && <Loading size={20} />}
          {!isSubmitting && (
            <Button
              disableElevation
              variant="contained"
              disabled={isSubmitting}
              form="cash-deposit-form"
              type="submit"
              color="primary"
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
