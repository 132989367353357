import {
  AppBar,
  Error,
  Info as InfoBase,
  Loading,
  numberFormat,
} from '@/common';
import { hasAnyRoles } from '@/auth/helpers';
import Button from '@mui/material/Button';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  dailyReportFetch,
  deleteReport,
} from '@/dailyReport/dailyReportDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, ListItem } from '@mui/material';
import { isNullOrEmpty } from '@/float-cash/util';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import closeIcon from '@/images/icons8_close.png';
import CircularProgress from '@mui/material/CircularProgress';

export const DailyReportDetails = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state) => state.auth);
  const { dailyReport, isLoading, error } = useSelector(
    (state) => state.dailyReport
  );
  const [day, setDay] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    setDay(searchParams.get('day'));
    setPhoneNumber(searchParams.get('phoneNumber'));
  }, [searchParams]);

  useEffect(() => {
    fetchDailyReportDetails();
  }, [dispatch, day, phoneNumber]);

  const fetchDailyReportDetails = () => {
    if (day && phoneNumber) {
      dispatch(dailyReportFetch(day, phoneNumber.trim()));
    }
  };

  const reportIsSameDay = () => {
    if (!dailyReport) return false;
    const today = new Date().toISOString().split('T')[0];
    const reportDay = dailyReport.day;
    return reportDay === today;
  };

  const canDelete = () => {
    return hasAnyRoles(user?.role, 'RETAIL_MANAGER') && reportIsSameDay();
  };
  return (
    <>
      <AppBar
        actions={[
          canDelete() && (
            <Button
              variant="contained"
              onClick={() => setDeleteModalOpen(true)}
            >
              Delete
            </Button>
          ),
        ]}
      >
        Report: {day}
      </AppBar>
      {error && (
        <Error error={error} onRetry={() => fetchDailyReportDetails()} />
      )}

      <DeleteReportModel
        dailyReport={dailyReport}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <DailyReportDetail dailyReport={dailyReport} />
      )}
    </>
  );
};

const DailyReportDetail = (props) => {
  const { dailyReport, elevated } = props;
  const [currencyAccountMap, setCurrencyAccountMap] = useState(new Map());
  useEffect(() => {
    if (dailyReport) {
      setCurrencyAccountMap(
        new Map(
          dailyReport.reports.map((report) => {
            return [report.currency, report];
          })
        )
      );
    }
  }, [dailyReport]);

  const getAccountByCurrency = (currency) => {
    return currencyAccountMap.get(currency);
  };

  const getFirstCurrencyAccount = () => {
    const firstKey = [...currencyAccountMap.keys()][0];
    return firstKey ? currencyAccountMap.get(firstKey) : null;
  };

  const getNetCashFlow = (currency) => {
    const account = getAccountByCurrency(currency);
    if (!account) return 0;
    const openingBalance = +account.openingBalance;
    const closingBalance = +account.closingBalance;
    if (isNaN(openingBalance) || isNaN(closingBalance)) return 0;
    return closingBalance - openingBalance;
  };
  return (
    <>
      {dailyReport ? (
        <Card
          elevation={
            elevated === null || elevated === undefined || elevated === true
              ? 1
              : 0
          }
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {!!dailyReport?.day ? (
                  <Info label="Day:">{dailyReport.day}</Info>
                ) : (
                  <></>
                )}
                {!isNullOrEmpty(dailyReport?.account?.agent?.agentName) && (
                  <Info label="Shop">
                    {dailyReport?.account?.agent?.agentName}
                  </Info>
                )}
                {!isNullOrEmpty(dailyReport?.account?.phoneNumber) && (
                  <Info label="Account">
                    {dailyReport?.account?.phoneNumber}
                  </Info>
                )}
                <Info label="Opening cash balance">
                  <List>
                    {Array.from(currencyAccountMap.keys()).map((currency) => {
                      return (
                        <ListItem key={currency} sx={{ p: 0 }}>
                          {currency}{' '}
                          {numberFormat(
                            getAccountByCurrency(currency)?.openingBalance
                          )}
                        </ListItem>
                      );
                    })}
                  </List>
                </Info>
                <Info label="Closing cash balance">
                  <List>
                    {Array.from(currencyAccountMap.keys()).map((currency) => {
                      return (
                        <ListItem key={currency} sx={{ p: 0 }}>
                          {currency}{' '}
                          {numberFormat(
                            getAccountByCurrency(currency)?.closingBalance
                          )}
                        </ListItem>
                      );
                    })}
                  </List>
                </Info>
                <Info label="Net cash flow">
                  <List>
                    {Array.from(currencyAccountMap.keys()).map((currency) => {
                      return (
                        <ListItem key={currency} sx={{ p: 0 }}>
                          {currency} {numberFormat(getNetCashFlow(currency))}
                        </ListItem>
                      );
                    })}
                  </List>
                </Info>
                {!isNullOrEmpty(getFirstCurrencyAccount()?.exchangeRate) && (
                  <Info label="USD to CDF Exchange Rate">
                    {getFirstCurrencyAccount()?.exchangeRate}
                  </Info>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

const Info = ({ label, children, border }) => (
  <InfoBase sx={{ py: 2 }} label={label} border={border ?? true}>
    {children}
  </InfoBase>
);

const DeleteReportModel = (props) => {
  const { dailyReport, deleteModalOpen, setDeleteModalOpen } = props;
  const dispatch = useDispatch();
  const { isDeleting, deleteError } = useSelector((state) => state.dailyReport);

  const getReportIds = () => {
    return (dailyReport.reports || []).map((report) => report.uuid);
  };

  return (
    <Dialog open={deleteModalOpen}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        {deleteError && (
          <Error
            error={deleteError}
            onRetry={() => dispatch(deleteReport(getReportIds()))}
          />
        )}
        <Stack direction="row" flex justifyContent="end">
          <Box>
            <Button variant="text" onClick={() => setDeleteModalOpen(false)}>
              <img src={closeIcon} alt="" width="30px" height="30px" />
            </Button>
          </Box>
        </Stack>
        <Stack flex justifyContent="center" alignItems="center" p={12}>
          <Typography align="center">
            Are you sure you wish to delete report for day {dailyReport?.day}?
          </Typography>

          <Button
            sx={{ mt: 8, width: '50%' }}
            variant="contained"
            color="error"
            disabled={isDeleting}
            onClick={() => dispatch(deleteReport(getReportIds()))}
          >
            <span>Delete</span>{' '}
            {isDeleting ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <></>
            )}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
