import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  loan: null,
  isLoading: false,
  error: '',
};

const loanSlice = createSlice({
  name: 'loan',
  initialState: initialState,
  reducers: {
    setLoan: (state, { payload }) => {
      state.loan = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.loan = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const loanFetch = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/loans/${uuid}`)
    .then((response) => dispatch(setLoan(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};
export const loanFetchById = (loanId) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/loans?loanId=${loanId}&operatorValue=contains`)
    .then((response) => dispatch(setLoan(response?.data[0])))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const loanUpdate = (loan) => (dispatch) => {
  dispatch(setLoading(true));
  const { uuid, ...payload } = loan;
  getApi()
    .patch(`/api/loans/payments/${uuid}`, payload)
    .then((response) => dispatch(setLoan(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setLoan, resetState, setLoading, setError } = loanSlice.actions;

export const loanReducer = loanSlice.reducer;
