import { agentFetch } from '@/agent';
import {
  AppBar,
  Bubble,
  Info as InfoBase,
  Loading,
  getApi,
  getApiError,
} from '@/common';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { transactionFetch } from '../transactionSlice';
import {
  getExtraTelcoBgColor,
  getExtraTelcoColor,
  getOutboundStatus,
  getOutboundStatusColor,
  getOutboundStatusText,
  getStatus,
  getStatusColor,
  getStatusMeaning,
  getStatusText,
  getTransactionAmount,
  getTransactionExtras,
  getTransactionFee,
  getUpdateTime,
  getUser,
} from '../utils';

export const FloatExchangeDetail = () => {
  // console.log('RXD:FloatExchangeDetail');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { transaction, isLoading } = useSelector((state) => state.transaction);

  const transactionExtras = getTransactionExtras(transaction);
  useEffect(() => {
    if (uuid !== transaction?.uuid && !isLoading) {
      dispatch(transactionFetch(uuid));
    }
  }, [dispatch, transaction, uuid, isLoading]);

  return (
    <>
      <AppBar>Float Exchange: {uuid}</AppBar>
      {!transaction || isLoading ? (
        <Loading />
      ) : (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item md={4} xs={12}>
            <SenderDetail
              transaction={transaction}
              transactionExtras={transactionExtras}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <ReceiverDetail
              transaction={transaction}
              transactionExtras={transactionExtras}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AppDetail
              transaction={transaction}
              transactionExtras={transactionExtras}
            />
            {/* <LocationDetail transaction={transaction} /> */}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const Info = ({ label, children, border }) => (
  <InfoBase sx={{ py: 2 }} label={label} border={border ?? true}>
    {children}
  </InfoBase>
);

const currency = import.meta.env?.VITE_CURRENCY_CODE ?? 'UGX';

const DisbuseDialog = ({
  transaction,
  transactionExtras,
  handleClickClose,
}) => {
  const dispatch = useDispatch();
  /**
   *
   * @param {*} uuid
   * @returns
   */
  const confirmDisbuseMent = (uuid) => () => {
    getApi()
      .put(`/api/transactions/${uuid}`, { statusMeaning: true })
      .then(() => {
        // refetch the transaction to update the ui
        dispatch(transactionFetch(uuid));
      })
      .catch((err) => console.error(getApiError(err)));

    handleClickClose();
  };

  return (
    <Stack direction={'column'} p={4}>
      <Typography
        sx={{ mb: 0, fontWeight: 'bold' }}
        variant="h5"
        component="div"
      >
        Send {currency} {getTransactionAmount(transaction)} to{' '}
        {transactionExtras?.receiverAgentId ?? transactionExtras.receiverPhone}
      </Typography>
      <Info
        label={transactionExtras.receiverAgentId ? 'Agent ID' : 'Agent Number'}
      >
        {transactionExtras?.receiverAgentId ?? transactionExtras.receiverPhone}
      </Info>
      <Info label="Network">
        <Bubble
          label={transactionExtras?.receivingTelco}
          color={getExtraTelcoColor(transactionExtras?.sendingTelco)}
          background={getExtraTelcoBgColor(transactionExtras?.sendingTelco)}
          sx={{ mt: 1 }}
        />
      </Info>
      {/* <Info label="Agent ID">{transactionExtras?.receiverAgentId}</Info> */}
      <Info label="Amount">
        {currency} {getTransactionAmount(transaction)}
      </Info>
      <Button
        variant="contained"
        type="submit"
        sx={{ mt: 3 }}
        onClick={confirmDisbuseMent(transaction.uuid)}
      >
        Confirm Disbursement
      </Button>
    </Stack>
  );
};

const AppDetail = ({ transaction, transactionExtras }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);

  return (
    <Card>
      <CardContent>
        <Typography sx={{ mb: 0 }} variant="h5" component="div">
          Other Details
        </Typography>

        <Info label="Initiated At">{getUpdateTime(transaction)}</Info>
        <Info label="Amount">
          {currency} {getTransactionAmount(transaction)}
        </Info>
        <Info label="Fees">
          {currency} {getTransactionFee(transaction)}
        </Info>
        <Info label="Inbound Status">
          <Bubble
            label={getStatus(transaction)}
            color={getStatusText(transaction)}
            background={getStatusColor(transaction)}
            sx={{ mt: 1 }}
          />
        </Info>

        <Accordion sx={{ mb: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>USSD Session</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box component="pre">{transaction.ussdMessages}</Box>
          </AccordionDetails>
        </Accordion>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" component="div">
            Outbound Status
          </Typography>
          <Bubble
            label={getOutboundStatus(transaction)}
            color={getOutboundStatusText(transaction)}
            background={getOutboundStatusColor(transaction)}
            sx={{ mt: 1 }}
          />
        </Box>
        {/* <Info label="Outbound Status">
          <Bubble
            label={getOutboundStatus(transaction)}
            color={getOutboundStatusText(transaction)}
            background={getOutboundStatusColor(transaction)}
            sx={{ mt: 1 }}
          />
        </Info> */}

        <Box
          sx={{
            mt: 2,
            display: getStatusMeaning(transaction) ? 'none' : 'block',
          }}
        >
          <Button
            onClick={handleClickOpen}
            variant="contained"
            sx={{
              width: '100%',
            }}
          >
            Disburse
          </Button>

          <SwipeableDrawer
            anchor={'bottom'}
            open={open}
            sx={{
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: 'fit-content',
                bottom: '25%',
                marginInline: 'auto',
                borderRadius: '20px',
              },
            }}
            onOpen={handleClickOpen}
            onClose={handleClickClose}
          >
            <DisbuseDialog
              transaction={transaction}
              transactionExtras={transactionExtras}
              handleClickClose={handleClickClose}
            />
          </SwipeableDrawer>
        </Box>
      </CardContent>
    </Card>
  );
};

const ReceiverDetail = ({ transaction, transactionExtras }) => {
  const dispatch = useDispatch();
  const { agent, isLoading, error } = useSelector((state) => state.agent);

  useEffect(() => {
    if (!isLoading && !error && transaction?.user?.agentUuid !== agent?.uuid) {
      dispatch(agentFetch(transaction?.user?.agentUuid));
    }
  }, [dispatch, transaction, agent, isLoading, error]);

  return (
    <Card>
      <CardContent>
        <Typography sx={{ mb: 0 }} variant="h5" component="div">
          Receiver Details
        </Typography>
        {isLoading ? (
          <Loading />
        ) : !agent || agent?.uuid !== transaction?.user?.agentUuid ? (
          <Alert severity="warning">No agent found</Alert>
        ) : (
          <>
            <Info label="Network">
              <Bubble
                label={transactionExtras?.receivingTelco}
                color={getExtraTelcoColor(transactionExtras?.sendingTelco)}
                background={getExtraTelcoBgColor(
                  transactionExtras?.sendingTelco
                )}
                sx={{ mt: 1 }}
              />
            </Info>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" component="div">
                {transactionExtras.receiverAgentId
                  ? 'Agent ID'
                  : 'Agent Number'}
              </Typography>
              <Typography variant="body1" component="div">
                {transactionExtras?.receiverAgentId ??
                  transactionExtras.receiverPhone}
              </Typography>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

const SenderDetail = ({ transaction, transactionExtras }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agent, isLoading, error } = useSelector((state) => state.agent);

  useEffect(() => {
    if (!isLoading && !error && transaction?.user?.agentUuid !== agent?.uuid) {
      dispatch(agentFetch(transaction?.user?.agentUuid));
    }
  }, [dispatch, transaction, agent, isLoading, error]);

  const handleClick = () => {
    if (agent) {
      navigate(`/agents/${agent.uuid}`);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography sx={{ mb: 0 }} variant="h5" component="div">
          Sender Details
        </Typography>
        {isLoading ? (
          <Loading />
        ) : !agent || agent?.uuid !== transaction?.user?.agentUuid ? (
          <Alert severity="warning">No agent found</Alert>
        ) : (
          <>
            <Info label="Network">
              <Bubble
                label={transactionExtras?.sendingTelco}
                color={getExtraTelcoColor(transactionExtras?.receivingTelco)}
                background={getExtraTelcoBgColor(
                  transactionExtras?.receivingTelco
                )}
                sx={{ mt: 1 }}
              />
            </Info>
            <Info label="Agent Number">{getUser(transaction)}</Info>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" component="div">
                Agent Name
              </Typography>
              <Typography variant="body1" component="div">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleClick}
                >
                  {agent.agentName}
                </Link>
              </Typography>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};
