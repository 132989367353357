import {
  AppBar,
  Bubble,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loanListExport, loanListFetch } from './loanListSlice';
import { setLoan } from './loanSlice';
import {
  getAgent,
  getAmount,
  getAmountDue,
  getAmountOutstanding,
  getAmountPaid,
  getDuration,
  getEndDate,
  getInterest,
  getStartDate,
  getStatusBackground,
  getStatusColor,
  getStatuses,
} from './utils';

export const LoanList = ({ embedded }) => {
  // console.log('RXD:LoanList');
  const dispatch = useDispatch();
  const { loanList, isLoading, error, lastFilter } = useSelector((state) => {
    return state.loanList;
  });

  const navigate = useNavigate();
  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'l.uuid',
      'a.uuid',
      'a.fieldAgentUuid',
    ]);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-loanId');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      // console.log('RXD:LoanList.fetching');
      dispatch(loanListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  return (
    <>
      {!embedded && <AppBar>Loan Disbursements</AppBar>}

      {error && (
        <Error error={error} onRetry={() => dispatch(loanListFetch(filters))} />
      )}

      <div style={{ height: 'calc(100vh - 190px)', minHeight: 300 }}>
        <OparetaTable
          getRowId={(loan) => loan.uuid}
          rows={loanList ?? []}
          columns={[
            {
              field: 'loanId',
              headerName: 'Loan ID',
              minWidth: 75,
              sortingOrder: getSortOrder(),
            },
            {
              field: 'a.agentName',
              headerName: 'Agent',
              minWidth: 300,
              align: 'left',
              headerAlign: 'left',
              sortingOrder: getSortOrder(),
              valueGetter: w(getAgent),
            },
            {
              field: 'loanAmount',
              headerName: 'Principal',
              minWidth: 120,
              type: 'number',
              align: 'left',
              headerAlign: 'left',
              sortingOrder: getSortOrder(),
              valueGetter: w(getAmount),
            },
            {
              field: 'monthlyInterest',
              headerName: 'Interest',
              minWidth: 120,
              headerAlign: 'left',
              align: 'left',
              sortingOrder: getSortOrder(),
              valueGetter: w(getInterest),
            },
            {
              field: 'amountPaid',
              headerName: 'Paid',
              minWidth: 120,
              type: 'number',
              align: 'left',
              headerAlign: 'left',
              sortingOrder: getSortOrder(),
              valueGetter: w(getAmountPaid),
            },
            {
              field: 'amountOutstanding',
              headerName: 'Outstanding',
              minWidth: 120,
              type: 'number',
              align: 'left',
              headerAlign: 'left',
              sortingOrder: getSortOrder(),
              valueGetter: w(getAmountOutstanding),
            },
            {
              field: 'status',
              headerName: 'Status',
              minWidth: 100,
              headerAlign: 'left',
              align: 'left',
              sortingOrder: getSortOrder(),
              type: 'singleSelect',
              valueOptions: getStatuses().map((type) => ({
                value: type,
                label: type,
              })),
              renderCell: w((loan) => (
                <Bubble
                  label={loan?.status}
                  color={getStatusColor(loan)}
                  background={getStatusBackground(loan)}
                />
              )),
            },

            {
              field: 'startDate',
              headerName: 'Start Date',
              align: 'left',
              headerAlign: 'left',
              minWidth: 120,
              type: 'dateTime',
              sortingOrder: getSortOrder(),
              valueGetter: w(getStartDate),
            },
            {
              field: 'endDate',
              headerName: 'End Date',
              align: 'left',
              headerAlign: 'left',
              minWidth: 120,
              type: 'dateTime',
              sortingOrder: getSortOrder(),
              valueGetter: w(getEndDate),
            },
            {
              field: 'durationInMonths',
              headerName: 'Duration',
              minWidth: 100,
              align: 'left',
              headerAlign: 'left',
              type: 'number',
              sortingOrder: getSortOrder(),
              valueGetter: w(getDuration),
            },
            {
              field: 'amountDue',
              headerName: 'Total',
              minWidth: 150,
              type: 'number',
              align: 'center',
              headerAlign: 'center',
              sortingOrder: getSortOrder(),
              valueGetter: w(getAmountDue),
            },
          ]}
          onExportClick={() => dispatch(loanListExport())}
          onCellClick={w((loan) => {
            dispatch(setLoan(loan));
            navigate(`/loans/${loan.uuid}`);
          })}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('LoanList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            columns: {
              columnVisibilityModel: getColumnVisibility('LoanList', {
                clientName: false,
                durationInMonths: false,
                amountDue: false,
              }),
            },
          }}
        />
      </div>
    </>
  );
};
