import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  status: 'editing',
  error: '',
  result: null,
};

const transactionFormSlice = createSlice({
  name: 'transactionForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
      state.result = null;
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
      state.result = null;
    },
    setResult: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
      state.result = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
      state.result = null;
    },
  },
});

export const transactionExecute = (type, data) => (dispatch) => {
  dispatch(setLoading());

  const action = getApi().post(`/api/transactions/${type}`, data);

  action
    .then((response) => dispatch(setResult(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { resetState, setLoading, setResult, setError } =
  transactionFormSlice.actions;

export const transactionFormReducer = transactionFormSlice.reducer;
