import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  transactionList: [],
  isLoading: false,
  lastFilter: null,
  error: '',
};

const transactionListSlice = createSlice({
  name: 'transactionList',
  initialState: initialState,
  reducers: {
    setTransactions: (state, { payload }) => {
      const { lastFilter, transactions } = payload;

      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.transactionList = transactions;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

const convertOperator = (operator) => {
  switch (operator) {
    case 'not':
      return '!=';
    case 'after':
      return '>';
    case 'onOrAfter':
      return '>=';
    case 'before':
      return '<';
    case 'onOrBefore':
      return '<=';
    case 'isEmpty':
      return 'isEmpty';
    case 'isNotEmpty':
      return 'isNotEmpty';
    case 'between':
      return 'between';
    case 'is':
    default:
      return '<=';
  }
};

/**
 * @description handles converting range filter
 * @param {string} parts
 * @param {string} filters
 */
const convertRange = (parts, filters, key) => {
  const [from, to] = parts[1].split(',');
  const epochFrom = new Date(from).getTime();
  const epochTo = new Date(to).getTime();
  const operator = convertOperator(parts[0]);
  if (!isNaN(epochFrom) && !isNaN(epochTo)) {
    filters.set(key, `${operator}:${epochFrom},${epochTo}`);
  }
};
/**
 *
 * @param {URLSearchParams} filters
 * @param {string[]} keys
 */
const convertDates = (filters, keys) => {
  keys.forEach((key) => {
    if (filters.has(key)) {
      const value = filters.get(key);
      const parts = value.split(':');
      if (parts.length === 2) {
        if (parts[1].split(',').length === 2) {
          convertRange(parts, filters, key);
        } else {
          const epoch = new Date(parts[1]).getTime();
          if (!isNaN(epoch)) {
            const operator = convertOperator(parts[0]);
            filters.set(key, `${operator}:${epoch}`);
          }
        }
      }
    }
  });
  return filters;
};

export const transactionListFetchUsingApi = (filters) =>
  getApi().get('/api/transactions', {
    params: convertDates(filters, ['requestTimestamp', 'updateTimestamp']),
  });

export const transactionListFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  transactionListFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setTransactions({
          lastFilter: filters?.toString(),
          transactions: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setError, setLoading, setTransactions } =
  transactionListSlice.actions;

export const transactionListReducer = transactionListSlice.reducer;
