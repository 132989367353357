import { Error, Loading } from '@/common';
import { embedDashboard } from '@/superset';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RISON } from 'rison2';
import { getApi } from './api';
import { captureEvent } from '@/firebase';

export const EmbeddedChart = ({ theKey, height, filters }) => {
  const { configuration, loading } = useSelector(
    (state) => state.dashboardConfig
  );
  const conf = useMemo(() => {
    if (!loading) {
      for (const conf of configuration) {
        if (conf.key === theKey) {
          captureEvent('DASHBOARD_REPORT', {
            key: theKey,
            embedId: conf?.value?.id,
            title: conf?.label,
          });
          return conf;
        }
      }
      captureEvent('DASHBOARD_REPORT', {
        key: theKey,
        embedId: null,
        title: null,
      });
    }
  }, [theKey, configuration, loading]);

  // console.log({ theKey, height, filters, conf, configuration });

  if (loading) {
    return <Loading />;
  }

  if (!conf?.value?.id) {
    return <NotConfigured conf={conf} />;
  }

  if (conf.type === 'iframe') {
    return <IFrameChart conf={conf} height={height} />;
  }

  if (conf.type === 'tableau') {
    return <TableauChart conf={conf} height={height} />;
  }
  return <SupersetChart conf={conf} height={height} filters={filters} />;
};

const SupersetChart = ({ conf, height, filters }) => {
  const containerRef = useRef(null);
  const [id, setId] = useState(conf?.value?.id);
  const [dashboard, setDashboard] = useState();
  const [iframeHeight, setIframeHeight] = useState(height);
  filters = filters ?? {};

  useEffect(() => {
    if (!conf) {
      //stops if dashboard is not configured
      return;
    }

    const confId = conf?.value?.id;
    const hideChartControls =
      'showToolbar' in conf?.value ? !conf.value.showToolbar : false;

    if (dashboard && confId === id) {
      // stop if we have already made embedDashboard() for this confId
      // id stores dashboard we have made embedDashboard() for
      return;
    }

    const fetchGuestTokenFromBackend = async () => {
      try {
        const response = await getApi().get(
          `/api/dashboard/${conf.uuid}/token`
        );
        return response.data ?? '';
      } catch (err) {
        console.error({ err });
      }
      return '';
    };

    filters.visible = !hideChartControls;
    filters.expanded = false;
    if ('f' in filters && typeof filters.f == 'object') {
      const f = filters.f;
      const value = (Array.isArray(f.value) ? f.value : [f.value]).map(
        (v) => `${v}`
      );
      const label = value.join('').replace(/\W+/g, '');

      const template = {};
      template[`NATIVE_FILTER-${f.id}`] = {
        id: `NATIVE_FILTER-${f.id}`,
        __cache: {
          label,
          validateStatus: false,
          value,
        },
        extraFormData: {
          filters: [
            {
              col: f.column,
              op: 'IN',
              val: value,
            },
          ],
        },
        filterState: {
          label,
          validateStatus: false,
          value,
        },
        ownState: {},
      };

      filters.native_filters = RISON.stringify(template);
      delete filters.f;
    }

    embedDashboard({
      id: confId,
      supersetDomain: import.meta.env.VITE_SUPERSET_URL,
      mountPoint: containerRef.current,
      fetchGuestToken: fetchGuestTokenFromBackend,
      dashboardUiConfig: {
        hideChartControls,
        hideTitle: true,
        hideTab: true,
        filters,
      },
    }).then((dashboard) => {
      setDashboard(dashboard);
      setId(confId);
    });
  }, [filters, conf, id, dashboard]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (dashboard) {
        const { height } = await dashboard.getScrollSize();
        setIframeHeight(height);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [dashboard]);

  return (
    <Box
      ref={containerRef}
      sx={{
        '& iframe': {
          width: '100%',
          height: iframeHeight ?? 'calc(100vh)',
          border: 'none',
          minHeight: '100px',
        },
      }}
    />
  );
};

const TableauChart = ({ conf, height }) => {
  useEffect(() => {
    if (window && document) {
      const script = document.createElement('script');
      const body = document.getElementsByTagName('body')[0];
      script.type = 'module';
      script.src =
        'https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js';
      body.appendChild(script);
    }
  }, []);

  return (
    <Box
      sx={{
        paddingBottom: 2,
        display: 'flex',
        justifyContent: 'center',
        '& iframe': {
          width: '100%',
          height: height ?? 'calc(300vh)',
          border: 'none',
          minHeight: '400px',
        },
      }}
    >
      <tableau-viz
        id="tableauViz"
        src={conf?.value?.id}
        device="phone"
        toolbar="bottom"
        hide-tabs
      ></tableau-viz>
    </Box>
  );
};

const IFrameChart = ({ conf, height }) => {
  return (
    <Box
      sx={{
        paddingBottom: 2,
        display: 'flex',
        justifyContent: 'center',
        '& iframe': {
          width: '100%',
          height: height ?? 'calc(100vh)',
          border: 'none',
          minHeight: '400px',
        },
      }}
    >
      <iframe src={conf?.value?.id} />
    </Box>
  );
};

const NotConfigured = ({ conf }) => (
  <Card>
    <Error
      error={`Report '${conf?.label ?? '-'}' is not published. Please ask data administrator for assistance`}
    />
  </Card>
);
