import { AppBar } from '@/common';
// import { useDispatch } from 'react-redux';

export const TransactionRequestList = ({ embedded }) => {
  // console.log('RXD:TransactionRequestList');
  // const dispatch = useDispatch();

  return (
    <>
      {!embedded && <AppBar>Transaction Requests</AppBar>}

      <div
        style={{
          display: 'flex',
          height: 'calc(100vh - 190px)',
          minHeight: 300,
        }}
      ></div>
    </>
  );
};
