import {
  AppBar,
  Bubble,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customersFetch } from './customerListSlice';
import { setCustomer } from './customerSlice';
import {
  getCustomerGender,
  getCustomerName,
  getCustomerNumber,
  getCustomerUpdatedAt,
  getKYCAgentName,
  getKYCStatusBgColor,
  getKycStatuses,
  getKYCStatusTextColor,
  getKYCStatusTextLabel,
} from './utils';
//

const KYCStatus = ({ customer }) => (
  <Bubble
    label={getKYCStatusTextLabel(customer)}
    background={getKYCStatusBgColor(customer)}
    color={getKYCStatusTextColor(customer)}
    borderRadius="0"
    fontWeight="light"
  />
);

export const CustomerList = ({ embedded }) => {
  const dispatch = useDispatch();
  const { customerList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.customerList;
    }
  );

  const navigate = useNavigate();

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, 'userUuid');
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', 'updatedAt');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(customersFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      align: 'center',
      type: 'date',
      headerAlign: 'center',
      minWidth: 200,
      flex: 1,
      sortingOrder: getSortOrder(),
      valueGetter: getCustomerUpdatedAt,
    },
    {
      field: 'firstName',
      headerName: 'Customer name',
      minWidth: 100,
      flex: 1,
      sortingOrder: getSortOrder(),
      valueGetter: getCustomerName,
    },
    {
      field: 'phoneNumber',
      headerName: 'Customer number',
      minWidth: 90,
      flex: 1,
      sortingOrder: getSortOrder(),
      valueGetter: getCustomerNumber,
    },
    {
      field: 'kycs.gender',
      headerName: 'Gender',
      align: 'left',
      headerAlign: 'left',
      minWidth: 90,
      flex: 1,
      valueGetter: getCustomerGender,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'kycs.status',
      headerName: 'Compliance Status',
      align: 'center',
      headerAlign: 'center',
      minWidth: 80,
      flex: 0.8,
      sortingOrder: getSortOrder(),
      type: 'singleSelect',
      valueOptions: getKycStatuses().map((status) => ({
        value: status,
        label: status,
      })),
      renderCell: ({ row: customer }) => <KYCStatus customer={customer} />,
    },
    {
      field: 'createdBy.agentName',
      headerName: 'KYC collected by',
      align: 'center',
      headerAlign: 'center',
      minWidth: 190,
      flex: 1,
      sortingOrder: getSortOrder(),
      valueGetter: getKYCAgentName,
    },
  ];

  return (
    <>
      {!embedded && <AppBar>Customers</AppBar>}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(customersFetch(filters))}
        />
      )}

      <div
        style={{
          display: 'flex',
          height: 'calc(100vh - 190px)',
          minHeight: 300,
        }}
      >
        <OparetaTable
          width="100%"
          getRowId={(customer) => customer?.uuid}
          rows={customerList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const customer = param.row;
            if (e.ctrlKey) {
              window.open(`/customers/${customer?.uuid}`);
            } else {
              dispatch(setCustomer(customer));
              navigate(`/customers/${customer?.uuid}`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('CustomerList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns, [
              { field: 'updatedAt', sort: 'asc' },
            ]),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('CustomerList', {}),
            },
          }}
        />
      </div>
    </>
  );
};
