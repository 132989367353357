import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';
import { agentFetch, resetState as resetStateAgent } from './agentSlice';

const initialState = {
  status: 'editing',
  error: '',
};

const agentAssignFormSlice = createSlice({
  name: 'agentAssignForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const agentAssignSave =
  ({ uuid, fieldAgentUuid, comment }) =>
  (dispatch) => {
    dispatch(setLoading(true));

    const data = { fieldAgentUuid, comment };
    getApi()
      .post(`/api/agents/${uuid}/assign`, data)
      .then((response) => {
        dispatch(resetState());
        dispatch(resetStateAgent());
        dispatch(agentFetch(uuid));
        dispatch(push(`/agents/${uuid}`));
      })
      .catch((err) => dispatch(setError(getApiError(err))));
  };

export const { resetState, setLoading, setError } =
  agentAssignFormSlice.actions;

export const agentAssignFormReducer = agentAssignFormSlice.reducer;
