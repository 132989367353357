import { AgentList } from '@/agent';
import { hasAnyRoles, rolesToSet } from '@/auth/helpers';
import {
  AppBar,
  BooleanBubble,
  Error,
  Info as InfoBase,
  Loading,
  TabPanel,
  useFilters,
} from '@/common';
import { a11yProps } from '@/common/TabPanel';
import { FEATURES, hasFeature } from '@/common/features';
import { LoanList } from '@/loan';
import { getRoleDefinition } from '@/user/utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  CardContent,
  Typography,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { FieldAgentList } from '.';
import { FieldAgentTerritoryList } from './FieldAgentTerritoryList';
import { fieldAgentFetch } from './fieldAgentSlice';
import { birthday, createdAt, isPdf } from './utils';
import { ExpenseList } from '@/expense';
import { ForFeature } from '@/common/AuthorizedPage';
import { FieldAgentManagerLogList } from './FieldAgentManagerLogList';
import { EmbeddedChart } from '@/common/EmbeddedChart';
import { captureEvent } from '@/firebase';
import { DASHBOARD_FIELD_AGENT_DETAIL } from '@/dashboardConfiguration/utils';
import Stack from '@mui/material/Stack';
import pdfIcon from '@/images/pdf-icon.png';

export const FieldAgentDetail = () => {
  // console.log('RXD:FieldAgentDetail');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { fieldAgent, isLoading, error } = useSelector(
    (state) => state.fieldAgent
  );
  const isFieldAgent = hasAnyRoles(
    user.role,
    'FIELD_AGENT,FIELD_AGENT_SUPPORT'
  );
  const canSeeLoan = hasAnyRoles(
    user.role,
    'FIELD_AGENT,LOAN_MANAGER,LOAN_REVIEWER,LOAN_VIEWER'
  );
  const canSeeReport = hasAnyRoles(
    user.role,
    'FIELD_AGENT_MANAGER,FIELD_AGENT_VIEWER'
  );

  useEffect(() => {
    if (uuid !== fieldAgent?.uuid && !isLoading && !error) {
      dispatch(fieldAgentFetch(uuid));
    }
  }, [dispatch, fieldAgent, uuid, isLoading, error]);

  const [filters, setFilters] = useFilters();
  const handleTabChange = (event, index) => {
    setFilters(new URLSearchParams(`tab=${index}`));
    captureEvent('TAB_CHANGE_FIELD_AGENT_DETAIL', {
      index,
      tab: event?.target?.innerText || '',
      user,
    });
  };
  let tab = filters.get('tab');
  tab = tab === null ? 0 : +tab;

  const isVisible = false; //TODO: To remove this logic once a decision has been made on whether to completely remove reports
  return (
    <>
      <AppBar
        actions={
          hasAnyRoles(user.role, 'FIELD_AGENT_MANAGER') && (
            <>
              <Button
                disableElevation
                sx={{ textTransform: 'none' }}
                variant="contained"
                border="2px solid"
                size="medium"
                component={RouterLink}
                to={`/field-agents/${uuid}/edit`}
              >
                Edit
              </Button>
              <ForFeature feature={FEATURES.FIELD_AGENT}>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`/field-agents/${uuid}/reassign`}
                >
                  Move Agents
                </Button>
              </ForFeature>
              <Button
                disableElevation
                sx={{ textTransform: 'none' }}
                variant="contained"
                border="2px solid"
                size="medium"
                component={RouterLink}
                to={`/field-agents/${uuid}/team`}
              >
                Manage Team
              </Button>
              <ForFeature feature={FEATURES.FIELD_AGENT_EXPENSE}>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`/field-agents/${uuid}/expenses/add`}
                >
                  Add Expense
                </Button>
              </ForFeature>
            </>
          )
        }
      >
        {`${fieldAgent?.category ?? 'Team Member'}: `}
        {uuid !== fieldAgent?.uuid ? uuid : fieldAgent?.name ?? '-'}
      </AppBar>

      {error ? (
        <Error error={error} />
      ) : !fieldAgent || isLoading ? (
        <Loading />
      ) : (
        <>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            sx={{ background: 'white' }}
            aria-label="field agent detail tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            {hasFeature(FEATURES.FIELD_AGENT) && (
              <Tab label="Overview" value={0} {...a11yProps(0)} />
            )}
            {hasFeature(FEATURES.AGENT) && isFieldAgent && (
              <Tab label="Agents" value={1} {...a11yProps(1)} />
            )}
            {hasFeature(FEATURES.LOAN) && canSeeLoan && (
              <Tab label="Loans" value={2} {...a11yProps(2)} />
            )}
            {hasFeature(FEATURES.FIELD_AGENT_TERRITORY) && (
              <Tab label="Territories" value={3} {...a11yProps(3)} />
            )}
            {hasFeature(FEATURES.FIELD_AGENT) && (
              <Tab label="Team" value={4} {...a11yProps(4)} />
            )}
            {hasFeature(FEATURES.FIELD_AGENT) && (
              <Tab label="Manager History" value={7} {...a11yProps(7)} />
            )}
            {hasFeature(FEATURES.FIELD_AGENT) && (
              <Tab label="Roles" value={5} {...a11yProps(5)} />
            )}
            {hasFeature(FEATURES.FIELD_AGENT_EXPENSE) && canSeeReport && (
              <Tab label="Expenses" value={6} {...a11yProps(6)} />
            )}
            {hasFeature(FEATURES.FIELD_AGENT) && canSeeReport && isVisible && (
              <Tab label="Report" value={8} {...a11yProps(8)} />
            )}
          </Tabs>
          <TabPanel value={tab} index={0}>
            <FieldAgentOverview fieldAgent={fieldAgent} />
          </TabPanel>
          {isFieldAgent && (
            <TabPanel value={tab} index={1}>
              <AgentListTable fieldAgent={fieldAgent} />
            </TabPanel>
          )}
          {canSeeLoan && (
            <TabPanel value={tab} index={2}>
              <LoanListTable fieldAgent={fieldAgent} />
            </TabPanel>
          )}
          <TabPanel value={tab} index={3}>
            <TerritoryListTable fieldAgent={fieldAgent} />
          </TabPanel>
          <TabPanel value={tab} index={4}>
            <TeamListTable fieldAgent={fieldAgent} />
          </TabPanel>
          <TabPanel value={tab} index={7}>
            <ManagerListTable fieldAgent={fieldAgent} />
          </TabPanel>
          <TabPanel value={tab} index={5}>
            <Roles fieldAgent={fieldAgent} />
          </TabPanel>
          {canSeeReport && (
            <TabPanel value={tab} index={6}>
              <ExpenseList
                mode="field-agents"
                embedded={{ 'fa.uuid': fieldAgent.uuid }}
              />
            </TabPanel>
          )}
          {canSeeReport && (
            <TabPanel value={tab} index={8}>
              <Report fieldAgent={fieldAgent} />
            </TabPanel>
          )}
        </>
      )}
    </>
  );
};

const Info = ({ label, children, border }) => (
  <InfoBase sx={{ py: 1 }} label={label} border={border ?? true}>
    {children}
  </InfoBase>
);

const FieldAgentOverview = ({ fieldAgent }) => (
  <Grid container spacing={2} sx={{ mt: 1 }}>
    <Grid item xs={12} md={3}>
      <Stats fieldAgent={fieldAgent} />
      <Users fieldAgent={fieldAgent} />
    </Grid>
    <Grid item xs={12} md={9}>
      <MainDetail fieldAgent={fieldAgent} />
    </Grid>
  </Grid>
);

const MainDetail = ({ fieldAgent }) => (
  <Stack direction="column">
    <MainInfoDetail fieldAgent={fieldAgent} />
    <AttachmentDetail fieldAgent={fieldAgent} />
  </Stack>
);

const AttachmentDetail = ({ fieldAgent }) => {
  const openDocument = (url) => {
    window.open(url, '_new');
  };

  return (
    <Card sx={{ mb: 2, p: 2 }}>
      <Grid container>
        <Grid item xs={12} md={6} direction="column">
          <Typography fontWeight="bold">National ID Front</Typography>
          <Box
            sx={{
              width: '100%',
              height: 180,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundImage: `url(${fieldAgent.nationalIdPhotoUrl ?? ''})`,
            }}
          ></Box>
        </Grid>
        {fieldAgent.nationalIdBackPhotoUrl && (
          <Grid item xs={12} md={6} direction="column">
            <Typography fontWeight="bold">National ID Back</Typography>
            <Box
              sx={{
                width: '100%',
                height: 180,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundImage: `url(${fieldAgent.nationalIdBackPhotoUrl ?? ''})`,
              }}
            ></Box>
          </Grid>
        )}
      </Grid>
      {(fieldAgent.otherKycDocuments || []).length > 0 && (
        <>
          <Typography fontWeight="bold" sx={{ mt: 3 }}>
            Other kyc documents
          </Typography>
          <Grid container>
            {fieldAgent.otherKycDocuments.map((document) => (
              <Grid item xs={12} md={6}>
                {isPdf(document.photoUrl) ? (
                  <Box
                    sx={{
                      cursor: 'pointer',
                      width: '100%',
                      height: 180,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={pdfIcon}
                      width="200px"
                      height="200px"
                      alt={document.name}
                      onClick={(e) => openDocument(document.photoUrl)}
                      style={{ objectFit: 'contain' }}
                    />
                  </Box>
                ) : (
                  <Box
                    onClick={(e) => openDocument(document.photoUrl)}
                    sx={{
                      width: '100%',
                      height: 180,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundImage: `url(${document.photoUrl ?? ''})`,
                    }}
                  ></Box>
                )}
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Card>
  );
};

const MainInfoDetail = ({ fieldAgent }) => (
  <Card sx={{ mb: 2 }}>
    <Grid container sx={{ px: 2, pb: 1 }}>
      <Grid item xs={12} md={3}>
        <Info label="Active" border={false}>
          <BooleanBubble value={fieldAgent?.isActive} />
        </Info>
      </Grid>
      <Grid item xs={12} md={3}>
        <Info label="Started At" border={false}>
          {createdAt(fieldAgent)}
        </Info>
      </Grid>
      <Grid item xs={12} md={3}>
        <Info label="Gender" border={false}>
          {fieldAgent.gender ?? '-'}
        </Info>
      </Grid>
      <Grid item xs={12} md={3}>
        <Info label="Phone Number" border={false}>
          {fieldAgent.phoneNumber ?? '-'}
        </Info>
      </Grid>
    </Grid>

    <Grid container sx={{ px: 2, pb: 1 }}>
      <Grid item xs={12} md={3}>
        <Info label="Phone Alternative 1" border={false}>
          {fieldAgent.phoneNumber2 ?? '-'}
        </Info>
      </Grid>
      <Grid item xs={12} md={3}>
        <Info label="Phone Alternative 2" border={false}>
          {fieldAgent.phoneNumber3 ?? '-'}
        </Info>
      </Grid>
      <Grid item xs={12} md={3}>
        <Info label="Email" border={false}>
          {fieldAgent.email ?? '-'}
        </Info>
      </Grid>
      <Grid item xs={12} md={3}>
        <Info label="Birthday" border={false}>
          {birthday(fieldAgent) ?? '-'}
        </Info>
      </Grid>
    </Grid>
    <Divider />

    <Grid container sx={{ px: 2, pb: 1 }}>
      <Grid item xs={12} md={3}>
        <Info label="Position" border={false}>
          {fieldAgent.category ?? '-'}
        </Info>
      </Grid>
      <Grid item xs={12} md={3}>
        <Info label="Manager" border={false}>
          {Boolean(fieldAgent.manager?.uuid) ? (
            <Link
              to={`/field-agents/${fieldAgent.manager.uuid}`}
              component={RouterLink}
            >
              {fieldAgent.manager.name}
            </Link>
          ) : (
            '-'
          )}
        </Info>
      </Grid>
    </Grid>
    <Divider />
    <Grid container sx={{ px: 2, pb: 1 }}>
      <Grid item xs={12} md={3}>
        <Info label="Total Team" border={false}>
          {fieldAgent.totalTeamMembers ?? '-'}
        </Info>
      </Grid>
      <Grid item xs={12} md={3}>
        <Info label="Managed Agents" border={false}>
          {fieldAgent.totalAgents ?? '-'}
        </Info>
      </Grid>
      <Grid item xs={12} md={3}>
        <Info label="Onboarded Agents" border={false}>
          {fieldAgent.totalOnboardedAgents ?? '-'}
        </Info>
      </Grid>
      <Grid item xs={12} md={3}>
        <Info label="Total Territories" border={false}>
          {fieldAgent.totalTerritories ?? '-'}
        </Info>
      </Grid>
    </Grid>
    <Grid container sx={{ px: 2, pb: 1 }}>
      <Grid item xs={12} md={3}>
        <Info label="Current Loans" border={false}>
          {fieldAgent.total ?? '-'}
        </Info>
      </Grid>
      <Grid item xs={12} md={3}>
        <Info label="Delinquent Loans" border={false}>
          {fieldAgent.total ?? '-'}
        </Info>
      </Grid>
      {fieldAgent.idNumber && (
        <Grid item xs={12} md={3}>
          <Info label="ID Number" border={false}>
            {fieldAgent.idNumber}
          </Info>
        </Grid>
      )}
      {(fieldAgent.comments || []).filter((value) => value.length > 0).length >
        0 && (
        <Grid item xs={12} md={3}>
          <Info label="Comments" border={false}>
            {fieldAgent.comments.join(',')}
          </Info>
        </Grid>
      )}
      {fieldAgent.address && (
        <Grid item xs={12} md={3}>
          <Info label="Address" border={false}>
            {fieldAgent.address}
          </Info>
        </Grid>
      )}
    </Grid>
  </Card>
);

const Users = ({ fieldAgent }) => {
  const { user: loggedInUser } = useSelector((state) => state.auth);
  return (
    <Card>
      <CardContent>
        {!('users' in fieldAgent) ? (
          <Loading />
        ) : fieldAgent?.users?.length ? (
          fieldAgent.users.map((user) => (
            <Box
              key={user.uuid}
              sx={{
                mt: 2,
                '& .MuiGrid-item': { fontSize: '0.9em', fontWeight: 300 },
              }}
            >
              {hasAnyRoles(loggedInUser.role, 'USER_MANAGER') && (
                <Grid container justifyContent="space-between">
                  <Grid item>UUID</Grid>
                  <Grid item>
                    <Link to={`/users/${user?.uuid}`} component={RouterLink}>
                      {user?.uuid}
                    </Link>
                  </Grid>
                </Grid>
              )}
              <Grid container justifyContent="space-between">
                <Grid item>External ID</Grid>
                <Grid item>{user.externalId ?? '-'}</Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item>Email</Grid>
                <Grid item>{user.email ?? '-'}</Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item>Phone Number</Grid>
                <Grid item>{user.phoneNumber ?? '-'}</Grid>
              </Grid>
              <Divider sx={{ mt: 2 }} />
            </Box>
          ))
        ) : (
          <Alert severity="warning">
            This profile has no users associated with them. They will not be
            able to login! Please edit, fill in the correct phone number & email
            then save.
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

const Stats = ({ fieldAgent }) => {
  return (
    <Card>
      <Box display="flex" justifyContent="center" sx={{ p: 3 }}>
        <Avatar
          alt="Profile Photo"
          sx={{ width: 160, height: 160 }}
          src={fieldAgent.profilePhotoUrl ?? ''}
        />
      </Box>
    </Card>
  );
};

const AgentListTable = ({ fieldAgent }) => {
  return (
    <Box sx={{ mt: 1 }}>
      <AgentList embedded={{ 'fa.uuid': fieldAgent.uuid }} />
    </Box>
  );
};

const LoanListTable = ({ fieldAgent }) => {
  return (
    <Box sx={{ mt: 1 }}>
      <LoanList embedded={{ 'a.fieldAgentUuid': fieldAgent.uuid }} />
    </Box>
  );
};

const TerritoryListTable = ({ fieldAgent }) => {
  return (
    <Box sx={{ mt: 1 }}>
      <FieldAgentTerritoryList embedded={{ fieldAgentUuid: fieldAgent.uuid }} />
    </Box>
  );
};

const ManagerListTable = ({ fieldAgent }) => {
  return (
    <Box sx={{ mt: 1 }}>
      <FieldAgentManagerLogList embedded={{ 'fa.uuid': fieldAgent.uuid }} />
    </Box>
  );
};

const Report = ({ fieldAgent }) => {
  return (
    <Card>
      <EmbeddedChart
        theKey={DASHBOARD_FIELD_AGENT_DETAIL}
        height="1000px"
        filters={{
          f: {
            id: '88HIB0fO_',
            column: 'fieldAgentUuid',
            value: fieldAgent.uuid,
          },
        }}
      />
    </Card>
  );
};

const TeamListTable = ({ fieldAgent }) => {
  return (
    <Box sx={{ mt: 1 }}>
      <FieldAgentList embedded={{ 'm.uuid': fieldAgent?.uuid }} />
    </Box>
  );
};

const Roles = ({ fieldAgent }) => {
  const roles = Array.from(
    new Set(
      (fieldAgent?.users ?? []).reduce((old, user) => {
        old.push(...Array.from(rolesToSet(user.role)));
        return old;
      }, [])
    )
  );

  return (
    <Card sx={{ mb: 2 }}>
      {roles.map((role) => (
        <Accordion key={role} expanded={true}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography>{role}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              {getRoleDefinition(role).map((description, index) => (
                <li key={`${role}-${index}`}>{description}</li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      ))}
    </Card>
  );
};
