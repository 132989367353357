import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  locations: [],
  isLoading: false,
  error: '',
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState: initialState,
  reducers: {
    setLocations: (state, { payload }) => {
      const { lastFilter, locations } = payload;

      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.locations = locations;
    },
    locationsReset: (state) => {
      state.locations = [];
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const locationsFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get('/api/locations', { params: filters })
    .then((response) =>
      dispatch(
        setLocations({
          lastFilter: filters?.toString(),
          locations: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { locationsReset, setLocations, setError, setLoading } =
  locationsSlice.actions;

export const locationsReducer = locationsSlice.reducer;
