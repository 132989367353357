export const PayoutStructure = {
  tier: 'tier',
  revenuePercent: '% of revenue',
  unitAmount: 'amount per unit',
};

export const Type = {
  amount: 'amount',
  percentage: 'percentage',
  combined: 'combined',
};

export const indicators = [
  { name: 'Number of Agent onboardings', value: 'Number of Agent onboardings' },
  {
    name: 'Airtime sale value by assigned agents',
    value: 'Airtime sale value by assigned agents',
  },
  {
    name: 'Number of customer registrations',
    value: 'Number of customer registrations',
  },
  {
    name: 'Number of customers served by agents',
    value: 'Number of customers served by agents',
  },
  {
    name: 'CICO commission value',
    value: 'CICO commission value',
  },
  {
    name: 'Number of active agents on app',
    value: 'Number of active agents on app',
  },
  {
    name: 'Base Salary',
    value: 'Base Salary',
  },
  {
    name: 'Monthly Allowance',
    value: 'Monthly Allowance',
  },
  {
    name: 'Amount rebalanced',
    value: 'Amount rebalanced',
  },
];
