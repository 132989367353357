import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppBar, dateFormat, Error } from '@/common';
import {
  Autocomplete,
  Box,
  CardContent,
  Stack,
  Card,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Switch,
  Button,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { PayoutStructure, Type, indicators } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteModal, Loading } from '@/common';
import {
  createCommission,
  fetchCommissionById,
  updateCommission,
  deleteCommission,
} from './CommissionListSlice';
import { fieldAgentListFetch } from '@/fieldAgent/fieldAgentListSlice';
import { fieldAgentPositionListFetch } from '@/fieldAgent/fieldAgentPositionListSlice';

const FIELD_TEAM_POSITION = 'Field Team Position';

const INDIVIDUAL_TEAM_MEMBER = 'Individual Team Member';

const options = [
  { value: FIELD_TEAM_POSITION, label: FIELD_TEAM_POSITION },
  { value: INDIVIDUAL_TEAM_MEMBER, label: INDIVIDUAL_TEAM_MEMBER },
];

const payout = [
  { name: 'Amount Per Unit', value: 'amount per unit' },
  { name: 'Tiered', value: 'tier' },
  { name: '% of Revenue', value: '% of revenue' },
];

const compensationType = [
  { name: 'Amount', value: 'amount' },
  { name: 'Percent', value: 'percentage' },
  { name: 'Amount + Percent', value: 'combined' },
];

const defaultCommissionValues = {
  category: FIELD_TEAM_POSITION,
  position: [],
  indicator: '',
  payoutStructure: '',
  parameters: [
    {
      value: 1,
    },
  ],
  floor: 0,
  ceiling: 0,
  validFromDate: '',
  validToDate: '',
  checkedFloor: false,
  checkedCeiling: false,
};
export const AddCommissionForm = () => {
  const [commission, setCommission] = useState(defaultCommissionValues);

  const [tierError, setTierError] = useState('');
  const [positionError, setPositionError] = useState('');
  const [payoutError, setPayoutError] = useState('payoutError');

  const [positionFields, setPositionFields] = useState([]);

  const [input, setInput] = useState('');

  const [open, setOpen] = useState(false);

  const { uuid } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const commissionDetail = useSelector((state) => {
    return state.commissionList;
  });

  const { fieldAgentList } = useSelector((state) => {
    return state.fieldAgentList;
  });

  const { fieldAgentPositionList } = useSelector((state) => {
    return state.fieldAgentPositionList;
  });

  useEffect(() => {
    if (uuid) {
      dispatch(fetchCommissionById(uuid));
    }
  }, []);

  useEffect(() => {
    if (commissionDetail.commission.uuid && uuid) {
      const {
        validFrom,
        validTo,
        floor,
        ceiling,
        payoutStructure,
        position,
        ...rest
      } = commissionDetail.commission;
      const cleanedData = {
        ...rest,
        validFromDate: dateFormat(validFrom, 'YYYY-MM-DD'),
        validToDate: validTo ? dateFormat(validTo, 'YYYY-MM-DD') : '',
        floor,
        ceiling,
        checkedFloor: !!floor,
        checkedCeiling: !!ceiling,
        payoutStructure: payoutStructure.toLowerCase(),
        position: [position],
      };
      setCommission(cleanedData);
    }
    if (commissionDetail.create) {
      navigate(`/field-agents/commissions`);
    }
  }, [commissionDetail]);

  useEffect(() => {
    if (
      (commission.payoutStructure === 'amount per unit' ||
        commission.payoutStructure === '% of revenue') &&
      commission.parameters?.[0]?.value <= 0
    ) {
      setPayoutError('Value cannot be equal to or less than 0');
    } else {
      setPayoutError('');
    }
  }, [commission.payoutStructure, commission.parameters]);

  useEffect(() => {
    (async () => {
      if (commission.category === FIELD_TEAM_POSITION) {
        await dispatch(fieldAgentPositionListFetch(''));
      }
      if (commission.category === INDIVIDUAL_TEAM_MEMBER && input.length > 2) {
        const newFilters = new URLSearchParams(input);
        newFilters.set('name', `contains:${input}`);
        await dispatch(fieldAgentListFetch(newFilters));
      }

      if (commission.category === INDIVIDUAL_TEAM_MEMBER && input.length < 3) {
        setPositionFields([]);
      }
    })();
  }, [commission.category, input]);

  useEffect(() => {
    if (
      commission.category === INDIVIDUAL_TEAM_MEMBER &&
      fieldAgentList.length > 0 &&
      input.length > 2
    ) {
      const names = fieldAgentList.map(({ name }) => name);

      setPositionFields(names);
    }
    if (
      commission.category === FIELD_TEAM_POSITION &&
      fieldAgentPositionList.length > 0
    ) {
      const names = fieldAgentPositionList.map(({ name }) => name);
      setPositionFields(names);
    }
  }, [fieldAgentList, commission.category, input, fieldAgentPositionList]);
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;

    if (name === 'percent' || name === 'amount') {
      const newValue =
        name === 'percent' ? parseFloat(value) : parseFloat(value);

      const newParameters = [
        {
          value: newValue,
          type: name === 'percent' ? Type.percentage : Type.amount,
        },
      ];

      setCommission((prev) => ({
        ...prev,
        parameters: newParameters,
      }));
      return;
    }

    if (name === 'position') {
      setCommission((prev) => ({
        ...prev,
        [name]: value,
      }));
      return;
    }

    if (name === 'category') {
      setCommission((prev) => ({
        ...prev,
        category: value,
        position: [],
      }));
      setPositionFields([]);
      setInput('');
      return;
    }

    setInput('');
    setPositionFields([]);
    setCommission((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePayoutChange = (event) => {
    const { value } = event.target;

    setCommission((prev) => ({
      ...prev,
      payoutStructure: value,
      parameters: [
        {
          value: 1,
          type: value === '% of revenue' ? 'percent' : 'amount',
        },
      ],
    }));
  };

  const handleTierChange = (index, field, value) => {
    setTierError('');
    const parsedValue = ['start', 'end', 'value', 'percent'].includes(field)
      ? parseFloat(value)
      : value;

    const newParameters = [...commission.parameters];
    newParameters[index] = { ...newParameters[index], [field]: parsedValue };
    setCommission((prev) => ({
      ...prev,
      parameters: newParameters,
    }));
  };

  const addTier = () => {
    const lastTierEnd =
      commission.parameters[commission.parameters.length - 1]?.end || 0;
    setCommission((prev) => ({
      ...prev,
      parameters: [
        ...prev.parameters,
        { start: lastTierEnd, end: '', type: '', value: '' },
      ],
    }));
  };

  const isLastTierPopulated = () => {
    const lastParameter =
      commission.parameters[commission.parameters.length - 1];
    return lastParameter.start && lastParameter.type && lastParameter.value;
  };

  const removeTier = (indexToRemove) => {
    setCommission((prev) => ({
      ...prev,
      parameters: prev.parameters.filter((_, index) => index !== indexToRemove),
    }));
  };

  const handleToggle = (field) => {
    setCommission((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const cleanedCommission = (data) => {
    const {
      validFromDate,
      validToDate,
      checkedFloor,
      checkedCeiling,
      floor,
      ceiling,
      ...rest
    } = data;

    return {
      ...rest,
      validFrom: dateFormat(validFromDate, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      validTo: validToDate
        ? dateFormat(validToDate, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        : null,
      ...(floor > 0 && { floor: parseFloat(floor) }),
      ...(ceiling > 0 && { ceiling: parseFloat(ceiling) }),
    };
  };

  const validateTieredPayoutStructure = (commission) => {
    let previousEnd = null;
    let index = 0;

    for (const tier of commission.parameters) {
      if (tier.end !== '' && tier.end < tier.start) {
        setTierError(
          `In Tier ${index + 1}, the end value must be greater than or equal to the start value.`
        );
        return false;
      }
      if (previousEnd !== null && tier.start < previousEnd) {
        setTierError(
          `In Tier ${index + 1}, the start value must be greater than or equal to the end value of the previous tier.`
        );
        return false;
      }
      previousEnd = tier.end;
      index++;
    }

    setTierError('');
    return true;
  };

  const validatePosition = (position) => {
    if (position.length === 0) {
      setPositionError('Position field is required');
      return false;
    }
    setPositionError('');
    return true;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const commissionData = cleanedCommission(commission);

    const { position, ...rest } = commissionData;

    if (commissionData.payoutStructure === PayoutStructure.tier) {
      if (!validateTieredPayoutStructure(commissionData)) {
        return;
      }
    }

    if (!validatePosition(position)) {
      return;
    }

    for (const pos of position) {
      const commissionObj = {
        ...rest,
        position: pos,
      };

      if (uuid) {
        await dispatch(updateCommission(uuid, commissionObj));
      } else {
        await dispatch(createCommission(commissionObj));
      }
    }
  };

  const handleDelete = () => {
    dispatch(deleteCommission(uuid));
    navigate(`/field-agents/commissions`);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    payoutStructure,
    floor,
    ceiling,
    checkedFloor,
    checkedCeiling,
    category,
    position,
    indicator,
  } = commission;

  return (
    <>
      <AppBar title="Add Commission">Add Field Team Commission</AppBar>
      <Card>
        <form onSubmit={handleSubmit}>
          <CardContent>
            {commissionDetail?.isLoading && <Loading />}
            {commissionDetail?.error && (
              <Box
                mt={8}
                mb={2}
                pr={5}
                ml={-3}
                sx={{
                  position: 'fixed',
                  top: 0,
                  width: '100%',
                  zIndex: 9,
                }}
              >
                <Error error={commissionDetail.error} />
              </Box>
            )}
            <Stack spacing={2}>
              <Typography variant="h6">
                Select who the commission applies to:
              </Typography>
              <Box mb={4}>
                <FormControl component="fieldset">
                  <RadioGroup
                    name="category"
                    value={category}
                    onChange={handleChange}
                  >
                    {options.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
              <Stack direction={{ sm: 'column', md: 'row' }} spacing={2} mt={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple={uuid ? false : true}
                    disablePortal
                    id="position"
                    options={positionFields}
                    name="position"
                    value={position}
                    data-testid="field-team-position"
                    onChange={(event, newValue) => {
                      const eventValue = {
                        target: {
                          name: 'position',
                          value: newValue,
                          type: 'autocomplete',
                        },
                      };
                      handleChange(eventValue);
                    }}
                    onInputChange={(_, newInputValue) => {
                      setInput(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Field Team Position" />
                    )}
                  />
                  {positionError && (
                    <Box mt={2} mb={2}>
                      <Typography variant="body1" color="error">
                        {positionError}
                      </Typography>
                    </Box>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="indicator-label">Indicator</InputLabel>
                  <Select
                    labelId="indicator-label"
                    id="indicator"
                    name="indicator"
                    value={indicator}
                    label="Indicator"
                    onChange={handleChange}
                    required
                  >
                    {indicators.map((indicator, index) => (
                      <MenuItem key={index} value={indicator.value}>
                        {indicator.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="payout-option-label">
                    Payout Option
                  </InputLabel>
                  <Select
                    labelId="payout-option-label"
                    id="payoutOption"
                    name="payoutStructure"
                    value={commission.payoutStructure}
                    label="Payout Option"
                    onChange={handlePayoutChange}
                    required
                  >
                    {payout.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>

            {payoutStructure && (
              <Stack
                mt={2}
                mb={2}
                sx={{ minHeight: '10rem' }}
                justifyContent="center"
                alignItem="center"
              >
                <Typography variant="body1">Parameters</Typography>
                <Stack spacing={2} mt={3}>
                  {payoutStructure === PayoutStructure.revenuePercent ? (
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Percent (%)"
                          type="number"
                          name="percent"
                          value={commission.parameters?.[0]?.value}
                          onChange={handleChange}
                          fullWidth
                        />
                        <Box height="1rem" mt={1}>
                          <Typography variant="body1" color="error">
                            {payoutError}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : payoutStructure === PayoutStructure.tier ? (
                    <Stack spacing={2} mt={3}>
                      {commission.parameters.map((param, index) => (
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={5} sx={{ ml: -4, mr: 8 }}>
                            <Stack
                              key={index}
                              direction={{ sm: 'column', md: 'row' }}
                              spacing={2}
                              sx={{ width: '100%' }}
                              pl={0}
                            >
                              <Box pt={2}>
                                <Typography
                                  variant="body1"
                                  sx={{ width: 'auto' }}
                                >{`Tier ${index + 1}`}</Typography>
                              </Box>
                              <TextField
                                label="Start"
                                type="number"
                                name="start"
                                value={param.start}
                                onChange={(e) =>
                                  handleTierChange(
                                    index,
                                    'start',
                                    e.target.value
                                  )
                                }
                                sx={{ flex: 1 }}
                              />
                              <TextField
                                label="End"
                                type="number"
                                name="end"
                                helperText={
                                  index === commission.parameters.length - 1
                                    ? 'Leave blank for no upper bound'
                                    : ''
                                }
                                value={param.end}
                                onChange={(e) =>
                                  handleTierChange(index, 'end', e.target.value)
                                }
                                sx={{ flex: 1 }}
                              />
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={param.type === 'combined' ? 5.5 : 5}
                          >
                            <Stack
                              key={index}
                              direction={{ sm: 'column', md: 'row' }}
                              spacing={2}
                              sx={{ width: '100%' }}
                              ml={-3}
                            >
                              <Box pt={2}>
                                <Typography
                                  variant="body1"
                                  sx={{ width: 'auto' }}
                                >
                                  Compensation
                                </Typography>
                              </Box>
                              <FormControl sx={{ flex: 1 }}>
                                <InputLabel id="payout-option-label">
                                  Type
                                </InputLabel>
                                <Select
                                  label="Type"
                                  value={param.type}
                                  onChange={(e) =>
                                    handleTierChange(
                                      index,
                                      'type',
                                      e.target.value
                                    )
                                  }
                                >
                                  {compensationType.map(
                                    ({ name, value }, i) => (
                                      <MenuItem key={i} value={value}>
                                        {name}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                              <TextField
                                label={
                                  param.type === 'combined' ? 'Amount' : 'Value'
                                }
                                type="number"
                                name="value"
                                value={param.value}
                                onChange={(e) =>
                                  handleTierChange(
                                    index,
                                    'value',
                                    e.target.value
                                  )
                                }
                                sx={{ flex: 1 }}
                              />

                              {param.type === 'combined' && (
                                <>
                                  <TextField
                                    label="Percent"
                                    type="number"
                                    name="percent"
                                    value={param.percent}
                                    onChange={(e) =>
                                      handleTierChange(
                                        index,
                                        'percent',
                                        e.target.value
                                      )
                                    }
                                    sx={{ flex: 1 }}
                                  />
                                </>
                              )}
                            </Stack>
                          </Grid>
                          <Grid item xs={param.type === 'combined' ? 0.5 : 1}>
                            {index > 0 && (
                              <IconButton onClick={() => removeTier(index)}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {tierError && (
                        <Box mt={2} mb={2}>
                          <Typography variant="body1" color="error">
                            {tierError}
                          </Typography>
                        </Box>
                      )}
                      <Grid container mt={4} spacing={2}>
                        <Grid item xs={3} md={1} sx={{ ml: -2.5 }}>
                          <Button
                            onClick={addTier}
                            variant="contained"
                            disabled={!isLastTierPopulated()}
                            fullWidth
                          >
                            Add Tier
                          </Button>
                        </Grid>
                        {!isLastTierPopulated() && (
                          <Grid item xs={8}>
                            <Box mt={1.5}>
                              <Typography variant="body1">
                                Please fill upperbound to add another tier
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Stack>
                  ) : payoutStructure === PayoutStructure.unitAmount ? (
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Amount"
                          type="number"
                          name="amount"
                          value={commission.parameters?.[0]?.value}
                          onChange={handleChange}
                          fullWidth
                        />
                        <Box height="1rem" mt={1}>
                          <Typography variant="body1" color="error">
                            {payoutError}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                </Stack>
              </Stack>
            )}

            <Grid container spacing={2} mt={3}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <Grid container>
                    <Grid item xs={4} md={1.5}>
                      <Stack direction="row" spacing={2}>
                        <Box pt={2}>
                          <Typography variant="body1">Floor Setting</Typography>
                        </Box>
                        <Box pt={1}>
                          <Switch
                            checked={checkedFloor}
                            onChange={() => handleToggle('checkedFloor')}
                            name="checkedFloor"
                            value={checkedFloor}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={8} md={2.5}>
                      <TextField
                        disabled={!checkedFloor}
                        label="Floor Amount"
                        helperText="Minimum amount payable regardless of qualifying activity"
                        name="floor"
                        value={floor}
                        onChange={handleChange}
                        defaultValue={0}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <Grid container>
                    <Grid item xs={4} md={1.5}>
                      <Stack direction="row" spacing={2}>
                        <Box pt={2}>
                          <Typography variant="body1">
                            Ceiling Setting
                          </Typography>
                        </Box>
                        <Box pt={1}>
                          <Switch
                            checked={checkedCeiling}
                            onChange={() => handleToggle('checkedCeiling')}
                            name="checkedCeiling"
                            value={checkedCeiling}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={8} md={2.5}>
                      <TextField
                        disabled={!checkedCeiling}
                        label="Ceiling Amount"
                        helperText="Maximum amount payable regardless of qualifying activity"
                        name="ceiling"
                        value={ceiling}
                        onChange={handleChange}
                        defaultValue={ceiling}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>

            <Stack>
              <Grid container spacing={2} mt={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="date"
                    name="validFromDate"
                    value={commission.validFromDate}
                    onChange={handleChange}
                    fullWidth
                    helperText="Valid from"
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="date"
                    name="validToDate"
                    value={commission.validToDate}
                    onChange={handleChange}
                    fullWidth
                    helperText="Valid to: Leave this blank if the commission should apply indefinitely"
                  />
                </Grid>
              </Grid>
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              mt={4}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ mt: 3 }}
                disabled={!!payoutError}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={() => setOpen(true)}
                disabled={!uuid}
                sx={{ mt: 3 }}
              >
                Delete
              </Button>
            </Stack>
            <DeleteModal
              open={open}
              handleClose={handleClose}
              handleDelete={handleDelete}
              primaryText={`Are you sure you wish to delete this commission`}
              loading={commissionDetail.loading}
            />
          </CardContent>
        </form>
      </Card>
    </>
  );
};
