import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { AppBar, Bubble, Error, Loading, useInitial } from '@/common';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actionsFetch } from './actionListSlice';
import { parserSave } from './parserFormSlice';
import { parserFetch } from './parserSlice';
import {
  getParserCategories,
  getParserStatuses,
  getParserTargets,
  getParserTelcos,
} from './utils';
import { captureEvent } from '@/firebase';

export const ParserForm = () => {
  // console.log('RXD:ParserForm');
  const dispatch = useDispatch();
  const { id: paramId } = useParams();
  const id = Boolean(paramId) ? +paramId : null;
  const { error: formError, status: formStatus } = useSelector(
    (state) => state.parserForm
  );
  const {
    parser,
    error: fetchError,
    isLoading: fetchIsLoading,
  } = useSelector((state) => state.parser);
  let { actionList } = useSelector((state) => state.actionList);

  if (actionList) {
    actionList = [...actionList].sort((a, b) => a.name.localeCompare(b.name));
  }

  const [action, setAction] = useState('');
  const [category, setCategory] = useState('');
  const [status, setStatus] = useState('');
  const [target, setTarget] = useState('');
  const [step, setStep] = useState();
  const [sender, setSender] = useState('');
  const [regex, setRegex] = useState('');
  const [sample, setSample] = useState('');
  const [telco, setTelco] = useState('');

  useEffect(() => {
    if (id !== parser?.id && !fetchIsLoading && !fetchError) {
      setAction('');
      setCategory('');
      setStatus('');
      setTarget('');
      setStep('');
      setSender('');
      setRegex('');
      setTelco('');
      if (id) {
        dispatch(parserFetch(id));
      }
    }
  }, [dispatch, parser, id, fetchError, fetchIsLoading]);

  useEffect(() => {
    if (id === parser?.id) {
      setAction(parser?.action?.id ?? '');
      setCategory(parser?.category ?? '');
      setStatus(parser?.status ?? '');
      setTarget(parser?.target ?? '');
      setStep(parser?.step ?? '');
      setSender(parser?.sender ?? '');
      setRegex(parser?.regex ?? '');
      setTelco(parser?.telco ?? '');
    }
  }, [parser, id]);

  useInitial(() => {
    dispatch(actionsFetch(new URLSearchParams('pp=1:400')));
  });

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      action: {
        id: +action,
        name: actionList.reduce((o, r) => (r.id === +action ? r.name : o), ''),
      },
      category,
      status,
      target,
      step: Boolean(step) ? +step : null,
      sender,
      regex,
      telco,
    };
    dispatch(
      parserSave(id, {
        ...data,
      })
    );
    captureEvent(id ? 'ADD_PARSER_FORM_SAVE' : 'UPDATE_PARSER_FORM_SAVE', {});
  };

  const w = (callback) => (e) => callback(e.target.value);

  const testResult = {
    helperText: 'You can insert sample text to test your regex against',
    error: false,
    matches: false,
    groups: [],
  };

  if (regex && regex.length > 0 && sample && sample.length > 0) {
    try {
      const pattern = new RegExp(regex);
      testResult.groups = [];
      if (!pattern.test(sample)) {
        testResult.helperText = 'This does not match!';
        testResult.error = true;
      } else {
        testResult.helperText = 'The pattern matches!';
        testResult.matches = true;
        const groups = pattern.exec(sample).groups;
        testResult.groups = !Boolean(groups)
          ? []
          : Object.keys(groups).map((key) => [key, groups[key]]);
      }
    } catch (error) {
      testResult.error = `${error}`;
    }
  }

  return (
    <>
      <AppBar>{id ? 'Edit Parser' : 'Add Parser'}</AppBar>

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <>
                <Box
                  component="form"
                  method="post"
                  sx={{ p: 1 }}
                  onSubmit={handleSave}
                >
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={12} sx={{ mb: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>Action ID</InputLabel>
                        <Select
                          label="Action ID"
                          value={action}
                          onChange={w(setAction)}
                          required
                        >
                          {(actionList ?? []).map((action) => (
                            <MenuItem key={action.id} value={action.id}>
                              {action.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sx={{ mb: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>Target</InputLabel>
                        <Select
                          label="Target"
                          value={target}
                          onChange={w(setTarget)}
                          required
                        >
                          {getParserTargets().map((target) => (
                            <MenuItem key={target} value={target}>
                              {target}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sx={{ mb: 2 }}>
                      <FormControl fullWidth>
                        {target === 'SMS' && (
                          <TextField
                            label="Sender"
                            variant="outlined"
                            type="text"
                            value={sender}
                            onChange={w(setSender)}
                            required
                          />
                        )}
                        {target === 'USSD' && (
                          <TextField
                            label="Step"
                            variant="outlined"
                            type="text"
                            value={step}
                            onChange={w(setStep)}
                            required
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sx={{ mb: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                          label="Status"
                          value={status}
                          onChange={w(setStatus)}
                          required
                        >
                          {getParserStatuses().map((status) => (
                            <MenuItem key={status} value={status}>
                              {status}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sx={{ mb: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>Category</InputLabel>
                        <Select
                          label="Category"
                          value={category}
                          onChange={w(setCategory)}
                          required
                        >
                          {getParserCategories().map((category) => (
                            <MenuItem key={category} value={category}>
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Telco</InputLabel>
                        <Select
                          label="Telco"
                          value={telco}
                          onChange={w(setTelco)}
                          required
                        >
                          {getParserTelcos().map((telco) => (
                            <MenuItem key={telco} value={telco}>
                              {telco}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={0}></Grid>
                    <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                      <FormControl fullWidth>
                        <TextField
                          id="regex"
                          label="Regex"
                          variant="outlined"
                          value={regex}
                          onChange={w(setRegex)}
                          rows="5"
                          multiline
                          required
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                      <FormControl
                        fullWidth
                        sx={{
                          mb: 2,
                          '& .MuiInputLabel-formControl': {
                            color:
                              testResult.error || testResult.matches
                                ? 'black'
                                : null,
                          },
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: testResult.error
                              ? '#af3232'
                              : testResult.matches
                                ? '#58a758'
                                : null,
                            color:
                              testResult.error || testResult.matches
                                ? 'white'
                                : null,
                          },
                        }}
                      >
                        <TextField
                          id="sample"
                          label="Sample"
                          variant="outlined"
                          value={sample}
                          onChange={w(setSample)}
                          rows="5"
                          error={testResult.error}
                          helperText={testResult.helperText}
                          multiline
                        />
                      </FormControl>
                      {testResult.groups.map(([group, value]) => (
                        <Box key={group}>
                          <Bubble label={group} />{' '}
                          <Bubble label={value} background="black" />
                        </Box>
                      ))}
                    </Grid>
                  </Grid>

                  <Divider sx={{ mb: 2 }} />

                  {formStatus === 'loading' ? (
                    <Loading />
                  ) : (
                    <Button variant="contained" sx={{ mb: 1 }} type="submit">
                      Save
                    </Button>
                  )}
                </Box>
              </>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
