import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  transaction: null,
  isLoading: false,
  error: '',
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState: initialState,
  reducers: {
    setTransaction: (state, { payload }) => {
      state.transaction = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.transaction = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const transactionFetch = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/transactions/${uuid}`)
    .then((response) => dispatch(setTransaction(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setTransaction, resetState, setLoading, setError } =
  transactionSlice.actions;

export const transactionReducer = transactionSlice.reducer;
