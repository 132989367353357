import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';

const initialState = {
  status: 'editing',
  error: '',
};

const userFormSlice = createSlice({
  name: 'userForm',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const userSave = (id, data) => (dispatch) => {
  dispatch(setLoading());

  const user = !!id
    ? getApi().patch(`/api/users/${id}`, data)
    : getApi().post(`/api/users`, data);

  user
    .then((response) => {
      dispatch(resetState());
      dispatch(push(`/users`));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { resetState, setLoading, setError } = userFormSlice.actions;

export const userFormReducer = userFormSlice.reducer;
