import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Loading } from './Loading';

export const DynamicSelect = ({
  label,
  renderOption,
  isLoading,
  error,
  options,
  value,
  onChange,
  mb,
  sx,
  disabled,
  multiple,
  required,
  helperText,
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', ...sx }}>
      <FormControl fullWidth sx={{ mb: mb ?? 1 }} error={Boolean(error)}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          labelId={label}
          label={label}
          variant="outlined"
          value={value}
          onChange={onChange}
          sx={{ flexGrow: 1 }}
          disabled={isLoading || disabled}
          multiple={multiple}
          required={required}
        >
          {options.map(renderOption)}
        </Select>
        {(Boolean(error) || Boolean(helperText)) && (
          <FormHelperText>{error ? error : helperText}</FormHelperText>
        )}
      </FormControl>
      {isLoading && <Loading size={25} css="margin-top:20px;flex-grow:0;" />}
    </Box>
  );
};
