import { getApi, getApiError } from '@/common';
import {
  notificationListFetch,
  setError as setListError,
} from '@/notification/notificationListSlice';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notification: null,
  isLoading: false,
  error: '',
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setNotification: (state, { payload }) => {
      state.notification = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.notification = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const notificationFetch = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/notifications/${uuid}`)
    .then((response) => dispatch(setNotification(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const notificationDelete = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .delete(`/api/notifications/${uuid}`)
    .then((response) =>
      dispatch(notificationListFetch(new URLSearchParams('pp=1:20&oo=uuid')))
    )
    .catch((err) => {
      dispatch(setError(getApiError(err)));
      dispatch(setListError(err.message));
    });
};

export const { setNotification, resetState, setLoading, setError } =
  notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;
