import { hasAnyRoles } from '@/auth/helpers';
import {
  AppBar,
  Bubble,
  Error,
  Loading,
  getColumnVisibility,
  getSortOrder,
  makeUnique,
  numberFormat,
  setColumnVisibility,
  titleCase,
  useFilters,
} from '@/common';
import { DynamicAutoComplete } from '@/common/DynamicAutoComplete';
import { DynamicSelect } from '@/common/DynamicSelect';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { captureEvent, logButtonEvents } from '@/firebase';
import { usersFetch } from '@/user/userListSlice';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@/common';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  agentsThresholdFetch,
  floatCashDisburseSave,
} from './floatCashDisburseSlice';
import { floatCashReviewSave } from './floatCashReviewSlice';
import { floatManagerAccountListFetch } from './floatManagerAccountListSlice';
import {
  agentSearch,
  floatRequestListFetch,
  setAgent,
  setLoadingAgent,
  submitRequest,
} from './floatRequestListSlice';
import {
  amountToNumberOrZero,
  getAmount,
  getCreatedAt,
  getRequestStatuses,
  getRequestType,
  getRequestTypeBackground,
  getRequestTypeColor,
  getStatus,
  getStatusBackground,
  getStatusColor,
  getUpdatedAt,
  toSingularPluralValueTitle,
} from './util';
import { settingValueFetch } from '@/settings/settingsSlice';
import { pluralize } from '@/common/strings';

export const FloatRequestList = ({ embedded }) => {
  // console.log('RXD:FloatRequestList');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { floatRequestList, isLoading, error, lastFilter } = useSelector(
    (state) => state.floatRequestList
  );

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  const [action, setAction] = useState('');
  const [requestType, setRequestType] = useState('');
  const [requestStatus, setRequestStatus] = useState('');
  const [checked, setChecked] = useState(new Checked());
  const [reviewDrawerOpen, setReviewDrawerOpen] = useState(false);
  const [cashDrawerOpen, setCashDrawerOpen] = useState(false);
  const [floatDrawerOpen, setFloatDrawerOpen] = useState(false);
  const [canOverrideApproval, setCanOverrideApproval] = useState(false);
  const [floatRequestModalOpen, setFloatRequestModalOpen] = useState(false);

  const handleFloatRequestModalClose = () => setFloatRequestModalOpen(false);

  const w = (callback) => (param) => callback(param.row);

  // console.log({
  //   action,
  //   checked,
  //   requestType,
  //   requestStatus,
  //   reviewDrawerOpen,
  //   cashDrawerOpen,
  //   floatDrawerOpen,
  // });

  const resetState = () => {
    setAction('');
    setRequestType('');
    setRequestStatus('');
    setChecked(new Checked());
    setReviewDrawerOpen(false);
    setCashDrawerOpen(false);
    setFloatDrawerOpen(false);
    dispatch(floatRequestListFetch(filters));
  };

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'fieldAgentUuid',
      'managerUuid',
    ]);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-createdAt');
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(floatRequestListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  useEffect(() => {
    const isReconciliationAdmin = hasAnyRoles(
      user.role,
      'RECONCILIATION_ADMIN'
    );
    setCanOverrideApproval(isReconciliationAdmin);
  }, [user, checked]);

  const enableRejectButton = () => {
    if (checked.size === 0 || requestStatus === 'REJECTED') return false;
    return requestStatus === 'PENDING' || canOverrideApproval;
  };

  const columns = [
    {
      field: 'isChecked',
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      flex: 0.2,
      sx: { padding: '0px' },
      sortingOrder: false,
      filterable: false,
      renderCell: w((request) => {
        return (
          <Checkbox
            size="small"
            disabled={
              request?.status === 'CANCELLED' ||
              request?.status === 'DISBURSED' ||
              (requestType !== '' &&
                requestStatus !== '' &&
                !(
                  requestStatus === 'APPROVED' &&
                  requestStatus === request?.status &&
                  request?.requestType === requestType
                ) &&
                !(
                  (requestStatus === 'PENDING' ||
                    requestStatus === 'REJECTED') &&
                  (request?.status === 'PENDING' ||
                    request?.status === 'REJECTED')
                ))
            }
            checked={checked.has(request?.uuid)}
            onChange={(e) => {
              if (e.target.checked) {
                checked.set(request?.uuid, request);
                if (checked.size === 1) {
                  setRequestType(request.requestType);
                  setRequestStatus(request.status);
                }
              } else {
                checked.delete(request?.uuid);
                if (checked.size === 0) {
                  setRequestType('');
                  setRequestStatus('');
                }
              }
              setChecked(checked.clone());
            }}
          />
        );
      }),
    },
    {
      field: 'createdAt',
      headerName: 'Requested At',
      valueGetter: w(getCreatedAt),
      minWidth: 200,
      type: 'dateTime',
      sortingOrder: getSortOrder(),
    },
    {
      field: 'agent.agentName',
      headerName: 'Agent Name',
      minWidth: 200,
      valueGetter: w((request) => request.agent?.agentName),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'phoneNumber',
      headerName: 'Agent Number',
      minWidth: 200,
      valueGetter: w((request) => request?.phoneNumber),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'currency',
      headerName: 'Currency',
      minWidth: 200,
      valueGetter: w((request) => request?.currency),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 200,
      valueGetter: w(getAmount),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      type: 'singleSelect',
      valueOptions: getRequestStatuses().map((status) => ({
        value: status,
        label: getStatus(status),
      })),
      renderCell: w((request) => (
        <Bubble
          label={getStatus(request?.status)}
          color={getStatusColor(request?.status)}
          background={getStatusBackground(request?.status)}
        />
      )),
    },
    {
      field: 'requestType',
      headerName: 'Request Type',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      type: 'singleSelect',
      valueOptions: [
        {
          value: 'FLOAT',
          label: 'float',
        },
        {
          value: 'CASH',
          label: 'cash',
        },
      ],
      renderCell: w((request) => (
        <Bubble
          label={getRequestType(request?.requestType)}
          color={getRequestTypeColor(request?.requestType)}
          background={getRequestTypeBackground(request?.requestType)}
        />
      )),
    },
    {
      field: 'requestedBy',
      headerName: 'Requested By',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      renderCell: w((request) => (
        <>
          {' '}
          {request?.user?.fieldAgent?.name && (
            <Box
              sx={{
                backgroundColor: '#DCF2EA',
                color: '#317159',
                display: 'inline-block',
                textAlign: 'center',
                width: '15px',
                lineHeight: '15px',
                height: '15px',
                borderRadius: '4px',
                marginRight: '2px',
                fontWeight: 'bold',
              }}
              title="Field Agent Initiated"
            >
              x
            </Box>
          )}
          {request?.user?.agent?.agentName ?? request?.user?.fieldAgent?.name}
        </>
      )),
    },
    {
      field: 'afa.name',
      headerName: 'Field Agent',
      minWidth: 200,
      valueGetter: w((request) => request.agent?.fieldAgent?.name ?? '-'),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      minWidth: 200,
      valueGetter: w(getUpdatedAt),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'statusReason',
      headerName: 'Status Detail',
      filterable: false,
      minWidth: 200,
    },
  ];

  return (
    <>
      <FloatRequestModal
        open={floatRequestModalOpen}
        handleClose={handleFloatRequestModalClose}
      />
      {!embedded && (
        <AppBar
          actions={
            hasAnyRoles(user.role, 'FLOAT_DISTRIBUTOR') ? (
              <>
                <Button
                  disableElevation
                  variant="contained"
                  component={RouterLink}
                  to="/float-cash/advance-cash"
                >
                  Advance Cash
                </Button>
                {
                  <Button
                    disableElevation
                    variant="contained"
                    component={RouterLink}
                    onClick={() => {
                      setFloatRequestModalOpen(true);
                      logButtonEvents('FLOAT_REQUESTS_ADD');
                    }}
                  >
                    Add Request
                  </Button>
                }
              </>
            ) : null
          }
        >
          Float Requests
        </AppBar>
      )}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(floatRequestListFetch(filters))}
        />
      )}

      {reviewDrawerOpen && (
        <ReviewForm
          open={reviewDrawerOpen}
          closeDrawer={() => setReviewDrawerOpen(false)}
          checked={checked}
          action={action}
          resetState={resetState}
        />
      )}

      {cashDrawerOpen && (
        <DisburseCashForm
          open={cashDrawerOpen}
          closeDrawer={() => setCashDrawerOpen(false)}
          checked={checked}
          resetState={resetState}
        />
      )}

      {floatDrawerOpen && (
        <DisburseFloatForm
          open={floatDrawerOpen}
          closeDrawer={() => setFloatDrawerOpen(false)}
          checked={checked}
          resetState={resetState}
        />
      )}

      <div
        style={{
          height: 'calc(100vh - 190px)',
          minHeight: 300,
        }}
      >
        <OparetaTable
          getRowId={(request) => request.uuid}
          rows={floatRequestList ?? []}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('FloatRequestList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('FloatRequestList', {
                currency: false,
                requestType: false,
                updatedAt: false,
              }),
            },
          }}
          ActionButtons={({ model, setModel, rows }) => {
            return hasAnyRoles(user.role, 'FLOAT_DISTRIBUTOR') ? (
              <>
                <Button
                  size="small"
                  sx={{ paddingX: 1 }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (checked.size === 0) {
                      for (const row of rows) {
                        if (
                          row.requestType === 'FLOAT' &&
                          row.status === 'APPROVED'
                        ) {
                          checked.set(row?.uuid, row);
                        }
                      }

                      if (checked.size > 0) {
                        setRequestType('FLOAT');
                        setRequestStatus('APPROVED');
                        setChecked(checked.clone());
                      }
                    } else {
                      setRequestType('');
                      setRequestStatus('');
                      setChecked(new Checked());
                    }
                  }}
                >
                  {checked.size === 0
                    ? `Select all approved float requests`
                    : `Deselect all (${checked.size})`}
                </Button>
                <Button
                  size="small"
                  disabled={
                    requestStatus !== 'PENDING' && requestStatus !== 'REJECTED'
                  }
                  sx={{ paddingX: 1 }}
                  onClick={(e) => {
                    e.preventDefault();
                    setAction('approve');
                    setReviewDrawerOpen(true);
                    logButtonEvents('FLOAT_APPROVE');
                  }}
                >
                  Approve
                </Button>
                <Button
                  size="small"
                  disabled={!enableRejectButton()}
                  color="error"
                  sx={{ paddingX: 1 }}
                  onClick={(e) => {
                    e.preventDefault();
                    setAction('rejected');
                    setReviewDrawerOpen(true);
                    logButtonEvents('FLOAT_REJECT');
                  }}
                >
                  Reject
                </Button>
                <Button
                  size="small"
                  disabled={requestStatus !== 'APPROVED'}
                  color="warning"
                  sx={{ paddingX: 1 }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (requestType === 'FLOAT') {
                      setFloatDrawerOpen(true);
                    } else {
                      setCashDrawerOpen(true);
                    }
                    logButtonEvents('FLOAT_DISBURSE');
                  }}
                >
                  Disburse
                </Button>
              </>
            ) : null;
          }}
          columns={columns}
        />
      </div>
    </>
  );
};

const ReviewForm = ({ open, checked, action, closeDrawer, resetState }) => {
  const dispatch = useDispatch();
  const { status, totalUpdated, error } = useSelector(
    (state) => state.floatCashReview
  );
  const [hasSubmitted, setHasSubmitted] = useState('');

  useEffect(() => {
    if (status === 'loading' && hasSubmitted !== 'loading') {
      setHasSubmitted('loading');
    } else if (status !== 'loading' && hasSubmitted === 'loading') {
      if (error) {
        setHasSubmitted('');
      } else {
        setHasSubmitted('success');
        setTimeout(() => {
          // console.log('ReviewForm.setTimeout');
          resetState();
          setHasSubmitted('');
        }, 3000);
      }
    }
  }, [error, hasSubmitted, resetState, status]);

  const handleSave = (e) => {
    e.preventDefault();
    const status = action === 'approve' ? 'APPROVED' : 'REJECTED';
    const reviews = {
      floatRequests: checked.keys().map((uuid) => ({ uuid, status })),
    };
    dispatch(floatCashReviewSave(reviews));
    captureEvent('FLOAT_REQUEST_SAVE', { type: action });
  };

  // console.log({ hasSubmitted, status, error, totalUpdated })

  return (
    <Drawer anchor="bottom" open={open} onClose={(e) => closeDrawer(false)}>
      <Box component="form" onSubmit={handleSave} p={2} autoComplete="off">
        {hasSubmitted === 'success' && (
          <Alert severity="success">
            {totalUpdated} requests successfully{' '}
            {action === 'approve' ? 'approved' : 'rejected'}
          </Alert>
        )}
        {error && <Error error={error} />}

        <Typography variant="h5" mb={2}>
          {action === 'rejected' ? titleCase('reject') : titleCase(action)}{' '}
          Requests
        </Typography>

        <Divider sx={{ marginY: 2 }} />

        <Typography variant="p">
          Are you sure you want to {action === 'rejected' ? 'reject' : action}{' '}
          {checked.size} {pluralize(checked.size, 'request')}?
        </Typography>

        <Divider sx={{ marginY: 2 }} />

        {status === 'loading' ? (
          <Loading />
        ) : (
          <Button
            variant="contained"
            type="submit"
            disabled={hasSubmitted === 'success'}
          >
            {action === 'rejected' ? titleCase('reject') : titleCase(action)}
          </Button>
        )}
      </Box>
    </Drawer>
  );
};

const DisburseCashForm = ({ open, checked, closeDrawer, resetState }) => {
  const dispatch = useDispatch();

  const { status, error, totalUpdated } = useSelector(
    (state) => state.floatCashDisburse
  );
  const {
    floatManagerAccountList,
    isLoading: floatManagerAccountListIsLoading,
    error: floatManagerAccountListError,
  } = useSelector((state) => state.floatManagerAccountList);

  const [cashAccount, setCashAccount] = useState(null);
  const [sum, setSum] = useState(0);
  const [fieldAgents, setFieldAgents] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState('');

  useEffect(() => {
    if (status === 'loading' && hasSubmitted !== 'loading') {
      setHasSubmitted('loading');
    } else if (status !== 'loading' && hasSubmitted === 'loading') {
      if (error) {
        setHasSubmitted('');
      } else {
        setHasSubmitted('success');
        setTimeout(() => {
          // console.log('DisburseCashForm.setTimeout');
          resetState();
          setHasSubmitted('');
          setCashAccount(null);
        }, 3000);
      }
    }
  }, [error, hasSubmitted, resetState, status]);

  const filterCashAccounts = (value) => {
    // console.log('RXD:filterCashAccounts', { value, cashAccount });
    const query = new URLSearchParams('pp=1:15&oo=bankName');

    if (value && value !== cashAccount?.bankName) {
      query.set('bankName', `contains:${value}`);
    }
    dispatch(floatManagerAccountListFetch(query));
  };

  useEffect(() => {
    let sum = 0;
    let currency = '';
    let fieldAgents = new Map();
    for (const key of checked.keys()) {
      const request = checked.get(key);
      const rf = request?.agent?.fieldAgent;

      sum += +request?.amount;
      currency = request?.currency;

      if (!fieldAgents.has(rf?.uuid)) {
        fieldAgents.set(rf?.uuid, {
          uuid: rf?.uuid,
          name: rf?.name,
          agents: 0,
          sum: 0,
        });
      }
      const fieldAgent = fieldAgents.get(rf?.uuid);
      fieldAgent.agents += 1;
      fieldAgent.sum += +request?.amount;
    }
    const fas = [];
    for (const data of Array.from(fieldAgents.values())) {
      data.sum = `${currency} ${numberFormat(data.sum)}`;
      fas.push(data);
    }
    fas.sort((a, b) => a?.name?.localeCompare(b?.name));
    setFieldAgents(fas);
    setSum(`${currency} ${numberFormat(sum)}`);
  }, [checked]);

  useEffect(() => filterCashAccounts(), []);

  const handleSave = (e) => {
    e.preventDefault();
    dispatch(
      floatCashDisburseSave({
        uuids: checked.keys(),
        status: 'DISBURSED',
        type: 'CASH',
        pin: '',
        phoneNumber: '',
        cashAccountId: cashAccount?.uuid,
      })
    );
    captureEvent('FLOAT_DISBURSED_SAVE', { type: 'cash' });
  };

  const gsx = { border: 1, borderColor: 'lightGray', p: 1 };

  // console.log('DisburseCashForm', { hasSubmitted });

  return (
    <Drawer anchor="bottom" open={open} onClose={(e) => closeDrawer()}>
      <Box component="form" onSubmit={handleSave} p={2} autoComplete="off">
        <Typography variant="h5" mb={2}>
          Disburse Cash
        </Typography>

        <Divider sx={{ marginY: 2 }} />

        {hasSubmitted === 'success' && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {totalUpdated} cash requests successfully issued
          </Alert>
        )}
        {error && <Error error={error} sx={{ mb: 2 }} />}

        <Typography variant="p">
          Are you sure you want to issue cash to {fieldAgents.length} field
          agents?
        </Typography>

        <Grid container mt={2}>
          <Grid item xs={4} sx={gsx}>
            Total issued will be
          </Grid>
          <Grid item xs={2} sx={{ ...gsx, textAlign: 'right' }}>
            {sum}
          </Grid>
        </Grid>
        {fieldAgents.map((fieldAgent) => {
          return (
            <Grid key={fieldAgent.uuid} container>
              <Grid item xs={2} sx={gsx}>
                {fieldAgent?.name}
              </Grid>
              <Grid item xs={2} sx={gsx}>
                {fieldAgent?.agents}{' '}
                {fieldAgent?.agents === 1 ? 'agent' : 'agents'}{' '}
              </Grid>
              <Grid item xs={2} sx={{ ...gsx, textAlign: 'right' }}>
                {fieldAgent.sum}
              </Grid>
            </Grid>
          );
        })}

        <Grid container sx={{ mt: 2 }}>
          <Grid item md={6}>
            <Divider sx={{ marginY: 2 }} />

            <DynamicAutoComplete
              options={makeUnique(
                [
                  ...floatManagerAccountList,
                  ...(cashAccount ? [cashAccount] : []),
                ],
                (x) => x?.uuid
              )}
              multiple={false}
              isOptionEqualToValue={(option, value) =>
                option?.uuid === value?.uuid
              }
              getOptionLabel={(option) => option?.bankName ?? ''}
              handleSearch={filterCashAccounts}
              renderOption={(props, cashAccount) => (
                <li {...props} key={cashAccount?.uuid}>
                  {cashAccount?.bankName ?? ''}
                </li>
              )}
              value={cashAccount}
              onChange={(value) => {
                setCashAccount(value);
              }}
              isLoading={floatManagerAccountListIsLoading}
              error={floatManagerAccountListError}
              required={true}
              renderInput={(value) => (params) => {
                // console.log('RXD:AdvanceCashForm:renderInput', { params });
                return (
                  <TextField {...params} value={value} label="Cash Account" />
                );
              }}
              helperText="This is a account for the source of the cash used for reconciliation and tracking"
            />
          </Grid>
        </Grid>

        <Divider sx={{ marginY: 2 }} />

        {status === 'loading' ? (
          <Loading />
        ) : (
          <Button
            variant="contained"
            type="submit"
            disabled={hasSubmitted === 'success'}
          >
            Disburse
          </Button>
        )}
      </Box>
    </Drawer>
  );
};

const DisburseFloatForm = ({ open, checked, closeDrawer, resetState }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const {
    userList,
    isLoading: userListIsLoading,
    error: userListError,
  } = useSelector((state) => {
    return state.userList;
  });
  const {
    status,
    totalUpdated,
    failedUpdates,
    agentThresholds,
    isLoadingThresholds,
    error,
  } = useSelector((state) => state.floatCashDisburse);
  const [requirePin, setRequirePin] = useState(false);
  const [flagError, setFlagError] = useState();
  const [pin, setPin] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [sum, setSum] = useState('');
  const [agents, setAgents] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState('');
  const [overFlowThresholds, setOverflowThresholds] = useState([]);
  const formRef = useRef();

  // console.log({ hasSubmitted, error, failedUpdates, status })

  useEffect(() => {
    const agentUuids = agents.map((agent) => agent.uuid);
    console.log('>>> Agent uuids: ', agentUuids);
    if (agentUuids.length > 0) dispatch(agentsThresholdFetch(agentUuids));
  }, [agents]);

  useEffect(() => {
    const checkedValuesArray = Array.from(checked._map.values());
    const checkedValuesMap = new Map(
      [].concat(...checkedValuesArray).map((value) => {
        return [
          value.agent.uuid,
          Object.assign({ agentUuid: value.agent?.uuid }, value),
        ];
      })
    );
    console.log('>>> checked values map: ', checkedValuesMap);
    const overFlowThresholds = []
      .concat(...agentThresholds)
      .map((value) => {
        const threshold = Object.assign({}, value);
        const checkedValue = checkedValuesMap.get(threshold.agent.uuid);
        if (
          checkedValue != null &&
          amountToNumberOrZero(threshold.dailyFloatRequestLimit) > 0
        ) {
          threshold.overflowAmount =
            amountToNumberOrZero(checkedValue.amount) +
            amountToNumberOrZero(threshold.totalFloatRequestToday) -
            amountToNumberOrZero(threshold.dailyFloatRequestLimit);
          threshold.requestUuid = checkedValue.uuid;
        } else {
          threshold.overflowAmount = 0;
        }
        return threshold;
      })
      .filter((value) => value.overflowAmount > 0);
    setOverflowThresholds(overFlowThresholds);
    console.log('>>> Overflow threshold: ', overFlowThresholds);
  }, [agentThresholds]);

  useEffect(() => {
    if (status === 'loading' && hasSubmitted !== 'loading') {
      setHasSubmitted('loading');
    } else if (status !== 'loading' && hasSubmitted === 'loading') {
      if (error || failedUpdates.length > 0) {
        setHasSubmitted('');
      } else if (failedUpdates.length === 0) {
        setHasSubmitted('success');
        setTimeout(() => {
          // console.log('DisburseFloatForm.setTimeout');
          setPin('');
          setPhoneNumber('');
          if (failedUpdates.length === 0) {
            setHasSubmitted('');
            resetState();
          }
        }, 3000);
      }
    }
  }, [error, failedUpdates, status]);

  const floatSettingSlug =
    'float_manager-enable_auto_sending_of_float_directly_to_agent';

  const handleSave = (e) => {
    console.log('>>> Saving float disbursal: ', e.target.id);
    e.preventDefault();
    let uuids;
    if (e.target.id === 'submit-primary') {
      uuids = checked.keys();
    } else {
      const overFlowRequestUuids = overFlowThresholds.map(
        (value) => value.requestUuid
      );
      uuids = Array.from(checked.keys()).filter(
        (value) => !overFlowRequestUuids.includes(value)
      );
    }
    if (uuids.length > 0) {
      dispatch(
        floatCashDisburseSave({
          uuids: uuids,
          status: 'DISBURSED',
          type: 'FLOAT',
          pin: requirePin ? pin : '0000',
          phoneNumber,
          cashAccountId: null,
        })
      );
      captureEvent('FLOAT_DISBURSED_SAVE', { type: 'float' });
    } else {
      window.alert('No safe requests to disburse');
    }
  };

  useEffect(() => {
    dispatch(
      usersFetch(
        new URLSearchParams(
          `pp=1:10&fa.uuid=${user.fieldAgentUuid}&phoneNumber=isNotEmpty:`
        )
      )
    );
    settingValueFetch(floatSettingSlug).then(setRequirePin).catch(setFlagError);
  }, [user]);

  useEffect(() => {
    let sum = 0;
    let currency = '';
    let agents = new Map();
    for (const key of checked.keys()) {
      const request = checked.get(key);
      sum += +request?.amount;
      currency = request?.currency;

      const uuid = request?.agent?.uuid;
      if (!agents.has(uuid)) {
        agents.set(uuid, {
          uuid,
          sum: 0,
          currency,
          requests: 0,
          failed: '',
          name: request?.agent?.agentName,
          fieldAgentName: request?.agent?.fieldAgent?.name,
          phoneNumber: request?.phoneNumber,
        });
      }
      const agent = agents.get(uuid);

      agent.requests += 1;
      agent.sum += +request?.amount;

      for (const failed of failedUpdates) {
        if (request?.uuid === failed?.uuid) {
          agent.failed += failed?.error + '. ';
        }
      }
    }
    const as = [];
    for (const agent of agents.values()) {
      agent.sum = `${currency} ${numberFormat(agent.sum)}`;
      as.push(agent);
    }
    as.sort((a, b) => a?.name?.localeCompare(b?.name));
    setSum(`${currency} ${numberFormat(sum)}`);
    setAgents(as);
  }, [checked, failedUpdates]);

  const gsx = { border: 1, borderColor: 'lightGray', p: 1 };

  // console.log('DisburseFloatForm', { hasSubmitted, requirePin });

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={(e) => {
        if (hasSubmitted === 'success') {
          resetState();
        } else {
          closeDrawer();
        }
      }}
    >
      <Box component="form" onSubmit={handleSave} p={2} ref={formRef}>
        {flagError && (
          <Error
            error={`Configuration error: ${flagError}`}
            onRetry={() =>
              settingValueFetch(floatSettingSlug)
                .then(setRequirePin)
                .catch(setFlagError)
            }
          />
        )}
        {error && <Error error={error} sx={{ mb: 2 }} />}
        {hasSubmitted === 'success' && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {totalUpdated} float requests successfully disbursed
          </Alert>
        )}
        <Typography variant="h5" mb={2}>
          Disburse Float
        </Typography>

        <Divider sx={{ marginY: 2 }} />

        <Grid container>
          <Grid item md>
            <Typography variant="p">
              Are you sure you want to disburse to {checked.size} agents?
            </Typography>

            <Grid container mt={2}>
              <Grid item xs={6} sx={gsx}>
                Total disbursed will be{' '}
              </Grid>
              <Grid item xs={1} sx={{ ...gsx, textAlign: 'right' }}>
                {sum}
              </Grid>
            </Grid>
            {agents.map((agent) => {
              return (
                <Box key={agent.uuid}>
                  {agent?.failed && (
                    <Grid container>
                      <Grid item xs={7} sx={gsx}>
                        <Typography sx={{ color: 'red' }}>
                          Disbursement was attempted for ({agent.sum},{' '}
                          {agent.name}, {agent.phoneNumber}) but failed because{' '}
                          {
                            <Typography fontWeight="bold">
                              {agent.failed}
                            </Typography>
                          }
                          . Please provide this information to the Opareta team
                          for resolution
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item xs={2} sx={gsx}>
                      {agent?.fieldAgentName}
                    </Grid>
                    <Grid item xs={2} sx={gsx}>
                      ({agent.requests}) {agent?.name}
                    </Grid>
                    <Grid item xs={2} sx={gsx}>
                      {agent?.phoneNumber}
                    </Grid>
                    <Grid item xs={1} sx={{ ...gsx, textAlign: 'right' }}>
                      {agent?.sum}
                    </Grid>
                  </Grid>
                </Box>
              );
            })}

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item md={4}>
                <DynamicSelect
                  sx={{ my: 2 }}
                  label="From Line"
                  helperText="Select the number to use to disburse"
                  options={userList}
                  renderOption={(user) => (
                    <MenuItem value={user?.phoneNumber} key={user?.phoneNumber}>
                      {user?.phoneNumber}
                    </MenuItem>
                  )}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  isLoading={userListIsLoading}
                  error={userListError}
                  required
                />
              </Grid>
              {requirePin && (
                <Grid item md={3}>
                  <FormControl fullWidth sx={{ my: 2 }}>
                    <TextField
                      id="pin"
                      label="PIN"
                      variant="outlined"
                      type="password"
                      required
                      value={pin}
                      autoComplete="new-password"
                      helperText="Enter your PIN"
                      onChange={(e) => setPin(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>

          {overFlowThresholds.length > 0 && (
            <Grid item md={4} direction="column">
              <Typography fontWeight="bold" color="warning.main">
                Warning! The following {overFlowThresholds.length}{' '}
                {toSingularPluralValueTitle(
                  overFlowThresholds.length,
                  'agent',
                  'agents'
                )}{' '}
                will be over their threshold:
              </Typography>
              <List>
                {overFlowThresholds.map((threshold) => {
                  return (
                    <ListItem sx={{ pl: 0, pr: 0 }}>
                      <Typography>
                        {threshold.agent?.agentName}{' '}
                        {threshold.agent?.simNumbers[0]?.phoneNumber} will be{' '}
                        {numberFormat(
                          amountToNumberOrZero(threshold.overflowAmount)
                        )}{' '}
                        over daily request threshold of{' '}
                        {amountToNumberOrZero(threshold.dailyFloatRequestLimit)}
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          )}
        </Grid>

        <Divider sx={{ marginY: 2 }} />

        {status === 'loading' || isLoadingThresholds ? (
          <Loading />
        ) : (
          <Stack direction="row">
            <Button
              variant="contained"
              id="submit-primary"
              color={overFlowThresholds.length > 0 ? 'warning' : 'primary'}
              type="submit"
              disabled={hasSubmitted === 'loading' || isLoadingThresholds}
              onClick={(e) => (formRef.current.id = e.target.id)}
            >
              {overFlowThresholds.length > 0 ? 'Disburse all' : 'Disburse'}
            </Button>
            {overFlowThresholds.length > 0 && (
              <Button
                variant="contained"
                type="submit"
                id="submit-secondary"
                disabled={hasSubmitted === 'loading' || isLoadingThresholds}
                onClick={(e) => (formRef.current.id = e.target.id)}
              >
                Disburse only safe requests
              </Button>
            )}
          </Stack>
        )}
      </Box>
    </Drawer>
  );
};

const FloatRequestModal = (props) => {
  const DIALING_CODE = import.meta.env?.VITE_DIALING_CODE ?? '';
  const CURRENCY = import.meta.env?.VITE_CURRENCY_CODE ?? '';
  const dispatch = useDispatch();
  const { open, handleClose } = props;
  const { user } = useSelector((state) => state.auth);
  const initialFormState = {
    requestType: 'FLOAT',
    status: 'APPROVED',
    amount: '',
    phoneNumber: null,
    currency: CURRENCY,
  };
  const [formData, setFormData] = useState(initialFormState);
  const { agent, error, isLoadingAgent, isSubmitting } = useSelector(
    (state) => state.floatRequestList
  );
  const handleSearch = useCallback(
    debounce((value) => dispatch(agentSearch(value)), 1000),
    []
  );

  useEffect(() => {
    if (!isSubmitting && open) {
      resetFormState();
      handleClose();
    }
  }, [isSubmitting]);

  const handleSave = (e) => {
    e.preventDefault();
    let _formData = formData;
    _formData.phoneNumber = getFullPhoneNumber(formData.phoneNumber);
    _formData.amount = amountToNumberOrZero(formData.amount);
    _formData.agent = {
      uuid: agent.uuid,
      agentName: agent.agentName,
    };
    _formData.user = user;
    dispatch(
      submitRequest({
        floatRequests: [_formData],
      })
    );
  };

  const handleOnChange = (title) => (e) => {
    let value = e.target.value;
    if (title === 'phoneNumber') {
      dispatch(setAgent({ agent: null }));
      const phoneNumber = getFullPhoneNumber(value);
      if (phoneNumber.length > 3) {
        dispatch(setLoadingAgent(true));
        handleSearch(getFullPhoneNumber(value));
      }
    }
    setFormData({ ...formData, [title]: value });
  };

  const getFullPhoneNumber = (value) => {
    return (value.startsWith('+') ? value : `${DIALING_CODE}${value}`).trim();
  };

  const resetFormState = () => {
    formData.requestType = 'FLOAT';
    formData.amount = '';
    formData.phoneNumber = null;
    formData.currency = CURRENCY;
    formData.status = 'APPROVED';
    dispatch(setAgent({ agent: null }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      component="form"
      method="POST"
      onSubmit={handleSave}
    >
      <DialogTitle>Request Float</DialogTitle>
      <DialogContent>
        {error && <Error error={error} />}
        <RadioGroup row>
          <FormControlLabel
            sx={{ mr: 8 }}
            control={
              <Radio
                checked={formData.requestType === 'FLOAT'}
                onChange={handleOnChange('requestType')}
                name="FLOAT"
                value="FLOAT"
              />
            }
            label="Float"
          />
          <FormControlLabel
            control={
              <Radio
                checked={formData.requestType === 'CASH'}
                onChange={handleOnChange('requestType')}
                value="CASH"
                name="CASH"
              />
            }
            label="Cash"
          />
        </RadioGroup>

        <FormControl fullWidth sx={{ mt: 3 }}>
          <TextField
            id="agentLine"
            label="Agent Line"
            variant="outlined"
            type="text"
            required
            value={formData.phoneNumber}
            onChange={handleOnChange('phoneNumber')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{DIALING_CODE}</InputAdornment>
              ),
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 3 }}>
          <TextField
            id="amount"
            label="Agent Amount"
            variant="outlined"
            type="number"
            required
            value={formData.amount}
            onChange={handleOnChange('amount')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{CURRENCY}</InputAdornment>
              ),
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 3 }}>
          <TextField
            id="agentName"
            label="Agent Name"
            variant="outlined"
            type="text"
            required
            value={agent?.agentName || ''}
            disabled
            InputProps={{
              endAdornment: isLoadingAgent && <Loading size={15} />,
            }}
          />
        </FormControl>
        {agent?.fieldAgent && (
          <FormControl fullWidth sx={{ mt: 3 }}>
            <TextField
              id="fieldAgentName"
              label="Field Agent Name"
              variant="outlined"
              type="text"
              required
              value={agent?.fieldAgent?.name || ''}
              disabled
            />
          </FormControl>
        )}
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Button onClick={handleClose} variant="contained" color="inherit">
          Cancel
        </Button>
        {isSubmitting ? (
          <Loading size={25} />
        ) : (
          <Button type="submit" variant="contained" disabled={!agent}>
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

class Checked {
  constructor() {
    this._map = new Map();
    this._keys = [];
  }

  get size() {
    return this._map.size;
  }

  keys() {
    return this._keys;
  }

  has(key) {
    return this._map.has(key);
  }

  set(key, value) {
    this._keys.push(key);
    return this._map.set(key, value);
  }

  delete(key) {
    const pos = this._keys.indexOf(key);
    if (pos > -1) {
      this._keys.splice(pos, 1);
    }
    return this._map.delete(key);
  }

  get(key) {
    return this._map.get(key);
  }

  clone() {
    return Object.assign(new Checked(), this);
  }
}
