import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  fieldAgentPosition: null,
  status: 'editing',
  error: '',
};

const fieldAgentPositionFormSlice = createSlice({
  name: 'fieldAgentPositionForm',
  initialState: initialState,
  reducers: {
    setFieldAgentPosition: (state, { payload }) => {
      state.fieldAgentPosition = payload;
    },
    resetState: (state) => {
      state.status = 'editing';
      state.error = '';
      // state.fieldAgentPosition = null;
    },
    setLoading: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.status = 'editing';
    },
  },
});

export const fieldAgentPositionSave = (id, data) => (dispatch) => {
  dispatch(setLoading(true));
  data = Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      value === '' ? null : value,
    ])
  );
  const action = id
    ? getApi().patch(`/api/field-agents/positions/${id}`, data)
    : getApi().post(`/api/field-agents/positions`, data);

  action
    .then((response) => {
      dispatch(setFieldAgentPosition(response.data));
      dispatch(resetState());
      // dispatch(push(`/field-agents/positions/${id ?? data.id}`));
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const fieldAgentPositionDelete = (id, newPositionId) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .delete(`/api/field-agents/positions/${id}/${newPositionId}`)
    .then((response) => {
      dispatch(setFieldAgentPosition(response.data));
      dispatch(resetState());
    })
    .catch((err) => {
      dispatch(setError(getApiError(err)));
    });
};

export const { setFieldAgentPosition, resetState, setLoading, setError } =
  fieldAgentPositionFormSlice.actions;

export const fieldAgentPositionFormReducer =
  fieldAgentPositionFormSlice.reducer;
