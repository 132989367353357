import { createSlice } from '@reduxjs/toolkit';
import { getApi } from '@/common';

const initialState = {
  agent: null,
  isLoading: false,
  error: '',
};

const agentSlice = createSlice({
  name: 'agent',
  initialState: initialState,
  reducers: {
    setAgent: (state, { payload }) => {
      state.agent = payload;
      state.isLoading = false;
      state.error = '';
    },
    resetState: (state) => {
      state.agent = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const agentFetch = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/agents/${uuid}`)
    .then((response) => dispatch(setAgent(response.data)))
    .catch((err) =>
      dispatch(setError(`${err.message} - ${err.response.statusText}`))
    );
};
export const agentEdit = (uuid) => (dispatch) => {
  dispatch(setLoading());
  getApi()
    .patch(`/api/agents/${uuid}`, { isVerified: true })
    .then((response) => dispatch(setAgent(response.data)))
    .catch((err) =>
      dispatch(setError(`${err.message} - ${err.response.statusText}`))
    );
};

export const { setAgent, resetState, setLoading, setError } =
  agentSlice.actions;

export const agentReducer = agentSlice.reducer;
